import http from "./httpMiddleWare";
import { ACCESS_POINT } from "../config";

const getMaster = async (tableName, adminId) => {
  const result = await http.get(
    ACCESS_POINT + `/master/${tableName}?adminId=${adminId}`
  );
  return result;
};

const createMaster = async (tableName, data) => {
  const result = await http.post(ACCESS_POINT + `/master/${tableName}`, data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return result;
};

const getMasterById = async (tableName, id) => {
  const result = await http.get(ACCESS_POINT + `/master/${tableName}/${id}`);
  return result;
};

const updateMaster = async (tableName, id, data) => {
  const result = await http.put(
    ACCESS_POINT + `/master/${tableName}/${id}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const updateMastervalue = async (tableName, id, data) => {
  console.log(tableName)
  console.log(id)
  console.log(data)
  console.log(`/hr/updatevalue/${tableName}/${id}`)
  const result = await http.put(
    ACCESS_POINT + `/hr/updatevalue/${tableName}/${id}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};



const updateMasterObj = async (tableName, id, data) => {
  const result = await http.put(
    ACCESS_POINT + `/master/${tableName}/${id}`,
    data
  );
  return result;
};
const deleteMaster = async (tableName, id) => {
  const result = await http.delete(ACCESS_POINT + `/master/${tableName}/${id}`);
  return result;
};

const DeleteMasterFromTable = async (tableName, id) => {
  const result = await http.delete(ACCESS_POINT + `/master/DeleteMasterFromTable/${tableName}/${id}`);
  return result;
};

const selectBoxCompetency = async (matrixType, adminId) => {
  const result = await http.get(
    ACCESS_POINT + `/master/selectBoxCompetency/${matrixType}/${adminId}`
  );
  return result;
};

const selectBoxTrainingProgram = async (adminId) => {
  // console.log(adminId,"adminId-2");
  const result = await http.post(
    ACCESS_POINT + `/master/selectBoxTrainingProgram/${adminId}`
  );
  return result;
};

const selectBoxDepartment = async (tableName, adminId) => {
  const result = await http.get(
    ACCESS_POINT + `/master/selectBoxDepartment/${tableName}/${adminId}`
  );
  return result;
};

const selectBoxUserType = async (tableName, adminId) => {
  const result = await http.get(
    ACCESS_POINT + `/master/selectBoxUserType/${tableName}/${adminId}`
  );
  return result;
};

const selectBoxTheme = async (textType, adminId, data) => {
  // console.log(data, "data");
  const result = await http.get(
    ACCESS_POINT + `/master/selectBoxTheme/${textType}/${adminId}`, data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const insertMuldidata = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/master/insertMuldidata/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const insertMuldidatahr = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/hr/insertMuldidata/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const tbl_cmpLevel = async adminId => {
  const result = await http.get(
    ACCESS_POINT + `/master/table/tbl_cmpLevel/${adminId}`
  );
  return result;
};

const selectBoxLevel = async (competencyId, adminId) => {
  const result = await http.get(
    ACCESS_POINT + `/master/selectBoxLevel/${competencyId}/${adminId}`
  );
  return result;
};

const tbl_indicators = async adminId => {
  const result = await http.get(
    ACCESS_POINT + `/master/table/tbl_indicators/${adminId}`
  );
  return result;
};

const getSelectBoxMaster = async (adminId, tableName, forId, forvalue) => {
  const result = await http.get(
    ACCESS_POINT +
    `/master/selectMaster/${adminId}/${tableName}/${forId}/${forvalue}`
  );
  return result;
};

const getJobDescription = async (tableName, adminId) => {
  const result = await http.get(
    ACCESS_POINT + `/master/table/getJobDescription/${tableName}/${adminId}`
  );
  return result;
};

const getThemeForSelect = async (adminId, textType) => {
  const result = await http.get(
    ACCESS_POINT + `/master/getThemes/${textType}/${adminId}`
  );
  return result;
};

const checkEmail = async (tableName, email) => {
  const result = await http.get(
    ACCESS_POINT + `/master/emailCheck/${tableName}/${email}`
  );
  return result;
};

const checkMobile = async (tableName, mobile) => {
  const result = await http.get(
    ACCESS_POINT + `/master/mobileCheck/${tableName}/${mobile}`
  );
  return result;
};

const checkUserName = async (tableName, userName) => {
  const result = await http.get(
    ACCESS_POINT + `/master/checkUserName/${tableName}/${userName}`
  );
  return result;
};

const getSelectBoxEmployeeMaster = async (
  adminId,
  tableName,
  forId,
  forvalue
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/master/getSelectBoxEmployeeMaster/${adminId}/${tableName}/${forId}/${forvalue}`
  );
  return result;
};
const updateGoal = async (data, goalID) => {
  const result = await http.post(
    `${ACCESS_POINT}/master/update/updateGoal/${goalID}`,
    data
  );
  return result;
};

const csvUpload = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/master/csvUpload/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const csvUploadhr = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/hr/csvUploadhr/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const gettrainingprgmmap = async (tableName, adminId) => {
  const result = await http.post(
    ACCESS_POINT + `/master/gettrainingprgmmap/${tableName}?adminId=${adminId}`
  );
  return result;
};

const trainerNames = async (adminId) => {
  const result = await http.post(
    ACCESS_POINT + `/master/trainerNames/${adminId}`
  );
  return result;
};

const trainerLocation = async (adminId) => {
  const result = await http.post(
    ACCESS_POINT + `/master/trainerLocation/${adminId}`
  );
  return result;
};

const Coursemapwithresources = async (adminId) => {
  // console.log(adminId,"adminId-2");
  const result = await http.post(
    ACCESS_POINT + `/master/Coursemapwithresources/${adminId}`
  );
  return result;
};

const Trainermapwithlocation = async (adminId) => {
  // console.log(adminId,"adminId-2");
  const result = await http.post(
    ACCESS_POINT + `/master/Trainermapwithlocation/${adminId}`
  );
  return result;
};

export default {
  csvUploadhr,
  csvUpload,
  getMaster,
  createMaster,
  getMasterById,
  updateMaster,
  deleteMaster,
  selectBoxCompetency,
  selectBoxTrainingProgram,
  selectBoxDepartment,
  selectBoxUserType,
  selectBoxTheme,
  insertMuldidata,
  insertMuldidatahr,
  tbl_cmpLevel,
  selectBoxLevel,
  tbl_indicators,
  getSelectBoxMaster,
  getJobDescription,
  getThemeForSelect,
  checkEmail,
  checkMobile,
  checkUserName,
  getSelectBoxEmployeeMaster,
  updateMasterObj,
  updateGoal,
  updateMastervalue,
  DeleteMasterFromTable,
  gettrainingprgmmap,
  trainerNames,
  trainerLocation,
  Coursemapwithresources,
  Trainermapwithlocation
};
