import React from "react";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import image from "../../image/18-128.png";

const CompetencyReport = ({ section1Data, section2Data, compList, Behavioralpdf, Technicalpdf, data, jobRoleName, jobdescription }) => {
  console.log(jobdescription)
  let a1 = [];
  let a2 = [];
  let arr = [];
  section1Data.map((ival, i) => {
    compList.map((jval, j) => {
      if (ival.cmpid === jval.competencyid) {
        arr.push({
          theme: jval.theme,
          comp: jval.competencyname,
          self: ival.self,
          peer: ival.peer,
          repo: ival.reportee,
          sup: ival.supervisor,
          cus: ival.customer,
          avg: ival.avg,
          seComment: ival.seComment,
          prComment: ival.prComment,
          reComment: ival.reComment,
          spComment: ival.spComment,
          csComment: ival.csComment
        });
      }
    });
  });
  let array1 = [];
  section1Data.map((ival, i) => {
    compList.map((jval, j) => {
      if (ival.cmpid === jval.competencyid) {
        array1.push({
          comp: jval.competencyname,
          self: ival.self,
          peer: ival.peer,
          repo: ival.reportee,
          sup: ival.supervisor,
          cus: ival.customer,
          avg: ival.avg
        });
      }
    });
  });
  let array2 = [];
  section2Data.map((ival, i) => {
    compList.map((jval, j) => {
      if (ival.supervisor === jval.type) {
        array2.push({
          theme: jval.theme,
          comp: jval.competencyname,
          self: ival.self,
          peer: ival.peer,
          repo: ival.reportee,
          sup: ival.supervisor,
          cus: ival.customer,
          avg: ival.avg,
          ind: ival.indicators
        });
      }
    });
  });
  let scr = [];
  scr.push(
    <View style={s.row}>
      <View style={s.S3column2}>
        <Text style={s.columnHeading}>0</Text>
      </View>
      <View style={s.S3column2}>
        <Text style={s.columnHeading}>1</Text>
      </View>
      <View style={s.S3column2}>
        <Text style={s.columnHeading}>2</Text>
      </View>
      <View style={s.S3column2}>
        <Text style={s.columnHeading}>3</Text>
      </View>
      <View style={s.S3column2}>
        <Text style={s.columnHeading}>4</Text>
      </View>
      <View style={s.S3column2}>
        <Text style={s.columnHeading}>5</Text>
      </View>
    </View>
  );

  let arr1 = [];
  let a = [];
  let avgArr = [];
  let avgCus = [];
  let AV = [];
  let arrComp = [];
  let avgPe = [];
  let avgSup = [];
  let avgRe = [];
  let avgSe = [];
  let compi = [];
  let ch = [];
  let ra = [];
  let as = [];

  let selfTick = [];
  let repTick = [];

  section1Data.map((ival, index) => {
    let tickerSelf = [];
    for (let i = 0; i < 6; i++) {
      if (Math.round(ival.self) === i) {
        tickerSelf.push(
          <View style={s.S3column2}>
            <Image style={{ width: 10 }} src={image}></Image>
          </View>
        );
      } else if (isNaN(ival.self)) {
        tickerSelf.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      } else {
        tickerSelf.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      }
    }
    selfTick.push(<View style={s.row}>{tickerSelf}</View>);
  });
  section1Data.map((ival, index) => {
    let tickerRep = [];
    for (let i = 0; i < 6; i++) {
      if (Math.round(ival.reportee) === i) {
        tickerRep.push(
          <View style={s.S3column2}>
            <Image style={{ width: 10 }} src={image}></Image>
          </View>
        );
      } else if (isNaN(ival.reportee)) {
        tickerRep.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      } else {
        tickerRep.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      }
    }
    repTick.push(<View style={s.row}>{tickerRep}</View>);
  });
  let superTick = [];
  section1Data.map((ival, index) => {
    console.log(ival);
    let tickerSup = [];
    for (let i = 0; i < 6; i++) {
      if (Math.round(ival.supervisor) === i) {
        tickerSup.push(
          <View style={s.S3column2}>
            <Image style={{ width: 10 }} src={image}></Image>
          </View>
        );
      } else if (isNaN(ival.supervisor)) {
        tickerSup.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      } else {
        tickerSup.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      }
    }
    superTick.push(<View style={s.row}>{tickerSup}</View>);
  });
  let peerTick = [];
  section1Data.map((ival, index) => {
    console.log(ival);
    let tickerPeer = [];
    for (let i = 0; i < 6; i++) {
      if (Math.round(ival.peer) === i) {
        tickerPeer.push(
          <View style={s.S3column2}>
            <Image style={{ width: 10 }} src={image}></Image>
          </View>
        );
      } else if (isNaN(ival.peer)) {
        tickerPeer.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      } else {
        tickerPeer.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      }
    }
    peerTick.push(<View style={s.row}>{tickerPeer}</View>);
  });
  let cusTick = [];
  section1Data.map((ival, index) => {
    let tickerCus = [];
    for (let i = 0; i < 6; i++) {
      if (Math.round(ival.customer) === i) {
        tickerCus.push(
          <View style={s.S3column2}>
            <Image style={{ width: 10 }} src={image}></Image>
          </View>
        );
      } else if (isNaN(ival.customer)) {
        tickerCus.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      } else {
        tickerCus.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      }
    }
    cusTick.push(<View style={s.row}>{tickerCus}</View>);
  });
  let avgTick = [];
  section1Data.map((ival, index) => {
    let tickerAvg = [];
    for (let i = 0; i < 6; i++) {
      if (Math.round(ival.avg) === i) {
        tickerAvg.push(
          <View style={s.S3column2}>
            <Image style={{ width: 10 }} src={image}></Image>
          </View>
        );
      } else if (isNaN(ival.avg)) {
        tickerAvg.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      } else {
        tickerAvg.push(
          <View style={s.S3column2}>
            <Text style={s.S3columnHeading}>-</Text>
          </View>
        );
      }
    }
    avgTick.push(<View style={s.row}>{tickerAvg}</View>);
  });

  return (
    <Document>



      <Page size="A4"   style={{backgroundColor:"#EEEDE7"}}>


        <View style={s.border}>
          <View>
            {data && data.image &&  `https://compexp.difuza.com/getpro/getprofile?fileurl=` +data.image &&
            <Image
              style={s.image}
              src={
                `https://compexp.difuza.com/getpro/getprofile?fileurl=` +
                data.image
              }
            />
            }
          </View>
          <View>
            <Text style={{ textAlign: "center", marginBottom: 20 }} >Competency Report of {data.name}</Text>
          </View>
          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Employee Name</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.name}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Age</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.age}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Email Address</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.email_id}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Telphone Number</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.telphone}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Mobile Number</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.mobile_no}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Qualification</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.qualification}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Location</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.address}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Job Role</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{jobRoleName}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Job Description</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}> : </Text>
            </View>
            <View style={s.section_3}>
              <Text style={s.fullrow}>{jobdescription}</Text>
            </View>
          </View>
        </View>


      </Page>

      <Page size="A4" style={{backgroundColor:"#EEEDE7"}}>
        <View>
          <View style={s.row}>
            <Text style={[s.center, s.marginT20]}>
              Section 1 - Summary of Rating
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.row}>
              <View style={styles.idpHead}>
                <Text style={s.columnHeading}>Theme</Text>
              </View>
              <View style={styles.idpHead}>
                <Text style={s.columnHeading}>Competency</Text>
              </View>
              <View style={styles.idpHead}>
                <Text style={s.columnHeading}>Rating</Text>
              </View>
              {/* <View style={s.column}>
              <Text style={s.columnHeading}>Comments</Text>
            </View> */}
            </View>
            <View style={s.row}>
              <View style={styles.column2}>
                <Text style={s.columnHeading}>{""}</Text>
              </View>
              <View style={styles.column2}>
                <Text style={s.columnHeading}>{""}</Text>
              </View>
              <View style={s.row}>
                <View style={styles.column2}>
                  <Text style={s.columnHeading}>SE</Text>
                </View>
                <View style={styles.column2}>
                  <Text style={s.columnHeading}>SP</Text>
                </View>
                <View style={styles.column2}>
                  <Text style={s.columnHeading}>PR</Text>
                </View>
                <View style={styles.column2}>
                  <Text style={s.columnHeading}>RE</Text>
                </View>
                <View style={styles.column2}>
                  <Text style={s.columnHeading}>CS</Text>
                </View>
                <View style={styles.column2}>
                  <Text style={s.columnHeading}>AVG</Text>
                </View>
              </View>
              {/* <View style={s.column}>
                <Text style={s.columnHeading}>{""}</Text>
              </View> */}
            </View>
            <View>
              {arr &&
                arr.map((ival, index) => {
                  // if (ival.self !== '-') {
                  //   ival.self = Math.round(ival.self);
                  // }
                  // if (ival.sup !== '-') {
                  //   ival.sup = Math.round(ival.sup);
                  // }
                  // if (ival.peer !== '-') {
                  //   ival.peer = Math.round(ival.peer);
                  // }
                  // if (ival.repo !== '-') {
                  //   ival.repo = Math.round(ival.repo);
                  // }
                  // if (ival.cus !== '-') {
                  //   ival.cus = Math.round(ival.cus);
                  // }
                  // if (ival.avg !== '-') {
                  //   ival.avg = Math.round(ival.avg);
                  // }
                  return (
                    <View>
                      <View style={styles.row}>
                        <View style={styles.idpHead}>
                          <Text style={s.comment}>{ival.theme}</Text>
                        </View>
                        <View style={styles.idpHead}>
                          <Text style={s.comment}>{ival.comp}</Text>
                        </View>
                        <View style={s.row}>
                          <View style={styles.column3}>
                            <Text style={[s.comment, s.leftS1]}>{ival.self}</Text>
                          </View>
                          <View style={styles.column3}>
                            <Text style={[s.comment, s.leftS1]}>{ival.sup}</Text>
                          </View>
                          <View style={styles.column3}>
                            <Text style={[s.comment, s.leftS1]}>{ival.peer}</Text>
                          </View>
                          <View style={styles.column3}>
                            <Text style={[s.comment, s.leftS1]}>{ival.repo}</Text>
                          </View>
                          <View style={styles.column3}>
                            <Text style={[s.comment, s.leftS1]}>{ival.cus}</Text>
                          </View>
                          <View style={styles.column3}>
                            <Text style={[s.comment, s.leftS1]}>
                              {Math.round(ival.avg)}
                            </Text>
                          </View>
                        </View>
                        <View style={s.cmtCol}>
                          {/* {ival.seComment &&
                        ival.seComment.map((kval, k) => {
                          return (
                            <View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  {kval.commentedby}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Area : {kval.area}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Strength : {kval.strength}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      <View style={s.row} />
                      {ival.prComment &&
                        ival.prComment.map((kval, k) => {
                          return (
                            <View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  {kval.commentedby}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Area : {kval.area}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Strength : {kval.strength}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      <View style={s.row} />
                      {ival.reComment &&
                        ival.reComment.map((kval, k) => {
                          return (
                            <View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  {kval.commentedby}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Area : {kval.area}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Strength : {kval.strength}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      <View style={s.row} />
                      {ival.spComment &&
                        ival.spComment.map((kval, k) => {
                          return (
                            <View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  {kval.commentedby}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Area : {kval.area}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Strength : {kval.strength}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      <View style={s.row} />
                      {ival.csComment &&
                        ival.csComment.map((kval, k) => {
                          return (
                            <View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  {kval.commentedby}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Area : {kval.area}
                                </Text>
                              </View>
                              <View style={s.row}>
                                <Text style={s.comment}>
                                  Strength : {kval.strength}
                                </Text>
                              </View>
                            </View>
                          );
                        })} */}
                        </View>
                      </View>
                      <View style={styles.row}>
                        <View style={s.column}>
                          <Text style={s.columnHeading}>{"Comments"}</Text>
                        </View>
                      </View>
                      <View style={styles.row}>
                        {ival.csComment &&
                          ival.csComment.map((kval, k) => {
                            return (
                              <View style={s.row}>
                                <View style={styles.idpHead}>
                                  <Text style={s.comment}>
                                    {kval.commentedby}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Area :   {kval.area}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Strength :   {kval.strength}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                      </View>
                      <View style={styles.row}>
                        {ival.spComment &&
                          ival.spComment.map((kval, k) => {
                            return (
                              <View style={s.row}>
                                <View style={styles.idpHead}>
                                  <Text style={s.comment}>
                                    {kval.commentedby}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Area :   {kval.area}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Strength :   {kval.strength}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                      </View>
                      <View style={styles.row}>
                        {ival.seComment &&
                          ival.seComment.map((kval, k) => {
                            return (
                              <View style={s.row}>
                                <View style={styles.idpHead}>
                                  <Text style={s.comment}>
                                    {kval.commentedby}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Area :   {kval.area}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Strength :   {kval.strength}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                      </View>
                      <View style={styles.row}>
                        {ival.reComment &&
                          ival.reComment.map((kval, k) => {
                            return (
                              <View style={s.row}>
                                <View style={styles.idpHead}>
                                  <Text style={s.comment}>
                                    {kval.commentedby}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Area :   {kval.area}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Strength :   {kval.strength}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                      </View>
                      <View style={styles.row}>
                        {ival.prComment &&
                          ival.prComment.map((kval, k) => {
                            return (
                              <View style={s.row}>
                                <View style={styles.idpHead}>
                                  <Text style={s.comment}>
                                    {kval.commentedby}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Area :   {kval.area}
                                  </Text>
                                </View>
                                <View style={styles.idpHead}>
                                  <Text style={[s.comment, { textAlign: "left", paddingLeft: '5px',paddingRight:"5px" }]}>
                                    Strength :   {kval.strength}
                                  </Text>
                                </View>
                              </View>
                            );
                          })}
                      </View>
                    </View>
                  );
                })}
            </View>
          </View>
        </View>
      </Page>
      {/*Page Two*/}


      <Page size="A4" style={{backgroundColor:"#EEEDE7"}}>
        <View >
          <View  >

            <View style={s.row}>
              <Text style={[s.center, s.marginT20]}>
                Section 2 - Total Rating Average Report
              </Text>
            </View>
            <View style={styles.table}>

              <View style={[styles.row]}>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Theme</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Competency</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Indicators</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Self</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Customer</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Peer</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Reportee</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Supervisor</Text>
                </View>
                <View style={styles.idpHead1}>
                  <Text style={s.columnHeading_row}>Avg</Text>
                </View>
              </View>
              {/* <View style={[styles.row]}>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
                <View style={s.column}>
                  <Text style={s.columnHeading}>{""}</Text>
                </View>
              </View> */}
              <View>
                {section2Data &&
                  section2Data.map((ival, index) => {
                    // console.log(ival);
                    return (
                      <View style={styles.row}>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.theme}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.compname}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.indicators}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.self}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.customer}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.peer}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.reportee}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.supervisor}</Text>
                        </View>
                        <View style={styles.idpHead1}>
                          <Text style={s.comment}>{ival.avg}</Text>
                        </View>
                      </View>
                    );
                  })}
              </View>
            </View>

          </View>
        </View >
      </Page>


      <Page size="A4" style={{backgroundColor:"#EEEDE7"}}>
        <View >


          <View style={s.row}>
            <Text style={[s.center, s.marginT20]}>
              Section 3  Derailers
            </Text>
          </View>


          <View style={styles.table}>
            <View style={styles.row}>
              <View style={[styles.section, styles.idpHead3]}>
                {/* <Text style={{ width: '50%' }} > </Text> */}
                <View >
                  <Text style={{ fontSize: 14, textAlign: 'center', color: 'red' }} > Behavioral Derailers </Text>
                </View>

                {/* {Behavioralpdf &&
                  Behavioralpdf.map(item => {
                    return (

                      <Text style={{ fontSize: 10, marginTop: 10 }}>  #     {item}</Text>

                    );
                  })} */}

              </View>
              <View style={[styles.section, styles.idpHead3]}>
                {/* <Text style={{ width: '50%' }}> </Text> */}
                <View >
                  <Text style={{ fontSize: 14, textAlign: 'center', color: 'red' }} > Technical Derailers </Text>
                </View>

                {/* {Technicalpdf &&
                  Technicalpdf.map(item => {
                    return (

                      <Text style={{ fontSize: 10, marginTop: 10 }}>  #     {item}</Text>

                    );
                  })} */}
              </View>

            </View>
            <View style={styles.row}>
              <View style={[styles.section, styles.idpHead3]}>
                {/* <Text style={{ width: '50%' }} > </Text>
                <View>
                  <Text style={{ fontSize: 14 }} > Behavioral Derailers </Text>
                </View> */}

                <View >
                  {Behavioralpdf &&
                    Behavioralpdf.map(item => {
                      return (
                        <Text style={{ fontSize: 10, marginTop: 10, marginLeft: 20 }}>  #     {item}</Text>
                      );
                    })}
                </View>

              </View>
              <View style={[styles.section, styles.idpHead3]}>
                {/* <Text style={{ width: '50%' }}> </Text>
                <View>
                  <Text style={{ fontSize: 14 }} > Technical Derailers </Text>
                </View> */}

                <View >
                  {Technicalpdf &&
                    Technicalpdf.map(item => {
                      return (
                        <Text style={{ fontSize: 10, marginTop: 10, marginLeft: 20 }}>  #     {item}</Text>
                      );
                    })}
                </View>
              </View>

            </View>
          </View>
        </View>

      </Page>

      <Page size="A4" style={{backgroundColor:"#EEEDE7"}}>
        <View>
          <View style={s.row}>
            <Text style={[s.center, s.marginT20]}>
              Section 4 - Frequency Distribution Report
            </Text>
          </View>
          <View style={styles.table}>

            <View style={styles.row}>
              <View style={[styles.idpHead2]}>
                <Text style={s.S3Subcol}>Competency Name </Text>
                <View style={s.space} />
                {/* <View style={s.space} /> */}
                {/* {section1Data &&
                  section1Data.map((ival, i) => {
                    return <Text style={s.S3columnHeading}>{ival.comp}</Text>;
                  })} */}
              </View>
              <View style={[styles.idpHead2]}>
                <Text style={s.S3Subcol}>Self</Text>
                <View style={s.space} />
                {scr}
                {/* <View style={s.space} /> */}
                {/* {selfTick} */}
              </View>
              <View style={[styles.idpHead2]}>
                <Text style={s.S3Subcol}>Reportees</Text>
                <View style={s.space} />
                {scr}
                {/* <View style={s.space} /> */}
                {/* {repTick} */}
              </View>
              <View style={[styles.idpHead2]}>
                <Text style={s.S3Subcol}>Supervisior</Text>
                <View style={s.space} />
                {scr}
                {/* <View style={s.space} /> */}
                {/* {superTick} */}
              </View>
              <View style={[styles.idpHead2]}>
                <Text style={s.S3Subcol}>Peer</Text>
                <View style={s.space} />
                {scr}
                {/* <View style={s.space} /> */}
                {/* {peerTick} */}
              </View>
              <View style={[styles.idpHead2]}>
                <Text style={s.S3Subcol}>Customer</Text>
                <View style={s.space} />
                {scr}
                {/* <View style={s.space} /> */}
                {/* {cusTick} */}
              </View>
              <View style={[styles.idpHead2]}>
                <Text style={s.S3Subcol}>Average</Text>
                <View style={s.space} />
                {scr}
                {/* <View style={s.space} /> */}
                {/* {avgTick} */}
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.idpHead2]}>
                {section1Data &&
                  section1Data.map((ival, i) => {
                    return <Text style={s.S3columnHeading}>{ival.comp}</Text>;
                  })}
              </View>
              <View style={[styles.idpHead2]}>
                {selfTick}
              </View>
              <View style={[styles.idpHead2]}>
                {repTick}
              </View>
              <View style={[styles.idpHead2]}>
                {superTick}
              </View>
              <View style={[styles.idpHead2]}>
                {peerTick}
              </View>
              <View style={[styles.idpHead2]}>
                {cusTick}
              </View>
              <View style={[styles.idpHead2]}>
                {avgTick}
              </View>
            </View>
          </View>
          <View style={s.row}>{as}</View>
        </View>
      </Page>
    </Document>
  );
};

const s = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row"
  },
  row_1: {
    flexDirection: "row",
    borderCollapse: "separate"
  },
  column: {
    margin: 5,
    // marginLeft: 10,
    width: "15%",
    flex: 1,
    textAlign: "center",
    flexDirection: "column"
  },
  column1: {
    margin: 5,
    width: "30%",
    flex: 1,
    flexDirection: "column",
    textAlign: "center"
  },
  column2: {
    margin: 0,
    width: "5%",
    flex: 1,
    flexDirection: "column"
  },
  center: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center"
  },
  marginT20: {

    margin: '20px 0px 20px 0px'
  },
  columnHeading: {
    color: "red",
    fontSize: 7,
    textAlign: "center"
  },
  columnHeading_row: {
    color: "red",
    fontSize: 10,
    textAlign: "center"
  },
  rowData: {
    fontSize: 10,
    margin: 0,
    width: "15%"
  },
  rowData1: {
    fontSize: 10,
    margin: 0,
    marginLeft: 20
  },
  columData: {
    margin: 0,
    fontSize: 10,
    width: "15%"
  },
  left: {
    marginLeft: 5,
    width: "auto"
  },
  comment: {
    fontSize: 9,
    textAlign: "center"
  },
  cmtCol: {
    // left: 47,
    width: "auto",
    flex: 1,
    flexDirection: "column"
  },
  S3colHeading: {
    margin: 5,
    marginLeft: 10,
    width: "14.28%",
    flex: 1,
    flexDirection: "column"
  },
  S3column2: {
    margin: 0,
    width: "2.21%",
    flex: 1,
    flexDirection: "column"
  },
  S3Subcol: {
    textAlign: "center",
    color: "red",
    fontSize: 10
  },
  space: {
    padding: 5
  },
  S3colHeadingram1: {
    margin: 5,
    marginLeft: 10,
    width: "85.71%",
    flex: 1,
    flexDirection: "column"
  },
  sec2comment: {
    fontSize: 10,
    marginLeft: 15,
    textAlign: "right"
  },
  s2comment: {
    fontSize: 10,
    textAlign: "center"
  },
  top: {
    top: 5
  },
  leftS1: {
    left: -6,
    top: 5
  },
  S3columnHeading: {
    fontSize: 8,
    textAlign: "center"
  },
  section: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 1
  },
  borderstyle: {
    borderLeft: '1px solid'
  },
  image: {
    height: 50,
    width: 50,
    borderRadius: 100,
    top: 95,
    left: 18
  },
  color: {
    color: "#2D5986",
    fontSize: 10,
    width: "25%",
    marginLeft: 75
  },
  d: {
    fontSize: 10,
    width: "25%",
    marginLeft: -45
  },
  dot: {
    fontSize: 10,
    width: "1%",
    marginLeft: 20
  },
  border: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    paddingBottom: 123,
    marginBottom: 123
  },
  topHead: {


    color: "white",
    fontSize: 12,

  },
  section_3: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 3,
    width: "80%",
    marginLeft: 30
  },
  fullrow: {
    position: "absolute",
    fontSize: 9,
    width: "80%",
    flexGrow: 3
  },


});

const styles = StyleSheet.create({
  row: {
    flexDirection: "row"
  },
  column: {
    margin: 5,
    // marginLeft: 10,
    width: "33.3%",
    flex: 1,
    textAlign: "center",
    flexDirection: "column",
    borderRightColor: '#000',
    borderRightWidth: 1,
  },
  column1: {
    margin: 5,
    width: "30%",
    flex: 1,
    flexDirection: "column",
    textAlign: "center"
  },
  column2: {
    margin: 0,
    marginLeft: -1.7,
    width: "5%",
    flex: 1,
    flexDirection: "column",
    borderRightColor: '#000',
    borderRightWidth: 1,
  },
  column3: {
    // margin: 0,
    width: "30%",
    flex: 2,
    flexDirection: "column",
    borderRightColor: '#000',
    borderRightWidth: 1,
    paddingRight: '16px',
    textAlign: 'center',
    paddingLeft: '16px',

  },
  columnHeading: {
    color: "red",
    fontSize: 7,
    textAlign: "center"
  },
  section: {
    position: "relative",
    // margin: 10,
    padding: 0,
    flexGrow: 1
  },
  idpHead: {
    width: "33.3%",
    // fontSize: 10,
    // color: "#2D5986",
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'

  },
  idpHead1: {
    width: "11.1%",
    // fontSize: 10,
    // color: "#2D5986",
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'

  },
  idpHead2: {
    width: "14.3%",
    // fontSize: 10,
    // color: "#2D5986",
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'

  },
  idpHead3: {
    width: "50%",
    // fontSize: 10,
    // color: "#2D5986",
    borderRightColor: '#000',
    borderRightWidth: 1,
    // textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: '10px'

  },
  idpdata: {
    width: "12.5%",
    fontSize: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    // padding: '4px'
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    width: 'auto'
  },
  cell: {
    width: "12.5%",
    fontSize: 10,
    color: "#000",
    borderRightColor: '#000',
    borderRightWidth: 1,
    // width: 'auto',
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '3px'

    // height: 'auto'
  },

})

export default CompetencyReport;
