import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import AdminHeader from '../screens/Header/AdminHeader';
import Login from '../screens/Login/Login';
import UserIdex from '../screens/Login/Userindex';

class LoginRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path={'/'} render={props => <Login {...props} />} />
        <Route exact path={'/userindex'} render={props => <UserIdex {...props} />} />
      </React.Fragment>
    );
  }
}

export default LoginRoutes;
