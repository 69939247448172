import React, { Component } from 'react';
import LoginModal from '../../components/Modal/Modal';
import HrMiddleWare from '../../middleWare/HrMiddleWare';
import { SingleSelect, FormMiddleWare } from '../../components/Form';
import ValidationView from '../../components/Form/ValidationView';
import './framwork.css';
var atob = require('atob');

class NewdictionaryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: atob(window.location.href.split('?')[1])
    };
  }
  async componentWillMount() {
    let data = this.state.datas.split(',');
    console.log(data);
    try {
      let indiList = await HrMiddleWare.getRandomData(
        'tbl_cmpLevel,tbl_indicators,ClusterMaster',
        `ClusterMaster.CmpId=tbl_cmpLevel.CmpId and tbl_cmpLevel.CmpId=${data[1]} and tbl_indicators.themeId=tbl_cmpLevel.id `,
        'tbl_cmpLevel.Level,tbl_cmpLevel.id,tbl_indicators.themeId,tbl_indicators.indicators'
      );
      console.log(indiList.data);
      this.setState({ indiList: indiList.data, data });
    } catch (error) {
      console.log(error);
    }
  }
  content = () => {
    let { indiList } = this.state;
    let content = [];
    let content1 = [];
    let content2 = [];
    let content3 = [];
    let content4 = [];
    indiList.map((ival, i) => {
      // console.log(i);
      // console.log(ival);
      // console.log(ival.Level.split('-')[1] )
      if (ival.Level.split('-')[1] == 1) {
        content1.push(
          <li style={{ color: '#c13c3c', listStyleType: 'disc' }}>
            <asc>{ival.indicators}</asc>
          </li>
        );
      } else if (ival.Level.split('-')[1] == 2) {
        content2.push(
          <li style={{ color: '#c13c3c', listStyleType: 'disc' }}>
            <asc>{ival.indicators}</asc>
          </li>
        );
      } else if (ival.proficiency_scale == 3) {
        content3.push(
          <li style={{ color: '#c13c3c', listStyleType: 'disc' }}>
            <asc>{ival.indicators}</asc>
          </li>
        );
      } else if (ival.proficiency_scale == 4) {
        content4.push(
          <li style={{ color: '#c13c3c', listStyleType: 'disc' }}>
            <asc>{ival.indicators}</asc>
          </li>
        );
      }
    });
    return (
      <tr valign="top">
        <td>
          {' '}
          <font size="5">Behaviour Manifests</font>
        </td>
        <td style={{ borderLeft: '1px dashed black' }}>
          <ul style={{ paddingLeft: '25px' }}>{content1}</ul>
        </td>
        <td style={{ borderLeft: '1px dashed black' }}>
          <ul style={{ paddingLeft: '25px' }}>{content2}</ul>
        </td>
        <td style={{ borderLeft: '1px dashed black' }}>
          <ul style={{ paddingLeft: '25px' }}>{content3}</ul>
        </td>
        <td style={{ borderLeft: '1px dashed black' }}>
          <ul style={{ paddingLeft: '25px' }}>{content4}</ul>
        </td>
      </tr>
    );
  };
  render() {
    let { data, indiList } = this.state;
    return (
      <section id="main-content">
        <section className="wrapper">
          <div>
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">{data && data[2]}</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-box-tool" data-widget="collapse" title="Collapse">
                    <i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="box-body">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th width="14%" height="40" className="hea">
                        <font size="4">PROFICIENCY SCALE</font>
                      </th>
                      <th width="14%" height="40" className="hea">
                        <font size="4">1</font>
                      </th>
                      <th width="14%" height="40" className="hea">
                        <font size="4">2</font>
                      </th>
                      <th width="14%" height="40" className="hea">
                        <font size="4">3</font>
                      </th>
                      <th width="14%" height="40" className="hea">
                        <font size="4">4</font>
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: 'white' }} colspan="5" height="30"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ color: '#f7ac4a' }}>
                        <font size="4">{data && data[3]}</font>
                      </td>
                      <td style={{ background: 'white' }} colspan="5" height="30">
                        {data && data[4]}
                      </td>
                    </tr>
                    {/* <tr>
                      <td colspan="5" style={{ background: '#1d95af', color: 'white', paddingLeft: '20px' }}>
                        <font size="4">Elements -</font> {indiList && indiList[0]['CompetencyImportant']}
                      </td>
                    </tr> */}
                    {indiList && this.content()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default NewdictionaryView;
