import React from 'react';

const Admin = () => {
  return (
    <div>
      <section className="content">
        <div className="box box-info">
          <div className="box-header with-border">
            <h3 className="box-title">Create Competency Matrix : Step 1</h3>
            <div className="box-tools pull-right">
              <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                <i className="fa fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="box-body">
            <div class="row form-group ">
              <div className="col-sm-2" />
              <div className="col-sm-2">
                <label htmlFor="exampleInputEmail1">Page Name</label>
              </div>
              <div className="col-sm-5">
                <input type="text" className="form-control" id="pageName" name="pageName" placeholder="Enter Page Name" />
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Admin;
