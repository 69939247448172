import React from 'react';
import { FormMiddleWare } from '../../../components/Form';
import Swal from 'sweetalert2';




class WizardNextPreviousButton extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            Step: this.props.step,
            nextPage: this.props.nextPage,
            previousPage: this.props.previousPage
        };
    }


    handlePrevious = (previousPage) => {
        window.location.replace(`/admin/${previousPage}`)

    }
    handleNext = (nextPage) => {
        window.location.replace(`/admin/${nextPage}`)
    }
    handleFinish = () => {
        Swal.fire({
            position: "center",
            type: "success",
            title: "Successfully completed wizard steps",
            showConfirmButton: false,
            timer: 3000
          });
        // window.location.replace('/admin/Mapping/MapEmployee')
    }

    render() {
        let { Step, nextPage, previousPage } = this.state
        console.log(this.props.step, "step");
        return (
            <React.Fragment>
                <div className='wizard-gropubtn'>
                    {Step > 1 &&
                        <button className="btn btn adminBtn" style={{ width: "100px", padding: "10px" }} onClick={() => this.handlePrevious(previousPage)}>Previous</button>
                    }
                    {Step > 15 &&
                        <button className="btn btn adminBtn" style={{ width: "100px", padding: "10px" }} onClick={this.handleFinish}>Finish</button>
                    }
                    {Step <= 15 &&
                        <button className="btn btn adminBtn" style={{ width: "100px", padding: "10px" }} onClick={() => this.handleNext(nextPage)}>Next</button>
                    }

                </div>

            </React.Fragment>
        );
    }
}

export default WizardNextPreviousButton;
