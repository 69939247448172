import React from 'react';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import SingleSelect from '../../../components/Form/SingleSelect';
import MultiSelect from '../../../components/Form/MultiSelect';
import ValidationView from '../../../components/Form/ValidationView';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import { Datatable } from '../../../components/Datatable';
import Swal from 'sweetalert2';

const tableName = 'tbl_cm_theme_worklevel';
class MapThemeToWorkLevel extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: '',
      workLevelOptionsBefore: [],
      behThemeOptions: [],
      behThemeSelected: null,
      behThemeId: null,
      behThemeLabelName: null,
      errorThemeSelected: null,
      workLevelOptions: [],
      workLevel_Selected: null,
      workLevelId: null,
      workLevelLabelName: null,
      errorWorkLevelSelected: null,
      updateData: false,
      data: []
    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(adminId, 'tbl_worklevel', 'id', 'worklevel', 'workLevelOptionsBefore');
    try {
      const { data } = await MasterMiddleWare.getThemeForSelect(adminId, 'Behavioral');
      if (data) {
        this.setState({ behThemeOptions: data });
      }
      const { data: mappingList } = await MappingMiddleware.getMapThemeWorkLevel(adminId);
      if (mappingList) {
        this.setState({ data: mappingList });
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: 'Theme',
      accessor: 'theme'
    },
    {
      Header: 'WorkLevel',
      accessor: 'worklevel'
    },

    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.editMap(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d)
    }
  ];

  editMap = value => {
    return (
      <button type="button" className="btn btn-warning" onClick={() => this.buttonEdit(value)}>
        Edit
      </button>
    );
  };

  deleteMap = value => {
    return (
      <button type="button" className="btn btn-danger" onClick={() => this.buttonDelete(value, tableName)}>
        Delete
      </button>
    );
  };

  buttonDelete = async value => {
    let { data } = this.state;
    const index = value.index;
    const previousData = [...data];
    const getData = previousData[index];
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this file!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then(async result => {
      if (result.value) {
        try {
          data = previousData.filter(val => val.id !== getData.id);
          this.setState({
            data
          });
          const result = await MappingMiddleware.deleteWorkLevelForTheme(getData.id);
          if (result) {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          }
        } catch (error) {
          Swal.fire('Cancelled', 'Something Went Wrong :)', 'error');
          this.setState({
            data: previousData
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file is safe :)', 'error');
        this.setState({
          data: previousData
        });
      }
    });
  };

  buttonEdit = value => {
    const { data } = this.state;
    const index = value.index;
    const previousData = [...data];
    const getData = previousData[index];
    this.setState({
      behThemeSelected: { label: getData.theme, value: getData.themeid },
      behThemeId: getData.themeid,
      behThemeLabelName: getData.theme,
      workLevel_Selected: { label: getData.worklevel, value: getData.worklevelid },
      workLevelId: getData.worklevelid,
      workLevelLabelName: getData.worklevel,
      updateData: true,
      index
    });
    this.behThemeSelect({ label: getData.theme, value: getData.themeid });
  };

  behThemeSelect = async selectedOption => {
    const { adminId, workLevelOptionsBefore } = this.state;
    this.setState({
      behThemeSelected: selectedOption,
      behThemeId: selectedOption.value,
      behThemeLabelName: selectedOption.label
      // workLevelSelected: '',
      // workLevelId: ''
    });

    try {
      const { data } = await MappingMiddleware.getWorkLevelForTheme(adminId, selectedOption.value);
      if (data) {
        let newWorkLevelOptionsBefore = [...workLevelOptionsBefore];
        const result = data.map(val => {
          newWorkLevelOptionsBefore = newWorkLevelOptionsBefore.filter(wrkLvl => val.worklevelid !== wrkLvl.value);
        });
        await Promise.all(result);
        this.setState({ workLevelOptions: newWorkLevelOptionsBefore });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = async () => {
    const { workLevelSelected,workLevel_Selected,behThemeId, workLevelId, adminId, behThemeLabelName, workLevelLabelName } = this.state;

  // 

    console.log(behThemeId)
     if (this.validate(behThemeId, 'errorThemeSelected', 'Please Select Theme')) return true;
     if (this.validate(workLevel_Selected, 'errorWorkLevelSelected', 'Please Select WorkLevel')) return true;
    console.log(workLevel_Selected)
    workLevel_Selected.map(async(ival)    =>{
      console.log(ival)
      const formData = new FormData();
      formData.append('themeid', behThemeId);
    formData.append('worklevelid', ival.value);
    formData.append('cmid', adminId);
    console.log([...formData])
    try {
      const result = await MappingMiddleware.mapThemeWorkLevel(formData, adminId);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been completed',
          showConfirmButton: false,
          timer: 1500
        });
        let newData = { id: result.data.insertId, theme: behThemeLabelName, themeid: behThemeId, worklevel: workLevelLabelName, worklevelid: workLevelId };
        const data = [newData, ...this.state.data];
        this.setState({
          data,
          behThemeId: null,
          workLevelId: null,
          behThemeSelected: null,
          workLevel_Selected: null,
          //workLevel_Selected
          workLevelOptions: []
        });
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
    }      )
    
    
    //formData.append('themeid', behThemeId);
    //formData.append('worklevelid', workLevelSelected.value);
    //formData.append('cmid', adminId);
    //console.log([...formData])
    {/*try {
      const result = await MappingMiddleware.mapThemeWorkLevel(formData, adminId);
      if (result) {
        let newData = { id: result.data.insertId, theme: behThemeLabelName, themeid: behThemeId, worklevel: workLevelLabelName, worklevelid: workLevelId };
        const data = [newData, ...this.state.data];
        this.setState({
          data,
          behThemeId: null,
          workLevelId: null,
          behThemeSelected: null,
          workLevelSelected: null,
          //workLevel_Selected
          workLevelOptions: []
        });
      }
    } catch (error) {
      console.log(error);
    }*/}
  };

  onUpdate = async () => {
    const { index, behThemeId, workLevelId, adminId, behThemeLabelName, workLevelLabelName } = this.state;

    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;
    getData.theme = behThemeLabelName;
    getData.themeid = behThemeId;
    getData.worklevel = workLevelLabelName;
    getData.worklevelid = workLevelId;

    const data = previousData.filter(val => val.id !== id);
    data.splice(index, 0, getData);
    this.setState({ data });
    const formData = new FormData();
    formData.append('themeid', behThemeId);
    formData.append('worklevelid', workLevelId);
    formData.append('cmid', adminId);

    try {
      const result = await MappingMiddleware.updateMapThemeWorkLevel(formData, id);
      if (result) {
        this.setState({
          behThemeId: null,
          workLevelId: null,
          behThemeSelected: null,
          workLevel_Selected: null,
          workLevelOptions: []
        });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  render() {
    const { behThemeOptions, behThemeSelected, errorThemeSelected, workLevelOptions, workLevelSelected,workLevel_Selected, errorWorkLevelSelected, updateData, data } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Map Theme To WorkLevel</h3>
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">Theme</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect handleChange={this.behThemeSelect} options={behThemeOptions} selectedService={behThemeSelected} />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorThemeSelected} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">Work Level</label>
                </div>
                <div className="col-sm-5">
                  {/*<SingleSelect handleChange={(e)=>{this.workLevelSelect(e)}} options={workLevelOptions} selectedService={workLevelSelected} />
                 */} <MultiSelect  handleChange={(e)=>{this.workLevelSelect(e)}} options={workLevelOptions} selectedService={workLevel_Selected} />

                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorWorkLevelSelected} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2"></div>
                <div className="col-sm-5">
                  {!updateData ? (
                    <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                      Update
                    </button>
                  )}
                </div>
                <div className="col-sm-3" />
              </div>
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MapThemeToWorkLevel;
