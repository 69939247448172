import React from 'react';
import {
  SingleSelect,
  FormMiddleWare,
  MultiSelect
} from '../../../components/Form';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import Swal from 'sweetalert2';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import HrMiddleWare from '../../../middleWare/HrMiddleWare';
import WizardPrograss from '../Master/wizardPrograssbar';
import WizardNextPreviousButton from '../Master/wizardNextPreviousButton';

class MapEmployee extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      groupCompanyOptions: [],
      groupCompanySelected: null,
      groupCompanyId: null,
      groupCompanyName: null,
      employeeOptions: [],
      employeeSelected: null,
      employeeId: null,
      employeeName: null,
      yearOptions: [],
      yearSelected: null,
      yearId: null,
      yearName: null,
      mapSupervisorOptions: [],
      mapSupervisorSelected: null,
      mapSupervisorId: null,
      mapPeerOptions: [],
      mapPeerSelected: null,
      mapPeerId: null,
      mapCustomerOptions: [],
      mapCustomerSelected: null,
      mapCustomerId: null,
      mapReporteesOptions: [],
      mapReporteesSelected: null,
      mapReporteesId: null
    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(
      adminId,
      'tbl_gcompanies',
      'id',
      'groupcompanies',
      'groupCompanyOptions'
    );
    let { data: yearOptions } = await MappingMiddleware.getYear();
    if (yearOptions) {
      this.setState({
        yearOptions
      });
    }
  }

  groupCompanySelectId = selectedOption => {
    this.setState({
      groupCompanySelected: selectedOption,
      groupCompanyId: selectedOption.value,
      groupCompanyName: selectedOption.label,
      yearSelected: null,
      yearId: null,
      yearName: null,
      employeeSelected: null,
      employeeId: null,
      employeeName: null,
      mapSupervisorOptions: [],
      mapSupervisorSelected: null,
      mapSupervisorId: null,
      mapPeerOptions: [],
      mapPeerSelected: null,
      mapPeerId: null,
      mapCustomerOptions: [],
      mapCustomerSelected: null,
      mapCustomerId: null,
      mapReporteesOptions: [],
      mapReporteesSelected: null,
      mapReporteesId: null
    });
    this.getSelectBoxEmployeeMaster(
      selectedOption.value,
      'tbl_users',
      'id',
      'name',
      'employeeOptions'
    );
  };

  getSelectBoxEmployeeMaster = async (
    adminId,
    tableName,
    forId,
    forvalue,
    stateValue
  ) => {
    const { data } = await MasterMiddleWare.getSelectBoxEmployeeMaster(
      adminId,
      tableName,
      forId,
      forvalue
    );
    if (data) {
      this.setState({ [stateValue]: data });
    }
  };

  yearSelectId = selectedOption => {
    this.setState({
      yearSelected: selectedOption,
      yearId: selectedOption.value,
      yearName: selectedOption.label,
      employeeSelected: null,
      employeeId: null,
      employeeName: null,
      mapSupervisorOptions: [],
      mapSupervisorSelected: null,
      mapSupervisorId: null,
      mapPeerOptions: [],
      mapPeerSelected: null,
      mapPeerId: null,
      mapCustomerOptions: [],
      mapCustomerSelected: null,
      mapCustomerId: null,
      mapReporteesOptions: [],
      mapReporteesSelected: null,
      mapReporteesId: null
    });
  };

  employeeSelectId = async selectedOption => {
    const { employeeOptions, yearId } = this.state;
    const previousData = [...employeeOptions];
    const filterData = previousData.filter(
      value => value.value !== selectedOption.value
    );
    this.setState({
      employeeSelected: selectedOption,
      employeeId: selectedOption.value,
      employeeName: selectedOption.label,
      mapSupervisorOptions: null,
      mapSupervisorSelected: null,
      mapSupervisorId: null,
      mapPeerOptions: null,
      mapPeerSelected: null,
      mapPeerId: null,
      mapCustomerOptions: null,
      mapCustomerSelected: null,
      mapCustomerId: null,
      mapReporteesOptions: null,
      mapReporteesSelected: null,
      mapReporteesId: null
    });
    const empId = selectedOption.value;
    const { data: jobOption } = await HrMiddleWare.userJobOption(empId)
    if (jobOption) {
      this.setState({ jobRoleOptions: jobOption })
    }
    // const { data: mapSupervisorData } = await MappingMiddleware.mapData(
    //   empId,
    //   'is_supervisor',
    //   yearId
    // );
    // if (mapSupervisorData) {
    //   let mapSupervisorOptions = filterData;
    //   let mapSupervisorSelected = [];
    //   let mapSupervisorId = '';
    //   mapSupervisorData.map(value => {
    //     const id = value.empid;
    //     mapSupervisorId += `${id},`;
    //     mapSupervisorOptions = mapSupervisorOptions.filter(
    //       value => value.value !== id
    //     );
    //     filterData.filter(value => {
    //       if (value.value === id) mapSupervisorSelected.push(value);
    //       return null;
    //     });
    //     return null;
    //   });
    //   mapSupervisorId = mapSupervisorId.replace(/,\s*$/, '');
    //   if (mapSupervisorData.length > 0) {
    //     this.setState({
    //       mapSupervisorOptions,
    //       mapSupervisorSelected,
    //       mapSupervisorId
    //     });
    //   } else {
    //     this.setState({
    //       mapSupervisorOptions
    //     });
    //   }
    // }
    // const { data: mapPeerData } = await MappingMiddleware.mapData(
    //   empId,
    //   'is_peer',
    //   yearId
    // );
    // if (mapPeerData) {
    //   let mapPeerOptions = filterData;
    //   let mapPeerSelected = [];
    //   let mapPeerId = '';
    //   mapPeerData.map(value => {
    //     const id = value.empid;
    //     mapPeerId += `${id},`;
    //     mapPeerOptions = mapPeerOptions.filter(value => value.value !== id);
    //     filterData.filter(value => {
    //       if (value.value === id) mapPeerSelected.push(value);
    //       return null;
    //     });
    //     return null;
    //   });
    //   mapPeerId = mapPeerId.replace(/,\s*$/, '');
    //   if (mapPeerData.length > 0) {
    //     this.setState({
    //       mapPeerOptions,
    //       mapPeerSelected,
    //       mapPeerId
    //     });
    //   } else {
    //     this.setState({
    //       mapPeerOptions
    //     });
    //   }
    // }
    // const { data: mapCustomerData } = await MappingMiddleware.mapData(
    //   empId,
    //   'is_significant',
    //   yearId
    // );
    // if (mapCustomerData) {
    //   let mapCustomerOptions = filterData;
    //   let mapCustomerSelected = [];
    //   let mapCustomerId = '';
    //   mapCustomerData.map(value => {
    //     const id = value.empid;
    //     mapCustomerId += `${id},`;
    //     mapCustomerOptions = mapCustomerOptions.filter(
    //       value => value.value !== id
    //     );
    //     filterData.filter(value => {
    //       if (value.value === id) mapCustomerSelected.push(value);
    //       return null;
    //     });
    //     return null;
    //   });
    //   mapCustomerId = mapCustomerId.replace(/,\s*$/, '');
    //   if (mapCustomerData.length > 0) {
    //     this.setState({
    //       mapCustomerOptions,
    //       mapCustomerSelected,
    //       mapCustomerId
    //     });
    //   } else {
    //     this.setState({
    //       mapCustomerOptions
    //     });
    //   }
    // }
    // const { data: mapReporteesData } = await MappingMiddleware.mapData(
    //   empId,
    //   'is_fro',
    //   yearId
    // );
    // if (mapReporteesData) {
    //   let mapReporteesOptions = filterData;
    //   let mapReporteesSelected = [];
    //   let mapReporteesId = '';
    //   mapReporteesData.map(value => {
    //     const id = value.empid;
    //     mapReporteesId += `${id},`;
    //     mapReporteesOptions = mapReporteesOptions.filter(
    //       value => value.value !== id
    //     );
    //     filterData.filter(value => {
    //       if (value.value === id) mapReporteesSelected.push(value);
    //       return null;
    //     });
    //     return null;
    //   });
    //   mapReporteesId = mapReporteesId.replace(/,\s*$/, '');
    //   if (mapReporteesData.length > 0) {
    //     this.setState({
    //       mapReporteesOptions,
    //       mapReporteesSelected,
    //       mapReporteesId
    //     });
    //   } else {
    //     this.setState({
    //       mapReporteesOptions
    //     });
    //   }
    // }
  };
  jobSelectId = async selectedOption => {
    const { employeeOptions, yearId, employeeSelected } = this.state;
    const previousData = [...employeeOptions];
    const filterData = previousData.filter(value => value.value !== employeeSelected.value);
    this.setState({
      jobSelect: selectedOption,
      jobId: selectedOption.value,
      jobName: selectedOption.label,
      mapSupervisorOptions: null,
      mapSupervisorSelected: null,
      mapSupervisorId: null,
      mapPeerOptions: null,
      mapPeerSelected: null,
      mapPeerId: null,
      mapCustomerOptions: null,
      mapCustomerSelected: null,
      mapCustomerId: null,
      mapReporteesOptions: null,
      mapReporteesSelected: null,
      mapReporteesId: null
    });
    const empId = employeeSelected.value;
    const jobId = selectedOption.value
    // const { data: jobOption } = await HrMiddleWare.userJobOption(empId)
    // if (jobOption) {
    //   this.setState({ jobRoleOptions: jobOption })
    // }
    // console.log(jobOption, "jobOption");
    const { data: mapSupervisorData } = await MappingMiddleware.mapData(empId, 'is_supervisor', yearId, jobId);
    if (mapSupervisorData) {
      let mapSupervisorOptions = filterData;
      let mapSupervisorSelected = [];
      let mapSupervisorId = '';

      mapSupervisorData.map(value => {
        const id = value.empid;
        mapSupervisorId += `${id},`;
        mapSupervisorOptions = mapSupervisorOptions.filter(value => value.value !== id);
        filterData.filter(value => {
          if (value.value === id) mapSupervisorSelected.push(value);
          return null;
        });
        return null;
      });
      mapSupervisorId = mapSupervisorId.replace(/,\s*$/, '');
      if (mapSupervisorData.length > 0) {
        this.setState({
          mapSupervisorOptions,
          mapSupervisorSelected,
          mapSupervisorId
        });
      } else {
        this.setState({
          mapSupervisorOptions
        });
      }
    }
    const { data: mapPeerData } = await MappingMiddleware.mapData(empId, 'is_peer', yearId, jobId);
    if (mapPeerData) {
      let mapPeerOptions = filterData;
      let mapPeerSelected = [];
      let mapPeerId = '';
      mapPeerData.map(value => {
        const id = value.empid;
        mapPeerId += `${id},`;
        mapPeerOptions = mapPeerOptions.filter(value => value.value !== id);
        filterData.filter(value => {
          if (value.value === id) mapPeerSelected.push(value);
          return null;
        });
        return null;
      });
      mapPeerId = mapPeerId.replace(/,\s*$/, '');
      if (mapPeerData.length > 0) {
        this.setState({
          mapPeerOptions,
          mapPeerSelected,
          mapPeerId
        });
      } else {
        this.setState({
          mapPeerOptions
        });
      }
    }
    const { data: mapCustomerData } = await MappingMiddleware.mapData(empId, 'is_significant', yearId, jobId);
    if (mapCustomerData) {
      let mapCustomerOptions = filterData;
      let mapCustomerSelected = [];
      let mapCustomerId = '';
      mapCustomerData.map(value => {
        const id = value.empid;
        mapCustomerId += `${id},`;
        mapCustomerOptions = mapCustomerOptions.filter(value => value.value !== id);
        filterData.filter(value => {
          if (value.value === id) mapCustomerSelected.push(value);
          return null;
        });
        return null;
      });
      mapCustomerId = mapCustomerId.replace(/,\s*$/, '');
      if (mapCustomerData.length > 0) {
        this.setState({
          mapCustomerOptions,
          mapCustomerSelected,
          mapCustomerId
        });
      } else {
        this.setState({
          mapCustomerOptions
        });
      }
    }
    const { data: mapReporteesData } = await MappingMiddleware.mapData(empId, 'is_fro', yearId, jobId);
    if (mapReporteesData) {
      let mapReporteesOptions = filterData;
      let mapReporteesSelected = [];
      let mapReporteesId = '';
      mapReporteesData.map(value => {
        const id = value.empid;
        mapReporteesId += `${id},`;
        mapReporteesOptions = mapReporteesOptions.filter(value => value.value !== id);
        filterData.filter(value => {
          if (value.value === id) mapReporteesSelected.push(value);
          return null;
        });
        return null;
      });
      mapReporteesId = mapReporteesId.replace(/,\s*$/, '');
      if (mapReporteesData.length > 0) {
        this.setState({
          mapReporteesOptions,
          mapReporteesSelected,
          mapReporteesId
        });
      } else {
        this.setState({
          mapReporteesOptions
        });
      }
    }
  };
  mapSupervisorId = selectedOption => {
    const { employeeId, employeeOptions } = this.state;
    const previousData = [...employeeOptions];
    const filterData = previousData.filter(value => value.value !== employeeId);

    let mapSupervisorOptions = filterData;
    let mapSupervisorSelected = [];

    if (selectedOption) {
      let selectedValue = '';
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, '');
      this.setState({
        mapSupervisorSelected: selectedOption,
        mapSupervisorId: selectedValue
      });

      selectedOption.map(value => {
        const id = value.empid;
        mapSupervisorOptions = mapSupervisorOptions.filter(
          value => value.value !== id
        );
        filterData.filter(value => {
          if (value.value === id) mapSupervisorSelected.push(value);
          return null;
        });
        return null;
      });
      this.setState({
        mapSupervisorOptions
      });
    } else {
      this.setState({
        mapSupervisorSelected: selectedOption,
        mapSupervisorId: null,
        mapSupervisorOptions
      });
    }
  };

  mapPeerId = selectedOption => {
    const { employeeId, employeeOptions } = this.state;
    const previousData = [...employeeOptions];
    const filterData = previousData.filter(value => value.value !== employeeId);

    let mapPeerOptions = filterData;
    let mapPeerSelected = [];

    if (selectedOption) {
      let selectedValue = '';
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, '');
      this.setState({
        mapPeerSelected: selectedOption,
        mapPeerId: selectedValue
      });

      selectedOption.map(value => {
        const id = value.empid;
        mapPeerOptions = mapPeerOptions.filter(value => value.value !== id);
        filterData.filter(value => {
          if (value.value === id) mapPeerSelected.push(value);
          return null;
        });
        return null;
      });
      this.setState({
        mapPeerOptions
      });
    } else {
      this.setState({
        mapPeerSelected: selectedOption,
        mapPeerId: null,
        mapPeerOptions
      });
    }
  };

  mapCustomerId = selectedOption => {
    const { employeeId, employeeOptions } = this.state;
    const previousData = [...employeeOptions];
    const filterData = previousData.filter(value => value.value !== employeeId);

    let mapCustomerOptions = filterData;
    let mapCustomerSelected = [];

    if (selectedOption) {
      let selectedValue = '';
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, '');
      this.setState({
        mapCustomerSelected: selectedOption,
        mapCustomerId: selectedValue
      });

      selectedOption.map(value => {
        const id = value.empid;
        mapCustomerOptions = mapCustomerOptions.filter(
          value => value.value !== id
        );
        filterData.filter(value => {
          if (value.value === id) mapCustomerSelected.push(value);
          return null;
        });
        return null;
      });
      this.setState({
        mapCustomerOptions
      });
    } else {
      this.setState({
        mapCustomerSelected: selectedOption,
        mapCustomerId: null,
        mapCustomerOptions
      });
    }
  };

  mapReporteesId = selectedOption => {
    const { employeeId, employeeOptions } = this.state;
    const previousData = [...employeeOptions];
    const filterData = previousData.filter(value => value.value !== employeeId);

    let mapReporteesOptions = filterData;
    let mapReporteesSelected = [];

    if (selectedOption) {
      let selectedValue = '';
      selectedOption.map(values => (selectedValue += `${values.value},`));
      selectedValue = selectedValue.replace(/,\s*$/, '');
      this.setState({
        mapReporteesSelected: selectedOption,
        mapReporteesId: selectedValue
      });

      selectedOption.map(value => {
        const id = value.empid;
        mapReporteesOptions = mapReporteesOptions.filter(
          value => value.value !== id
        );
        filterData.filter(value => {
          if (value.value === id) mapReporteesSelected.push(value);
          return null;
        });
        return null;
      });
      this.setState({
        mapReporteesOptions
      });
    } else {
      this.setState({
        mapReporteesSelected: selectedOption,
        mapReporteesId: null,
        mapReporteesOptions
      });
    }
  };

  submitData = async () => {
    const {
      adminId,
      groupCompanyId,
      employeeId,
      yearId,
      mapSupervisorId,
      mapPeerId,
      mapCustomerId,
      mapReporteesId,
      jobSelect
    } = this.state;

    const formData = new FormData();
    formData.append('adminId', adminId);
    formData.append('groupCompanyId', groupCompanyId);
    formData.append('employeeId', employeeId);
    formData.append('yearId', yearId);
    formData.append('mapSupervisorId', mapSupervisorId);
    formData.append('mapPeerId', mapPeerId);
    formData.append('mapCustomerId', mapCustomerId);
    formData.append('mapReporteesId', mapReporteesId);
    formData.append('jobRole', jobSelect.value);

    try {
      const { data } = await MappingMiddleware.mapEmployeeData(formData);
      if (data) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been saved',
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          groupCompanySelected: null,
          groupCompanyId: null,
          groupCompanyName: null,
          employeeOptions: [],
          employeeSelected: null,
          employeeId: null,
          employeeName: null,
          yearSelected: null,
          yearId: null,
          yearName: null,
          mapSupervisorOptions: [],
          mapSupervisorSelected: null,
          mapSupervisorId: null,
          mapPeerOptions: [],
          mapPeerSelected: null,
          mapPeerId: null,
          mapCustomerOptions: [],
          mapCustomerSelected: null,
          mapCustomerId: null,
          mapReporteesOptions: [],
          mapReporteesSelected: null,
          mapReporteesId: null,
          jobSelect:null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      groupCompanyOptions,
      groupCompanySelected,
      employeeOptions,
      employeeSelected,
      yearOptions,
      yearSelected,
      mapSupervisorSelected,
      mapSupervisorOptions,
      mapPeerOptions,
      mapPeerSelected,
      mapCustomerOptions,
      mapCustomerSelected,
      mapReporteesOptions,
      mapReporteesSelected,
      jobRoleOptions,
      jobSelect
    } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">
                Map Employee For 360 Degree Assessment
              </h3>
              <WizardPrograss step={13} />

              <div className="box-tools pull-right">
                <button
                  className="btn btn-box-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="row form-group ">
                <div className="col-sm-1" />
                <div className="col-sm-3">
                  <label htmlFor="groupCompany">Select Group Company</label>
                </div>
                <div className="col-sm-3">
                  <SingleSelect
                    handleChange={this.groupCompanySelectId}
                    options={groupCompanyOptions}
                    selectedService={groupCompanySelected}
                  />
                </div>
                <div className="col-sm-5" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-1" />
                <div className="col-sm-3">
                  <label htmlFor="year">Financial Year</label>
                </div>
                <div className="col-sm-3">
                  <SingleSelect
                    handleChange={this.yearSelectId}
                    options={yearOptions}
                    selectedService={yearSelected}
                  />
                </div>
                <div className="col-sm-5" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-1" />
                <div className="col-sm-3">
                  <label htmlFor="employee">Employee</label>
                </div>
                <div className="col-sm-3">
                  <SingleSelect
                    handleChange={this.employeeSelectId}
                    options={employeeOptions}
                    selectedService={employeeSelected}
                  />
                </div>
                <div className="col-sm-5" />
              </div>
              <div className="row form-group">
                <div className="col-sm-1" />
                <div className="col-sm-3">
                  <label htmlFor="employee">Job Role</label>
                </div>
                <div className="col-sm-3">
                  <SingleSelect
                    handleChange={this.jobSelectId}
                    options={jobRoleOptions}
                    selectedService={jobSelect}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-1"></div>
                <div className="col-sm-3">
                  <label>Map Supervisor</label>
                </div>
                <div className="col-sm-3">
                  <MultiSelect
                    handleChange={this.mapSupervisorId}
                    options={mapSupervisorOptions}
                    selectedService={mapSupervisorSelected}
                  />
                </div>
                <div className="col-sm-5" />
              </div>
              <div className="row form-group">
                <div className="col-sm-1"></div>
                <div className="col-sm-3">
                  <label>Map Peer</label>
                </div>
                <div className="col-sm-3">
                  <MultiSelect
                    handleChange={this.mapPeerId}
                    options={mapPeerOptions}
                    selectedService={mapPeerSelected}
                  />
                </div>
                <div className="col-sm-5" />
              </div>
              <div className="row form-group">
                <div className="col-sm-1"></div>
                <div className="col-sm-3">
                  <label>Map Customer</label>
                </div>
                <div className="col-sm-3">
                  <MultiSelect
                    handleChange={this.mapCustomerId}
                    options={mapCustomerOptions}
                    selectedService={mapCustomerSelected}
                  />
                </div>
                <div className="col-sm-5" />
              </div>
              <div className="row form-group">
                <div className="col-sm-1"></div>
                <div className="col-sm-3">
                  <label>Map Reportees</label>
                </div>
                <div className="col-sm-3">
                  <MultiSelect
                    handleChange={this.mapReporteesId}
                    options={mapReporteesOptions}
                    selectedService={mapReporteesSelected}
                  />
                </div>
                <div className="col-sm-5" />
              </div>
              <div className="row form-group">
                <div className="col-sm-1"></div>
                <div className="col-sm-3" />
                <div className="col-sm-3">
                  <button className="btn btn-success" onClick={this.submitData}>
                    Submit
                  </button>
                </div>
                <div className="col-sm-5" />
                <WizardNextPreviousButton step={13}previousPage={"Master/Users"} nextPage={"Mapping/MapWorkleveToCompetency"} />

              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MapEmployee;
