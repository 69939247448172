import React from 'react';
import ValidationView from '../../../components/Form/ValidationView';
import SingleSelect from '../../../components/Form/SingleSelect';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";


class Derailers extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: localStorage.getItem('userId'),
      addMap: 'Add',
      excelHead: [
        { label: "SI.NO", key: "id" },
        { label: "Derailers", key: "derailers" },
        { label: "Description", key: "description" }
      ]
    };
  }
  async componentDidMount() {
    const { adminId } = this.state;
    this.getSelectBoxMaster(adminId, 'tbl_worklevel', 'id', 'worklevel', 'workLevelOptions');
    this.getSelectBoxMaster(adminId, 'tbl_jobRole', 'id', 'jobRoleName', 'jobRoleOptions');
    this.getSelectBoxMaster(adminId, 'tbl_derailers', 'id', 'derailers', 'derailerOptions');
    this.getMaster('tbl_derailers');
  }
  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'Derailers',
      accessor: 'derailers'
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, 'tbl_derailers')
    }
  ];
  buttonEdit = (value) => {
    this.setState({
      index: value.index,
      id: value.original.id,
      addMap: 'Edit',
      derailer: value.original.derailers,
      description: value.original.description
    });
  }
  changeState = addMap => {
    this.setState({ addMap });
  }
  resetState = () => {
    this.setState({ derailer: '', description: '' })
  }
  onSubmit = () => {
    const { workLevelId, jobRoleId, addMap, adminId, derailer, description, derailerId } = this.state;

    if (addMap === 'Add') {
      if (this.validate(derailer, 'errorDerailer', 'Please enter derailer')) return true;
      if (this.validate(description, 'errorDescription', 'Please enter description')) return true;

      var formData = new FormData();
      formData.append('derailers', derailer);
      formData.append('description', description);
      formData.append('customerid', adminId);
      formData.append('adminId', adminId);
      formData.append('status', 'active');

      this.addnew('tbl_derailers', formData, 'Derailers Added')

    } else if (addMap === 'Map') {

      if (this.validate(workLevelId, 'errorWorkLevelSelected', 'Please Select Work Level')) return true;
      if (this.validate(jobRoleId, 'errorJobRoleSelected', 'Please Select JobRole')) return true;
      if (this.validate(derailerId, 'errorDerailerSelected', 'Please Select Derailer')) return true;

      var formData = new FormData();
      formData.append('worklevelid', workLevelId);
      formData.append('jobroleid', jobRoleId);
      formData.append('customerid', adminId);
      formData.append('derailersid', derailerId);
      this.addnew('Tblderailers_map', formData, 'Derailers Mapped')
    } else if (addMap === 'Edit') {

      if (this.validate(derailer, 'errorDerailer', 'Please enter derailer')) return true;
      if (this.validate(description, 'errorDescription', 'Please enter description')) return true;

      var formData = new FormData();
      formData.append('derailers', derailer);
      formData.append('description', description);
      formData.append('customerid', adminId);
      formData.append('adminId', adminId);
      formData.append('status', 'active');

      this.addnew('tbl_derailers', formData, 'Derailers Updated')
    }
  }
  addnew = async (tableName, formData, msg) => {
    try {
      let addMap = this.state.addMap;
      if (addMap === 'Add' || addMap === 'Map') {
        var result = await MasterMiddleWare.createMaster(tableName, formData);
      } else if (addMap === 'Edit') {
        var result = await MasterMiddleWare.updateMaster(tableName, this.state.id, formData);
      }
      if (result) {
        this.resetState();
        Swal.fire({
          position: 'center',
          type: 'success',
          title: msg,
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { workLevelOptions, workLevelSelected,excelHead, errorWorkLevelSelected, jobRoleOptions, jobRoleSelected, errorJobRoleSelected, addMap, description, derailer, errorDerailer, errorDescription, derailerOptions, derailerSelected, errorDerailerSelected, data } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">{addMap} Derailer</h3>
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">

              <div className="row form-group ">
                <div className="col-sm-4" />
                <div className="col-sm-3">
                  <button type="button" className="btn btn-info" onClick={() => this.changeState('Add')}>
                    Add Derailers
                  </button>
                </div>
                <div className="col-sm-3">
                  <button type="button" className="btn btn-warning" onClick={() => this.changeState('Map')}>
                    Map Derailers
                  </button>
                </div>
                <div className="col-sm-2" />
              </div>

              {(addMap === 'Add' || addMap === 'Edit') ? (
                <div>
                  <div className="row form-group ">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="indicators">Derailer</label>
                    </div>
                    <div className="col-sm-5">
                      <textarea className="form-control" rows="3" id="description" name="derailer" placeholder="Enter derailer" onChange={this.handleUserInput} value={derailer}></textarea>
                    </div>
                    <div className="col-sm-3" />
                  </div>
                  <ValidationView errorname={errorDerailer} />
                  <div className="row form-group ">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="indicators">Description</label>
                    </div>
                    <div className="col-sm-5">
                      <textarea className="form-control" rows="3" id="description" name="description" placeholder="Enter description" onChange={this.handleUserInput} value={description}></textarea>
                    </div>
                    <div className="col-sm-3" />
                  </div>
                  <ValidationView errorname={errorDescription} />
                </div>
              )
                :
                (
                  <div>
                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="competency">Work Level</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect handleChange={this.workLevelSelect} options={workLevelOptions} selectedService={workLevelSelected} />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <ValidationView errorname={errorWorkLevelSelected} />

                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="competency">Job Role</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect handleChange={this.jobRoleSelect} options={jobRoleOptions} selectedService={jobRoleSelected} />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <ValidationView errorname={errorJobRoleSelected} />
                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="competency">Derailers</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect handleChange={this.derailerSelect} options={derailerOptions} selectedService={derailerSelected} />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <ValidationView errorname={errorDerailerSelected} />
                  </div>
                )
              }
              <div className="row form-group ">
                <div className="col-sm-4" />
                <div className="col-sm-5">
                  <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                    {addMap}
                  </button>
                </div>
                <div className="col-sm-3" />
              </div>
              {data && data.length ?
                <div className="row form-group ">
                  <div className="col-sm-10" />
                  <div className="col-sm-2">
                    <CSVLink
                      headers={excelHead}
                      data={data}
                      filename={"Derailers.xls"}
                      className="btn btncolor"
                      target="_blank"
                    >
                      Generate Excel
                    </CSVLink>
                  </div>
                </div>
                :
                <></>}
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Derailers;