const env = {
  // ACCESS_POINT: "http://localhost:21009",
  ACCESS_POINT: "https://compexp.difuza.com",
  // VIDHYA_ACCESS_POINT:"http://localhost:5000",
  VIDHYA_ACCESS_POINT: "https://df2exp.difuza.com",
  FILEPATH :"https://video.rem.coach/~remcoach/vidhyaonline/"
};


module.exports = env;
