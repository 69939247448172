import React, { Component, createRef } from "react";
import qrscanner from 'qr-scanner';
import Swal from 'sweetalert2';
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";


class QrScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scannedData: null,
            userLocation: null,
        }
        this.videoRef = createRef();
        this.mapRef = createRef(); // Ref for the map container
        this.map = null; // Reference to the Google Map object
    }

    componentDidMount() {
        // Fetch user's current location
        let attendance=localStorage.getItem('attendancemarked');
        this.setState({attendance})
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    console.log(userLocation, "userLocation");
                    this.setState({ userLocation });
                },
                error => {
                    console.error('Error getting user location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    startScanner = () => {
        // var qrbtn = document.getElementById("qrscanner");
        // var gmap = document.getElementById("gmap");
        // if (qrbtn.style.display === "none") {
        //     qrbtn.style.display = "block";
        // }
        // if (gmap.style.display === "block") {
        //     gmap.style.display = "none";
        // }

        if (this.videoRef.current) {
            localStorage.removeItem('scannedData');
            const video = this.videoRef.current;
            this.scanner = new qrscanner(video, result => {
                this.handleQRCode(result.data);
            }, {
                highlightScanRegion: true,
            });
            this.scanner.start();
        }
    };

    stopScanner = () => {
        if (this.scanner) {
            this.scanner.stop();
            // var qrbtn = document.getElementById("qrscanner");
            // var gmap = document.getElementById("gmap");
            // if (qrbtn.style.display === "none") {
            //     qrbtn.style.display = "block";
            // } else {
            //     qrbtn.style.display = "none";
            // }
            // if (gmap.style.display === "none") {
            //     gmap.style.display = "block";
            // } else {
            //     gmap.style.display = "none";
            // }

        }
    };

    handleQRCode = async (datas) => {
        this.stopScanner()
        localStorage.setItem('scannedData', datas);
        this.setState({ scannerdata: datas });
        const { userLocation } = this.state;
        // console.log(datas, "datas");
        let data = JSON.parse(datas)

        if (!userLocation) {
            console.error('User location is not available.');
            return;
        }


        const { prgmlatitude, prgmlongitude } = data;

        if (prgmlatitude && prgmlongitude) {
            const distance = this.calculateDistance(prgmlatitude, prgmlongitude, userLocation.lat, userLocation.lng);
            const allowedDistance = 100;

            if (distance <= allowedDistance) {
                const { scannerdata } = this.state;
                let scannerdatas = JSON.parse(scannerdata)

                const formData = new FormData();
                formData.append('trainerId', parseInt(scannerdatas.trainerId));
                formData.append('programId', parseInt(scannerdatas.programId));
                formData.append('jobRoleId', parseInt(localStorage.getItem("jobRoleId")));
                formData.append('userTypeId', parseInt(localStorage.getItem("userTypeId")));
                formData.append('designation', localStorage.getItem("designation"));
                formData.append('jobName', localStorage.getItem("jobName"));
                formData.append('adminId', parseInt(localStorage.getItem("adminId")));
                formData.append('userId', parseInt(localStorage.getItem("userId")));
                formData.append('yearId', parseInt(localStorage.getItem("yearId")));
                formData.append('userName', localStorage.getItem("userName"));
                formData.append('attendance', 'yes');
                // console.log([...formData], "formdata");
                try {
                    let tableName = "tbl_nominate_user_attendance"
                    // console.log(tableName, "tableName");

                    // return false
                    const result = await MasterMiddleWare.createMaster(tableName, formData);
                    // this.stopScanner()
                    console.log(result, "result");
                    if (result) {
                        Swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Your attendance marked successfully',
                            showConfirmButton: false,
                            timer: 4000
                        });
                        localStorage.setItem('attendancemarked', "yes");
                        // setTimeout(
                        //     window.location.reload(),
                        //     4000
                        // );
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                // Scanning is not allowed outside the building location
                // this.stopScanner()
                Swal.fire({
                    position: 'center',
                    type: 'warning',
                    title: "You are not in the designated location for scanning",
                    showConfirmButton: false,
                    timer: 3000
                });
            }
        } else {
            console.error("Invalid latitude or longitude:", prgmlatitude, prgmlongitude);
        }

        // this.initMap();

        // this.stopScanner()
    };

    // initMap = () => {
    //     const { scannerdata } = this.state;
    //     // console.log(scannerdata, "scannerdata");
    //     let scannerdatas = JSON.parse(scannerdata)

    //     if (scannerdatas && scannerdatas.prgmlatitude && scannerdatas.prgmlongitude) {
    //         const lat = parseFloat(scannerdatas.prgmlatitude);
    //         const lng = parseFloat(scannerdatas.prgmlongitude);

    //         // Check if lat and lng are valid numbers
    //         if (!isNaN(lat) && !isNaN(lng)) {
    //             // console.log("Initializing map with coordinates:", lat, lng);

    //             // Initialize map
    //             if (!this.map) {
    //                 this.map = new window.google.maps.Map(this.mapRef.current, {
    //                     center: { lat, lng },
    //                     zoom: 15
    //                 });
    //             } else {
    //                 this.map.setCenter({ lat, lng });
    //             }
    //             const marker = new window.google.maps.Marker({
    //                 position: { lat, lng },
    //                 map: this.map,
    //                 title: "Scanned Location"
    //             });
    //         } else {
    //             console.error("Invalid latitude or longitude:", lat, lng);
    //         }
    //     } else {
    //         console.error("Invalid scanner data:", scannerdatas);
    //     }
    // };

    calculateDistance = (lat1, lng1, lat2, lng2) => {
        const earthRadius = 6371000; // Earth radius in meters
        const lat1Rad = this.degreesToRadians(lat1);
        const lat2Rad = this.degreesToRadians(lat2);
        const deltaLatRad = this.degreesToRadians(lat2 - lat1);
        const deltaLngRad = this.degreesToRadians(lng2 - lng1);

        const a = Math.sin(deltaLatRad / 2) * Math.sin(deltaLatRad / 2) +
            Math.cos(lat1Rad) * Math.cos(lat2Rad) *
            Math.sin(deltaLngRad / 2) * Math.sin(deltaLngRad / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = earthRadius * c;
        return distance;
    };

    degreesToRadians = (degrees) => {
        return degrees * Math.PI / 180;
    };


    render() {
        return (
            <section id="main-content">
                <section className="wrapper">
                    <div className="box box-info">
                        <div className="box-header with-border">
                            <div className="box-tools pull-right">
                                <button
                                    className="btn btn-box-tool"
                                    data-widget="collapse"
                                    title="Collapse"
                                >
                                    <i className="fa fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="box-body">
                            <div>
                                <button onClick={this.startScanner} style={{ fontSize: "15px" }}>Open Scanner</button>
                                <div className="qr-code-scanner" id="qrscanner" style={{ display: "block", marginTop: "15px" }}>
                                    <div class="heading"> QR - SCANNER</div>
                                    <video ref={this.videoRef} style={{ width: '100%', height: "100%" }} />
                                </div>
                                {/* <div className="qr-code-scanner" id="gmap" style={{ display: "none" }}>
                                    <div class="heading"> PROGRAM CONDUCTED LOCATION</div>
                                    <div ref={this.mapRef} style={{ width: '100%', height: "400px" }}></div> 

                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}

export default QrScanner;
