import React from "react";
import { ACCESS_POINT } from "../../config";
import moment from 'moment'
import logo from "../Header/HrImg/Murugappa_Group_Logo.png";
import wexel from "../Header/HrImg/rsz_wexel.jpg";
import profile from "../Header/HrImg/ui-sherman.jpg"
import customFont from "../ttf_files/Montserrat-SemiBold_72e0d276db1e5baa6f2b4caddfef5b93.ttf"
import ptsans from "../ttf_files/pt-sans.narrow.ttf"

// import logo1 from './m.png'
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  PDFViewer
} from "@react-pdf/renderer";
import url from "socket.io-client/lib/url";

const IDPconsolidate = ({
  data,
  short,
  mid,
  long,
  section1,
  section2,
  section3,
  group,

  // logosrc
}) => {

  var imagelogo = group[0].image
  const groupcompId = localStorage.getItem("groupcompId");

  // console.log(data, "data");
  // console.log(short, "short", mid, "mid", long, "long");
  // console.log(section1, "section1", section2, "section2", section3, "section3", group, "group");
  return (
    <Document>
      <Page size="A4" style={s.body} >
        <Image
          style={s.logo}
          src={groupcompId == "52" ? wexel : groupcompId == "24" ? logo :
            `https://compexp.difuza.com/getpro/getprofile?fileurl_1=` +
            imagelogo
          }
          fixed
        />

        <View style={{
          marginLeft: 30,
          marginRight: 30, marginTop: 30
        }}>
          <Text style={[s.head, { fontFamily: 'CustomFont' }]}>Individual Development Plan </Text>
        </View>
        <View style={
          [s.section, s.st]}
        >
          <Text style={s.para}>
            Individual Development Plan (IDP) is the corner stone of Leadership Development.  It is a structured process through which an individual reflects on his/her performance and aspirations.  A conscious evaluation of strengths and areas of improvement on leadership competencies are done. The various inputs for creating the IDP can be 360 degree feedback, key result areas, performance appraisal feedback, informal feedback as well as self perception.
            After an individual identifies the competencies he/she needs to develop then a well defined road map is created to build the specific competency.  The road map constitutes of change objectives, action items, schedule, and measurement criteria.  IDP is a tool that helps in monitoring and reviewing individual development against the target.  In the absence of a structured IDP and a systematic and regular review it is often observed that the development plan of a Hi Potential remains at an intent stage and seldom put into action.
            The best organizations in the learning and development space use Individual Development Plan (IDP) to develop its Hi Potentials.  We are providing the details of an IDP and its process of creation for your reference.

          </Text>
          <Text style={s.para}  >
            After an individual identifies the competencies he/she needs to develop then a well defined road map is created to build the specific competency.  The road map constitutes of change objectives, action items, schedule, and measurement criteria.  IDP is a tool that helps in monitoring and reviewing individual development against the target.  In the absence of a structured IDP and a systematic and regular review it is often observed that the development plan of a Hi Potential remains at an intent stage and seldom put into action.
            The best organizations in the learning and development space use Individual Development Plan (IDP) to develop its Hi Potentials.  We are providing the details of an IDP and its process of creation for your reference.
          </Text>
        </View>
        {/* next page   */}
        {/* <Page wrap={false}> */}
        <Text style={[s.parabreak, { marginTop: '10px' }]} break></Text>
        <View style={{
          marginLeft: 30,
          marginRight: 30, marginTop: 30
        }}>
          <Text style={[s.head, { fontFamily: 'CustomFont' }]}> Guidelines for creating Change Objectives </Text>
        </View>

        <View style={
          [s.section, s.st]}
        >
          <Text style={s.para2}>
            Take the following points into consideration while creating your change objectives:
          </Text>
          <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>
            1) Your change objectives ideally should focus on
          </Text>
          <Text style={[s.paragg2, { fontFamily: 'ptsans' }]}>
            - Self
          </Text>
          <Text style={[s.paragg2, { fontFamily: 'ptsans' }]}>
            - Team and
          </Text>
          <Text style={[s.paragg2, { fontFamily: 'ptsans' }]}>
            -  Organization
          </Text>
          <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>
            2) Estimate the effort involved and make an attempt to balance it with your other
            commitments.
          </Text>
          {/* <Text style={s.parabreak} break> */}
          <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>
            3) Look at the effort estimate in relation to potential pay-off.
          </Text>
          <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>
            4) You may consider atleast one change objective for long-term (2 to 3 years).
          </Text>
          <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>
            5) You may consider change objectives that have medium term (1 to 2 years)
            and short-term results (May be 3-12 months)
          </Text>
          <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>
            6) You may consider a combination of growth and maintenance tasks as change
            objectives.
          </Text>
          <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>
            7) You may like to integrate your change objectives with your KRAs.
          </Text>




        </View>

        {/* </Page> */}
        
        {/* next page   */}
        {/* <Page wrap={false}> */}
        <View style={{
          marginLeft: 30,
          marginRight: 30, marginTop: 30
        }}>
          <Text style={[s.head, { fontFamily: 'CustomFont' }]}>  My Individual Development Plan (IDP) </Text>
        </View>

        <View style={
          [s.section, s.st]}
        >

          <Text style={[s.para2]}>
            What is an IDP?
          </Text>
          <Text style={s.parag1}>
            - A written plan that outlines what career goals you want to accomplish and what steps you can take to meet those goals.
          </Text>
          <Text style={s.parag1}>
            - A tool you can use to identify, organize and plan your career.
          </Text>
          <Text style={s.parag1}>
            - A tool for creating a personalized plan that best reflects your career aspirations, whether you want to plan for professional development, promotional opportunities or your personal development.
          </Text>
          <Text style={s.para2}>
            {" "}
          </Text>
          <Text style={s.para2}>
            Why have an IDP?
          </Text>
          <Text style={s.parag1}>
            - To focus your professional efforts and identify individual work goals.
          </Text>
          <Text style={s.parag1}>
            - To use as a communication, development and/or planning tool.
          </Text>
          <Text style={s.parag1}>
            - To assist in obtaining resources
          </Text>
          <Text style={s.parag1}>
            - To serve as your career action plan for skill building, professional development and career management.
          </Text>

          <Text style={s.para2}>
            {" "}
          </Text>
          <Text style={s.para2} break>
            INSTRUCTIONS
          </Text>
          <Text style={s.para2}>
            Getting Started:
          </Text>
          <Text style={s.para2next}>
            Since the IDP asks you to identify and write down your goals, what do you do if you don’t feel ready to set goals? One possibility is that you can use the IDP to jump start your goal setting! If you are not sure what your career goals are then you might try the following:
          </Text>

          <Text style={s.para2}>
            {" "}
          </Text>
          <Text style={[s.parabreak, s.parag1]} break>
            Ask yourself
          </Text>
          <Text style={s.parag1}>
            What areas of my work do I want to develop?
          </Text>
          <Text style={s.parag1}>
            What areas of my work do I need to improve?
          </Text>
          <Text style={s.parag1}>
            What direction is my department/ function/ unit/ company going and what do I need to grow with it?
          </Text>
        </View>
        {/* </Page> */}

        {/* next page   */}
        {/* <Page wrap={false}> */}
        <View style={{
          marginLeft: 30,
          marginRight: 30, marginTop: 30
        }}>
          <Text style={s.head}> Filling out the IDP Form </Text>
        </View>

        <View style={
          [s.section, s.st]}
        >
          <Text style={s.para2}>
            Section 1: My Goals - What are my overall goals that I want to accomplish in this time period?
          </Text>
          <Text style={s.parag1}>
            - Write down the overall goals you want to accomplish in the:
          </Text>
          <Text style={s.para2}>
            Short-term/next 3 to 12 months:
          </Text>
          {short &&
            short.length > 0 &&
            short.map((item, i) => {
              return (
                <View style={{ paddingLeft: 40 }}>


                  <Text style={s.parag1}>
                    {i + 1}. {item.goalname}
                  </Text>
                </View>
              )
            })}
          <Text style={s.parag1}>
            {" "}
          </Text>

          <Text style={s.para2}>
            Mid-term/next 1 to 2 years:
          </Text>

          {mid &&
            mid.length > 0 &&
            mid.map((item, i) => {
              return (
                <View style={{ paddingLeft: 40 }}>


                  <Text style={s.parag1}>
                    {i + 1}.  {item.goalname}
                  </Text>
                </View>
              )
            })}
          <Text style={s.parag1}>
            {" "}
          </Text>
          <Text style={s.para2}>
            Long-term/2 to 3 years:
          </Text>
          {long &&
            long.length > 0 &&
            long.map((item, i) => {
              return (
                <View style={{ paddingLeft: 40 }}>

                  <Text style={s.parag1}>
                    {i + 1}. {item.goalname}
                  </Text>
                </View>
              )
            })}


        </View>
        {/* </Page>                     */}

        {/* next page   */}
        {/* <Page wrap={false}> */}

        <View style={[s.section, s.st]}
        >
          <Text style={s.para2}>
            Section 2: Skills and knowledge to be acquired. What specifically do I need to learn?
          </Text>
          <Text style={s.parag1}>
            - You can identify what skills and knowledge you want to develop; write them down.
          </Text>
          {section1 &&
            section1.length > 0 &&
            section1.map((item, i) => {
              return (
                <View style={{ paddingLeft: 40 }}>


                  <Text style={s.parag1}>
                    {i + 1}.  {item.name}
                  </Text>
                </View>
              )
            })}

          <Text style={s.parag1}>
            {""}
          </Text>

          <Text style={s.para2}>
            Section 3: Next Steps - How am I going to acquire the skills and knowledge?
          </Text>
          <Text style={s.parag1}>
            - Write down specific action steps you need to take to gain the skills or acquire the skills and knowledge you identified.
          </Text>
          {section2 &&
            section2.length > 0 &&
            section2.map((item, i) => {
              return (
                <View style={{ paddingLeft: 40 }}>


                  <Text style={s.parag1}>
                    {i + 1}. {item.name}
                  </Text>
                </View>
              )
            })}
          <Text style={s.parag1}>
            {""}
          </Text>

          <Text style={s.para2}>
            Resources - Section 4: What resources do I need?
          </Text>
          <Text style={s.parag1}>
            - What resources do you need to complete your goals?
          </Text>
          <Text style={s.parag1}>
            - Often a resource may be as simple as getting release time to take a course.
          </Text>
          <Text style={s.parag1}>
            - However, other resources you may need include: funds to pay for outside training, education, memberships, conferences; coverage for your duties; temporarily adjusting your work schedule; getting support from your supervisor or support network. </Text>

          {section3 &&
            section3.length > 0 &&
            section3.map((item, i) => {
              return (
                <View style={{ paddingLeft: 40 }}>


                  <Text style={s.parag1}>
                    {i + 1}.  {item.name}
                  </Text>
                </View>
              )
            })}

        </View>
        {/* </Page> */}

        {/* next page   */}
        {/* <Page wrap={false}> */}

        <View style={[s.section, s.st]}
        >
          <Text style={s.para2}>
            Time Schedule - Section 5: What is my time schedule? When have I completed each step?
          </Text>
          <Text style={s.parag1}>
            - Write down the date you plan to start working on this goal and the date you plan to finish, for example, if you are taking a one-day workshop, then just put down the day of the workshop in both columns.
          </Text>
          <Text style={s.parag1}>
            - Check off and date your progress as each specific step is completed.
          </Text>


        </View>
        {/* </Page> */}
        <View style={[s.section, s.st]}
        >
          <View style={styles.table}>
            <View style={styles.row}>
              <Text style={styles.idpHead}>S.no</Text>
              <Text style={styles.idpHead}>Goal</Text>
              <Text style={styles.idpHead}>Criteria of Completion</Text>
              <Text style={styles.idpHead}>Scheduled Start Date</Text>
              <Text style={styles.idpHead}> Scheduled End Date</Text>
              <Text style={styles.idpHead}>Actual End Date</Text>
              <Text style={styles.idpHead}>Reasons for Deviations (if any)</Text>
            </View>
            {data &&
              data.length > 0 &&
              data.map((item, i) => {
                return (
                  <View style={styles.row}>
                    <Text style={styles.cell}>{i + 1}</Text>
                    <Text style={styles.cell}>{item.goalname}</Text>
                    <Text style={styles.cell}>{item.criteria}</Text>
                    <Text style={styles.cell}>{moment(item.startdate).format("DD-MM-YYYY")}</Text>
                    <Text style={styles.cell}> {moment(item.enddate).format("DD-MM-YYYY")}</Text>
                    <Text style={styles.cell}>{moment(item.actual_end_date).format("DD-MM-YYYY")}</Text>
                    <Text style={styles.cell}>{item.reason_deviation}</Text>
                  </View>
                );
              })}
            {/* Add more rows as needed */}
          </View>
          <View style={s.section}>

            {/* <Text style={s.para3}>
              {" "}
            </Text>
            <Text style={s.para3}>
              {" "}
            </Text>
            <Text style={s.para3}>
              {" "}
            </Text>
            <Text style={s.para3}>
              {" "}
            </Text> */}
            <Text style={s.para3}>
              {" "}
            </Text>
            <Text style={s.para3}>
              {" "}
            </Text>
            <Text style={s.para3}>
              Signoff:                    Individual:                    Reporting Manager:
            </Text>
            <Text style={s.para2} >

              Congratulations! You are ready with your IDP Version 1.0. You can further fine-tune the same by incorporating inputs from your Reporting Manager & HR Head

            </Text>
          </View>
        </View>



        {/* Page Number */}
        <Text
          style={s.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};


Font.register({ family: 'CustomFont', src: customFont });
Font.register({ family: 'ptsans', src: ptsans });



const s = StyleSheet.create({
  row: {
    flexDirection: "row"
  },
  column: {
    flexDirection: "column"
  },
  section: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 1
  },

  list1: {
    listStyleType: "circle"
  },
  para: {
    // textIndent: "2",
    textAlign: "justify",
    letterSpacing: "1",
    fontSize: 12,
    marginTop: 10,
  },
  para2: {
    // textIndent: "2",
    textAlign: "justify",
    letterSpacing: "1",
    fontSize: 13,
    marginTop: 10,
    // fontWeight:'bold',
    // src:Montserrat
    // fontFamily: "Montserrat"
    fontFamily: 'CustomFont'
  },
  para3: {
    letterSpacing: "1",
    fontSize: 12,
    marginTop: 10,
    fontWeight: "bold",
    textAlign: "center",


  },
  para2next: {
    textAlign: "justify",
    letterSpacing: "1",
    fontSize: 12,
    marginTop: 10,
    marginLeft: 30,
    fontFamily: 'ptsans'
  },
  parag1: {
    // textIndent: "2",
    textAlign: "justify",
    lineHeight: "4px",
    // letterSpacing: "1",
    fontSize: 12,
    marginLeft: 30,
    marginTop: 10,
    // src:ptsans
    // fontFamily:'ptsansfamily'
    fontFamily: 'ptsans'

  },
  parabreak: {
    // textIndent: "2",
    textAlign: "justify",
    // letterSpacing: "1",
    fontSize: 12,
    marginLeft: 30,
    marginTop: "15mm",
  },
  paragg2: {
    // textIndent: "2",
    textAlign: "justify",
    // letterSpacing: "1",
    fontSize: 12,
    marginLeft: 50,
    marginTop: 10,
    // src:ptsans
    // fontFamily:'ptsansfamily'
    fontFamily: 'ptsans'

  },
  color: {
    color: "#2D5986",
    fontSize: 10,
    width: "25%",
    marginLeft: 75
  },
  d: {
    fontSize: 10,
    width: "25%",
    marginLeft: -45
  },
  dot: {
    fontSize: 10,
    width: "1%",
    marginLeft: 20
  },
  border: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    paddingBottom: 123,
    marginBottom: 123
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald"
  },
  head: {
    marginTop: "35px",
    textAlign: "center",
    fontWeight: 700,
    color: "white",
    fontSize: 18,
    lineHeight: 1.3,
    marginBottom: "10px",
    backgroundColor: "#666666",
    marginLeft: 10,
    marginRight: 10,
    fontFamily: 'CustomFont'
    // src: Montserrat,
    // fontFamily: "Montserrat",




  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  }, logo: {
    top: "3px",
    left: "450px",
    width: "10%"
  },
  body: {
    paddingTop: 50,
    paddingBottom: 65,
    paddingHorizontal: 2,
    backgroundColor:"#EEEDE7"
  },
  breaking: {
    height: "100%",
    padding: "100%"
  },
  page: {
    width: "210mm",
    minHeight: "297mm",

  },
  rowdata: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 10,
    marginLeft: 150,
    top: 5
  },
  rowdata1: {
    position: "relative",
    margin: 8,
    padding: 0,
    top: 5,
    flexGrow: 1,
    fontSize: 15,
    marginLeft: 150
  },
  rowdata4: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 10,
    top: 7,
    marginLeft: 150
  },
  rowdata2: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 8,
    top: 5,
    marginLeft: 150
  },
  subdata: {
    position: "relative",
    margin: 7,
    padding: 0,
    flexGrow: 1,
    color: "#2D5986",
    fontSize: 10,
    width: "35%"
  },
  subdata1: {
    position: "relative",
    margin: 7,
    marginLeft: 10,
    // marginTop:'23px',
    padding: 0,
    flexGrow: 1,
    color: "red",
    fontSize: 10,
    width: "35%",
    top: 10
  },
  top: {
    marginTop: -130,
    paddingTop: 0
  },
  derailers: {
    marginTop: "15px",
    color: "red",
    // color: "#2D5986",
    fontSize: 10,
    width: "30%",
    marginLeft: "35px",
    textAlign: "center"
  },
  derailers1: {
    marginTop: "15px",
    color: "red",
    // color: "#2D5986",
    fontSize: 10,
    width: "70%",
    marginLeft: "140px"
    // textAlign: "center"
    // marginLeft: "35px",
    // marginTop: "15px",
    // color: "red",
    // fontSize: 10,
    // width: "70%",
    // textAlign: "center"
    // wordWrap: "break-word"
  },
  derailers_under: {
    marginTop: "15px",
    color: "black",
    // color: "#2D5986",
    fontSize: 10,
    width: "50%",
    marginLeft: "15px"
  },
  derailers1_under: {
    // marginLeft: "px",
    marginTop: "15px",
    color: "black",
    fontSize: 10,
    width: "70%",
    textAlign: "justify"
    // wordWrap: "break-word"
  },
  image: {
    height: 50,
    width: 50,
    borderRadius: 100,
    top: 65,
    left: 18
  },
  partOne: {
    width: "25 %"
  },
  partTwo: {
    width: "75 %"
  },
  topHead: {
    textAlign: "center",
    fontWeight: 700,
    top: -30,
    color: "white",
    fontSize: 12,
    backgroundColor: "gray",
    marginLeft: 10,
    marginRight: 10
  },
  idpHead: {
    width: "12.5%",
    fontSize: 10,
    color: "#2D5986"
  },
  idpdata: {
    width: "12.5%",
    fontSize: 10
  },
  rowDate: {
    position: "absolute",
    fontSize: 9,
    width: "12.5%",
    flexGrow: 3,
    marginLeft: 10
  },
  fullrow: {
    position: "absolute",
    fontSize: 9,
    width: "80%",
    flexGrow: 3
  },
  section_2: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 3
  },
  section_3: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 3,
    width: "80%",
    marginLeft: 30
  },
  rowdata5: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 15,
    marginLeft: 150
  },
  width_20: {
    width: "20%",
    fontSize: 10,
    color: "red"
  },
  width_70: {
    width: "70%",
    fontSize: 10
  },
  width_10: {
    width: "10%",
    fontSize: 10,
    marginLeft: 20
  },

  st: { marginLeft: 60, marginRight: 60, marginTop: 40 }

});
const styles = StyleSheet.create({
  row: {
    flexDirection: "row"
  },
  column: {
    flexDirection: "column"
  },
  section: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 1
  },
  idpHead: {
    width: "14.3%",
    fontSize: 10,
    color: "#2D5986",
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'

  },
  idpdata: {
    width: "12.5%",
    fontSize: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    // padding: '4px'
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    width: 'auto'
  },
  cell: {
    width: "14.3%",
    fontSize: 10,
    color: "#000",
    borderRightColor: '#000',
    borderRightWidth: 1,
    // width: 'auto',
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '3px'

    // height: 'auto'
  },

})

export default IDPconsolidate;
