import React from 'react';

const ValidationView = ({errorname}) => {
  return (
    <React.Fragment>
      <div className="row form-group ">
        <div className="col-sm-2" />
        <div className="col-sm-2" />
        <div className="col-sm-5">
          <span className="errorMsg">{errorname}</span>
        </div>
        <div className="col-sm-3" />
      </div>
    </React.Fragment>
  );
};

export default ValidationView;
