import React, { Component, createRef } from "react";
import { FormMiddleWare, SingleSelect } from "../../components/Form";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import Datatable from "../../components/Datatable/Datatable";
import LoginModal from "../../components/Modal/Modal";
import Swal from 'sweetalert2';
// import SugeestImg from "./VidhyaOnlinenew.png"
import SugeestImg from "./E-Learning.jpg"
import moment from 'moment';
import QRCode from "qrcode.react"
import QrScanner from 'qr-scanner';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';





class Traingprogram extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            vidhyaUser: localStorage.getItem("userDetailsVidhya"),
            videoShow: false,
            NominateForm: false,
            Duration: [{ label: "1-Hour", value: 1 },
            { label: "2-Hour", value: 2 },
            { label: "3-Hour", value: 3 },
            { label: "4-Hour", value: 4 },
            ],
            resources: [{ label: "PDF", value: 1 },
            { label: "PPT", value: 2 },
            { label: "vIDEOS", value: 3 },
            { label: "Webinars", value: 4 },
            ],
            nominatesubbtn: false,
            qrCode: null,
            showScanner: false,
            scannedData: null,
            facingMode: 'environment',
            classval: true,
            elearnval: false,

        }
        this.videoRef = createRef();
    }
    async componentWillMount() {
        // console.log(this.state.userId,"state user id");
        let userId = null;
        let yearId = localStorage.getItem("yearId")
        let JobId = localStorage.getItem("jobRoleId")
        if (this.state.userId) {
            userId = this.state.userId;
        } else {
            userId = await localStorage.getItem("userId");
        }
        let adminId = await localStorage.getItem("adminId");

        try {
            let section2Data = await HrMiddleWare.section2(userId);
            // console.log(section2Data, "section2Data");
            let userData = await HrMiddleWare.getUserDetailsyearbased(userId, JobId);
            let section1Data = await HrMiddleWare.section1(userId);
            // console.log(section1Data, "section1Data");
            //programs data get
            let { data } = await HrMiddleWare.gettrainingprgmmap(yearId, adminId, section1Data)

            const vdhyaProgramresult = await HrMiddleWare.getFreedom(
                "*",
                "tbl_mapping_page",
                `categoryId= 310 and customerid=807`,
                1, 1);
            let filtereddata
            await Promise.all(data.map((ival) => {
                filtereddata = vdhyaProgramresult.data.filter(item => ival.vidhyaProgramId.some(item2 => item.pageId == item2))
            }))
            data.map((ival) => {
                filtereddata.map((jval) => {
                    let label = jval.subCategoryName
                    let pageId = jval.pageId
                    let type = "Elearn"
                    ival.trainingprograms.push({ label, pageId, type })
                })
            })

            // const getThemeId = []
            // console.log(userId, "userId");
            if (section1Data) {
                section1Data.data.map(async (ival) => {
                    let GetThemeid = await HrMiddleWare.getRandomData(
                        "tbl_cm_theme_worklevel_competency",
                        `competencyid = ${ival.cmpid} and jobRoleId=${userData.data[0].jobRole} and worklevelid =${userData.data[0].worklevel}`,
                        "*"
                    );
                    if (GetThemeid.data) {
                        GetThemeid.data.map(async (kval) => {
                            let GetThemeName = await HrMiddleWare.getRandomData(
                                "tbl_theme",
                                `id= ${kval.themeid}`,
                                "*"
                            );
                            if (GetThemeName.data) {
                                GetThemeName.data.map((jval) => {
                                    if (ival.cmpid == kval.competencyid && kval.themeid === jval.id) {
                                        ival.themeName = jval.theme
                                    }
                                })
                            }
                        })

                    }

                })
            }
            // console.log(getThemeId, "getThemeId");
            let compList = [
                ...userData.data[0].userBehComp,
                ...userData.data[0].userTechComp
            ];

            let userDetails = await HrMiddleWare.getRandomData(
                "tbl_users",
                `id = ${userId}`,
                "*"
            );

            let jobRole = await HrMiddleWare.getRandomData(
                "tbl_jobRole",
                `id = ${userData.data[0].jobRole} and customerid = ${adminId} and adminId = ${adminId}`,
                "*"
            );
            let jobRoleName = jobRole.data[0].jobRoleName;



            let MappedDerail = await HrMiddleWare.getRandomData(
                "Tblderailers_map,tbl_derailers",
                `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and  
            Tblderailers_map.designationid = ${userDetails.data[0].designation} and
             Tblderailers_map.customerId = ${adminId} and 
             tbl_derailers.id=Tblderailers_map.derailersid`,
                "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
            );
            // console.log(MappedDerail.data)
            ///console.log(MappedDerail.data)
            let behaviourderailers = [];
            let technicalderailers = [];
            let Behavioralpdf = [];
            let Technicalpdf = [];
            MappedDerail.data.map((ival, v) => {
                if (ival.userId) {
                    // console.log(userId)
                    // console.log(ival.userId.includes(userId))
                    if (ival.userId.includes(userId) == true) {
                        // console.log(ival.textType);
                        if (ival.textType == "Technical") {
                            // console.log(ival)
                            technicalderailers.push(<div>
                                <p>{ival.derailers}</p>
                            </div>)
                            Technicalpdf.push(ival.derailers)
                        }
                        else if (ival.textType == "Behavioral") {
                            // console.log(ival)
                            behaviourderailers.push(<div>
                                <p>{ival.derailers}</p>
                            </div>)
                            Behavioralpdf.push(ival.derailers)
                        }


                    }
                }
            })
            // console.log(userDetails.data[0].jobRole);
            let jobdescription = await HrMiddleWare.getRandomData(
                "tbl_jobdescription",
                `jobRole = ${userDetails.data[0].jobRole} and
       department = ${userDetails.data[0].department} and
       customerid = ${adminId} and status ='active'`,
                "*"
            );

            if (jobdescription.data.length) {
                this.setState({ jobdescription: jobdescription.data[0].jobdescription })
            }
            else {
                this.setState({ jobdescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque vitae felis in malesuada. Nulla viverra odio vitae condimentum iaculis. Sed at egestas erat, eu maximus nibh. Pellentesque non lobortis augue, in tincidunt enim. Sed imperdiet, justo non maximus feugiat, arcu elit vestibulum purus, vel semper turpis sem a velit. Quisque vitae lobortis erat. Quisque in quam eu dolor sollicitudin viverra. Nulla sit amet felis vitae neque mollis tempus. Mauris quis velit nec diam finibus porttitor. Suspendisse luctus interdum maximus. Sed rutrum imperdiet feugiat. Pellentesque dictum elit sed quam posuere bibendum. Curabitur porta volutpat sapien, sit amet vulputate lacus gravida ac.' })
            }

            await this.setState({ behaviourderailers, technicalderailers, Behavioralpdf, Technicalpdf })
            await this.setState({ userData: userData.data[0] });
            await this.setState({
                data,
                section1Data: section1Data.data,
                compList,
                section2Data: section2Data.data,
                jobRoleName,
                userDetails: userDetails.data[0],
                adminId, userId
            });
            if (this.qrScanner) {
                this.qrScanner.stop();
                this.qrScanner.destroy();
            }
        } catch (error) {
            console.error(error);
        }
    }

    // componentDidMount() {
    //     this.qrScanner = new QrScanner(
    //       this.videoRef.current,
    //       result => this.setState({ scannedDatas: result }),
    //       {
    //         onDecodeError: error => console.error(error),
    //         highlightScanRegion: true,
    //       }
    //     );
    //     this.qrScanner.start();
    //   }
    // componentDidMount() {
    //     if (this.videoRef.current) {
    //         this.qrScanner = new QrScanner(
    //             this.videoRef.current,
    //             result => {
    //                 this.setState({ scannedData: result })
    //                 // localStorage.setItem("scannerloc", result.locationId)
    //                 // localStorage.setItem("scannertrai", result.trainerId)
    //                 // localStorage.setItem("scannerprgmid", result.programId)
    //             },
    //             {
    //                 onDecodeError: error => console.error('QR Code scan error:', error),
    //                 highlightScanRegion: true,
    //             }
    //         );
    //         this.qrScanner.start().catch(err => console.error('QR Scanner start error:', err));
    //     }
    // }



    column = [
        {
            Header: props => <span style={{ color: "red" }}> Type </span>,
            accessor: "matrixType",
            // Cell: d => this.type(d)
        },
        // {
        //     Header: props => <span style={{ color: "red" }}> Theme </span>,
        //     accessor: "themeName",
        //     // Cell: d => this.theme(d)
        // },
        {
            Header: props => <span style={{ color: "red" }}> Competency </span>,
            accessor: "competencyname"
        },
        // {
        //     Header: props => <span style={{ color: "red" }}> SE </span>,
        //     accessor: "self"
        // },
        // {
        //     Header: props => <span style={{ color: "red" }}> SP </span>,
        //     accessor: "supervisor"
        // },
        // {
        //     Header: props => <span style={{ color: "red" }}> RE </span>,
        //     accessor: "reportee"
        // },
        // {
        //     Header: props => <span style={{ color: "red" }}> PR </span>,
        //     accessor: "peer"
        // },
        // {
        //     Header: props => <span style={{ color: "red" }}> CS </span>,
        //     accessor: "customer"
        // },
        {
            Header: props => <span style={{ color: "red" }}> Avg </span>,
            accessor: "avg"
        },
        {
            Header: props => <span style={{ color: "red" }}> View program </span>,
            accessor: "cmpid",
            Cell: d => this.view(d, "comments")
        }
    ];

    view = (d, modalWindowId) => {
        let { Programs, AllPrograms } = this.state
        // console.log(this.state.classval,"classval");
        // console.log(AllPrograms, "AllPrograms");
        return (
            <LoginModal
                buttonTitle="View"
                title="Training programs"
                id="Comments"
                extraClass="btn btncolor"
                onClick={() => this.commentCon(d)}
                extraStyle={{ backgroundColor: "#56528F" }}
                maxwidth="modal-lg"
                // bodyText={this.state.arr}
                bodyText={
                    <>
                        <div className="row form-group " >
                            <div className="col-sm-2" />
                            <div className="col-sm-8" style={{ textAlign: "center" }}>
                                <ul className="nav nav-tabs" style={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                                    <li className="active" onClick={() => this.setState({ classval: true, elearnval: false })}>
                                        <a data-toggle="tab" href="#classvalue">
                                            Classroom training programs
                                        </a>
                                    </li>

                                    <li onClick={() => this.setState({ elearnval: true, classval: false })}>
                                        <a data-toggle="tab" href="#elearnvalu">
                                            E-learn training programs
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-2" />
                        </div>




                        {this.state.classval && AllPrograms && AllPrograms.length &&
                            AllPrograms.map((jval) => {
                                return (
                                    <>
                                        {
                                            jval.map((kval) => {
                                                return (
                                                    <>
                                                        {/* <h2>Classroom training programs</h2> */}
                                                        <div class="suggest-card">
                                                            <div class="card__corner"></div>
                                                            <p class="card-int__title">{kval.trainingProgram ? kval.trainingProgram : ""}  ({kval.trainerLocation ? kval.trainerLocation : ""}({kval.trainerVenue ? kval.trainerVenue : ""}))</p>
                                                            <div class="card__img">
                                                                {/* <img src={SugeestImg} /> */}
                                                                <span class="card__span">Classroom training programs</span>
                                                            </div>
                                                            <div class="card-int">
                                                                {/* <p class="excerpt">Lorem ipsum dolor sit amet consectetur adipiscing elit, donec suspendisse vulputate dictumst enim per mus imperdiet, platea non massa dictum tempus sapien.</p> */}
                                                                <button class="card-int__buttons btn btncolor" data-dismiss="modal" onClick={() => this.nominate(kval.id, kval.program, kval.trainingProgram)} >Nominate</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })

                                        }
                                    </>
                                )
                            })
                        }

                        {this.state.elearnval && Programs && Programs.map((ival) => {
                            // if (ival && ival.type == "Class") {
                            //     return (
                            //         <>
                            //             {/* <h2>Classroom training programs</h2> */}
                            //             <div class="suggest-card">
                            //                 <div class="card__corner"></div>
                            //                 <p class="card-int__title">{ival.label}</p>
                            //                 <div class="card__img">
                            //                     {/* <img src={SugeestImg} /> */}
                            //                     <span class="card__span">Classroom training programs</span>
                            //                 </div>
                            //                 <div class="card-int">
                            //                     {/* <p class="excerpt">Lorem ipsum dolor sit amet consectetur adipiscing elit, donec suspendisse vulputate dictumst enim per mus imperdiet, platea non massa dictum tempus sapien.</p> */}
                            //                     <button class="card-int__buttons btn btncolor" data-dismiss="modal" onClick={() => this.nominate(ival)} >Nominate</button>
                            //                 </div>
                            //             </div>
                            //         </>
                            //     )
                            // } else 

                            if (ival && ival.type == "Elearn") {
                                return (
                                    <>
                                        {/* <h2>Elearn training programs</h2> */}
                                        <div class="suggest-card">
                                            <div class="card__corner"></div>
                                            <p class="card-int__title">{ival.label}</p>
                                            <div class="card__imgg">
                                                <img src={SugeestImg} style={{ width: "100%" }} />
                                                {/* <span class="card__span">Category</span> */}
                                            </div>
                                            <div class="card-int">
                                                <p class="excerpt">Lorem ipsum dolor sit amet consectetur adipiscing elit, donec suspendisse vulputate dictumst enim per mus imperdiet, platea non massa dictum tempus sapien.</p>
                                                <button class="card-int__buttons btn btncolor" data-dismiss="modal" onClick={() => this.viewVidhyaPrgm(ival.label, ival.pageId)}>View</button>
                                            </div>
                                        </div>
                                    </>
                                )
                            }


                        })}
                    </>
                }

            />
        );
    };

    commentCon = async d => {

        let arr = [];
        d = d.original;
        let mapdata = []
        let average = d.avg - 1
        // console.log(average,"average");

        // let result1 = [];
        // await Promise.all(d.trainingprograms.map(async (ival, i) => {
        //     const result = await HrMiddleWare.getVideoContentById(ival.pageId);
        //     if (result.data && result.data.length) {
        //         let authorimage = result.data[0].authorimage;
        //         let id = result.data[0].id;
        //         result1.push({ id, authorimage });
        //     }
        // }));
        // let uniqueIds = result1.filter((value, index, self) => {
        //     return self.findIndex(item => item.id === value.id) === index;
        // });
        // console.log(uniqueIds,"uniqueIds");
        // console.log(d.trainingprograms,"d.trainingprograms");

        // uniqueIds.forEach((jval, j) => {
        //     d.trainingprograms.forEach((kval, k) => {
        //         if (jval.pageId == kval.pageId) {
        //             kval.image=jval.authorimage
        //             return false
        //         }

        //     })
        // })
        // console.log(d.trainingprograms,"d.trainingprograms");

        d.trainingprograms.map((ival, i) => {


            arr.push(this.iMcon(ival.label, ival.pageId, ival.image, i + 1));

        })

        if (arr && arr.length) {
            await this.setState({ arr, Programs: d.trainingprograms });
            this.programdetailsget(d.trainingprograms)
        } else if (!d.trainingprograms && d.trainingprograms.length == 0) {
            arr = <div style={{ textAlign: "center", fontSize: "20px", fontWeight: "400" }}>No training programs</div>
            await this.setState({ arr });
        }

    };

    iMcon = (trainingProgram, pageId, image, index) => {
        // console.log(trainingProgram, "trainingProgram", pageId, "pageId", image, "image");
        return (
            <div>
                <div style={{ textWrap: "wrap" }} ><span style={{ color: "red" }}>{index}</span> . {trainingProgram}
                    <img src={image}></img>
                    {pageId != null &&
                        // <button className="btn btncolor viewvidhyavideo" data-dismiss="modal" onClick={() => this.viewVidhyaPrgm(trainingProgram,pageId)}>View</button>
                        <span className="viewvidhyavideo" data-dismiss="modal" onClick={() => this.viewVidhyaPrgm(trainingProgram, pageId)} > View training program video</span>
                    }
                </div>
            </div>
        );
    };

    siNo = d => {
        return <label>{d.index + 1}</label>;
    };

    // GetUserData = async () => {
    //     // const EmplyoeeId = 112001
    //     // const Password = "112001"
    //     const EmplyoeeId = 10123
    //     const Password = "coromandel"
    //     try {

    //         const Employee = await HrMiddleWare.getFreedom("*", "cmndl_tbl_Employee", `employee_id= '${EmplyoeeId}' and customerid=807 and status = 0`, 1, 1);
    //         // console.log(Employee,"Employee");
    //         if (Employee && Employee.data.length) {
    //             if (Employee && Employee.data.length) {
    //                 if (Employee.data[0].is_register == 1 && Employee.data[0].userid) {
    //                     const getuserdet = await HrMiddleWare.getFreedom(
    //                         "*",
    //                         "tbl_user_web",
    //                         `id=${Employee.data[0].userid} and status = "active"`,
    //                         1, 1
    //                     )
    //                     // console.log(getuserdet,"getuserdet");
    //                     if (getuserdet && getuserdet.data && getuserdet.data.length) {
    //                         if (Password === getuserdet.data[0].withoutmd5) {
    //                             getuserdet.data[0].Employeeid = EmplyoeeId
    //                             localStorage.setItem("userDetailsVidhya", JSON.stringify(getuserdet.data[0]));
    //                             // geting cource list
    //                             // console.log(this.state.vidhyaUser);
    //                             let result = await HrMiddleWare.getCoursesList(JSON.parse(this.state.vidhyaUser), 310);
    //                             // console.log(result,"result");
    //                             console.log(result.data, 'dat..');
    //                             if (result && result.data) {
    //                                 this.setState({ vidhyaClusterData: result.data })
    //                                 let vidhyaClusterData = result.data
    //                                 let ProgramNames = []
    //                                 if (vidhyaClusterData) {
    //                                     let wait = await vidhyaClusterData.map((ival) => {
    //                                         if (ival.data) {
    //                                             ival.data.map((jval) => {
    //                                                 if (jval.data) {
    //                                                     jval.data.map((kval) => {
    //                                                         // console.log(kval,"kval");
    //                                                         if (kval.subCategoryName) {
    //                                                             let label = kval.subCategoryName
    //                                                             let value = kval.pageId
    //                                                             ProgramNames.push({ label, value })
    //                                                             // ProgramNames.push(kval.subCategoryName)
    //                                                         }
    //                                                     })
    //                                                 }
    //                                             })
    //                                         }
    //                                     })
    //                                     Promise.all(wait)
    //                                     this.setState({ ProgramNames })
    //                                 }
    //                             }
    //                         } else {
    //                             this.setState({ errorUser: true, errorMsg: 'Incorrect password' })
    //                         }
    //                     } else {
    //                         this.setState({ errorUser: true, errorMsg: 'User Not Found' })
    //                     }

    //                 } else {
    //                     // this.setState({ modal15: true })
    //                     // this.register()
    //                 }
    //             }
    //         } else {
    //             // window.location.href = '/'
    //             this.setState({ errorUser: true, errorMsg: 'User Not Found' })
    //         }
    //     } catch (error) { console.log(error) }
    // }

    // getVideo = async () => {
    //     const result = await HrMiddleWare.getVideoContentById(724);
    //     const FILEPATH = "https://video.rem.coach/~remcoach/vidhyaonline/"

    //     console.log(result, "result");
    //     if (result) {
    //         this.setState({ videoUrl: `${FILEPATH}` + result.data[0].filename, })

    //     }

    // }

    // viewVidhyaPrgm = async (trainingProgram, pageId) => {
    //     // console.log(trainingProgram,pageId,"trainingProgram,pageId");
    //     const result = await HrMiddleWare.getVideoContentById(pageId);
    //     const FILEPATH = "https://video.rem.coach/~remcoach/vidhyaonline/"
    //     const trainingProgramName = trainingProgram;

    //     console.log(result.data, "result");
    //     // let videoUrl;
    //     if (result && result.data.length) {
    //         // videoUrl=`${FILEPATH}` + result.data[0].filename
    //         this.setState({ videoShow: true, videoUrl: `${FILEPATH}` + result.data[0].filename, trainingProgramName })

    //     } else {
    //         Swal.fire({
    //             position: 'center',
    //             type: 'question',
    //             title: 'No videos found',
    //             showConfirmButton: false,
    //             timer: 3000
    //         });

    //     }
    //     // console.log(videoUrl,"videoUrl");
    // };
    viewVidhyaPrgm = async (trainingProgram, pageId) => {
        let { userId } = this.state
        let userid = await localStorage.getItem("userId");
        let userType = await localStorage.getItem("userTypeId");

        let userDetailUnPromote = await HrMiddleWare.getRandomData(
            "tbl_users",
            `id = ${userid}`,
            "*"
        );
        if (userDetailUnPromote) {
            localStorage.setItem("userDetails", JSON.stringify(userDetailUnPromote.data[0]));

            if (pageId) {
                if(userType == 5){
                    window.location.href = `/user/elearnVideoContenct/${pageId}`;
                }else{
                    window.location.href = `/hr/elearnVideoContenct/${pageId}`;

                }
            }
            console.log(userDetailUnPromote.data[0], "userDetailUnPromote");
        }
        // 
    };

    back = async () => {
        this.setState({ videoShow: false, NominateForm: false })
    }

    nominate = async (id, prgmid, prgmname) => {
        // console.log(id,prgmid,"id,prgmid");
        let { adminId } = this.state
        // console.log(val,"val");
        this.setState({ NominateForm: true, trainingProgramName: prgmname })
        const { data } = await HrMiddleWare.nominadurresourcecost(id, prgmid, adminId);
        // console.log(data, "data");
        // return false

        await Promise.all(data.map((ival) => {
            // this.state.Duration.map((jval) => {
            //     if (ival.duration == jval.value) {
            //         let label = jval.label
            //         let value = ival.duration
            //         ival.duration = { label, value }
            //     }
            // })
            // this.state.resources.map((jval) => {
            //     if (ival.resource == jval.value) {
            //         let label = jval.label
            //         let value = ival.resource
            //         ival.resource = { label, value }
            //     }
            // })
            if (ival.trainerName && ival.trainer) {
                let label = ival.trainerName
                let value = ival.trainer
                ival.trainerName = { label, value }
            }
            // if (ival.trainerLoc && ival.trainerLocation) {
            //     let label = ival.trainerLocation
            //     let value = ival.trainerLoc
            //     ival.trainerLocation = { label, value }
            // }

        }))
        // console.log(data,"data");
        this.setState({
            programId: prgmid,
            selectedstartdate: data[0].startDate,
            selectedenddate: data[0].endDate,
            // DurationSelected: data[0].duration,
            // resourcesSelected: data[0].resource.label,
            Programcost: data[0].programcost,
            // trainerLocationSelected: data[0].trainerLocation.label,
            // trainerLocationSelectedId: data[0].trainerLocation.value,
            trainerSelected: data[0].trainerName.label,
            trainerSelectedId: data[0].trainerName.value,
            TrainingVenue: data[0].trainerVenue,
            prgmlatitude: data[0].latitude,
            prgmlongitude: data[0].longitude,
            // DateandTime: moment(data[0].DateTime).format("MM-D-YYYY h:mm A"),
            // DateandandTime: data[0].DateTime
        });
        // console.log(this.state.selectedstartdate, "this.state.selectedstartdate");
        // console.log(this.state.selectedenddate, " this.state.selectedenddate");
        if (this.state.programId != (null || "") && this.state.Programcost != (null || "") && this.state.trainerLocationSelected != (null || "") && this.state.trainerSelected != (null || "") && this.state.TrainingVenue != (null || "")
            && this.state.selectedstartdate != "" && this.state.selectedenddate != "" && this.state.selectedstartdate != null && this.state.selectedenddate != null && this.state.prgmlatitude != null && this.state.prgmlongitude != null && this.state.prgmlatitude != "" && this.state.prgmlongitude != "") {
            this.setState({ nominatesubbtn: true })
        }

    };

    programdetailsget = async (program) => {
        let { adminId } = this.state
        let AllPrograms = [];
        await Promise.all(program.map(async (ival) => {
            if (ival && ival.type == "Class") {
                const { data } = await HrMiddleWare.nominateformclassroomprogram(ival.value, adminId);
                // console.log("enter");
                AllPrograms.push(data)
            }
        }))
        this.setState({ AllPrograms: AllPrograms })
    };

    onSubmit = async () => {
        const { adminId, userId, programId, trainerLocationSelectedId, prgmlatitude, prgmlongitude, trainerSelectedId, DateandandTime, selectedstartdate, selectedenddate } = this.state;
        // if (this.validate(classroomProgramsId, 'errorclassroomPrograms', 'Please select classroom training program')) return true;
        // if (this.validate(Duration, 'errorDuration', 'Please select duration')) return true;

        // return false
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('programId', programId);
        formData.append('programTrainer', trainerSelectedId);
        formData.append('programLoc', trainerLocationSelectedId);
        formData.append('prgmlatitude', prgmlatitude);
        formData.append('prgmlongitude', prgmlongitude);
        // formData.append('programDateTime', DateandandTime);
        formData.append('programStartDate', selectedstartdate);
        formData.append('programEndDate', selectedenddate);
        formData.append('adminId', adminId);
        formData.append('formsubmited', "yes");
        console.log([...formData], "formdata");
        // let QRprogramId= programId+trainerLocationSelectedId+trainerSelectedId

        // const previousData = [...this.state.data];
        // return false
        try {
            // console.log(tableName, "tableName");
            // return false
            const result = await HrMiddleWare.createMasterforQR('tbl_nominateform', formData);
            console.log(result, "result");
            if (result) {
                // Swal.fire({
                //     position: 'center',
                //     type: 'success',
                //     title: 'TrainingProgram mapping has been saved',
                //     showConfirmButton: false,
                //     timer: 1500
                // });
                // this.getMaster(tableName);
                this.setState({
                    alertVisible: true,
                    matrixType: '',
                    competencySelected: '',
                    programsSelected: '',
                    yearSelected: '',
                    qrcode: true,
                    QRdata: result.data

                });
                // setTimeout(
                //     window.location.reload(),
                //     4000
                // );
            }
        } catch (error) {
            // this.setState({
            //     data: previousData
            // });
            console.log(error);
        }
    };

    printDiv = () => {
        const input = document.getElementById('printableArea');

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imageWidth = 400; // Set the image width to 400px
            const imageHeight = (imageWidth * canvas.height) / canvas.width;
            const x = (pdfWidth - imageWidth) / 2;
            const y = (pdfHeight - imageHeight) / 2;

            pdf.addImage(imgData, 'PNG', x, y, imageWidth, imageHeight);
            pdf.save('download.pdf');
        });

    };

    render() {
        let { userData, section1Data, data, vidhyaUser, ProgramNames, vidhyaClusterData, videoUrl, videoShow, NominateForm,
            TrainingVenue, trainerOptions, trainerSelected, errortrainerlocation, trainerLocationSelected, trainerLocation,
            Duration, DurationSelected, resources, resourcesSelected, Programcost, updateData, errortrainer, DateandTime, showScanner, scannedData, facingMode } = this.state

        let height = window.innerHeight - 50;
        // let DATA 
        // if (scannedData) {
        //      DATA = JSON.parse(this.state.scannedData)
        //     localStorage.setItem("scannerdata",DATA)
        //     console.log(DATA, "DATA");
        // }
        return (
            <section id="main-content">
                <section className="wrapper" id="wrapper" style={{ height: height }}>
                    <div className="box box-info">
                        <div className="box-header with-border">
                            {/* <h2 className="box-title" style={{ color: "#5a508e" }} >
                                Suggested traing program for {userData && userData.name}
                            </h2> */}
                            <div className="box-tools pull-right">
                                <button
                                    className="btn btn-box-tool"
                                    data-widget="collapse"
                                    title="Collapse"
                                >
                                    <i className="fa fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        {videoShow ?
                            <div class="box-body">
                                <button className="btn btncolor" onClick={this.back}>Back</button>
                                <br />  <br />
                                <div style={{ fontSize: "large", fontWeight: "500", textAlign: "center" }}>{this.state.trainingProgramName}</div>
                                <div>
                                    {videoUrl ?
                                        <video
                                            // crossOrigin
                                            // onContextMenu={(e) => e.preventDefault()}
                                            id="video"
                                            controls
                                            style={{
                                                width: "100%",
                                                margin: "0px 0px",
                                                // maxHeight: 426,
                                                position: 'relative'
                                            }}
                                            // crossOrigin="anonymous"
                                            preload="metadata"
                                            className="player-center"
                                            // ref={ref => {
                                            //   this.ref = ref;
                                            // }}
                                            // onDuration={this.onDuration}
                                            // onProgress={state => {
                                            // }}
                                            src={this.state.videoUrl}
                                            onLoadStart={() => this.setState({ videoloader: true })}
                                            onLoadedData={() => this.setState({ videoloader: false })}
                                            onError={() => this.setState({ videoError: true })}
                                        >



                                        </video>
                                        :
                                        <></>
                                    }
                                </div>

                            </div>
                            : NominateForm ?
                                <div className="box-body">
                                    <div className="wizardCards">
                                        <button className="btn btncolor" onClick={this.back}>Back</button>
                                        <br />  <br />
                                        <div style={{ fontSize: "large", fontWeight: "500", textAlign: "center" }}>Training program : {this.state.trainingProgramName}</div>
                                        {this.state.qrcode && this.state.QRdata ?
                                            <div>
                                                <div className="row form-group " id="printableArea">
                                                    <div className="col-sm-4" />
                                                    <div className="col-sm-4" style={{ textAlign: "center" }}>
                                                        <div className="qr-code-viewer" dangerouslySetInnerHTML={{ __html: this.state.QRdata }}>
                                                        </div>

                                                        {/* {this.state.qrcode && this.state.QRdata && <QRCode className="qrcode" value={this.state.QRdata} />} */}
                                                    </div>
                                                    <div className="col-sm-4" />
                                                </div>
                                                <br /> <br /> <br />
                                                <div class="centered header-color">
                                                    <div className="row">
                                                        <div className="col-sm-10 " />
                                                        <div className="col-sm-2" >
                                                            <button className="btn btncolor" onClick={this.printDiv}>Print PDF</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <button onClick={this.toggleScanner}>Toggle QR Scanner</button>
                                                {showScanner && (
                                                    <QrReader
                                                        delay={300}
                                                        onError={this.handleError}
                                                        onScan={this.handleScan}
                                                        style={{ width: '100%', maxWidth: '500px', margin: '20px auto' }}
                                                    />
                                                )}
                                                {scannedData && <p>Scanned Data: {scannedData}</p>} */}

                                            </div>
                                            :
                                            <div>
                                                {/* <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="trainingprogramvalue">Trainer location</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <SingleSelect selectedService={trainerLocationSelected} disabled={true} />
                                                        <input type="textarea" className="form-control" name="trainerLocationSelected" id="trainerLocationSelected" value={trainerLocationSelected} disabled />

                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group " /> */}
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="trainingprogramvalue">Trainer name</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        {/* <SingleSelect selectedService={trainerSelected} disabled={true} /> */}
                                                        <input type="textarea" className="form-control" name="trainerSelected" id="trainerSelected" value={trainerSelected} disabled />

                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group " />
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="TrainingVenue">Training Venue</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input type="textarea" className="form-control" name="TrainingVenue" id="TrainingVenue" value={TrainingVenue} disabled />
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group " />
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">Date</div>
                                                    <div className="col-sm-2">
                                                        From Date
                                                        <input
                                                            type="date"
                                                            id="myDate"
                                                            className="form-control"
                                                            // onChange={this.fromDateSelect}
                                                            value={this.state.selectedstartdate}
                                                            disabled
                                                        />
                                                        <span className="error-shows">
                                                            {this.state.startdateerror}
                                                        </span>
                                                    </div>
                                                    {/* <div className="col-sm-1"/> */}
                                                    <div className="col-sm-2">
                                                        To Date
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            // onChange={this.endDateSelect}
                                                            value={this.state.selectedenddate}
                                                            disabled
                                                        />
                                                        <span className="error-shows">
                                                            {this.state.endateerror}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-2 error-shows"> </div>
                                                </div>
                                                {/* <div className="row form-group ">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="trainingprogramvalue">Duration</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect options={Duration} selectedService={DurationSelected} disabled={true} />

                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                            <div className="row form-group " /> */}
                                                {/* 
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="trainingprogramvalue">Tr.resources</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input type="textarea" className="form-control" name="resourcesSelected" id="resourcesSelected" value={resourcesSelected} disabled />


                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group " /> */}

                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="Programcost">Program cost</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <input type="number" className="form-control" name="Programcost" id="Programcost" value={Programcost} disabled />
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group " />

                                                {/* <div className="row form-group ">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="DateandTime">Date & Time</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <input type="text" className="form-control" name="DateandTime" id="DateandTime" value={DateandTime} disabled />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                            <div className="row form-group " /> */}

                                                {/*  <div className="row form-group ">
                                                    <div className="col-sm-4" />
                                                    <div className="col-sm-4">
                                                        {!updateData ? (
                                                            <button type="button" className="btn btn-success" onClick={this.onSubmit}
                                                                disabled={this.state.nominatesubbtn ? false : true}
                                                            >
                                                                Submit
                                                            </button>
                                                        ) : (
                                                            <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                                                                Update
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="col-sm-4" />
                                                </div>
                                                <div className="row form-group " />*/}
                                                {/* {this.state.qrcode && this.state.programId && <QRCode value={this.state.programId} />} */}

                                            </div>

                                        }
                                    </div>
                                </div>
                                :

                                <div class="box-body">
                                    {/* <button type="button" className="btn btn-success" onClick={this.getVideo}>
                                    GetVideo
                                </button>
                                <div>
                                    {videoUrl ?
                                        <video
                                            // crossOrigin
                                            // onContextMenu={(e) => e.preventDefault()}
                                            id="video"
                                            controls
                                            style={{
                                                width: "100%",
                                                margin: "0px 0px",
                                                // maxHeight: 426,
                                                position: 'relative'
                                            }}
                                            // crossOrigin="anonymous"
                                            preload="metadata"
                                            className="player-center"
                                            // ref={ref => {
                                            //   this.ref = ref;
                                            // }}
                                            // onDuration={this.onDuration}
                                            // onProgress={state => {
                                            // }}
                                            src={this.state.videoUrl}
                                            onLoadStart={() => this.setState({ videoloader: true })}
                                            onLoadedData={() => this.setState({ videoloader: false })}
                                            onError={() => this.setState({ videoError: true })}
                                        >



                                        </video> : <></>
                                    }
                                </div>
                                <>
                                    <LoginModal
                                        buttonTitle="View program"
                                        title="Programms"
                                        id="Competency"
                                        onClick={this.GetUserData}

                                        extraClass="btn btn-success"
                                        extraStyle={{ backgroundColor: "#56528F", color: "white" }}
                                        maxwidth="modal-lg"
                                        bodyText={
                                            <>
                                                {ProgramNames && ProgramNames.map((ival, i) => {
                                                    return (
                                                        <div className="program-list " style={{ display: "flex", padding: "20px 30px 10px 30px" }}>
                                                            <p className="program-sino" style={{ fontWeight: "bold", }}> {i + 1}</p>.<h5>{ival.subcat}</h5>
                                                            <button className="btn btncolor" onClick={() => this.viewVidhyaPrgm(ival)}>View</button>
                                                        </div>
                                                    )
                                                })}
                                            </>

                                        }
                                    />
                                </> */}
                                    <div>
                                        <div class="centered header-color">
                                            <h3>Competency based suggested traing programs </h3>
                                        </div>
                                        {/* <div>
                                            <button className="btn btncolor" onClick={this.toggleScanner}>QR Scanner</button>
                                           
                                        </div> */}
                                        {/* <div className="qr-code-scanner">
                                            <h1>QR Code Scanner</h1>
                                            <video ref={this.videoRef} className="qr-video"></video>
                                            <div className="scanned-data">
                                                <h2>Scanned Data:</h2>
                                                {this.state.scannedData ? (
                                                    <>
                                                        <pre>locationId id is : {DATA.locationId}</pre>
                                                        <pre>programId id is :{DATA.programId}</pre>
                                                        <pre>trainerId id is :{DATA.trainerId}</pre>
                                                    </>

                                                ) : (
                                                    <p>No data scanned yet.</p>
                                                )}
                                            </div>
                                        </div> */}


                                        <br />
                                        {data && (
                                            <Datatable data={data} columnHeading={this.column} />
                                        )}
                                        {!data && (
                                            <div style={{ textAlign: "center", fontSize: "20px", fontWeight: "400" }}> No training programs mapped</div>
                                        )}

                                    </div>
                                </div>
                        }
                    </div>
                </section>
            </section>
        )

    }
}

export default Traingprogram;