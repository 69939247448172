import React from "react";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import Datatable from "../../components/Datatable/Datatable";
import ValidationView from "../../components/Form/ValidationView";
import Swal from "sweetalert2";

class AddCriticals extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = { addData: [] };
  }

  async componentWillMount() {
    try {
      let adminId = await localStorage.getItem("adminId");
      const Theme = await HrMiddleWare.getRandomData(
        " tbl_theme",
        `customerid = ${adminId} and textType = "Technical" and status = "active"`,
        "theme as label , id as value"
      );
      const competency = await HrMiddleWare.getRandomData(
        "  tbl_competency",
        `customerid = ${adminId} and textType = "Technical" and status = "active"`,
        "competencyname as label , id as value"
      );
      let data = await HrMiddleWare.getRandomData(
        "tbl_tech_comp_criticals",
        `cmpId = ${adminId} and status = 'active'`,
        "*"
      );
      this.setState({
        Theme: Theme.data,
        competency: competency.data,
        addData: data.data,
        adminId
      });
    } catch (error) {
      console.log(error);
    }
  }
  themeSelect = e => {
    this.setState({ themeSelect: e });
  };
  competencySelect = e => {
    this.setState({ competencySelect: e });
  };
  definitionText = e => {
    this.setState({ definitionText: e.target.value });
  };
  importanceText = e => {
    this.setState({ importanceText: e.target.value });
  };
  knowledgeText = e => {
    this.setState({ knowledgeText: e.target.value });
  };
  performanceText = e => {
    this.setState({ performanceText: e.target.value });
  };

  column = [
    {
      Header: "Theme",
      Accessor: "theme",
      Cell: d => this.getValueFromArray(d.original.theme, this.state.Theme)
    },
    {
      Header: "Competency",
      accessor: "competency",
      Cell: d =>
        this.getValueFromArray(d.original.competency, this.state.competency)
    },
    {
      Header: "Definition",
      accessor: "defenition"
    },
    {
      Header: "Importance",
      accessor: "important"
    },
    {
      Header: "Knowledge area",
      accessor: "critical_knowledge"
    },
    {
      Header: "Performance Parameter",
      accessor: "critical_performance"
    }
  ];

  getValueFromArray = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  submitData = async () => {
    const {
      adminId,
      themeSelect,
      competencySelect,
      definitionText,
      importanceText,
      knowledgeText,
      performanceText
    } = this.state;

    if (this.validate(themeSelect, "errorthemeSelect", "Please select Theme"))
      return true;
    if (
      this.validate(
        competencySelect,
        "errorcompetency",
        "Please select competency"
      )
    )
      return true;
    if (
      this.validate(
        definitionText,
        "errorDefinition",
        "Please select definition"
      )
    )
      return true;
    if (
      this.validate(importanceText, "errorimportant", "Please select important")
    )
      return true;
    if (
      this.validate(knowledgeText, "errorknowledge", "Please select knowledge")
    )
      return true;
    if (
      this.validate(
        performanceText,
        "errorperformanceText",
        "Please select performance"
      )
    )
      return true;

    let formData = new FormData();
    formData.append("theme", themeSelect.value);
    formData.append("competency", competencySelect.value);
    formData.append("defenition", definitionText);
    formData.append("important", importanceText);
    formData.append("critical_knowledge", knowledgeText);
    formData.append("critical_performance", performanceText);
    formData.append("cmpId", adminId);
    formData.append("status", "active");
    try {
      let result = await HrMiddleWare.addMaster(
        "tbl_tech_comp_criticals",
        formData
      );
      if (result) {
        let data = await HrMiddleWare.getRandomData(
          "tbl_tech_comp_criticals",
          `cmpId = ${adminId} and status = 'active'`,
          "*"
        );
      let d=  data.data.sort((a,b)=>b.id-a.id)
        this.setState({
          addData:d,
          themeSelect: "",
          competencySelect: "",
          definitionText: "",
          importanceText: "",
          knowledgeText: "",
          performanceText: ""
        });

        Swal.fire({
          position: "center",
          type: "success",
          title: "Technical Details Added ",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) { }
  };
  render() {
    const {
      Theme,
      themeSelect,
      competency,
      competencySelect,
      definitionText,
      importanceText,
      knowledgeText,
      performanceText,
      errorthemeSelect,
      errorcompetency,
      errorDefinition,
      errorimportant,
      errorknowledge,
      errorperformanceText
    } = this.state;
    return (
      <React.Fragment>
        <section id="main-content">
          <section className="wrapper" id="wrapper">
            <div className="container">
              <div className="form-group">
                <div className="col-sm-12 padding-10 bg-white">
                  <div className="">
                    <div className="box-header with-border">
                      <h1 className="box-title">Add Technical Details</h1>
                      <div className="box-tools pull-right">
                        <button
                          className="btn btn-box-tool"
                          data-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="row form-group ">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="year">Theme</label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            options={Theme}
                            handleChange={this.themeSelect}
                            selectedService={themeSelect}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <ValidationView errorname={errorthemeSelect} />
                      <div className="row form-group ">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="year">Competency</label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            options={competency}
                            handleChange={this.competencySelect}
                            selectedService={competencySelect}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <ValidationView errorname={errorcompetency} />
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">Definition</label>
                        </div>
                        <div className="col-sm-5">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter Job Description Text "
                            onChange={this.definitionText}
                            value={definitionText}
                          />
                          {/* <span className="error-shows">
                                            {errorText}
                                        </span> */}
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <ValidationView errorname={errorDefinition} />
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">Importance</label>
                        </div>
                        <div className="col-sm-5">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter Job Description Text "
                            onChange={this.importanceText}
                            value={importanceText}
                          />
                          {/* <span className="error-shows">
                                            {errorText}
                                        </span> */}
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <ValidationView errorname={errorimportant} />
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">
                            Knowledge area
                          </label>
                        </div>
                        <div className="col-sm-5">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter Job Description Text "
                            onChange={this.knowledgeText}
                            value={knowledgeText}
                          />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <ValidationView errorname={errorknowledge} />
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">
                            Performance Parameter
                          </label>
                        </div>
                        <div className="col-sm-5">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter Job Description Text "
                            onChange={this.performanceText}
                            value={performanceText}
                          />
                          {/* <span className="error-shows">
                                            {errorText}
                                        </span> */}
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <ValidationView errorname={errorperformanceText} />
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3" />
                        <div className="col-sm-3">
                          <button
                            className="btn btn-success"
                            class="btncolorsubmit"
                            onClick={this.submitData}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="col-sm-5" />
                      </div>
                      {this.state.addData && (
                        <Datatable
                          data={this.state.addData}
                          columnHeading={this.column}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </React.Fragment>
    );
  }
}

export default AddCriticals;
