import React from 'react';
import Select from 'react-select';
import './Style.css';
import PropTypes from 'prop-types';

const SingleSelect = ({ errorServices, handleChange, selectedService,disabled, options, width, ...res }) => {
  return (
    <React.Fragment>
      <Select className={width} clearValue value={selectedService} onChange={handleChange} options={options} {...res} isDisabled={disabled} placeholder="Select"/>
    </React.Fragment>
  );
};

SingleSelect.propTypes = {
  options: PropTypes.array,
  handleChange: PropTypes.func,
  selectedService: PropTypes.object
};

export default SingleSelect;
