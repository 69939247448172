import http from './httpMiddleWare';
import { ACCESS_POINT } from '../config';

const checkUser = async (tableName, formData) => {
  console.log(`${ACCESS_POINT}/login/loginCheck/${tableName}`);

  const result = await http.post(`${ACCESS_POINT}/login/loginCheck/${tableName}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return result;
};
export default { checkUser };
