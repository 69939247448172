import React, { Component, isValidElement } from "react";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import DatePicker from "../../components/Form/DatePicker";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import moment from "moment";

export default class Idpmodel extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        userId: null,
        goalname:"",
        enddate:"",
        startdate:"",
        criteria:"",
        supportrequired:"",
        IsEdit:false,
        reason_deviation:"",
        actual_end_date:"",
        percentage:"",
        status:{},
        EditId:null,
        StatusList:[{label:"In Progress",value:1},{label:"In Review",value:2},
                            {label:"New",value:3},{label:"Cancelled",value:4}]

      }
    }

     async componentWillReceiveProps(props){
       //  console.log(props);
         if(props.Editbody != undefined  && props.Editbody.id){
              let EditData = props.Editbody;
              let sdate = EditData.startdate ? new Date(EditData.startdate): "";
              let edate = EditData.enddate ? new Date(EditData.enddate): "";
              let adate;
              if (EditData.actual_end_date != '-') {
                   adate = EditData.actual_end_date ? new Date(EditData.actual_end_date): "";
                   this.setState({
                    actual_end_date:adate
               })
              }
             this.setState({
                 IsEdit:true,
                 goalname:EditData.goalname,
                 criteria:EditData.criteria,
                 supportrequired:EditData.supportrequired,
                 reason_deviation:EditData.reason_deviation,
                 startdate: sdate,
                 enddate:edate,
                 EditId:EditData.id,
                //  actual_end_date:adate,
                 status : EditData.status,
                 percentage:EditData.percentage
            })
         }else{
            this.setState({
                IsEdit:false,
                goalname:"",
                criteria:"",
                supportrequired:"",
                reason_deviation:"",
                startdate: "",
                enddate:"",
                EditId:null,
                actual_end_date:"",
                status :"",
                percentage:""
           })
         }
     }
    async componentDidMount() {
         let user_ID = this.props.userId ? this.props.userId : await localStorage.getItem("userId") ;
        this.setState({userId:user_ID,termType:this.props.termType,tech_type:this.props.tech_type,yearid:this.props.yearid});
    }

    handleChange = async e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    changeDate = async (e,a) =>{
        if(a=="startdate"){
            this.setState({startdate:e})
        }else if(a=="enddate"){
        
            this.setState({enddate:e})
        }else{
            this.setState({actual_end_date:e})
        }
    }

    EditHistory=async()=>{
        const{startdate,enddate,goalname,criteria,tech_type,yearid,supportrequired,
            reason_deviation,actual_end_date,status,percentage,termType,userId
            }=this.state;
           // let userId =
            const formData1 = new FormData();
            formData1.append("editedby", await localStorage.getItem("userId"));
           // formData.append("termtype",termType);
            formData1.append("goalname",goalname);
            formData1.append("tech_type",tech_type);
            formData1.append("yearid",yearid);
            formData1.append(
                "startdate",
                moment(startdate).format("YYYY-MM-DD")
              );
              formData1.append(
                "enddate",
                moment(enddate).format("YYYY-MM-DD")
              );
            formData1.append("criteria",criteria);
            formData1.append("tearmtype",termType);
            formData1.append("supportrequired",supportrequired);
            formData1.append("reason_deviation",reason_deviation);
            formData1.append("statusChange",status.label);
            formData1.append("percentage",percentage);
            formData1.append("goal_id",this.state.EditId);
            formData1.append(
                "actual_end_date",
                moment(actual_end_date).format("YYYY-MM-DD")
              );
              console.log([...formData1])
         let EditHistory = await MasterMiddleWare.createMaster("idp_goals_history",formData1);
         console.log(EditHistory);
    }
    Update=async()=>{
        const{startdate,enddate,goalname,criteria,tech_type,yearid,supportrequired,
        reason_deviation,userId,actual_end_date,status,percentage,termType
        }=this.state;
      //  let userId = await localStorage.getItem("userId");
        let formData = new FormData();
        formData.append("userid", userId);
       // formData.append("termtype",termType);
        formData.append("goalname",goalname);
        formData.append("tech_type",tech_type);
        formData.append("yearid",yearid);
        formData.append(
            "startdate",
            moment(startdate).format("YYYY-MM-DD")
          );
          formData.append(
            "enddate",
            moment(enddate).format("YYYY-MM-DD")
          );
        formData.append("criteria",criteria);
        formData.append("supportrequired",supportrequired);
        formData.append("reason_deviation",reason_deviation);
        formData.append("status",status.label);
        formData.append("percentage",percentage);
        formData.append(
            "actual_end_date",
            moment(actual_end_date).format("YYYY-MM-DD")
          );
          console.log([...formData]);
        let result = await MasterMiddleWare.updateMaster("tbl_idp_goalterm",this.state.EditId ,formData); 
          console.log(result); 
       // if(result){
            //let userId = await localStorage.getItem("userId");
           
         if(result){
            // console.log(EditHistory)
         await  this.EditHistory();
         let Array={};
            Array.id=this.state.EditId;
            Array.goalname=goalname;
            Array.userid = userId;
            Array.startdate=moment(startdate).format("YYYY-MM-DD");
            Array.enddate=moment(enddate).format("YYYY-MM-DD");
            Array.criteria=criteria;
            Array.supportrequired=supportrequired;
            Array.tech_type = tech_type;
            Array.yearid = yearid;
            Array.actual_end_date=moment(actual_end_date).format("YYYY-MM-DD");;
            Array.reason_deviation=reason_deviation;
            Array.percentage=percentage;
            Array.status =status.label;
             this.props.these1(termType,Array);
            this.setState({
                goalname:"",
                startdate:"",
                enddate:"",
                actual_end_date:"",
                reason_deviation:"",
                status:{},
                percentage:"",
                criteria:"",
                supportrequired:"",
                IsEdit:false
               });
            }
          //  console.log(EditHistory);
        //}
       // if(result){
            
        //}

    }

    onSubmit=async()=>{
        const{startdate,userId,enddate,goalname,criteria,tech_type,yearid,supportrequired,termType}=this.state;
        //let userId = await localStorage.getItem("userId");
        let formData = new FormData();
        formData.append("userid", userId);
        formData.append("termtype",termType);
        formData.append("goalname",goalname);
        formData.append("tech_type",tech_type);
        formData.append("yearid",yearid);
        formData.append(
            "startdate",
            moment(startdate).format("YYYY-MM-DD")
          );
          formData.append(
            "enddate",
            moment(enddate).format("YYYY-MM-DD")
          );
        formData.append("criteria",criteria);
        formData.append("supportrequired",supportrequired);
        let result = await MasterMiddleWare.createMaster("tbl_idp_goalterm", formData);
        if(result){
            console.log(result);
            let Array={};
            Array.id=result.data.insertId;
            Array.goalname=goalname;
            Array.userid = userId;
            Array.startdate=moment(startdate).format("YYYY-MM-DD");
            Array.enddate=moment(enddate).format("YYYY-MM-DD");
            Array.termtype=termType;
            Array.criteria=criteria;
            Array.supportrequired=supportrequired;
            Array.tech_type = tech_type;
            Array.yearid = yearid;
            Array.actual_end_date=null;
            Array.reason_deviation=null;
            Array.percentage=null;
            Array.status =null;
            
            
            this.setState({
                goalname:"",
                startdate:"",
                enddate:"",
                criteria:"",
                supportrequired:"",
            })
            console.log(Array)
            this.props.these(termType,Array);
        }


        // console.log([...formData])
    }

    handleStatus=async e =>{
        this.setState({status:e})
    }

    render(){
        return(<React.Fragment>
            <div className="row form-group ">
            <div className="col-sm-3" />
        <div className="col-sm-5"><h4>{this.state.AlertText}</h4></div>
            </div>
             <div className="row form-group ">
            <div className="col-sm-2" />
            <div className="col-sm-2">Goal</div>
                <div className="col-sm-5">
                <input
                    className="form-control"
                        rows="3"
                        id="goal"
                        name="goalname"
                        placeholder="Enter the goal"
                        onChange={e=>this.handleChange(e)}
                        value={this.state.goalname}
                    />
                    </div>
            <div className="col-sm-3"/>
            </div>
            <div className="row form-group ">
            <div className="col-sm-2" />
            <div className="col-sm-2">Start date</div>
            <div className="col-sm-2">
            <DatePicker
                startDate={this.state.startdate}
                changeDate={e =>
                this.changeDate(e, "startdate")
                }
            />
            </div>
            <div className="col-sm-1">End date</div>
            <div className="col-sm-2">
            <DatePicker
                startDate={this.state.enddate}
                changeDate={e =>
                this.changeDate(e, "enddate")
                }
            />
            </div>
            </div>

            <div className="row form-group ">
            <div className="col-sm-2" />
            <div className="col-sm-2">Criteria</div>
                <div className="col-sm-5">
                <textarea
                    className="form-control"
                        rows="3"
                        id="goal"
                        name="criteria"
                        placeholder="Enter the criteria"
                        onChange={e=>this.handleChange(e)}
                        value={this.state.criteria}
                    />
                    </div>
            <div className="col-sm-3"/>
            </div>

            <div className="row form-group ">
            <div className="col-sm-2" />
            <div className="col-sm-2">Support Required</div>
                <div className="col-sm-5">
                <textarea
                    className="form-control"
                        rows="3"
                        id="goal"
                        name="supportrequired"
                        placeholder="Enter the required support"
                        onChange={e=>this.handleChange(e)}
                        value={this.state.supportrequired}
                    />
                    </div>
            <div className="col-sm-3"/>
            </div>

            {this.state.IsEdit ? <React.Fragment>
                 
                <div className="row form-group ">
            <div className="col-sm-2" />
            <div className="col-sm-2">Reason Deviation</div>
                <div className="col-sm-5">
                <textarea
                    className="form-control"
                        rows="3"
                        id="goal"
                        name="reason_deviation"
                        placeholder="Enter the reason deviation"
                        onChange={e=>this.handleChange(e)}
                        value={this.state.reason_deviation}
                    />
                    </div>
            <div className="col-sm-3"/>
            </div>
            <div className="row form-group ">
            <div className="col-sm-2"/>
            <div className="col-sm-2">Actual End date</div>
            <div className="col-sm-4">
            <DatePicker
                startDate={this.state.actual_end_date}
                changeDate={e =>
                this.changeDate(e, "actual_end_date")
                }
            />
            </div>
            </div>
            <div className="row form-group ">
            <div className="col-sm-2" />
            <div className="col-sm-2">Status</div>
                <div className="col-sm-5">
                <SingleSelect
                        handleChange={this.handleStatus}
                        options={this.state.StatusList}
                        selectedService={this.state.status}
                     />
                    </div>
            <div className="col-sm-3"/>
            </div>

            <div className="row form-group ">
            <div className="col-sm-2" />
            <div className="col-sm-2">Progress Percentage</div>
                <div className="col-sm-5">
                <input
                    className="form-control"
                       type="number"
                        rows="3"
                        id="goal"
                        name="percentage"
                        placeholder="Enter the percentage"
                        onChange={e=>this.handleChange(e)}
                        value={this.state.percentage}
                    />
                    </div>
            <div className="col-sm-3"/>
            </div>
            </React.Fragment> : null}

            <div className="row form-group ">
                        <div className="col-sm-4"/>
                        <div className="col-sm-4">
                        <button
                          type="button"
                          className={this.state.IsEdit ? "btn btn-primary": "btn btn-success"}
                          onClick={this.state.IsEdit ? this.Update :this.onSubmit}
                          data-dismiss="modal"
                           >
                        {this.state.IsEdit ? "Update" : "Submit"} </button>
                         </div>
                     </div>
        </React.Fragment>)
    }
}