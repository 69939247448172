import React from 'react';

const Footer = () => {
  return (
    <footer className="main-footer">
      <strong>
        Copyright <a href="https://www.difuza.com" target='_blank'>difuza</a>.
      </strong>
      All rights reserved.
    </footer>
  );
};

export default Footer;
