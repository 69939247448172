import React, { Component } from "react";
import { Route } from "react-router-dom";
import Hr from "../screens/Hr/Hr";
import HrHeader from "../screens/Header/HrHeader";
import EmployeeList from "../screens/Hr/EmployeeList";
import WizardForm from "../screens/Hr/WizardForm";
import Dashboard from "../screens/Hr/Dashboard";
import Mappingemployee from "../screens/Hr/Mappingemployee";
import RoleandCompetencymatrix from "../screens/Hr/RoleandCompetencymatrix";
import AddCriticals from "../screens/Hr/AddCriticals";
import Derailers from "../screens/Hr/Derailers";
import Hradduser from "../screens/Hr/Hradduser";
import CompetencyMatrix from '../screens/Hr/CompetencyMatrix';
import Dictionary from '../screens/Hr/Dictionary';
import DictionaryView from '../screens/Hr/DictionaryView';
import ComptencyReport from '../screens/Hr/ComptencyReport';
import ConsolidateReport from '../screens/Hr/ConsolidateReport';
import Organogram from '../screens/Hr/Organogram';
import DictionaryWiz from '../screens/Hr/DictionaryWiz';
import TechCompDictionary from '../screens/Hr/TechCompDictionary';
import IDPHistory from '../screens/Hr/IDPHistory';
import Newdictionary from '../screens/Hr/Newdictionary';
import NewdictionaryView from '../screens/Hr/NewdictionaryView';
import ViewProfile from '../screens/Hr/ViewProfile';
import Hr_userConsreport from "../screens/User/hr_userConsreport";
import Addidppage from "../screens/Hr/Addidppage";
import ManageReporteeidp from "../screens/Hr/ManageReporteeIdp"
import HrWizardForm from "../screens/Hr/hrWizardForm";
import Updateprofile from "../screens/Hr/Updateprofile";
import Traingprogram from "../screens/Hr/sugestedTraingProgram";
import QrScanner from "../screens/Hr/QrScanner";
import VideoContent from "../screens/Hr/VideoContent";
import TraingprogramAdd from '../screens/Hr/HrTraingprogramAdd'
import TraingprogramMapping from '../screens/Hr/HrTraingprogramMapping'
import CoursemapwithTrainer from '../screens/Hr/HrCoursemapwithTrainer'

//@import url('../../node_modules/react-select/dist/react-select.css');
//@import url(http://fonts.googleapis.com/css?family=Ruda:400,700,900)

class HrRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path={"/hr"}
          render={props => (
            <div>
              <HrHeader />
              <Hr {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/hr/EmployeeList"}
          render={props => (
            <div>
              <HrHeader />
              <EmployeeList {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/hrWizardForm"}
          render={props => (
            <div>
              <HrHeader />
              <HrWizardForm {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/hr/wizardForm"}
          render={props => (
            <div>
              <HrHeader />
              <WizardForm {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/hr/dashboard"}
          render={props => (
            <div>
              <HrHeader />
              <Dashboard {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/mappingemployee"}
          render={props => (
            <div>
              <HrHeader />
              <Mappingemployee />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Users"}
          render={props => (
            <div>
              <HrHeader />
              <Hradduser />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Derailers"}
          render={props => (
            <div>
              <HrHeader />
              <Derailers {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/hr/RoleandCompetencymatrix"}
          render={props => (
            <div>
              <HrHeader />
              <RoleandCompetencymatrix />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Matrix"}
          render={props => (
            <div>
              <HrHeader />
              <CompetencyMatrix {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/AddCriticals"}
          render={props => (
            <div>
              <HrHeader />
              <AddCriticals {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Dictionary"}
          render={props => (
            <div>
              <HrHeader />
              <Dictionary {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/DictionaryView"}
          render={props => (
            <div>
              <HrHeader />
              <DictionaryView {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Report"}
          render={props => (
            <div>
              <HrHeader />
              <ComptencyReport {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Updateprofile"}
          render={props => (
            <div>
              <HrHeader />
              <Updateprofile {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/ConsolidateReport"}
          render={props => (
            <div>
              <HrHeader />
              <ConsolidateReport {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Organogram"}
          render={props => (
            <div>
              <HrHeader />
              <Organogram {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/DictionaryWiz"}
          render={props => (
            <div>
              <HrHeader />
              <DictionaryWiz {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/TechCompDictionary"}
          render={props => (
            <div>
              <HrHeader />
              <TechCompDictionary {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/IDPHistory"}
          render={props => (
            <div>
              <HrHeader />
              <IDPHistory {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Newdictionary"}
          render={props => (
            <div>
              <HrHeader />
              <Newdictionary {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/NewdictionaryView"}
          render={props => (
            <div>
              <HrHeader />
              <NewdictionaryView {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/ViewProfile"}
          render={props => (
            <div>
              <HrHeader />
              <ViewProfile {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/hr/Hr_userConsreport/:userid?"}
          render={props => (
            <div>
              <HrHeader />
              <Hr_userConsreport {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/Addidppage"}
          render={props => (
            <div>
              <HrHeader />
              <Addidppage {...props} />
            </div>
          )}
        />

        <Route
          exact
          path={"/hr/manageIdppage/:id?"}
          render={props => (
            <div>
              <HrHeader />
              <ManageReporteeidp {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/sugestedTraingProgram"}
          render={props => (
            <div>
              <HrHeader />
              <Traingprogram {...props} />
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/QrScanner"}
          render={props => (
            <div>
              <HrHeader />
              <QrScanner {...props} />
            </div>
          )}
        />
          <Route
          exact
          path={'/Hr/HrTraingprogramAdd'}
          render={props => (
            <div className="wrapper">
              <HrHeader />
              <div className="content-wrapper">
                <TraingprogramAdd {...props} />
              </div>
            </div>
          )}
        />
         <Route
          exact
          path={'/Hr/HrTraingprogramMapping'}
          render={props => (
            <div className="wrapper">
              <HrHeader />
              <div className="content-wrapper">
                <TraingprogramMapping {...props} />
              </div>
            </div>
          )}
        />
        <Route
          exact
          path={'/Hr/HrCoursemapwithTrainer'}
          render={props => (
            <div className="wrapper">
              <HrHeader />
              <div className="content-wrapper">
                <CoursemapwithTrainer {...props} />
              </div>
            </div>
          )}
        />
        <Route
          exact
          path={"/hr/elearnVideoContenct/:id"}
          render={props => (
            <div>
              <HrHeader />
              <VideoContent {...props} />
            </div>
          )}
        />
      
      </React.Fragment>
    );
  }
}

export default HrRoutes;
