import React from "react";
import LoginModal from "../../components/Modal/Modal";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import ValidationView from "../../components/Form/ValidationView";
import Datatable from "../../components/Datatable/Datatable";
import "./framwork.css";
import './style.css'

var btoa = require("btoa");

class Dictionary extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      show: false,
      // show1: true
      // show2: false,
      dictionaryhide: false,
      textType: '',
      themeOptions: [],
      themeOptionSelected: '',
      designationOptions: [],
      designationSelected: '',
      workLevelOptions: [],
      workLevelSelected: '',
      mapped_derailers_data: [],
      chanestate: 1

    };
  }
  async componentWillMount() {
    let adminId = await localStorage.getItem("adminId");
    let userId = await localStorage.getItem("userId");
    try {


      let newthemedata = await HrMiddleWare.getRandomData('ClusterMaster,tbl_theme', 'ClusterMaster.themeId=tbl_theme.id group by themeId ', 'ClusterMaster.themeId');
      //  console.log(newthemedata.data)
      var newthemeid = '';
      newthemedata.data.map((i) => {
        //console.log(i)
        newthemeid += i.themeId + ',';
      })
      // console.log(newthemeid)
      var newThemeid = newthemeid.replace(/,\s*$/, "");

      // console.log(newthemeid,"newthemeid");
      let themeList = await HrMiddleWare.getRandomData(
        "tbl_theme",
        ` id  not in (${newThemeid}) and customerid=${adminId} and textType='Behavioral' `,
        "theme as label,id as value"
      );
      let themeList1 = await HrMiddleWare.getRandomData(
        "tbl_theme",
        ` id not in (${newThemeid}) and customerid=167 and textType='Behavioral' `,
        "theme as label,id as value"
      );
      // console.log(themeList1,"themeList1");
      // console.log(themeList,"themeList");
      let framWorkList = await HrMiddleWare.getRandomData(
        "tbl_competencyframework,tbl_competency,tbl_theme,tbl_worklevel",
        `tbl_theme.id=tbl_competencyframework.theme and tbl_competency.id=tbl_competencyframework.competency and tbl_worklevel.id=tbl_competencyframework.worklevel Order by tbl_theme.id ASC`,
        "tbl_competency.competencyname,tbl_competency.Description,tbl_theme.theme,tbl_worklevel.worklevel,tbl_competency.id as cid,tbl_theme.id as tid,tbl_worklevel.id as wid"
      );
      // console.log(framWorkList,"framWorkList");
      let defaultList = await HrMiddleWare.getRandomData(
        "tbl_competencyframework,tbl_competency,tbl_theme,tbl_worklevel",
        `tbl_theme.id=tbl_competencyframework.theme and tbl_competency.id=tbl_competencyframework.competency  and tbl_competencyframework.status='default' and tbl_worklevel.id=tbl_competencyframework.worklevel Order by tbl_theme.id ASC`,
        "tbl_competency.competencyname,tbl_competency.Description,tbl_theme.theme,tbl_worklevel.worklevel,tbl_competency.id as cid,tbl_theme.id as tid,tbl_worklevel.id as wid,tbl_competencyframework.status"
      );

      let workLeveList = await HrMiddleWare.getRandomData(
        "tbl_worklevel",
        `customerid=${adminId} order by worklevel ASC`,
        "worklevel as label,id as value"
      );
      let workLeveList1 = await HrMiddleWare.getRandomData(
        "tbl_worklevel",
        `customerid=167 order by worklevel ASC`,
        "worklevel as label,id as value"
      );
      // console.log(workLeveList1,"workLeveList1");
      // console.log(workLeveList,"workLeveList");
      this.getSelectBoxMaster(
        adminId,
        "tbl_designation",
        "id",
        "designation",
        "designationOptions"
      );


      this.getSelectBoxMaster(
        adminId,
        "tbl_worklevel",
        "id",
        "worklevel",
        "workLevelOptions"
      );


      let subThemeList = themeList.data.filter((ival, i) => {
        if (i < 5) {
          return ival;
        }
      });
      this.setState({
        themeList: themeList.data,
        themeList1: themeList1.data,
        adminId,
        userId,
        framWorkList: framWorkList.data,
        defaultList: defaultList.data,
        workLeveList: workLeveList.data,
        workLeveList1: workLeveList1.data,
        subThemeList
      });
    } catch (error) {
      console.log(error);
    }
  }
  addComp = () => {
    console.log(0);



  };

  defaultList = () => {
    this.setState({ show: true });
  };
  bacfun = () => {
    this.setState({ show: false });
  };
  onSubmit = async () => {
    let {
      themeSelect,
      workSelect,
      competency,
      competencyDef,
      competencyImp,
      adminId
    } = this.state;

    if (this.validate(themeSelect, "errorthemeSelect", "Please select Theme"))
      return true;
    if (
      this.validate(workSelect, "errorworkSelect", "Please select Work-level")
    )
      return true;
    if (
      this.validateText(
        competency,
        "errorcompetency",
        "Please enter Competency"
      )
    )
      return true;
    if (
      this.validateText(
        competencyDef,
        "errorcompetencyDef",
        "Please enter Definition"
      )
    )
      return true;
    if (
      this.validateText(
        competencyImp,
        "errorcompetencyImp",
        "Please select Importance"
      )
    )
      return true;
    let value1 = {};
    value1.themeId = themeSelect.value;
    value1.competencyname = competency;
    value1.Description = competencyDef;
    value1.CompetencyImportant = competencyImp;
    value1.textType = "Behavioral";
    value1.status = "active";
    value1.customerid = adminId;
    value1.adminId = adminId;

    let value2 = {};
    value2.theme = themeSelect.value;
    value2.worklevel = workSelect.value;
    value2.status = adminId;
    let body = {};
    body.tn1 = "tbl_competency";
    body.tn2 = "tbl_competencyframework";
    body.val1 = value1;
    body.val2 = value2;
    try {
      const result = await HrMiddleWare.multiInsert(body);
      if (result) {
        console.log(body);
        this.setState({
          themeSelect: "",
          workSelect: "",
          competency: "",
          competencyDef: "",
          competencyImp: ""
        })
      }
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };
  innerContent = (ia, ja) => {
    // console.log(ia,"ia",ja,"ja");
    // console.log(framWorkList,"framWorkList");
    let res = this.state.framWorkList.filter((ival, i) => {
      if (ival.wid === ja && ival.tid === ia) {
        return ival;
      }
    });
    // console.log(res, "res");
    let arr = [];
    if (res && res.length) {
      let result = res.map((ival, i) => {
        // console.log(ival,"ivalss");
        let val = btoa(
          `${ival.tid},${ival.wid},${ival.cid},${ival.competencyname},${ival.Description},11`
        );
        // console.log(val,"val");
        arr.push(

          <tr>
            <td
              style={{
                padding: "5px 5px 5px 5px",
                borderBottom: "0.5px solid #1d95af"
              }}
            >
              <a
                href="#"
                onClick={() => window.open(`DictionaryView?${val}`, "_blank")}
                style={{ color: "#000" }}
              >
                {ival.competencyname}
              </a>
            </td>
          </tr>
        );
      });
      return <table style={{ width: "100%" }}>{arr}</table>;
    }
    // return "--------";
  };
  innerContent1 = (ia, ja) => {
    // console.log(ia,"ia",ja,"ja");
    let res = this.state.framWorkList.filter((ival, i) => {
      if (ival.wid === ja && ival.tid === ia) {
        return ival;
      }
    });
    // console.log(res, "res");
    let arr = [];
    if (res && res.length) {
      let result = res.map((ival, i) => {
        // console.log(ival,"ivalss");
        let val = btoa(
          `${ival.tid},${ival.wid},${ival.cid},${ival.competencyname},${ival.Description},12`
        );
        // console.log(val,"val");
        arr.push(

          <tr>
            <td
              style={{
                padding: "5px 5px 5px 5px",
                borderBottom: "0.5px solid #1d95af"
              }}
            >
              <a
                href="#"
                onClick={() => window.open(`DictionaryView?${val}`, "_blank")}
                style={{ color: "#000" }}
              >
                {ival.competencyname}
              </a>
            </td>
          </tr>
        );
      });
      return <table style={{ width: "100%" }}>{arr}</table>;
    }
    // return "--------";
  };


  handleRadioInput = async e => {
    this.setState({ [e.target.name]: e.target.value });
    let targetValue = e.target.value;
    let adminId = this.state.adminId;
    this.setState({ targetValue: targetValue })

    try {


      let themeOptions = await HrMiddleWare.getRandomData(
        "tbl_theme",
        `textType='${targetValue}' and adminId = ${this.state.adminId}`,
        "tbl_theme.theme as label ,tbl_theme.id as value"
      );

      if (themeOptions) {
        this.setState({
          themeOptions: themeOptions.data

        });
      }

    } catch (error) {
      console.error(error);
    }

  }
  mappedderailersview = () => {


    if (this.state.chanestate == 1) {
      this.setState({ dictionaryhide: true, chanestate: 2 })
    }
    else {
      this.setState({ dictionaryhide: false, chanestate: 1 })
    }

  }

  themeOptionselect = (e) => {
    console.log(e)
    this.setState({ themeOptionSelected: e })
  }


  viewderailers = async () => {
    const {
      groupcompId,
      workLevelId,
      adminId,
      description,
      themeOptionSelected,
      designationSelected,
      designationId,
      mapped_derailers_data,
      textType,
      targetValue
    } = this.state;

    console.log(workLevelId)
    console.log(adminId)
    console.log(textType)
    console.log(themeOptionSelected)
    console.log(designationId)


    if (
      this.validate(
        targetValue,
        "errorthemetype",
        "Please Select Type"
      )
    )
      return true;

    if (
      this.validate(
        workLevelId,
        "errorWorkLevelSelected",
        "Please Select Work Level"
      )
    )
      return true;


    if (
      this.validate(
        themeOptionSelected,
        "errorthemeOptionSelected",
        "Please Select Work Level"
      )
    )
      return true;

    if (
      this.validate(
        designationId,
        "errordesignationSelected",
        "Please Select Designation"
      )
    )
      return true;



    let getdatatable = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      // `textType='${targetValue}' and designationid=${designationId} and worklevelid=${workLevelId} and themeid=${themeOptionSelected.value}  and tbl_derailers.id=Tblderailers_map.derailersid and Tblderailers_map.customerId = ${this.state.adminId} `,
      `textType='${targetValue}' and designationid=${designationId} and worklevelid=${workLevelId} and themeid=${themeOptionSelected.value}  and tbl_derailers.id=Tblderailers_map.derailersid and Tblderailers_map.customerId = ${this.state.adminId} `,
      "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
    );
    console.log(getdatatable.data)
    this.setState({ mapped_derailers_data: getdatatable.data })

  }

  column_view = [
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,

      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Derailers </span>,
      accessor: "derailers"
    },
    {
      Header: props => (
        <span style={{ fontWeight: "bold" }}> Description </span>
      ),
      accessor: "description"
      // Cell: d => this.NEW(d)
    }

  ];


  render() {
    let {
      themeList,
      themeList1,
      themeSelect,
      workLeveList,
      workLeveList1,
      workSelect,
      competency,
      competencyDef,
      competencyImp,
      errorthemeSelect,
      errorworkSelect,
      errorcompetency,
      errorcompetencyDef,
      errorcompetencyImp,
      framWorkList,
      subThemeList,
      defaultList,
      show,
      textType,
      themeOptions,
      themeOptionSelected,
      designationOptions,
      designationSelected,
      workLevelSelected,
      workLevelOptions,
      mapped_derailers_data,
      errorWorkLevelSelected,
      errorthemeOptionSelected,
      errordesignationSelected,
      errorthemetype
    } = this.state;
    themeList = show ? subThemeList : themeList;
    framWorkList = show ? defaultList : framWorkList;
    return (
      <section id="" style={{padding:"10px 30px"}}>
        <section className="wrapper" id="wrapper">
          <div>
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Competency Dictionary</h3>
                <div className="box-tools pull-right">
                  <button
                    className="btn btn-box-tool"
                    data-widget="collapse"
                    title="Collapse"
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="box-body">
                <div className="row form-group">
                  <div className="col-sm-3" />
                  <div className="col-sm-3" >

                    <button
                      type="button"
                      className="newview-btn-new"
                      onClick={() => this.mappedderailersview()}
                    >
                      View Mapped Derailers
                    </button>
                  </div>
                  <div className="col-sm-2">
                    {!this.state.show && (
                      <LoginModal
                        buttonTitle="Add Competency"
                        title="Add Competency"
                        id="Competency"
                        extraClass="newview-btn-new"
                        extraStyle={{ backgroundColor: "#56528F" }}
                        bodyText={
                          <div>
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-2">Theme</div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  handleChange={d =>
                                    this.selectBoxStore("themeSelect", d)
                                  }
                                  options={themeList}
                                  selectedService={themeSelect}
                                  placeholder="Select Theme"
                                />
                              </div>
                            </div>
                            <ValidationView errorname={errorthemeSelect} />
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-2">Work-Level</div>
                              <div className="col-sm-5">
                                <SingleSelect
                                  handleChange={d =>
                                    this.selectBoxStore("workSelect", d)
                                  }
                                  options={workLeveList}
                                  selectedService={workSelect}
                                  placeholder="Select Work-level"
                                />
                              </div>
                            </div>
                            <ValidationView errorname={errorworkSelect} />
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-2">Competency</div>
                              <div className="col-sm-5">
                                <input
                                  type="text"
                                  name="competency"
                                  placeholder="Enter Competency"
                                  className="form-control"
                                  onChange={this.handleUserInput}
                                  value={competency}
                                />
                              </div>
                            </div>
                            <ValidationView errorname={errorcompetency} />
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-2">
                                Competency Definition
                              </div>
                              <div className="col-sm-5">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Competency Definition"
                                  name="competencyDef"
                                  onChange={this.handleUserInput}
                                  value={competencyDef}
                                />
                              </div>
                            </div>
                            <ValidationView errorname={errorcompetencyDef} />
                            <div className="row form-group">
                              <div className="col-sm-1" />
                              <div className="col-sm-2">
                                Competency Definition
                              </div>
                              <div className="col-sm-5">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Competency Importaant"
                                  name="competencyImp"
                                  onChange={this.handleUserInput}
                                  value={competencyImp}
                                />
                              </div>
                            </div>
                            <ValidationView errorname={errorcompetencyImp} />

                            <div className="row form-group">
                              <div className="col-sm-3" />
                              <div className="col-sm-5">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={this.onSubmit}
                                >
                                  Add Competency
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                  {this.state.show ? (
                    <div className="row form-group">
                      <div className="col-sm-10"></div>
                      {this.state.show && (
                        <div className="col-sm-1">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.bacfun}
                          >
                            Back
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="col-sm-3">
                        <button
                          type="button"
                          className="newview-btn-new"
                          onClick={this.defaultList}
                        >
                          Default Competency
                        </button>
                      </div>
                      <div className="col-sm-1">
                        <button
                          type="button"
                          className="newview-btn-new"
                          onClick={this.addComp}
                        >
                          Print
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {!this.state.dictionaryhide && this.state.show == false && (<div>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "14%" }}>THEMES</th>
                        {workLeveList &&
                          workLeveList.map((ival, i) => {
                            return (
                              <th
                                style={{
                                  borderLeft: "1px solid white",
                                  width: "14%"
                                }}
                              >
                                {ival.label}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {themeList &&
                        themeList.map((ival, i) => {
                          return (
                            <tr>
                              <td
                                style={{
                                  padding: "5px 5px 5px 10px",
                                  border: "0.5px solid #1d95af",
                                  borderBottom: "2.5px solid #1d95af"
                                }}
                              >
                                {ival.label}
                              </td>
                              {workLeveList &&
                                workLeveList.map((jval, j) => {
                                  return (
                                    <td
                                      valign="top"
                                      style={{
                                        border: "0.5px solid #1d95af",
                                        borderBottom: "2.5px solid #1d95af",
                                        padding: "0px"
                                      }}
                                    >
                                      {this.innerContent(ival.value, jval.value)}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>)}
                {!this.state.dictionaryhide && this.state.show == true && (<div>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "14%" }}>THEMES</th>
                        {workLeveList1 &&
                          workLeveList1.map((ival, i) => {
                            return (
                              <th
                                style={{
                                  borderLeft: "1px solid white",
                                  width: "14%"
                                }}
                              >
                                {ival.label}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {themeList1 &&
                        themeList1.map((ival, i) => {
                          return (
                            <tr>
                              <td
                                style={{
                                  padding: "5px 5px 5px 10px",
                                  border: "0.5px solid #1d95af",
                                  borderBottom: "2.5px solid #1d95af"
                                }}
                              >
                                {ival.label}
                              </td>
                              {workLeveList1 &&
                                workLeveList1.map((jval, j) => {
                                  return (
                                    <td
                                      valign="top"
                                      style={{
                                        border: "0.5px solid #1d95af",
                                        borderBottom: "2.5px solid #1d95af",
                                        padding: "0px"
                                      }}
                                    >
                                      {this.innerContent1(ival.value, jval.value)}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>)}
                {this.state.dictionaryhide && (
                  <div>

                    <div className="row form-group ">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-2">
                        <label>Theme Type</label>
                      </div>
                      <div class="col-sm-5">
                        <label>
                          <input type="radio" id="Behavioral" name="textType" value="Behavioral" checked={textType === 'Behavioral' && true} onChange={this.handleRadioInput} />
                          &nbsp;&nbsp;&nbsp;Behavioral
                        </label>
                        &nbsp;&nbsp;&nbsp;
                        <label>
                          <input type="radio" id="Technical" name="textType" value="Technical" checked={textType === 'Technical' && true} onChange={this.handleRadioInput} /> &nbsp;&nbsp;&nbsp;Technical
                        </label>
                      </div>
                      <div class="col-sm-3" />
                    </div>



                    <ValidationView errorname={errorthemetype} />
                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="competency"> Theme/Function</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.themeOptionselect}
                          options={themeOptions}
                          selectedService={themeOptionSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <ValidationView errorname={errorthemeOptionSelected} />
                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="competency">Designation</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.designationSelect}
                          options={designationOptions}
                          selectedService={designationSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <ValidationView errorname={errordesignationSelected} />

                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="competency">Work Level</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.workLevelSelectsingal}
                          options={workLevelOptions}
                          selectedService={workLevelSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <ValidationView errorname={errorWorkLevelSelected} />

                    <div className="row form-group ">
                      <div className="col-sm-4" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={this.viewderailers}
                        >
                          submit
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {mapped_derailers_data && (
                      <Datatable data={mapped_derailers_data} columnHeading={this.column_view} />
                    )}
                  </div>


                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Dictionary;
