import React, { Component } from 'react';
import LoginModal from "../../components/Modal/Modal";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import ValidationView from "../../components/Form/ValidationView";
import './framwork.css';
var atob = require('atob');

class DictionaryWiz extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  async componentWillMount() {

    try {

    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <section id="" style={{padding:"10px 30px"}}>
        <section className="wrapper" id="wrapper">

          <div class="wizard">
            <div class="wizard-inner">
              <div className="col-sm-12 padding-10 bg-white">
              </div>
              <form>
                <div class="tab-content">
                  <div className="col-sm-12 padding-10 bg-white">
                    <div class="tab-pane active" role="tabpanel" id="step1">
                      <div class="col-sm-4" style={{ textAlign: "-webkit-center" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ padding: "18px" }}
                          onClick={() =>
                            window.open(
                              "/hr/Dictionary",
                              "_blank"
                            )
                          }
                        >
                          Behavioral Competency
                                    </button>
                      </div>
                      <div class="col-sm-4" style={{ textAlign: "-webkit-center" }}>
                        <button
                          type="button"
                          className="btn btn-warning"
                          style={{ padding: "18px" }}
                          onClick={() =>
                            window.open(
                              "/hr/TechCompDictionary",
                              "_blank"
                            )
                          }
                        >
                          Technical Competency
                                    </button>
                      </div>
                      <div class="col-sm-4" style={{ textAlign: "-webkit-center" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ padding: "18px" }}
                          onClick={() =>
                            window.open(
                              "/hr/Newdictionary",
                              "_blank"
                            )
                          }
                        >
                          Behaviour Dictionary-2
                                    </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default DictionaryWiz;