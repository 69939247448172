import React, { Component } from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import Datatable from "../../components/Datatable/Datatable";
import image from "../../image/18-128.png";
import LoginModal from "../../components/Modal/Modal";
import html2canvas from "html2canvas";
import * as jsPDF from "jspdf";
import "./style.css";

class ProfileCompReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      section2Data: [],
      userid: this.props.userid
    };
  }
  async componentWillMount() {
    let userId = this.state.userid;

    let adminId = await localStorage.getItem("adminId");
    try {
      let section2Data = await HrMiddleWare.section2(userId);
      let userData = await HrMiddleWare.getUserDetails(userId);
      let compList = [
        ...userData.data[0].userBehComp,
        ...userData.data[0].userTechComp
      ];
      let section1Data = await HrMiddleWare.section1(userId);
      // console.log(section1Data);

      let userDetails = await HrMiddleWare.getRandomData(
        "tbl_users",
        `id = ${userId}`,
        "*"
      );

      let jobRole = await HrMiddleWare.getRandomData(
        "tbl_jobRole",
        `id = ${userData.data[0].jobRole} and customerid = ${adminId} and adminId = ${adminId}`,
        "*"
      );
      let jobRoleName = jobRole.data[0].jobRoleName;
      await this.setState({ userData: userData.data[0] });
      await this.setState({
        compList,
        section1Data: section1Data.data,
        section2Data: section2Data.data,
        jobRoleName,
        userDetails: userDetails.data[0]
      });
    } catch (error) {
      console.error(error);
    }
  }
  column = [
    {
      Header: props => <span style={{ color: "red" }}> Type </span>,
      accessor: "textType"
      // Cell: d => this.type(d, this.state.compList)
    },
    // {
    //   Header: props => <span style={{ color: "red" }}> Theme </span>,
    //   accessor: "theme",
    //   Cell: d => this.theme(d)
    // },
    {
      Header: props => <span style={{ color: "red" }}> Competency </span>,
      accessor: "comp"
    },
    {
      Header: props => <span style={{ color: "red" }}> SE </span>,
      accessor: "self"
    },
    {
      Header: props => <span style={{ color: "red" }}> SP </span>,
      accessor: "supervisor"
    },
    {
      Header: props => <span style={{ color: "red" }}> RE </span>,
      accessor: "reportee"
    },
    {
      Header: props => <span style={{ color: "red" }}> PR </span>,
      accessor: "peer"
    },
    {
      Header: props => <span style={{ color: "red" }}> CS </span>,
      accessor: "customer"
    },
    {
      Header: props => <span style={{ color: "red" }}> Avg </span>,
      accessor: "avg"
    },
    {
      Header: props => <span style={{ color: "red" }}> Status </span>,
      accessor: "avg",
      Cell: d => this.need(d.original.avg)
    },
    {
      Header: props => <span style={{ color: "red" }}> View Comments </span>,
      accessor: "cmpid",
      Cell: d => this.view(d, "comments")
    }
  ];
  need = d => {
    if (d < 2) {
      return "Need Improvement";
    } else {
      return "Good";
    }
  };
  view = (d, modalWindowId) => {
    return (
      <LoginModal
        buttonTitle="View"
        title="Over All Strength & Area of Improvement"
        id="Comments"
        extraClass="btn btncolor"
        onClick={() => this.commentCon(d)}
        extraStyle={{ backgroundColor: "#56528F" }}
        maxwidth="modal-lg"
        bodyText={this.state.arr}
      />
    );
  };
  commentCon = async d => {
    let arr = [];
    d = d.original;
    if (d.seComment && d.seComment.length) {
      d.seComment.map((ival, i) => {
        arr.push(this.iMcon(ival.commentedby, ival.area, ival.strength));
      });
    }
    if (d.csComment && d.csComment.length) {
      d.csComment.map((ival, i) => {
        arr.push(this.iMcon(ival.commentedby, ival.area, ival.strength));
      });
    }
    if (d.prComment && d.prComment.length) {
      d.prComment.map((ival, i) => {
        arr.push(this.iMcon(ival.commentedby, ival.area, ival.strength));
      });
    }
    if (d.reComment && d.reComment.length) {
      d.reComment.map((ival, i) => {
        arr.push(this.iMcon(ival.commentedby, ival.area, ival.strength));
      });
    }
    if (d.spComment && d.spComment.length) {
      d.spComment.map((ival, i) => {
        arr.push(this.iMcon(ival.commentedby, ival.area, ival.strength));
      });
    }
    await this.setState({ arr });
  };
  iMcon = (by, area, str) => {
    return (
      <div>
        <div style={{ color: "red" }}>{by}</div>
        <div>STRENGTH : {area}</div>
        <div>AREA OF IMPROVEMENT : {str}</div>
      </div>
    );
  };
  type = (d, compList) => {
    console.log(d);
    // let { compList } = this.state;
    let ar = "";
    if (compList) {
      compList.map((ival, i) => {
        console.log(ival);
        if (d.original.cmpid == ival.competencyid) {
          // ar = ival.textType;
          return ival.textType;
        }
      });
    }
    return "ar";
  };
  theme = d => {
    let { compList } = this.state;
    let ar;
    compList.filter((ival, i) => {
      if (d.original.cmpid == ival.competencyid) {
        ar = ival.theme;
      }
    });
    return ar;
  };
  column1 = [
    {
      Header: props => <span style={{ color: "red" }}> Theme </span>,
      accessor: "theme"
    },
    {
      Header: props => <span style={{ color: "red" }}> Competency </span>,
      accessor: "compname"
    },
    {
      Header: props => <span style={{ color: "red" }}> Customer </span>,
      accessor: "customer"
    },
    {
      Header: props => <span style={{ color: "red" }}> Peer </span>,
      accessor: "peer"
    },
    {
      Header: props => <span style={{ color: "red" }}> Reportee </span>,
      accessor: "reportee"
    },
    {
      Header: props => <span style={{ color: "red" }}> Supervisor </span>,
      accessor: "supervisor"
    },
    {
      Header: props => <span style={{ color: "red" }}> Avg </span>,
      accessor: "avg"
    }
  ];
  content = section1Data => {
    let arr1 = [];
    section1Data.map((ival, l) => {
      let arr = [];
      arr.push(<td>{ival.comp}</td>);
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 6; j++) {
          if (i === 0 && j === Math.round(ival.self)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 1 && j === Math.round(ival.reportee)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 2 && j === Math.round(ival.supervisor)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 3 && j === Math.round(ival.peer)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 4 && j === Math.round(ival.customer)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (i === 5 && j === Math.round(ival.avg)) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else if (
            ((j === i) === 0 && isNaN(ival.self)) ||
            (j === 0 && i === 1 && isNaN(ival.reportee)) ||
            (j === 0 && i === 2 && isNaN(ival.supervisor)) ||
            (j === 0 && i === 3 && isNaN(ival.peer)) ||
            (j === 0 && i === 4 && isNaN(ival.customer))
          ) {
            arr.push(
              <td>
                <img style={{ width: 20 }} src={image}></img>
              </td>
            );
          } else {
            arr.push(<td>-</td>);
          }
        }
      }
      arr1.push(<tr>{arr}</tr>);
    });
    return arr1;
  };
  loops = () => {
    let arr = [];
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 6; j++) {
        arr.push(<td>{j}</td>);
      }
    }
    return arr;
  };
  viewfun = () => {
    document.getElementsByClassName("Hidebutton")[0].style.display = "none ";
    //document.getElementsByClassName('testclass')[0].style.display = 'none ';
    const input = document.getElementById("main-content");
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
    });
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 1, 1, width, height);
      setTimeout(function() {
        //alert('Hello');
        document.getElementsByClassName("Hidebutton")[0].style.display =
          "block ";
        //   document.getElementsByClassName('testclass')[0].style.display = 'block ';
      }, 2000);

      pdf.save("download.pdf");
    });
  };
  render() {
    const {
      userData,
      section1Data,
      section2Data,
      jobRoleName,
      userDetails,
      compList
    } = this.state;
    console.log(section1Data);
    console.log(compList);
    return (
      <section className="wrapper">
        <div className="box box-info">
          <div className="box-header with-border">
            <h2 className="box-title" style={{ color: "#5a508e" }}>
              Competency Report of {userData && userData.name}
            </h2>
            <div className="box-tools pull-right">
              <button
                className="btn btn-box-tool"
                data-widget="collapse"
                title="Collapse"
              >
                <i className="fa fa-minus"></i>
              </button>
            </div>
          </div>
          <div></div>
          <div class="box-body" style={{ padding: "0px", fontSize: "10px" }}>
            <div>
              <div class="centered header-color">
                <h3>Section 1 - Summary of Rating</h3>
              </div>
              <br />
              {section1Data && (
                <Datatable data={section1Data} columnHeading={this.column} />
              )}
              <div className="row form-group" style={{ paddingTop: 20 }}>
                <div className="col-sm-12">
                  <b style={{ color: "Red" }}>SE - </b> &nbsp;&nbsp;
                  <b style={{ color: "black" }}>Self</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <b style={{ color: "Red" }}>RE - </b> &nbsp;&nbsp;
                  <b style={{ color: "black" }}>Reportees</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <b style={{ color: "Red" }}>SP - </b> &nbsp;&nbsp;
                  <b style={{ color: "black" }}>Supervisior</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <b style={{ color: "Red" }}>CS - </b> &nbsp;&nbsp;
                  <b style={{ color: "black" }}>Customer</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <b style={{ color: "Red" }}>PR - </b> &nbsp;&nbsp;
                  <b style={{ color: "black" }}>Peer</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
            <br />
            <div>
              <div class="centered header-color">
                <div className="row">
                  <div className="col-sm-2 " />
                  <div className="col-sm-8 ">
                    <h3>Section 2 - Total Rating Average Report</h3>
                  </div>
                </div>
              </div>
              <br />
              {section2Data && (
                <Datatable data={section2Data} columnHeading={this.column1} />
              )}
            </div>
            <br />
            <div>
              <div class="centered header-color">
                <h3>Section 3 - Frequency Distribution Report</h3>
              </div>
              <br />
              <table
                className="table table-bordered dt-responsive nowrap example"
                cellspacing="0"
                width="100%"
                style={{ border: "1px solid #080808" }}
              >
                <thead>
                  <tr align="center">
                    <th>Competency Name</th>
                    <th colspan="6">Self </th>
                    <th colspan="6">Reportees</th>
                    <th colspan="6">Supervisior</th>
                    <th colspan="6">Peer</th>
                    <th colspan="6">Customer</th>
                    <th colspan="6">Average</th>
                  </tr>
                </thead>
                <tbody>
                  <tr align="center">
                    <td></td>
                    {this.loops()}
                  </tr>
                  {section1Data && this.content(section1Data)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ProfileCompReport;
