import React from 'react';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import { FormMiddleWare, SingleSelect } from '../../../components/Form';
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";
import { FilePond } from 'react-filepond';
import { Style } from "react-style-tag";
import WizardPrograss from './wizardPrograssbar';
import WizardNextPreviousButton from './wizardNextPreviousButton';
import { reject, result } from 'lodash';
import { error } from 'jquery';

const tableName = `tbl_theme`;

class Themes extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      theme: null,
      errortheme: null,
      textType: null,
      errortextType: null,
      alertVisible: false,
      updateData: false,
      index: null,
      excelHead: [
        { label: "SI.NO", key: "id" },
        { label: "THEME", key: "theme" },
        { label: "TYPE", key: "textType" },

      ],
      excelHeadmultidata: [
        // { label: "SI.NO", key: "id" },
        { label: "theme", key: "Theme" },
        { label: "textType", key: "ThemeType" },
      ],
      addUserColumn: [

        {
          label: "ThemeType", value: 1, type: "selectbox", selectOptions: [
            { label: "Behavioral", value: 1 },
            { label: "Technical", value: 2 }
          ]
        },
        { label: "Theme", value: 2, type: "text" },

      ],
      statusArray: [],
      multyIndicatorsAdd: true,
      userAddRow: "",
      RowCount: "",
      showDatatable: true,
      submitDataAlert: false,

    };
  }

  async componentWillMount() {
    this.getMaster(tableName);
  }

  /**
   * column will be main for the table to dispay the selected value
   * Header : title for the table
   * accessor : key in the josn which was sent to the table
   * Cell: used to create custom button for the filed in the table
   *
   * link : Ref this link for more info : https://github.com/tannerlinsley/react-table/tree/v6
   */
  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'Theme',
      accessor: 'theme'
    },
    {
      Header: 'Type',
      accessor: 'textType'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }
  ];

  onSubmit = async () => {
    const { theme, textType, adminId } = this.state;
    if (this.validate(textType, 'errortextType', 'Please select type')) return true;
    if (this.validate(theme, 'errortheme', 'Please enter theme')) return true;
    const formData = new FormData();
    formData.append('theme', theme);
    formData.append('textType', textType);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.createMaster(tableName, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Themes has been saved',
          showConfirmButton: false,
          timer: 1500
        });
        this.getMaster(tableName);
        this.setState({
          alertVisible: true,
          theme: '',
          textType: ''
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  buttonEdit = value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    this.setState({
      index,
      theme: getData.theme,
      textType: getData.textType,
      errortheme: '',
      errortextType: '',
      alertVisible: false,
      updateData: true
    });
  };

  onUpdate = async () => {
    // get all the state value and assign the variable
    const { theme, textType } = this.state;
    if (this.validate(textType, 'errortextType', 'Please select type')) return true;
    if (this.validate(theme, 'errortheme', 'Please enter theme')) return true;
    let index = this.state.index;

    /**
     * create a new json array with the update value from the form
     * this array will be passed to the backend for updating the user
     */
    const formData = new FormData();
    formData.append('theme', theme);
    formData.append('textType', textType);

    // get all the user
    const previousData = [...this.state.data];

    // get only the edit user from the list of user
    const getData = { ...previousData[index] };
    const id = getData.id;
    getData.theme = theme;
    getData.textType = textType;

    // get all the user except the edit user
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, getData); // update the user value in the list at particular index

    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param formData : json list value for update
       */
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Theme has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          data,
          alertVisible: true,
          theme: '',
          textType: '',
          updateData: false
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };
  submitcsv = async (e) => {
    await this.setState({ btnDisablecsv: true })

    // const groupcompId = await localStorage.getItem('groupcompId');
    const { theme, textType, adminId, files } = this.state;
    const formData = new FormData();
    formData.append("file", files[0]);
    // formData.append('groupcompId', groupcompId)
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    const previousData = [...this.state.data];
    // console.log(...formData,"formdata");
    // console.log(tableName,"tableName");
    // return false
    try {
      const result = await MasterMiddleWare.csvUpload(tableName, formData);
      // console.log(result, "result csv");
      // const result = await MasterMiddleWare.createMaster(tableName, formData);
      let Timing = 3000
      if (result.data.Message == "success") {
        if (result.data.csvHeaderErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvHeaderErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else if (result.data.csvDataErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvDataErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else {

          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Themes has been saved',
            showConfirmButton: false,
            timer: 1500
          });

        }
        this.getMaster(tableName);
        this.setState({
          alertVisible: true,
          theme: '',
          textType: ''
        });
        setTimeout(() => {
          window.location.reload()
        }, Timing);

      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  }

  singlemultiselect = (e, val) => {
    this.setState({
      multi: val
    });
  };

  handleFileUpload = (fileItems) => {
    this.setState({
      files: fileItems.map((fileItem) => fileItem.file),
    });
  };

  handleSelectChange = async (value, rowIndex, columnLabel) => {
    let { adminId } = this.state
    // console.log(value, "value");
    // console.log(rowIndex, "rowIndex");
    // console.log(columnLabel, "columnLabel");
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: columnLabel === "Theme" || columnLabel === "ThemeType" ? value.label : value.value } : row
      )
    }));
  };

  addRow = (value) => {
    console.log(value, "values");
    let { userAddRow, statusArray } = this.state
    // this.setState({ statusArray: [] })
    let addIndex = value ? statusArray.length :0

    const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({
      ThemeType: "",
      ThemeTypeSelect: "",
      ThemeTypeOption: [
        { label: "Behavioral", value: 1 },
        { label: "Technical", value: 2 }
      ],
      Theme: "",
      index:value ? addIndex:index

    }));
    this.setState(prevState => ({
      statusArray: [...prevState.statusArray, ...newItems]
    }));

    console.log(statusArray, "statusArray");
  }

  handleInputChange = (e, rowIndex, columnLabel) => {
    const { value } = e.target;
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel]: value } : row
      )
    }));
  };

  addMultySubmit = async () => {
    let { statusArray, adminId } = this.state
    // await this.setState({ btnDisablecsv: true })
    // console.log(statusArray, "statusArray");

    return new Promise((resolve, reject) => {
      this.setState({  submitDataAlert: true })
      setTimeout(() => {
        this.setState({ submitDataAlert: false })
        resolve()
      }, 3000)
    })
      .then(() => {
        const formData = new FormData();
        formData.append("statusArray", JSON.stringify(statusArray));
        // formData.append('groupcompId', groupcompId)
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        const previousData = [...this.state.data];
        return MasterMiddleWare.insertMuldidata(tableName, formData);
      })
      .then(result => {
        if (result.data.Message == "success") {
          if (result.data.fillDataErrorMsg) {
            const maxLength = 29;
            const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
            console.log(wrappedErrorMsg, "wrappedErrorMsg");
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: wrappedErrorMsg,
              showConfirmButton: false,
              timer: 6000
            });
            // Swal.fire({
            //   position: 'center',
            //   type: 'warning',
            //   title: `${result.data.fillDataErrorMsg}`,
            //   showConfirmButton: false,
            //   timer: 2000

            // });
          } else {

            Swal.fire({
              position: 'center',
              type: 'success',
              title: 'Themes has been saved',
              showConfirmButton: false,
              timer: 1500
            });
            this.getMaster(tableName);
            this.setState({
              alertVisible: true,
              theme: '',
              textType: '',
              btnDisablecsv: false,
              statusArray: "",
              userAddRow: ""
            });
            setTimeout(() => {
              window.location.reload()
            }, 3000);
          }

        }
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      })
  }

  render() {
    const { data, theme, textType, errortextType, errortheme, updateData, excelHead,
      addUserColumn,
      userAddRow,
      RowCount,
      statusArray,
      showDatatable } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Themes</h3>
              <WizardPrograss step={6} />
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              {this.state.multyIndicatorsAdd ?
                <div className="wizardCards">

                  <div className="row form-group ">
                    <div className="col-sm-4" />
                    <div className="col-sm-3" onClick={(e) => this.singlemultiselect(e, 1)} >
                      <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency">Add single theme type </label>
                    </div>
                    {/* <div className="col-sm-1" /> */}
                    <div className="col-sm-3" onClick={(e) => this.singlemultiselect(e, 2)}>
                      <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency"> Add multiple theme type </label>
                    </div>
                    <div className="col-sm-2" />
                  </div>
                  {this.state.multi == 2 ?
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label htmlFor="exampleInputEmail1">
                            Upload Files:
                          </label>
                        </div>
                        <div className="col-sm-5 filepond">
                          <FilePond
                            // type="file"
                            files={this.state.files}
                            // className="custom-file-input"
                            id="customFile"
                            // accept='.csv'
                            acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                            // onChange={this.fileupload}
                            // files={this.state.Testimonial_image}
                            onupdatefiles={this.handleFileUpload}
                            allowReorder={true}
                            allowMultiple={false}
                            width={10}
                            maxFileSize={'300KB'}
                            // imageValidateSizeMaxWidth={550}
                            // imageValidateSizeMaxHeight={355}
                            imagePreviewMinHeight="150"
                            imagePreviewMaxHeight="150"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                          />
                          {/* <label
   className="custom-file-label"
   htmlFor="customFile"
 >
   {this.state.csv ? this.state.csv.name : 'Choose File'}
 </label> */}
                        </div>
                      </div>
                      <br />
                      <div className="row-form-group">
                        <div className="col-sm-4" />
                        {/* <div className="col-sm-2" /> */}
                        <div className="col-sm-2">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => this.submitcsv(e)}
                            disabled={this.state.btnDisablecsv ? true : false}
                          >
                            {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                            {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                          </button>
                        </div>
                      </div>
                      <br />
                      {/* <div className="row form-group">
                        <div className="col-md-2">
                          <a href="../../files/themeSample.csv" download="themeSample.csv">
                            download sample.csv
                          </a>
                        </div>
                        <div className="col-md-3" />
                      </div> */}
                      <div className="row form-group">
                        <div className="col-md-2">
                          <button className='btn btn-primary' style={{ marginTop: "-15px" }} onClick={() => this.setState({ multyIndicatorsAdd: false, showDatatable: false })}>Create  theme CSV file</button>
                          {/* <button onClick={this.downloadSampleCsv}>Download CSV</button> */}
                        </div>
                        <div className="col-md-3" />
                      </div>
                    </div>
                    :
                    <div>
                      <div className="row form-group ">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-2">
                          <label> Theme Type</label>
                        </div>
                        <div class="col-sm-5">
                          <label>
                            <input type="radio" id="Behavioral" name="textType" value="Behavioral" checked={textType === 'Behavioral' && true} onChange={this.handleUserInput} />
                            &nbsp;&nbsp;&nbsp;Behavioral
                          </label>
                          &nbsp;&nbsp;&nbsp;
                          <label>
                            <input type="radio" id="Technical" name="textType" value="Technical" checked={textType === 'Technical' && true} onChange={this.handleUserInput} /> &nbsp;&nbsp;&nbsp;Technical
                          </label>
                        </div>
                        <div class="col-sm-3" />
                      </div>
                      <div className="row form-group ">
                        <div className="col-sm-2" />
                        <div className="col-sm-2" />
                        <div className="col-sm-5">
                          <span className="errorMsg">{errortextType}</span>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group ">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label htmlFor="theme">Theme</label>
                        </div>
                        <div className="col-sm-5">
                          <input type="text" className="form-control" name="theme" id="theme" placeholder="Please enter theme" value={theme} onChange={this.handleUserInput} />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group ">
                        <div className="col-sm-2" />
                        <div className="col-sm-2" />
                        <div className="col-sm-5">
                          <span className="errorMsg">{errortheme}</span>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group ">
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                          {!updateData ? (
                            <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                              Submit
                            </button>
                          ) : (
                            <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                              Update
                            </button>
                          )}
                        </div>
                        <div className="col-sm-4" />
                      </div>
                      <WizardNextPreviousButton step={6} nextPage={"Master/Function"} previousPage={"Master/WorkLevel"} />
                    </div>
                  }
                </div>

                :
                <>
                  <button className='btn btn-primary' onClick={() => this.setState({ multyIndicatorsAdd: true, showDatatable: true })}>Back</button>
                  <br></br>

                  <>
                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="indicators">How many themes add ?</label>
                      </div>
                      <div className="col-sm-5">
                        <input type="number" className="form-control" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                      </div>
                      <div className="col-sm-3" >
                        <button className='btn btn-primary' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                        {/* <button className='btn btn-info' onClick={()=>this.setState({statusArray:[]})}>Clear</button> */}
                      </div>
                      {/* {statusArray && statusArray.length ?
                        <div className="row form-group ">
                          <div className="col-sm-10" />
                          <div className="col-sm-2">
                            <CSVLink
                              headers={this.state.excelHeadmultidata}
                              data={statusArray}
                              filename={"theme.xls"}
                              className="btn btncolor"
                              target="_blank"
                            >
                              Generate Excel
                            </CSVLink>
                          </div>
                        </div>
                        :
                        <></>} */}
                    </div>


                    <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No </th>

                            {addUserColumn.map((index) => (
                              <th key={index}> {index.label}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                            // console.log(ival,"type",i,"i")

                            <tr>
                              <td style={{ textAlign: "center" }} >{i + 1}</td>
                              {addUserColumn.map((jval, index) => (
                                <>
                                  <td style={{ textAlign: "center" }} >{jval && jval.type === "text" ? <>
                                    <textarea
                                      style={{ height: "50px", width: "280px" }}
                                      // type="text"
                                      value={ival[jval.label]}
                                      onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                    /></> : jval.type === "selectbox" ?
                                    <div style={{ width: "280px" }}>
                                      <SingleSelect
                                        handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                        options={ival[jval.label + 'Option']}
                                        selectedService={ival[jval.label + "Select"]}
                                      // disabled={false}
                                      />

                                      {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                    </div>
                                    :
                                    <>
                                    </>}
                                  </td>
                                </>
                              ))}
                            </tr>
                          ))}

                        </tbody>
                      </table>
                      {this.state.submitDataAlert ?
                        <div className='loader1'></div>
                        :
                        <>
                          {statusArray && statusArray.length > 0 &&

                            <div className="row form-group ">
                              <div className="col-sm-1" >
                                <button className='btn btn-primary' onClick={() => this.addRow(1)}>Add one</button>
                              </div>
                              <div className="col-sm-1">
                                <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                                  Submit
                                </button>
                              </div>
                              <div className="col-sm-1">
                                <CSVLink
                                  headers={this.state.excelHeadmultidata}
                                  data={statusArray}
                                  filename={"theme.xls"}
                                  className="btn btncolor"
                                  target="_blank"
                                >
                                  Generate Excel
                                </CSVLink>
                              </div>

                            </div>
                          }
                        </>
                      }
                    </div>
                    {/* {statusArray && statusArray.length > 0 &&
                      <div className="row form-group " style={{ marginTop: "20px" }}>
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                          <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                            Submit
                          </button>

                        </div>
                        <div className="col-sm-4" />
                      </div>
                    } */}

                  </>

                </>

              }
              {data && data.length && showDatatable ?
                <div className="row form-group ">
                  <div className="col-sm-10" />
                  <div className="col-sm-2">
                    <CSVLink
                      headers={excelHead}
                      data={data}
                      filename={"Theme.xls"}
                      className="btn btncolor"
                      target="_blank"
                    >
                      Generate Excel
                    </CSVLink>
                  </div>
                </div>
                :
                <></>}
              {data && showDatatable && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
        <Style>
          {`/*
Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
Template Version: 1.0
Author: Carlos Alvarez
Website: http://blacktie.co
Premium: http://www.gridgum.com
*/

/* Import fonts */
@import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);

/* BASIC THEME CONFIGURATION */
.filepond{
  height: 82px;
  background-color: #f1f1f1;
  border-radius: 20px;
  width: 300px;
}

.filepond--credits{
  color:#f7f7f7;
}

.filepond--root :not(text) {
  font-size: 13px;
}

.multi:hover{
  color: limegreen;
}


`}

        </Style >
      </React.Fragment>
    );
  }
}

export default Themes;
