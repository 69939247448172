import React, { Component } from "react";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import "./style.css";
import Swal from "sweetalert2";
import queryString from "query-string";

class ProfileCompMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      id: this.props.id,
      companyId: null,
      jobRoleId: null,
      jobRoleName: null,
      workLevelId: null,
      workLevelName: null,
      departmentName: null,
      matrixType: null,
      financialYearId: null,
      functionName: null,
      themeList: [],
      fullCompList: [],
      indicatorList: [],
      levelList: [],
      checkSelected: false,
      selectedCompetency: null,
      indicatorsSelected: [],
      themeSelected: null,
      indicatorClicked: false,
      alreadySelectedIndicator: [],
      originalMappedArray: []
    };
  }

  async componentDidMount() {
    let jobRoleId = await localStorage.getItem("jobRoleId");
    let jobRoleName = await localStorage.getItem("jobRoleName");
    let workLevelId = await localStorage.getItem("workLevelId");
    let workLevelName = await localStorage.getItem("workLevelName");
    let financialYearId = await localStorage.getItem("financialYearId");
    let departmentName = await localStorage.getItem("departmentName");
    let functionName = await localStorage.getItem("functionName");
    let matrixType = await localStorage.getItem("type");
    let companyId = this.state.userId;

    console.log(this.state.id);
    console.log(companyId);

    try {
      const { data: themeList } = await MappingMiddleware.getThemeListMM(
        jobRoleId,
        workLevelId,
        companyId,
        matrixType
      );

      // const { data: userDetails } = await HrMiddleWare.getUserDetails(userId);

      if (themeList) {
        const map = new Map();
        const themeData = [];
        for (const item of themeList) {
          if (!map.has(item.themeid)) {
            map.set(item.themeid, true); // set any value to Map
            themeData.push({ themeid: item.themeid, theme: item.theme });
          }
        }
        console.log(themeData);
        this.setState({
          themeSelected: themeList[0].themeid,
          themeList: themeData,
          fullCompList: themeList,
          jobRoleId,
          jobRoleName,
          workLevelId,
          workLevelName,
          financialYearId,
          matrixType,
          companyId,
          departmentName,
          functionName
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  changeTheme = themeId => {
    const { indicatorClicked } = this.state;
    if (!indicatorClicked) {
      this.setState({ themeSelected: themeId });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Save Changes before Proceeding!"
      });
    }
  };

  getIndicators = async competencyId => {
    const {
      companyId,
      selectedCompetency,
      indicatorClicked,
      indicatorsSelected,
      jobRoleId,
      workLevelId,
      themeSelected
    } = this.state;
    if (!indicatorClicked) {
      try {
        const {
          data: indicatorList
        } = await MappingMiddleware.getIndicatorsList(competencyId, companyId);
        if (indicatorList) {
          const map = new Map();
          const levelList = [];
          for (const item of indicatorList) {
            if (!map.has(item.levelid)) {
              map.set(item.levelid, true); // set any value to Map
              levelList.push({ levelid: item.levelid, Level: item.Level });
            }
          }
          this.setState({
            indicatorList,
            levelList,
            selectedCompetency: competencyId
          });
        }
        const {
          data: alreadySelectedIndicator
        } = await MappingMiddleware.getAlreadySelectedIndicator(
          themeSelected,
          jobRoleId,
          workLevelId,
          competencyId,
          companyId
        );
        if (alreadySelectedIndicator) {
          let indicatorIdArray = [];
          alreadySelectedIndicator.map(indic =>
            indicatorIdArray.push(indic.indicators)
          );
          this.setState({
            alreadySelectedIndicator: indicatorIdArray,
            originalMappedArray: indicatorIdArray
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (indicatorsSelected.length === 0) {
      this.setState({ indicatorClicked: false });
    } else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Save Changes before Proceeding!"
      });
    }
  };

  mapSelectedIndicators = (indicatorId, levelId) => {
    let {
      indicatorsSelected,
      alreadySelectedIndicator,
      originalMappedArray
    } = this.state;
    if (alreadySelectedIndicator.length > 0) {
      indicatorsSelected = [...alreadySelectedIndicator];
    }
    if (indicatorsSelected.length > 0) {
      if (indicatorsSelected.includes(indicatorId)) {
        let removedList = indicatorsSelected.filter(
          indicator => indicator !== indicatorId
        );
        let removedSelectedIdList = alreadySelectedIndicator.filter(
          indicator => indicator !== indicatorId
        );

        this.setState({
          indicatorsSelected: removedList,
          alreadySelectedIndicator: removedSelectedIdList,
          indicatorClicked:
            JSON.stringify(originalMappedArray.sort()) ===
            JSON.stringify(removedSelectedIdList.sort())
              ? false
              : true
        });
      } else {
        indicatorsSelected.push(indicatorId);
        alreadySelectedIndicator.push(indicatorId);
        this.setState({
          indicatorsSelected,
          alreadySelectedIndicator,
          indicatorClicked: true
        });
      }
    } else {
      indicatorsSelected.push(indicatorId);
      alreadySelectedIndicator.push(indicatorId);
      this.setState({
        indicatorsSelected,
        indicatorClicked: true,
        alreadySelectedIndicator
      });
    }
  };

  onSubmit = async () => {
    let {
      selectedCompetency,
      indicatorsSelected,
      indicatorList,
      themeSelected,
      jobRoleId,
      workLevelId,
      companyId,
      financialYearId,
      alreadySelectedIndicator,
      matrixType
    } = this.state;

    // indicatorsSelected = [...alreadySelectedIndicator];
    // console.log(indicatorsSelected);
    let indicatorWithLevel = [];
    indicatorList.map(indicatorList => {
      indicatorsSelected.filter(indicId => {
        if (parseInt(indicatorList.indicatorId) === parseInt(indicId)) {
          indicatorWithLevel.push(indicatorList);
        }
      });
    });

    const formData = new FormData();
    formData.append("theme", themeSelected);
    formData.append("worklevel", workLevelId);
    formData.append("jobRoleId", jobRoleId);
    formData.append("levelid", JSON.stringify(indicatorWithLevel));
    formData.append("competencyId", selectedCompetency);
    formData.append("indicators", JSON.stringify(indicatorWithLevel));
    formData.append("adminId", companyId);
    formData.append("type", "framework");
    formData.append("financial_year", financialYearId);
    formData.append("categoryType", matrixType);
    // console.log([...formData]);
    try {
      const result = await MappingMiddleware.mappedMatrixAdmin(formData);
      if (result) {
        this.setState({
          indicatorsSelected: [],
          indicatorClicked: false
        });
        Swal.fire({
          position: "center",
          type: "success",
          title: "Changes Saved Successfully",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      jobRoleName,
      workLevelName,
      themeList,
      fullCompList,
      levelList,
      indicatorList,
      themeSelected,
      selectedCompetency,
      alreadySelectedIndicator,
      departmentName,
      functionName,
      matrixType
    } = this.state;

    let competencyListArray = [];
    let fullPanelList = [];
    if (themeList) {
      themeList.map((themes, index) => {
        competencyListArray = [];
        fullCompList.map((themesAndComp, key) => {
          if (themesAndComp.themeid === themes.themeid) {
            competencyListArray.push(
              <label>
                <input
                  type="checkbox"
                  value=""
                  onClick={() => this.getIndicators(themesAndComp.competencyid)}
                  checked={
                    selectedCompetency === themesAndComp.competencyid
                      ? true
                      : false
                  }
                />
                {themesAndComp.competencyname}&emsp;&emsp;&emsp;
              </label>
            );
          }
        });
        fullPanelList.push(
          <div
            class={index === 0 ? `tab-pane fade in active` : `tab-pane fade`}
            id={themes.themeid}
          >
            <div class="checkbox">{competencyListArray}</div>
          </div>
        );
      });
    }

    let indicatorArrayLevel1 = [];
    let indicatorArrayLevel2 = [];
    let indicatorArrayLevel3 = [];
    let indicatorArrayLevel4 = [];
    if (levelList) {
      levelList.map((levels, index) => {
        indicatorList.map((indicators, key) => {
          if (levels.levelid === indicators.levelid) {
            if (
              indicators.Level === "level 1" ||
              indicators.Level === "Level 1" ||
              indicators.Level === "Level-1" ||
              indicators.Level === "Level-1"
            ) {
              // console.log(1);
              indicatorArrayLevel1.push(
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </label>
                </div>
              );
            } else if (
              indicators.Level === "LEVEL 2" ||
              indicators.Level === "Level 2" ||
              indicators.Level === "Level-2"
            ) {
              // console.log(2);
              indicatorArrayLevel2.push(
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </label>
                </div>
              );
            } else if (
              indicators.Level === "Level-3" ||
              indicators.Level === "Level 3" ||
              indicators.Level === "LEVEL 3" ||
              indicators.Level === "Level-3" ||
              indicators.Level === "LEVEL3"
            ) {
              // console.log(3);
              indicatorArrayLevel3.push(
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </label>
                </div>
              );
            } else if (
              indicators.Level === "LEVEL 4" ||
              indicators.Level === "Level 4" ||
              indicators.Level === "Level-4"
            ) {
              // console.log(4);
              indicatorArrayLevel4.push(
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value=""
                      onClick={() =>
                        this.mapSelectedIndicators(
                          indicators.indicatorId,
                          indicators.levelid
                        )
                      }
                      checked={
                        alreadySelectedIndicator.includes(
                          indicators.indicatorId
                        )
                          ? true
                          : false
                      }
                    />
                    {indicators.indicators}&emsp;&emsp;&emsp;
                  </label>
                </div>
              );
            }
          }
        });
      });
    }

    return (
      <div>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              {/* <h3 className="box-title">Create Competency Matrix : Step 2</h3> */}
              <div className="box-tools pull-right">
                <button
                  className="btn btn-box-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="box-body">
              {parseInt(matrixType) === 2 && (
                <div>
                  <div class="row form-group ">
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Department</label>
                    </div>
                    <div className="col-sm-2">{departmentName}</div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-3" />
                  </div>
                  <div class="row form-group ">
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Function</label>
                    </div>
                    <div className="col-sm-2">{functionName}</div>
                    <div className="col-sm-5"></div>
                    <div className="col-sm-3" />
                  </div>
                </div>
              )}
              <div class="row form-group ">
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">WorkLevel</label>
                </div>
                <div className="col-sm-2">{workLevelName}</div>
                <div className="col-sm-5"></div>
                <div className="col-sm-3" />
              </div>
              <div class="row form-group ">
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Job Role</label>
                </div>
                <div className="col-sm-2">{jobRoleName}</div>
                <div className="col-sm-5"></div>
                <div className="col-sm-3" />
              </div>
              <div class="panel with-nav-tabs panel-danger">
                <div class="panel-heading">
                  <ul class="nav nav-tabs">
                    {themeList &&
                      themeList.map((themes, index) => (
                        <li
                          class={
                            themeSelected === themes.themeid ? `active` : ``
                          }
                        >
                          <a
                            href={`#${themes.themeid}`}
                            onClick={() => this.changeTheme(themes.themeid)}
                            data-toggle="tab"
                          >
                            {themes.theme}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="panel-body">
                  <div className="tab-content">{fullPanelList}</div>
                  <br />
                  {indicatorArrayLevel1 && (
                    <div>
                      <div className="panel panel-default">
                        <div className="panel-heading">
                          <div class="row form-group ">
                            <div className="col-sm-3">Level-1</div>
                            <div className="col-sm-3">Level-2</div>
                            <div className="col-sm-3">Level-3</div>
                            <div className="col-sm-3">Level-4</div>
                          </div>
                        </div>
                        <div className="panel-body">
                          <div class="row form-group ">
                            <div className="col-sm-3">
                              {indicatorArrayLevel1}
                            </div>
                            <div className="col-sm-3">
                              {indicatorArrayLevel2}
                            </div>
                            <div className="col-sm-3">
                              {indicatorArrayLevel3}
                            </div>
                            <div className="col-sm-3">
                              {indicatorArrayLevel4}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.onSubmit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ProfileCompMap;
