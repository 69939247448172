import React from 'react';
import FormMiddleWare from '../../components/Form/FormMiddleware';
//import './login.css';
import logo from '../../image/Murugappa_Group_Logo.svg.png';
import LoginMiddleWare from '../../middleWare/LoginMiddleWare';
import { SingleSelect } from '../../components/Form';
import { Style } from 'react-style-tag';
import './animate/animate.css';
import './animate/animate.min.css';
import './css/style.css';
import './lib/icon/ionicons.min.css';
//import './bootstrap.min.css';

{
  /*not showing  */
}
//import './font-awesome/font-awesome.min.css';
//import './owlcarousel/owl.carousel.min.css';
//import './lightbox/lightbox.min.css';

class Userindex extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      password: null,
      errorUserName: null,
      errorPassword: null,
      passwordView: 1,
      userType: [{ label: 'Admin', value: 2 }, { label: 'Employee', value: 5 }, { label: 'HR', value: 6 }]
    };
  }

  componentDidMount() {
    let script = document.createElement('script');
    script.src = process.env.PUBLIC_URL + '/login_pages/lib/jquery/jquery.min.js';
    script.async = true;
    let script_2 = document.createElement('script');
    script_2.src = process.env.PUBLIC_URL + '/login_pages/lib/jquery/jquery-migrate.min.js';
    script_2.async = true;
    document.body.appendChild(script_2);
    let script_3 = document.createElement('script');
    script_3.src = process.env.PUBLIC_URL + '/login_pages/lib/bootstrap/bootstrap.bundle.min.js';
    script_3.async = true;
    document.body.appendChild(script_3);
    let script_4 = document.createElement('script');
    script_4.src = process.env.PUBLIC_URL + '/login_pages/lib/easing/easing.min.js';
    script_4.async = true;
    document.body.appendChild(script_4);
    let script_5 = document.createElement('script');
    script_5.src = process.env.PUBLIC_URL + '/login_pages/superfish/hoverIntent.js';
    script_5.async = true;
    document.body.appendChild(script_5);
    let script_6 = document.createElement('script');
    script_6.src = process.env.PUBLIC_URL + '/login_pages/superfish/superfish.min.js';
    script_6.async = true;
    document.body.appendChild(script_6);
    let script_7 = document.createElement('script');
    script_7.src = process.env.PUBLIC_URL + '/login_pages/lib/wow/wow.min.js';
    script_7.async = true;
    document.body.appendChild(script_7);
    let script_8 = document.createElement('script');
    script_8.src = process.env.PUBLIC_URL + '/login_pages/lib/wow/waypoints.min.js';
    script_8.async = true;
    document.body.appendChild(script_8);
    let script_9 = document.createElement('script');
    script_9.src = process.env.PUBLIC_URL + '/login_pages/lib/wow/counterup.min.js';
    script_9.async = true;
    document.body.appendChild(script_9);
    let script_10 = document.createElement('script');
    script_10.src = process.env.PUBLIC_URL + '/login_pages/lib/wow/owl.carousel.min.js';
    script_10.async = true;
    document.body.appendChild(script_10);
    let script_11 = document.createElement('script');
    script_11.src = process.env.PUBLIC_URL + '/login_pages/lib/wow/isotope.pkgd.min.js';
    script_11.async = true;
    document.body.appendChild(script_11);
    let script_12 = document.createElement('script');
    script_12.src = process.env.PUBLIC_URL + '/login_pages/lib/wow/lightbox.min.js';
    script_12.async = true;
    document.body.appendChild(script_12);
    let script_13 = document.createElement('script');
    script_13.src = process.env.PUBLIC_URL + '/login_pages/lib/wow/jquery.touchSwipe.min.js';
    script_13.async = true;
    document.body.appendChild(script_13);
    let script_14 = document.createElement('script');
    script_14.src = process.env.PUBLIC_URL + '/login_pages/lib/jquery/main.js';
    script_14.async = true;
    document.body.appendChild(script_14);

    let sheet = document.createElement('link');
    sheet.rel = 'stylesheet';
    sheet.href = process.env.PUBLIC_URL + 'login_pages/bootstrap/bootstrap.min.css';
    sheet.type = 'text/css';
    document.head.appendChild(sheet);
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.checkUser();
    }
  };
  selectUserType = e => {
    this.setState({ selectedUserType: e });
    // this.checkUser();
  };
  viewPassword = () => {
    const { passwordView } = this.state;
    const passcode = document.querySelector("input[name='password']");
    if (passwordView) {
      passcode.setAttribute('type', 'text');
      this.setState({ passwordView: 0 });
    } else {
      passcode.setAttribute('type', 'password');
      this.setState({ passwordView: 1 });
    }
  };
  checkUser = async () => {
    console.log(1);
    const { selectedUserType, userName, password } = this.state;
    if (this.validate(selectedUserType, 'errorUserType', 'Select User Type')) return true;
    if (this.validate(userName, 'errorUserName', 'Please Enter UserName')) return true;
    if (this.validate(password, 'errorPassword', 'Please Enter Password')) return true;
    const formData = new FormData();
    formData.append('userType1', selectedUserType.value === 2 ? 1 : selectedUserType.value);
    formData.append('userType', selectedUserType.value);
    formData.append('userName', userName);
    formData.append('password', password);
    console.log([...formData]);
    try {
      const { data } = await LoginMiddleWare.checkUser('tbl_user', formData);
      if (data.length > 0) {
        localStorage.setItem('userId', data[0].id);
        localStorage.setItem('name', data[0].name);
        localStorage.setItem('userName', data[0].user_name);
        localStorage.setItem('userTypeId', data[0].userTypeId);
        localStorage.setItem('groupcompId', data[0].groupcomp);
        if (selectedUserType.value === 1 || selectedUserType.value === 2) {
          localStorage.setItem('adminId', data[0].adminId);
          window.location.href = '/admin/CompetencyMatrix/CompFormOne';
          // this.props.history.push('/admin/Master/WorkLevel');
        } else if (selectedUserType.value === 6) {
          localStorage.setItem('adminId', data[0].adminId);
          window.location.href = 'hr/EmployeeList';
          // this.props.history.push('/hr');
        } else if (selectedUserType.value === 5) {
          localStorage.setItem('adminId', data[0].adminId);
          window.location.href = '/user/dashboard';
          // this.props.history.push('/user');
        } else {
          window.location.href = '/admin/Master/WorkLevel';
          // this.props.history.push('/admin/Master/WorkLevel');
        }
        this.setState({ selectedUserType: '', userName: '', password: '' });
      } else {
        this.setState({
          errorPassword: 'UserName or Password Is Incorrect'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  modelclick = async () => {
    var x = document.getElementById('myModal');
    //alert(x.style.display);
    // if (x.style.display === 'none') {
    //   alert('show');
    //   x.style.display = 'block';
    // } else {
    //   alert('hide');
    //   x.style.display = 'none';
    // }
  };

  render() {
    const stylebutton = {
      fontFamily: 'Montserrat',
      fontWeight: '500',
      fontSize: '16',

      letterSpacing: '1px',
      display: 'inline - block',
      padding: '8px 32px',
      borderRadius: '50px',
      transition: '0.5s',
      margin: '10px',
      background: ' #18d26e'
    };
    const { password, userName, errorUserName, errorPassword, errorUserType } = this.state;
    return (
      <React.Fragment>
        <Style>{`
.login-logo img{
height:80px;
width:120px;
}
input[type=text],input[type=password]  {

  border: 0;
  outline: 0;
  background-color: transparent;
  border-bottom: 1px solid  white;
  color:#ffffff;


}
 ::placeholder{ color:#ffffff;} select{margin-top:20px;padding:10px 0; }

 select{ margin-bottom:20px; width:200px; height:45px; }
 .login-box-body
{
border: 0px solid rgba(0, 0, 0, 0.3);

}
.backgroundimageset{
     background-image: url("./img/about-plan.jpg");
       background-color: #000000;
       -web-kit-background-size:cover;
       background-size:cover;
       position:absolute;
       box-sizing:border-box;
       padding:40px;
       margin:0px;padding:0px;

 }
 .boxsizechange{

  max-width: 600px;
  margin: auto;
  /*background: #cecece;
  background: rgba(0, 0, 0, 0.9); opacity: 0.6; */
  padding: 10px;
 background: rgba(0, 0, 0, 0.5);

}
.loginstyle{

font-family: "Montserrat", sans-serif;
font-weight: 500;
font-size: 16px;
letter-spacing: 1px;
display: inline-block;
padding: 8px 32px;
border-radius: 50px;
transition: 0.5s;
margin: 10px;
color: #fff;
background: #18d26e;

}
.loginstyle:hover{ background-color:white; color:#18d26e;}
.validationsize
{

font-size:12px;
}

    `}</Style>
        <header id="header" style={{ padding: ' 4px 0px' }}>
          {/*main div */}
          <div class="container-fluid">
            <div id="logo" style={{ paddingLeft: '5' }} class="pull-left">
              <h1>
                <a href="#intro" class="scrollto">
                  <img src={require('./image/murugappa_logo1.png')} alt="" title="" />{' '}
                </a>
              </h1>
            </div>
            <nav id="nav-menu-container">
              <ul class="nav-menu">
                <li class="menu-active">
                  <a href="#intro">Home</a>
                </li>
                <li>
                  <a href="#about">About Us</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#portfolio">Portfolio</a>
                </li>
                <li>
                  <a href="#team">Team</a>
                </li>
                <li class="menu-has-children">
                  <a href="">Drop Down</a>
                  <ul>
                    <li>
                      <a href="#">Drop Down 1</a>
                    </li>
                    <li>
                      <a href="#">Drop Down 3</a>
                    </li>
                    <li>
                      <a href="#">Drop Down 4</a>
                    </li>
                    <li>
                      <a href="#">Drop Down 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#contact">Contact</a>
                </li>
                <li>
                  <button
                    type="button"
                    id="myModalshow"
                    data-toggle="modal"
                    data-target="#myModal"
                    class="buttoncolor btn btn btn-lg"
                    onClick={this.modelclick}
                    style={{
                      letterSpacing: '1',
                      display: ' inline-block',
                      borderRadius: 20,
                      transition: '0.5s',
                      margin: 0,
                      background: '#18d26e',
                      color: '#fff',
                      textAlign: 'center',
                      padding: '1px 9px',
                      width: 70,
                      height: 25,
                      fontSize: 12,
                      fontWeight: 'bold'
                    }}
                  >
                    Login
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          {/*main div */}

          <Style>{`
               .buttoncolor
{
font-family: "Montserrat", sans-serif;
letter-spacing: 1px;
display: inline-block;
border-radius: 20px;
transition: 0.5s;
margin: 0px;
color: #fff;
background: #18d26e;
text-align:center;
padding: 1px 9px;
width:70px;
height:25px;
font-size:12px;
font-weight:bold;

}
            `}</Style>
        </header>

        <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog modal-lg ">
            <div class="modal-content backgroundimageset" style={{ backgroundImage: 'url(' + require('./img/about-plan.jpg') + ')' }}>
              <div class="modal-header " style={{ borderBottom: 'none' }}>
                <button type="button" class="close" data-dismiss="modal" style={{ color: 'white', fontSize: '40px' }}>
                  &times;
                </button>
                <h4 class="modal-title"> </h4>
              </div>
              <div class="modal-body boxsizechange">
                <div class="login-box">
                  <div class="login-box-body" style={{ width: '500', background: 'none' }}>
                    <div class="login-logo">
                      <center>
                        <img style={{ width: '179', height: '134', marginTop: '-26' }} src={require('./image/murugappa_logo1.png')} />
                      </center>
                    </div>
                    {/*<!-- /.login-logo --> */}
                    <center>
                      {' '}
                      <p class="login-box-msg" style={{ color: '#ffffff', fontWeight: 'bold', fontSize: '1rem' }}>
                        Sign in to Murugappa Competency Framework
                      </p>{' '}
                    </center>
                    <div class="err" style={{ color: 'white' }} align="center"></div>
                    {/* <form action="adminCheck" method="post" name="frm" id="frm" onsubmit="return frmSub(this)"> */}
                    <div class="row form-group">
                      <div class="col-sm-2"></div>

                      <div class="col-sm-8" style={{ marginTop: '27px' }}>
                        {/* <select name="userTypeId" id="userTypeId" style={{ fontSize: '15px', marginTop: '30px', paddingBottom: '0px' }}>
                            <option value="">Select User Type</option>
                          </select> */}
                        <SingleSelect options={this.state.userType} handleChange={this.selectUserType} selectedService={this.state.selectedUserType} />
                        {/* <span id="errormsgusertype" class="errormsgusertype validationsize" style={{ color: 'white' }}></span>{' '} */}
                        <span className="error-show">{errorUserType}</span>
                      </div>

                      <div class="col-sm-2"></div>
                    </div>
                    <div class="row form-group">
                      <div class="col-sm-2"></div>

                      <div class="col-sm-8">
                        {/*<span class="input-group-addon">
                            <i class="glyphicon glyphicon-user"></i>
                          </span> */}
                        <div class="input-group" style={{ zIndex: 0 }}>
                          <span class="input-group-addon" style={{ width: 'auto', padding: '4px 12px' }}>
                            <a href="" style={{ color: '#555555' }}>
                              <i class="fa fa-user" />
                            </a>
                          </span>
                          <input className="form-control" type="text" name="userName" placeholder="username" value={userName} onChange={this.handleUserInput} onKeyDown={this.handleKeyDown} />
                          <span className="error-view" style={{ right: '0' }}>
                            {errorUserName}
                          </span>
                          {/* <span id="errormsg" class="errormsg validationsize" style={{ color: 'white' }}></span> */}
                        </div>
                      </div>

                      <div class="col-sm-2"></div>
                    </div>
                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <div class="row form-group">
                      <div class="col-sm-2"></div>

                      <div class="col-sm-8">
                        {/* <input type="password" name="password" id="password" class="password" placeholder="Password" style={{border:'0 !important' , outline: '0',backgroundColor: 'transparent',  borderBottom: '1px solid white',color:' #ffffff'}} /> */}
                        <div class="input-group" style={{ zIndex: 0 }}>
                          <span class="input-group-addon" style={{ width: 'auto', padding: '4px 12px' }}>
                            <a href="" style={{ color: '#555555' }}>
                              <i class="fa fa-eye-slash" />
                            </a>
                          </span>
                          <input
                            className="form-control"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="password"
                            value={password}
                            onChange={this.handleUserInput}
                            onKeyDown={this.handleKeyDown}
                          />
                          {/* <span id="errormsgpassword" class="errormsgpassword validationsize" style={{ color: 'white' }}></span> */}
                        </div>
                        <span className="error-view" style={{ right: '0' }}>
                          {errorPassword}
                        </span>
                      </div>

                      <div class="col-sm-2"></div>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="checkbox icheck">&nbsp;</div>
                      </div>
                      {/* <!-- /.col --> */}
                      <div class="col-sm-5">
                        <button type="submit" style={stylebutton} class=" loginstyle btn btn-block btn-flat" onClick={this.checkUser}>
                          Sign In
                        </button>
                      </div>
                      {/*<!-- /.col --> */}
                      <div class="col-sm-4"></div>
                    </div>
                    {/* </form> */}
                  </div>
                  {/*<!-- /.login-box-body --> */}
                </div>
                {/*<!-- /.login-box-body --> */}
              </div>
              <div class="modal-footer" style={{ borderTop: 'none' }}>
                <button type="button" class="btn btn-default" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*Intro Section */}
        <section id="intro">
          <div class="intro-container">
            <div id="introCarousel" class="carousel  slide carousel-fade" data-ride="carousel">
              <ol class="carousel-indicators"></ol>

              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <div class="carousel-background">
                    <img src={require('./img/intro-carousel/1.jpg')} alt="" />
                  </div>
                  <div class="carousel-container">
                    <div class="carousel-content">
                      <h2>In such dynamic business environment</h2>
                      <p> </p>
                      <a href="#featured-services" class="btn-get-started scrollto">
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="carousel-background">
                    <img src={require('./img/intro-carousel/2.jpg')} alt="" />
                  </div>
                  <div class="carousel-container">
                    <div class="carousel-content">
                      <h2>Few compelling question facing us in this VUCA world are...</h2>
                      <h2>How can we differentiate ourselves to stand apart?</h2>
                      <p>How can we build and develop human capital?</p>
                      <a href="#featured-services" class="btn-get-started scrollto">
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="carousel-background">
                    <img src={require('./img/intro-carousel/3.jpg')} alt="" />
                  </div>
                  <div class="carousel-container">
                    <div class="carousel-content">
                      <h2>How we can ensure every employee achieves their true potential? </h2>
                      <p>How we can achieve our vision with a positive impact on the society?</p>
                      <a href="#featured-services" class="btn-get-started scrollto">
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="carousel-background">
                    <img src={require('./img/intro-carousel/4.jpg')} alt="" />
                  </div>
                  <div class="carousel-container">
                    <div class="carousel-content">
                      <h2>All this is possible </h2>
                      <p>By leveraging Murugappa Competency Framework.</p>
                      <a href="#featured-services" class="btn-get-started scrollto">
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="carousel-background">
                    <img src={require('./img/intro-carousel/5.jpg')} alt="" />
                  </div>
                  <div class="carousel-container">
                    <div class="carousel-content">
                      <h2>'What' is to be developed? </h2>
                      <p>'why' these need to be developed? </p>
                      <p> 'How' can these be developed?</p>
                      {/* </p> */}
                      <a href="#featured-services" class="btn-get-started scrollto">
                        Get Started
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <a class="carousel-control-prev" href="#introCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon ion-chevron-left" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>

              <a class="carousel-control-next" href="#introCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon ion-chevron-right" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </section>
        {/*<!-- #intro --> */}
        <main id="main">
          {/*   Featured Services Section */}
          <section id="featured-services">
            <div class="container">
              <div class="row">
                <div class="col-lg-4 box-bg">
                  <i class="ion-ios-bookmarks-outline"></i>
                  <h4 class="title">
                    <a href="">Lorem Ipsum Delino</a>
                  </h4>
                  <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                </div>

                <div class="col-lg-4 box box-bg">
                  <i class="ion-ios-stopwatch-outline"></i>
                  <h4 class="title">
                    <a href="">Dolor Sitema</a>
                  </h4>
                  <p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                </div>

                <div class="col-lg-4 box-bg">
                  <i class="ion-ios-heart-outline"></i>
                  <h4 class="title">
                    <a href="">Sed ut perspiciatis</a>
                  </h4>
                  <p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                </div>
              </div>
            </div>
          </section>
          {/*<!-- #featured-services --> */}
          {/*      About Us Section */}
          <section id="about">
            <div class="container">
              <header class="section-header">
                <h3>About Us</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </header>

              <div class="row about-cols">
                <div class="col-md-4 wow fadeInUp">
                  <div class="about-col">
                    <div class="img">
                      <img src={require('./img/about-mission.jpg')} alt="" class="img-fluid" />
                      <div class="icon">
                        <i class="ion-ios-speedometer-outline"></i>
                      </div>
                    </div>
                    <h2 class="title">
                      <a href="#">Our Mission</a>
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat.
                    </p>
                  </div>
                </div>

                <div class="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
                  <div class="about-col">
                    <div class="img">
                      <img src={require('./img/about-plan.jpg')} alt="" class="img-fluid" />
                      <div class="icon">
                        <i class="ion-ios-list-outline"></i>
                      </div>
                    </div>
                    <h2 class="title">
                      <a href="#">Our Plan</a>
                    </h2>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
                      vitae dicta sunt explicabo.
                    </p>
                  </div>
                </div>

                <div class="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="about-col">
                    <div class="img">
                      <img src={require('./img/about-vision.jpg')} alt="" class="img-fluid" />
                      <div class="icon">
                        <i class="ion-ios-eye-outline"></i>
                      </div>
                    </div>
                    <h2 class="title">
                      <a href="#">Our Vision</a>
                    </h2>
                    <p>
                      Nemo enim ipsam voluptatem quia voluptas sit aut odit aut fugit, sed quia magni dolores eos qui ratione voluptatem sequi nesciunt Neque porro quisquam est, qui dolorem ipsum quia
                      dolor sit amet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*      Services Section */}
          <section id="services">
            <div class="container">
              <header class="section-header wow fadeInUp">
                <h3>Services</h3>
                <p>
                  Laudem latine persequeris id sed, ex fabulas delectus quo. No vel partiendo abhorreant vituperatoribus, ad pro quaestio laboramus. Ei ubique vivendum pro. At ius nisl accusam lorenta
                  zanos paradigno tridexa panatarel.
                </p>
              </header>

              <div class="row">
                <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-duration="1.4s">
                  <div class="icon">
                    <i class="ion-ios-analytics-outline"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Lorem Ipsum</a>
                  </h4>
                  <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                </div>
                <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-duration="1.4s">
                  <div class="icon">
                    <i class="ion-ios-bookmarks-outline"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Dolor Sitema</a>
                  </h4>
                  <p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                </div>
                <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-duration="1.4s">
                  <div class="icon">
                    <i class="ion-ios-paper-outline"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Sed ut perspiciatis</a>
                  </h4>
                  <p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                </div>
                <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                  <div class="icon">
                    <i class="ion-ios-speedometer-outline"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Magni Dolores</a>
                  </h4>
                  <p class="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                </div>
                <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                  <div class="icon">
                    <i class="ion-ios-barcode-outline"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Nemo Enim</a>
                  </h4>
                  <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                </div>
                <div class="col-lg-4 col-md-6 box wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
                  <div class="icon">
                    <i class="ion-ios-people-outline"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Eiusmod Tempor</a>
                  </h4>
                  <p class="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
                </div>
              </div>
            </div>
          </section>
          {/*      Call To Action Section */}
          <section id="call-to-action" class="wow fadeIn">
            <div class="container text-center">
              <h3>Call To Action</h3>
              <p>
                {' '}
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
              </p>
              <a class="cta-btn" href="#">
                Call To Action
              </a>
            </div>
          </section>
          {/*<!-- #call-to-action --> */}
          {/*      Skills Section */}
          <section id="skills">
            <div class="container">
              <header class="section-header">
                <h3>Our Skills</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip
                </p>
              </header>

              <div class="skills-content">
                <div class="progress">
                  <div class="progress-bar bg-success" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">
                      HTML <i class="val">100%</i>
                    </span>
                  </div>
                </div>

                <div class="progress">
                  <div class="progress-bar bg-info" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">
                      CSS <i class="val">90%</i>
                    </span>
                  </div>
                </div>

                <div class="progress">
                  <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">
                      JavaScript <i class="val">75%</i>
                    </span>
                  </div>
                </div>

                <div class="progress">
                  <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100">
                    <span class="skill">
                      Photoshop <i class="val">55%</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*      Facts Section */}
          <section id="facts" class="wow fadeIn">
            <div class="container">
              <header class="section-header">
                <h3>Facts</h3>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p>
              </header>

              <div class="row counters">
                <div class="col-lg-3 col-6 text-center">
                  <span data-toggle="counter-up">274</span>
                  <p>Clients</p>
                </div>

                <div class="col-lg-3 col-6 text-center">
                  <span data-toggle="counter-up">421</span>
                  <p>Projects</p>
                </div>

                <div class="col-lg-3 col-6 text-center">
                  <span data-toggle="counter-up">1,364</span>
                  <p>Hours Of Support</p>
                </div>

                <div class="col-lg-3 col-6 text-center">
                  <span data-toggle="counter-up">18</span>
                  <p>Hard Workers</p>
                </div>
              </div>

              <div class="facts-img">
                <img src={require('./img/facts-img.png')} alt="" class="img-fluid" />
              </div>
            </div>
          </section>
          {/*facts */}
          {/*      Portfolio Section */}
          <section id="portfolio" class="section-bg">
            <div class="container">
              <header class="section-header">
                <h3 class="section-title">Our Portfolio</h3>
              </header>

              <div class="row">
                <div class="col-lg-12">
                  <ul id="portfolio-flters">
                    <li data-filter="*" class="filter-active">
                      All
                    </li>
                    <li data-filter=".filter-app">App</li>
                    <li data-filter=".filter-card">Card</li>
                    <li data-filter=".filter-web">Web</li>
                  </ul>
                </div>
              </div>

              <div class="row portfolio-container">
                <div class="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/app1.jpg')} class="img-fluid" alt="" />
                      <a href={'./img/portfolio/app1.png'} data-lightbox="portfolio" data-title="App 1" class="link-preview" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">App 1</a>
                      </h4>
                      <p>App</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-web wow fadeInUp" data-wow-delay="0.1s">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/web3.jpg')} class="img-fluid" alt="" />
                      <a href="img/portfolio/web3.jpg" class="link-preview" data-lightbox="portfolio" data-title="./Web3" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">Web 3</a>
                      </h4>
                      <p>Web</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp" data-wow-delay="0.2s">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/app2.jpg')} class="img-fluid" alt="" />
                      <a href="./img/portfolio/app2.jpg" class="link-preview" data-lightbox="portfolio" data-title="App 2" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">App 2</a>
                      </h4>
                      <p>App</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-card wow fadeInUp">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/card2.jpg')} class="img-fluid" alt="" />
                      <a href={require('./img/portfolio/card2.jpg')} class="link-preview" data-lightbox="portfolio" data-title="Card 2" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">Card 2</a>
                      </h4>
                      <p>Card</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-web wow fadeInUp" data-wow-delay="0.1s">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/web2.jpg')} class="img-fluid" alt="" />
                      <a href={require('./img/portfolio/web2.jpg')} class="link-preview" data-lightbox="portfolio" data-title="Web 2" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">Web 2</a>
                      </h4>
                      <p>Web</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp" data-wow-delay="0.2s">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/app3.jpg')} class="img-fluid" alt="" />
                      <a href={require('./img/portfolio/app3.jpg')} class="link-preview" data-lightbox="portfolio" data-title="App 3" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">App 3</a>
                      </h4>
                      <p>App</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-card wow fadeInUp">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/card1.jpg')} class="img-fluid" alt="" />
                      <a href={require('./img/portfolio/card1.jpg')} class="link-preview" data-lightbox="portfolio" data-title="Card 1" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">Card 1</a>
                      </h4>
                      <p>Card</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-card wow fadeInUp" data-wow-delay="0.1s">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/card3.jpg')} class="img-fluid" alt="" />
                      <a href={require('./img/portfolio/card3.jpg')} class="link-preview" data-lightbox="portfolio" data-title="Card 3" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">Card 3</a>
                      </h4>
                      <p>Card</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item filter-web wow fadeInUp" data-wow-delay="0.2s">
                  <div class="portfolio-wrap">
                    <figure>
                      <img src={require('./img/portfolio/web1.jpg')} class="img-fluid" alt="" />
                      <a href={require('./img/portfolio/web1.jpg')} class="link-preview" data-lightbox="portfolio" data-title="Web 1" title="Preview">
                        <i class="ion ion-eye"></i>
                      </a>
                      <a href="#" class="link-details" title="More Details">
                        <i class="ion ion-android-open"></i>
                      </a>
                    </figure>

                    <div class="portfolio-info">
                      <h4>
                        <a href="#">Web 1</a>
                      </h4>
                      <p>Web</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*<!-- #portfolio --> */}
          {/*      Clients Section */}
          <section id="clients" class="wow fadeInUp">
            <div class="container">
              <header class="section-header">
                <h3>Our Clients</h3>
              </header>

              <div class="owl-carousel clients-carousel">
                <img src={require('./img/clients/client-1.png')} alt="" />
                <img src={require('./img/clients/client-2.png')} alt="" />
                <img src={require('./img/clients/client-3.png')} alt="" />
                <img src={require('./img/clients/client-4.png')} alt="" />
                <img src={require('./img/clients/client-5.png')} alt="" />
                <img src={require('./img/clients/client-6.png')} alt="" />
                <img src={require('./img/clients/client-7.png')} alt="" />
                <img src={require('./img/clients/client-8.png')} alt="" />
              </div>
            </div>
          </section>
          {/*      Clients Section */}
          <section id="testimonials" class="section-bg wow fadeInUp">
            <div class="container">
              <header class="section-header">
                <h3>Testimonials</h3>
              </header>

              <div class="owl-carousel testimonials-carousel">
                <div class="testimonial-item">
                  <img src={require('./img/testimonial-1.jpg')} class="testimonial-img" alt="" />
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                  <p>
                    <img src={require('./img/quote-sign-left.png')} class="quote-sign-left" alt="" />
                    Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at
                    semper.
                    <img src={require('./img/quote-sign-right.png')} class="quote-sign-right" alt="" />
                  </p>
                </div>

                <div class="testimonial-item">
                  <img src={require('./img/testimonial-2.jpg')} class="testimonial-img" alt="" />
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                  <p>
                    <img src={require('./img/quote-sign-left.png')} class="quote-sign-left" alt="" />
                    Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim
                    culpa.
                    <img src={require('./img/quote-sign-right.png')} class="quote-sign-right" alt="" />
                  </p>
                </div>

                <div class="testimonial-item">
                  <img src={require('./img/quote-sign-right.png')} class="quote-sign-right" alt="" />
                  <img src={require('./img/testimonial-3.jpg')} class="testimonial-img" alt="" />
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                  <p>
                    <img src={require('./img/quote-sign-left.png')} class="quote-sign-left" alt="" />
                    Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                    <img src={require('./img/quote-sign-right.png')} class="quote-sign-right" alt="" />
                  </p>
                </div>

                <div class="testimonial-item">
                  <img src={require('./img/testimonial-4.jpg')} class="testimonial-img" alt="" />
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                  <p>
                    <img src={require('./img/quote-sign-left.png')} class="quote-sign-left" alt="" />
                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore
                    illum veniam.
                    <img src={require('./img/quote-sign-right.png')} class="quote-sign-right" alt="" />
                  </p>
                </div>

                <div class="testimonial-item">
                  <img src={require('./img/testimonial-5.jpg')} class="testimonial-img" alt="" />
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                  <p>
                    <img src={require('./img/quote-sign-left.png')} class="quote-sign-left" alt="" />
                    Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore
                    nisi cillum quid.
                    <img src={require('./img/quote-sign-right.png')} class="quote-sign-right" alt="" />
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/*testimonials */}
          {/*      Team Section */}
          <section id="team">
            <div class="container">
              <div class="section-header wow fadeInUp">
                <h3>Team</h3>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p>
              </div>

              <div class="row">
                <div class="col-lg-3 col-md-6 wow fadeInUp">
                  <div class="member">
                    <img src={require('./img/team-1.jpg')} class="img-fluid" alt="" />
                    <div class="member-info">
                      <div class="member-info-content">
                        <h4>Walter White</h4>
                        <span>Chief Executive Officer</span>
                        <div class="social">
                          <a href="">
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-google-plus"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div class="member">
                    <img src={require('./img/team-2.jpg')} class="img-fluid" alt="" />
                    <div class="member-info">
                      <div class="member-info-content">
                        <h4>Sarah Jhonson</h4>
                        <span>Product Manager</span>
                        <div class="social">
                          <a href="">
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-google-plus"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                  <div class="member">
                    <img src={require('./img/team-3.jpg')} class="img-fluid" alt="" />
                    <div class="member-info">
                      <div class="member-info-content">
                        <h4>William Anderson</h4>
                        <span>CTO</span>
                        <div class="social">
                          <a href="">
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-google-plus"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div class="member">
                    <img src={require('./img/team-4.jpg')} class="img-fluid" alt="" />
                    <div class="member-info">
                      <div class="member-info-content">
                        <h4>Amanda Jepson</h4>
                        <span>Accountant</span>
                        <div class="social">
                          <a href="">
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-facebook"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-google-plus"></i>
                          </a>
                          <a href="">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*      team */}
          {/*      Contact Section */}
          <section id="contact" class="section-bg wow fadeInUp">
            <div class="container">
              <div class="section-header">
                <h3>Contact Us</h3>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p>
              </div>

              <div class="row contact-info">
                <div class="col-md-4">
                  <div class="contact-address">
                    <i class="ion-ios-location-outline"></i>
                    <h3>Address</h3>
                    <address>A108 Adam Street, NY 535022, USA</address>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="contact-phone">
                    <i class="ion-ios-telephone-outline"></i>
                    <h3>Phone Number</h3>
                    <p>
                      <a href="tel:+155895548855">+1 5589 55488 55</a>
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="contact-email">
                    <i class="ion-ios-email-outline"></i>
                    <h3>Email</h3>
                    <p>
                      <a href="mailto:info@example.com">info@example.com</a>
                    </p>
                  </div>
                </div>
              </div>

              <div class="form">
                <div id="sendmessage">Your message has been sent. Thank you!</div>
                <div id="errormessage"></div>
                <form action="" method="post" role="form" class="contactForm">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                      <div class="validation"></div>
                    </div>
                    <div class="form-group col-md-6">
                      <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                      <div class="validation"></div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                    <div class="validation"></div>
                  </div>
                  <div class="form-group">
                    <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                    <div class="validation"></div>
                  </div>
                  <div class="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
          {/*contact */}

          <Style>{`.login-logo img{
height:80px;
width:120px;
}
#usertypeDiv1
{
display:none;
}`}</Style>
          <div class="forgetpassmawesome"></div>
        </main>
        {/**Footer */}
        <footer id="footer" class="section-bg">
          <div class="container">
            <div class="copyright">
              &copy; Copyright <strong>TRAXERP</strong>. All Rights Reserved
            </div>
            <div class="credits">
              Designed by <a href="https://www.traxerp.com/">Dotcue</a>
            </div>
          </div>
        </footer>
        <a href="#" class="back-to-top">
          <i class="fa fa-chevron-up"></i>
        </a>
      </React.Fragment>
    );
  }
}

export default Userindex;
