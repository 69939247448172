import React, { Component } from "react";
import { Link } from "react-router-dom";

//import logo from '../../image/Murugappa_Group_Logo.svg.png';
import logo from "../../image/difuzaWebLogo.png";
import { ACCESS_POINT } from "../../config/index";
// import userImg from "../../image/user2-160x160.jpg";
import userImg from "../../image/person_1.png";

import "./AdminStyle.css";
import HrMiddleWare from "../../middleWare/HrMiddleWare";

class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: []
    };
  }
  async componentWillMount() {
    const adminId = await localStorage.getItem("userId");
    const user = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${adminId}`,
      "*"
    );
    if (user) {
      this.setState({ user: user.data[0] });
    }
  }
  async componentDidMount() {
    const adminId = await localStorage.getItem("userId");
    const username = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${adminId}`,
      "*"
    );
    if (username) {
      localStorage.setItem("userlog", JSON.stringify(username.data[0]));
      this.setState({ username_img: username.data[0] });
    }
    document
      .getElementsByTagName("body")[0]
      .setAttribute("class", "hold-transition skin-blue sidebar-mini");
  }

  logout = () => {
    localStorage.clear();
    window.location = "/";
  };

  render() {
    const { user } = this.state;
    const userTypeId = localStorage.getItem("userTypeId");
    const user_Name = JSON.parse(localStorage.getItem("userlog"));
    // console.log(user_Name);
    const img = ACCESS_POINT + "/getpro/getprofile?fileurl=" + user.image;
    const styles = {
      width: "250px",
      height: "70px",
      marginRight: "15px",
      paddingRight: "0px",
      marginLeft: "-15px",
      marginBottom: "0px",
      paddingBottom: "18px"
    };
    return (
      <div>
        <header className="main-header">
          <a href="#" className="logo">
            <span className="logo-mini">
              <img
                src={logo}
                alt="novalid"
                height="60px"
                width="60px"
                style={{ background: 'white' }}
              />
            </span>

            <span className="logo-lg">
              <img
                src={logo}
                style={styles}
                alt="novalid"
                height="45px"
                width="85px"
              />
            </span>
          </a>

          <nav className="navbar navbar-static-top">
            <a
              href="#"
              alt="nodae"
              className="sidebar-toggle"
              data-toggle="push-menu"
              role="button"
            >            
            <span style={{fontSize:"15px",marginLeft:"-12px"}}>&#9776;</span>

              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </a>

            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <li className="dropdown user user-menu">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img src={img ? img : userImg} alt="novalid" className="user-image" />
                    <span className="hidden-xs">
                      {user_Name && user_Name.name}
                    </span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="user-footer">
                      <a href="#" className="">
                        Profile
                      </a>
                      <span className="log-link" onClick={this.logout}>
                        Logout
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <aside className="main-sidebar">
          <section className="sidebar">
            <ul className="sidebar-menu" data-widget="tree">
              <li className="treeview">
                <a href="#">
                  <i className="fa fa-dashboard"></i> <span>Dashboard</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu">
                  <li className="active">
                    <a
                      className="nav-link"
                      href={`/admin/CompetencyMatrix/CompFormOne`}
                    >
                      <i className="fa fa-circle-o"></i>Create Competency Matrix
                    </a>
                  </li>
                  <li className="active">
                    {/* <Link
                      className="nav-link"
                      to={`/admin/Mapping/MapEmployee`}
                    >
                      <i className="fa fa-circle-o"></i>Create Map Employees
                    </Link> */}
                    <a className="nav-link" href={`/admin/Mapping/MapEmployee`}>
                      <i className="fa fa-circle-o"></i>Create Map Employees
                    </a>
                  </li>
                  <li className="treeview">
                    <a
                      href="#"
                      onClick='callmain("mainmenu","behavioralsubDropdown");'
                    >
                      <i className="fa fa-shield"></i> <span>Behavioral</span>{" "}
                      <i className="fa fa-angle-left pull-right"></i>
                    </a>
                    <ul className="treeview-menu" id="behavioralsubDropdown">
                      <li className="active">
                        <a
                          className="nav-link"
                          href={`/admin/Mapping/MapThemeToWorkLevel`}
                        >
                          <i className="fa fa-circle-o"></i>Map Theme to Work
                          Level
                        </a>
                      </li>
                      <li className="active">
                        <a
                          className="nav-link"
                          href={`/admin/Mapping/MapWorkleveToCompetency`}
                        >
                          <i className="fa fa-circle-o"></i>Map Work level
                          Competency
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="treeview">
                    <a
                      href="#"
                      onClick='callmain("mainmenu","techsubDropdown");'
                    >
                      <i className="fa fa-shield"></i> <span>Technical</span>{" "}
                      <i className="fa fa-angle-left pull-right"></i>
                    </a>
                    <ul className="treeview-menu" id="techsubDropdown">
                      <li className="active">
                        <a
                          className="nav-link"
                          href={`/admin/Mapping/MapDepartmentToFunction`}
                        >
                          <i className="fa fa-circle-o"></i>Map Department &
                          Function
                        </a>
                      </li>
                      <li className="active">
                        <a
                          className="nav-link"
                          href={`/admin/Mapping/MapFunctionToWorklevel`}
                        >
                          <i className="fa fa-circle-o"></i>Map Function &
                          Worklevel
                        </a>
                      </li>
                      <li className="active">
                        <a
                          className="nav-link"
                          href={`/admin/Mapping/MapCompetencyLevel`}
                        >
                          <i className="fa fa-circle-o"></i>Map Competency Level
                        </a>
                      </li>
                      <li className="active">
                        <a
                          className="nav-link"
                          href={`/admin/Mapping/MapCompetencyParams`}
                        >
                          <i className="fa fa-circle-o"></i>Map Competency
                          Params
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li className="treeview">
                <a href="#">
                  <i className="fa fa-folder"></i> <span>Masters</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right"></i>
                  </span>
                </a>
                <ul className="treeview-menu" id="MasterMenuId">
                  {parseInt(userTypeId) === 1 && (
                    <li className="active">
                      <a className="nav-link" href={`/admin/Master/Customer`}>
                        <i className="fa fa-circle-o"></i>Customer
                      </a>
                    </li>
                  )}
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/GroupCompany`}>
                      <i className="fa fa-circle-o"></i>Group Company
                    </a>
                  </li>
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/JobRole`}>
                      <i className="fa fa-circle-o"></i>Job Role
                    </a>
                  </li>
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Department`}>
                      <i className="fa fa-circle-o"></i>Department
                    </a>
                  </li>
                  <li className="active">
                    <a
                      className="nav-link"
                      href={`/admin/Master/JobDescription`}
                    >
                      <i className="fa fa-circle-o"></i>Job Description
                    </a>
                  </li>

                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/WorkLevel`}>
                      <i className="fa fa-circle-o"></i>Work level
                    </a>
                  </li>


                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Themes`}>
                      <i className="fa fa-circle-o"></i>Themes
                    </a>
                  </li>
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Function`}>
                      <i className="fa fa-circle-o"></i>Function
                    </a>
                  </li>
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Competency`}>
                      <i className="fa fa-circle-o"></i>Competency
                    </a>
                  </li>
                  <li className="active">
                    <a
                      className="nav-link"
                      href={`/admin/Master/ProficiencyScale`}
                    >
                      <i className="fa fa-circle-o"></i>Proficiency Scale
                    </a>
                  </li>
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Indicators`}>
                      <i className="fa fa-circle-o"></i>Indicators
                    </a>
                  </li>
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Location`}>
                      <i className="fa fa-circle-o"></i>Location
                    </a>
                  </li>
                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Users`}>
                      <i className="fa fa-circle-o"></i>Users
                    </a>
                  </li>


                  <li className="active">
                    <a className="nav-link" href={`/admin/Master/Derailers`}>
                      <i className="fa fa-circle-o"></i>Derailers
                    </a>
                  </li>

                </ul>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-circle-o text-aqua"></i>{" "}
                  <span>History</span>
                </a>
              </li>
              <li className="">
                <a className="" href={`/admin/Master/promotion`}>
                  <span className="fa fa-circle-o"></span>Promotion
                </a>
              </li>
              <li className="">
                <a className="" href={`/admin/Master/TraingprogramAdd`}>
                  <span className="fa fa-circle-o"></span>Traingprogram
                </a>
              </li>
              <li className="">
                <a className="" href={`/admin/Master/TraingprogramMapping`}>
                  <span className="fa fa-circle-o"></span>ProgramMapping
                </a>
              </li>
              <li className="">
                <a className="" href={`/admin/Master/CoursemapwithTrainer`}>
                  <span className="fa fa-circle-o"></span>Course map with trainer
                </a>
              </li>
              {/* <li className="">
                <a className="" href={`/admin/Master/Trainermapwithlocation`}>
                  <span className="fa fa-circle-o"></span>Trainer map with location
                </a>
              </li> */}
            </ul>
          </section>
        </aside>
        {/* <div className="content-wrapper"></div> */}
        <div className="control-sidebar-bg"></div>
      </div>
    );
  }
}

export default AdminHeader;
