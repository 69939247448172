import React, { Component, isValidElement } from "react";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import { Datatable } from "../../components/Datatable";
import moment from "moment";

export default class ViewHistoryIdp extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        userId: null,
        EditHistory:[],
        behTech: [
            { label: "Behavioural", value: 1 },
            { label: "Technical", value: 2 }
          ],
          goalterm: [
            { label: "short", value: 1 },
            { label: "mid", value: 2 },
            { label: "long", value: 3 },
          ],
          goaltermselected:{},
          behTechSelected:{},
          financialYearSelected:{},
          ShortArray:[],
          MidArray:[],
          LongArray:[],
        column:[
            {
                Header: "GoalName",
                accessor: "goalname"
              },
              {
                  Header:"Percentage",
                  accessor:"percentage"
              },
              {
                Header:"status",
                accessor:"statusChange"
            },
            {
                Header:"Reason_Deviation",
                accessor:"reason_deviation"
            },
            {
                Header:"Actual_Enddate",
                accessor:"actual_end_date"
            },
            {
                Header:"EditedBy",
                accessor:"name"
            },
            {
                Header:"EditedAt",
                accessor:"createdAt"
            },
        ],
        ManageIDP:[],
    }
}

async componentWillMount(){
    //console.log(this.props.match.params.id);
    let id = this.props.match.params.id ;
     this.setState({userId:id})
    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      this.setState({ yearList: yearList.data });
    }
//tbl_users.id=${id} and idp_goals_history.goal_id =tbl_idp_goalterm.id and tbl_idp_goalterm.userid =${id}
    let EditHistory = await HrMiddleWare.getRandomData(
        "tbl_users,idp_goals_history,tbl_idp_goalterm",
        `tbl_idp_goalterm.userid = ${id} and idp_goals_history.editedby = tbl_users.id and  tbl_idp_goalterm.id = idp_goals_history.goal_id`,
        "idp_goals_history.*,tbl_users.name"
      );
      if(EditHistory){
        
                EditHistory.data.map((ival,i)=>{
                  //console.log(ival);
                  EditHistory.data[i].createdAt=moment(ival.createdAt).format("YYYY-MM-DD");
                })
          this.setState({EditHistory:EditHistory.data});
      }
}

behTechSelect=async e =>{
    this.setState({behTechSelected:e});
    await this.GetHistory(e.label,this.state.financialYearSelected.value ? this.state.financialYearSelected.value : null );
}

financialYearSelect =async e =>{
    this.setState({financialYearSelected:e});
    await this.GetHistory(this.state.behTechSelected.label ? this.state.behTechSelected.label : null,e.value);
}


GoaltermSelect=async e =>{
    const{ShortArray,MidArray,LongArray}=this.state;
    this.setState({goaltermselected:e});
    let Data=[];
    console.log(e.label);
    console.log(MidArray);
    if(e.label=="short" && ShortArray != null){
        let wait = await  ShortArray.map(async(ival,i)=>{
            let result = await HrMiddleWare.getRandomData(
                "idp_goals_history,tbl_idp_goalterm,tbl_users",
                `tbl_idp_goalterm.id = ${ival} and idp_goals_history.goal_id=${ival} and tbl_users.id =idp_goals_history.editedby`,
                'idp_goals_history.*,tbl_users.name'
               )
         // and idp_goals_history.tearmtype=${e.label}
         if(result){
          result.data.map(async(jval,j)=>{
            //  console.log(jval);
                Data.push(jval)
            })
            
         }
        })
        await Promise.all(wait);
        //this.setState({EditHistory:Data});
       // console.log(Data);
        
    }else if(e.label=="mid"  && MidArray != null){
        
        let wait = await  MidArray.map(async(ival,i)=>{
            let result = await HrMiddleWare.getRandomData(
                "idp_goals_history,tbl_idp_goalterm,tbl_users",
                `tbl_idp_goalterm.id = ${ival} and idp_goals_history.goal_id=${ival} and tbl_users.id =idp_goals_history.editedby`,
                'idp_goals_history.*,tbl_users.name'
               )
         // and idp_goals_history.tearmtype=${e.label}
         if(result){
          result.data.map(async(jval,j)=>{
              //console.log(jval);
                Data.push(jval)
            })
            
         }
        })
        await Promise.all(wait);
    }else if(e.label=="long" && LongArray != null){
        let wait = await  LongArray.map(async(ival,i)=>{
            let result = await HrMiddleWare.getRandomData(
                "idp_goals_history,tbl_idp_goalterm,tbl_users",
                `tbl_idp_goalterm.id = ${ival} and idp_goals_history.goal_id=${ival} and tbl_users.id =idp_goals_history.editedby`,
                'idp_goals_history.*,tbl_users.name'
               )
         // and idp_goals_history.tearmtype=${e.label}
         if(result){
          result.data.map(async(jval,j)=>{
             // console.log(jval);
                Data.push(jval)
            })
            
         }
        })
        await Promise.all(wait);

    };
    Data.map((ival,i)=>{
          Data[i].createdAt=moment(ival.createdAt).format("YYYY-MM-DD");
    });
  this.setState({EditHistory:Data})

}

GetHistory=async(a=null,b=null)=>{
    console.log(a,b,this.state.userId);
     if(a!=null & b !=null){
        let ManageIDP = await HrMiddleWare.getRandomData(
            "tbl_idp_goals",
            `userid = ${this.state.userId} and yearId = ${b} and type ='${a}'`,
            '*'
           )
           console.log(ManageIDP.data);
           if(ManageIDP.data){
            this.setState({ManageIDP:ManageIDP.data});
            ManageIDP.data.map((ival,i)=>{
                   if(ival.short_term != null){
                       this.setState({ShortArray:JSON.parse(ival.short_term)});
                   }
                    if(ival.mid_term != null){
                       console.log(ival.mid_term)
                    this.setState({MidArray:JSON.parse(ival.mid_term)});
                   }
                    if(ival.long_term != null){
                    this.setState({LongArray:JSON.parse(ival.long_term)});
                   }
            })
               

        }
     }
}


render(){
  //  console.log(this.state.EditHistory)
  return(
      <React.Fragment>
        <section id="main-content">
          <section className="wrapper" id="wrapper">
            <div className="container">
            <div className="row form-group">
                <div className="col-sm-12 padding-10 bg-white height-300" style={{height:"auto"}}>
                  <h2>View History</h2>
                  
                  <div className="row form-group ">
                   <div className="col-sm-2" />
                   <div className="col-sm-2">IDP type</div>
                   <div className="col-sm-5">
                   <SingleSelect
                        handleChange={this.behTechSelect}
                        options={this.state.behTech}
                        selectedService={this.state.behTechSelected}
                     />
                    </div>
                    <div className="col-sm-3"/>
                    </div>
                    <div className="row form-group ">
                                  <div className="col-sm-2"/>
                                  <div className="col-sm-2">Select Year</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={this.financialYearSelect}
                                      options={this.state.yearList}
                                      selectedService={this.state.financialYearSelected}
                                    />
                                  </div>
                                  <div className="col-sm-3"/>
                                      </div>
                   {this.state.ManageIDP.length ? <React.Fragment>
                    <div className="row form-group ">
                                  <div className="col-sm-2"/>
                                  <div className="col-sm-2">Select Goal-Type</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={this.GoaltermSelect}
                                      options={this.state.goalterm}
                                      selectedService={this.state.goaltermselected}
                                    />
                                  </div>
                                  <div className="col-sm-3"/>
                                      </div>
                   </React.Fragment>:null}                   
                  <div className="row form-group ">
                        <div className="col-sm-12">
                            {this.state.EditHistory.length ? 
                             <Datatable
                             data={this.state.EditHistory}
                             columnHeading={this.state.column}
                           />
                            :null}
                        </div>
                        </div>
                        </div>
             </div></div>   
             </section></section>
      </React.Fragment>
  )
}
}

