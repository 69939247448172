import React from 'react';
import { Image, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import image from '../../image/18-128.png';

const CompetencyPDF = ({ data, userData, jobRoleName, section1Data, compList, section2Data }) => {
  console.log(data);
  console.log(userData);
  console.log(section1Data);
  console.log(compList);
  console.log(section2Data);

  let arr = [];
  // section1Data.map(item => {
  //   compList.map(element => {
  //     if (item.cmpid == element.competencyid) {
  //       arr.push({ theme: element.theme, comp: element.competencyname, cmt1: item.seComment[0].strength, cmt2: item.seComment[0].area });
  //     }
  //   });
  // });
  let indi = [];
  {
    userData &&
      userData.userFullIndicatorList.map((item, i) => {
        item.indicators.map(element => {
          indi.push(element.indicators);
        });
      });
  }

  let mark = [];
  for (var i = 0; i < 7; i++) {
    for (var j = 0; j < 6; j++) {
      if (i == 0) {
        mark.push(<Text style={s.inner}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>);
      } else {
        mark.push(<Text style={s.inner}>{j}</Text>);
      }
    }
  }
  let a1 = [];
  let a2 = [];
  section1Data.map((ival, l) => {
    a1.push(<Text style={s.tickcontent}>{'test'}</Text>);
    for (let i = -1; i < 6; i++) {
      for (let j = 0; j < 6; j++) {
        if (i === -1) {
          a1.push(
            <View style={s.row}>
              <Text>&nbsp;&nbsp;</Text>
            </View>
          );
        } else if (i === 0 && j === Math.round(ival.self)) {
          a1.push(
            <View style={s.row}>
              {/* <Text style={s.subdata}>{ival.comp}</Text> */}
              <Text>
                <Image style={s.img} src={image}></Image>
              </Text>
            </View>
          );
        } else if (i === 1 && j === Math.round(ival.reportee)) {
          a1.push(
            <View style={s.row}>
              {/* <Text style={s.subdata}>{ival.comp}</Text> */}
              <Text>
                <Image style={s.img} src={image}></Image>
              </Text>
            </View>
          );
        } else if (i === 2 && j === Math.round(ival.supervisor)) {
          a1.push(
            <View style={s.row}>
              {/* <Text style={s.subdata}>{ival.comp}</Text> */}
              <Text>
                <Image style={s.img} src={image}></Image>
              </Text>
            </View>
          );
        } else if (i === 3 && j === Math.round(ival.peer)) {
          a1.push(
            <View style={s.row}>
              {/* <Text style={s.subdata}>{ival.comp}</Text> */}
              <Text>
                <Image style={s.img} src={image}></Image>
              </Text>
            </View>
          );
        } else if (i === 4 && j === Math.round(ival.customer)) {
          a1.push(
            <View style={s.row}>
              {/* <Text style={s.subdata}>{ival.comp}</Text> */}
              <Text>
                <Image style={s.img} src={image}></Image>
              </Text>
            </View>
          );
        } else if (i === 5 && j === Math.round(ival.avg)) {
          a1.push(
            <View style={s.row}>
              {/* <Text style={s.subdata}>{ival.comp}</Text> */}
              <Text>
                <Image style={s.img} src={image}></Image>
              </Text>
            </View>
          );
        } else if (
          ((j === i) === 0 && isNaN(ival.self)) ||
          (j === 0 && i === 1 && isNaN(ival.reportee)) ||
          (j === 0 && i === 2 && isNaN(ival.supervisor)) ||
          (j === 0 && i === 3 && isNaN(ival.peer)) ||
          (j === 0 && i === 4 && isNaN(ival.customer))
        ) {
          a1.push(
            <View style={s.row}>
              {/* <Text style={s.subdata}>{ival.comp}</Text> */}
              <Text>
                <Image style={s.img} src={image}></Image>
              </Text>
            </View>
          );
        } else {
          a1.push(
            <View style={s.tashrow}>
              <Text>&nbsp;&nbsp;</Text>
              <Text>-</Text>
            </View>
          );
        }
      }
    }
  });
  a2.push(<View style={s.row}>{a1}</View>);

  return (
    <Document>
      <Page size="A4" style={{backgroundColor:"#EEEDE7"}}>
        <View style={s.border}>
          <View>
            <Text style={s.head}>Competency Report of {data.name}</Text>
          </View>
        </View>

        <View style={s.border}>
          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Employee Name</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.name}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Age</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.age}</Text>
            </View>
            {/* <View style={s.section}/> */}
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Email Address</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.email_id}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Telphone Number</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.telphone}</Text>
            </View>
            {/* <View style={s.section}/> */}
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Mobile Number</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.mobile_no}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Qualification</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.qualification}</Text>
            </View>
            {/* <View style={s.section}/> */}
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Location</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.address}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Job Role</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{jobRoleName}</Text>
            </View>
            {/* <View style={s.section}/> */}
          </View>
        </View>
        <Page wrap={false}>
          <View style={s.border}>
            <View>
              <Text style={s.head}>Competency Report of {data.name}</Text>
            </View>
          </View>

          <View>
            <Text style={s.head}>Section 1 - Summary of Rating</Text>
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Theme</Text>
              <View style={s.row}></View>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Competency Name</Text>
            </View>
            <View style={s.section}>
              <Text style={s.cmprating}>Competency Rating</Text>
              <View style={s.section}>
                <View style={s.smallrow}>
                  <Text style={s.small}>SE</Text>
                  <Text style={s.small}>RE</Text>
                  <Text style={s.small}>SP</Text>
                  <Text style={s.small}>PR</Text>
                  <Text style={s.small}>CS</Text>
                  <Text style={s.small}>AVR</Text>
                </View>
                {section1Data &&
                  section1Data.map(item => {
                    return (
                      <View style={s.section}>
                        <View style={s.row}>
                          <View>
                            <Text style={s.small1}>{item.self}</Text>
                          </View>
                          <View>
                            <Text style={s.small1}>{item.supervisor}</Text>
                          </View>
                          <View>
                            <Text style={s.small1}>{item.reportee}</Text>
                          </View>
                          <View>
                            <Text style={s.small1}>{item.peer}</Text>
                          </View>
                          <View>
                            <Text style={s.small1}>{item.customer}</Text>
                          </View>
                          <View>
                            <Text style={s.small1}>{item.avg}</Text>
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>
            </View>

            {/* <View style={s.section}>
              <Text style={s.cmpstrength}>Strength and Area Of Improvement</Text>
              <View>
                {section1Data &&
                  arr.map(item => {
                    return (
                      <View>
                        <View style={s.row}>
                          <Text style={s.color1}>{userData.name}</Text>
                        </View>
                        <View>
                          <Text style={s.subdata2}>Strength :</Text>
                          <Text style={s.subdata3}>{item.cmt1}</Text>
                          <Text style={s.subdata2}>Area :</Text>
                          <Text style={s.subdata3}>{item.cmt2}</Text>
                        </View>
                      </View>
                    );
                  })}
              </View>
            </View> */}

            {/* <View style={s.section}>
              <Text style={s.color}>Status</Text>
              {section1Data &&
                section1Data.map(item => {
                  if (Math.round(item.avg) < 2) {
                    item.avg = 'Need Improvement';
                  } else {
                    item.avg = 'Good';
                  }
                  return (
                    <View>
                      <View style={s.row}>
                        <View style={s.section}>
                          <Text style={s.subdata2}>{item.avg}</Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
            </View> */}
          </View>

          <View>
            {section1Data &&
              arr.map(item => {
                return (
                  <View>
                    <View style={s.row}>
                      <Text style={s.themestyle}>{item.theme}</Text>
                    </View>
                    <View style={s.row}>
                      <Text style={s.compentencystyle}>{item.comp}</Text>
                    </View>
                  </View>
                );
              })}
          </View>
        </Page>
        <Page wrap={false}>
          <View style={s.border}>
            <View>
              <Text style={s.head}>Competency Report of {data.name}</Text>
            </View>
          </View>

          <View>
            <Text style={s.head}>Section 2 - Total Rating Average Report</Text>
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color2}>SI.No</Text>
              {userData &&
                userData.userFullIndicatorList.map((item, i) => {
                  return <Text style={s.subdata}>{i + 1}</Text>;
                })}
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Competency</Text>
              {userData &&
                userData.userFullIndicatorList.map((item, i) => {
                  return <Text style={s.subdata}>{item.competencyname}</Text>;
                })}
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Level</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Indicator Names</Text>
              {indi &&
                indi.map(element => {
                  return <Text style={s.subdata}>{element}</Text>;
                })}
            </View>
            <View style={s.section}>
              <Text style={s.color2}>RE</Text>
              {section2Data &&
                section2Data.map((item, i) => {
                  return <Text style={s.subdata}>{item.reportee}</Text>;
                })}
            </View>
            <View style={s.section}>
              <Text style={s.color2}>SP</Text>
              {section2Data &&
                section2Data.map((item, i) => {
                  return <Text style={s.subdata}>{item.supervisor}</Text>;
                })}
            </View>
            <View style={s.section}>
              <Text style={s.color2}>PR</Text>
              {section2Data &&
                section2Data.map((item, i) => {
                  return <Text style={s.subdata}>{item.peer}</Text>;
                })}
            </View>
            <View style={s.section}>
              <Text style={s.color2}>CS</Text>
              {section2Data &&
                section2Data.map((item, i) => {
                  return <Text style={s.subdata}>{item.customer}</Text>;
                })}
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Rating Average</Text>
              {section2Data &&
                section2Data.map((item, i) => {
                  return <Text style={s.subdata}>{item.avg}</Text>;
                })}
            </View>
          </View>
        </Page>
        <Page wrap={false}>
          <View style={s.border}>
            <View>
              <Text style={s.head}>Competency Report of {data.name}</Text>
            </View>
          </View>

          <View>
            <Text style={s.head}>Section 3 - Frequency Distribution Report</Text>
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color2}>Competency Name</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Self</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Reportees</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Supervisior</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Peer</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Customer</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color2}>Average</Text>
            </View>
          </View>

          <View>
            <View style={s.row}>{mark}</View>
          </View>

          <View>
            {/* <View style={s.row}> */}
            <Text style={{ width: '100%' }}>{a2}</Text>
            {/* </View> */}
          </View>
        </Page>
        <Text style={s.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
      </Page>
    </Document>
  );
};

const s = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  tashrow: {
    flexDirection: 'row'
  },

  smallrow: {
    flexDirection: 'row',
    marginLeft: 19
  },
  column: {
    flexDirection: 'column'
  },
  section: {
    position: 'relative',
    margin: 10,
    padding: 0,
    flexGrow: 1
  },
  border: {
    borderBottomColor: 'black',
    borderBottomWidth: 1
  },
  head: {
    textAlign: 'center',
    fontWeight: 700,
    color: '#2d5986',
    fontSize: 12,
    lineHeight: 1.1,
    marginTop: 20,
    marginBottom: 20
  },
  color: {
    color: '#2D5986',
    fontSize: 10,
    width: '20%'
  },
  cmprating: {
    marginLeft: 70,
    color: '#2D5986',
    fontSize: 10,
    width: '20%'
  },
  themestyle: {
    fontSize: 7,
    marginTop: 0,
    /*  bottom: '185 em !important', */
    marginLeft: 10,
    position: 'absolute'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  },
  d: {
    fontSize: 10,
    width: '25%',
    marginLeft: -60
  },
  dot: {
    fontSize: 10,
    width: '1%',
    marginLeft: 10
  },
  small: {
    position: 'relative',
    fontSize: 7,
    marginLeft: 10
  },
  cmpstrength: {
    fontSize: 7,
    marginLeft: 50,
    position: 'relative',
    color: '#2D5986'
  },
  small1: {
    position: 'relative',
    fontSize: 7,
    marginLeft: 20
    // width: '20%',
    // flexGrow: 1
  },
  subdata: {
    position: 'relative',
    padding: 5,
    flexGrow: 1,
    fontSize: 8,
    width: '20%'
  },
  tickcontent: {
    marginLeft: 0,
    fontSize: 7,
    paddingLeft: 0,
    float: 'left',
    letterSpacing: ''
  },
  subdata1: {
    position: 'relative',
    padding: 5,
    flexGrow: 1,
    fontSize: 8,
    width: '20%',
    marginLeft: 100,
    marginTop: -19
  },
  compentencystyle: {
    position: 'relative',
    padding: 0,
    flexGrow: 1,
    fontSize: 7,
    /*width: '20%',*/
    marginLeft: 110,
    bottom: '185 em !important',
    marginTop: -10
  },
  color1: {
    color: '#d9534f',
    position: 'relative',
    padding: 5,
    flexGrow: 1,
    fontSize: 8,
    width: '20%',
    marginLeft: 60
  },
  subdata2: {
    position: 'relative',
    padding: 1,
    flexGrow: 1,
    fontSize: 8,
    width: '20%',
    margin: 0,
    marginLeft: 60
    // marginTop : -19
  },
  subdata3: {
    position: 'relative',
    padding: 1,
    flexGrow: 1,
    fontSize: 8,
    width: '20%',
    marginLeft: 40,
    marginTop: -11
  },
  color2: {
    // color : '#d9534f',
    position: 'relative',
    padding: 5,
    flexGrow: 1,
    fontSize: 8
    // width : '20%'
  },
  inner: {
    position: 'relative',
    padding: 0,
    margin: 0,
    flexGrow: 1,
    fontSize: 8
    // marginLeft: -5
    // width : '16.6%'
  },
  table: {
    display: 'table',
    width: 'auto',
    fontSize: 7
    // borderStyle: "solid",
    // borderWidth: 1,
    //  borderRightWidth: 0,
    //   borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    fontSize: 7
  },
  tableCol: {
    //  width: "25%",
    // borderStyle: "solid",
    //  borderWidth: 1,
    // borderLeftWidth: 0,
    //  borderTopWidth: 0
  },
  tableCell: {
    position: 'relative',
    // margin: "auto",
    padding: 0,
    margin: 0,
    marginTop: 0,
    fontSize: 8
  },
  img: {
    width: 10,
    height: 10
  },
  innerstyle: {
    fontSize: 16
  }
});

export default CompetencyPDF;
