import React from 'react';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import SingleSelect from '../../../components/Form/SingleSelect';
import ValidationView from '../../../components/Form/ValidationView';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import { Datatable } from '../../../components/Datatable';
import Swal from 'sweetalert2';
// import { get } from 'https';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';

const tableName = `tbl_tech_comp_criticals`;

class MapCompetencyParams extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: '',
      themeOptions: [],
      themeSelected: null,
      themeId: null,
      themeLabelName: null,
      errorthemeSelected: null,
      competencyOptions: [],
      competencySelected: null,
      competencyId: null,
      competencyLabelName: null,
      errorcompetencySelected: null,
      definition: '',
      importance: '',
      knowledgeArea: '',
      performanceParameter: '',
      updateData: false,
      selectboxDisable: false,
      data: []
    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getTableMaster(adminId);
    this.getThemeMaster(adminId);
    this.getCompetencyMaster(adminId);
  }

  getTableMaster = async adminId => {
    try {
      const { data } = await MappingMiddleware.getCompetencyParamsData(adminId);
      if (data) {
        this.setState({ data });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getThemeMaster = async adminId => {
    try {
      const { data: themeOptions } = await MappingMiddleware.getThemeMaster(adminId);
      if (themeOptions) {
        this.setState({
          themeOptions
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getCompetencyMaster = async adminId => {
    try {
      const { data: competencyOptions } = await MappingMiddleware.getMapthemeTocompetencyData(adminId);
      if (competencyOptions) {
        this.setState({
          competencyOptions
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  column = [
    {
      Header: 'Theme',
      accessor: 'themename'
    },
    {
      Header: 'Competency',
      accessor: 'competencyname'
    },
    {
      Header: 'Definition',
      accessor: 'defenition'
    },
    {
      Header: 'Importance',
      accessor: 'important'
    },
    {
      Header: 'Knowledge area',
      accessor: 'critical_knowledge'
    },
    {
      Header: 'Performance Parameter',
      accessor: 'critical_performance'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }
  ];

  themeSelect = async selectedOption => {
    this.setState({
      themeSelected: selectedOption,
      themeId: selectedOption.value,
      themeLabelName: selectedOption.label
    });
  };

  competencySelect = async selectedOption => {
    this.setState({
      competencySelected: selectedOption,
      competencyId: selectedOption.value,
      competencyLabelName: selectedOption.label
    });
  };

  onSubmit = async () => {
    const { themeId, competencyId, definition, importance, knowledgeArea, performanceParameter, adminId } = this.state;
    if (this.validate(themeId, 'errorthemeSelected', 'Please Select Theme')) return true;
    if (this.validate(competencyId, 'errorcompetencySelected', 'Please Select Competency')) return true;
    const formData = new FormData();
    formData.append('theme', themeId);
    formData.append('competency', competencyId);
    formData.append('defenition', definition);
    formData.append('important', importance);
    formData.append('critical_knowledge', knowledgeArea);
    formData.append('critical_performance', performanceParameter);
    formData.append('cmpId', adminId);

    try {
      const result = await MappingMiddleware.mapCompetencyParamsData(formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been completed',
          showConfirmButton: false,
          timer: 1500
        });
        this.getTableMaster(adminId);
        this.setState({
          themeSelected: null,
          themeId: null,
          themeLabelName: null,
          errorthemeSelected: null,
          competencySelected: null,
          competencyId: null,
          competencyLabelName: null,
          errorcompetencySelected: null,
          definition: '',
          importance: '',
          knowledgeArea: '',
          performanceParameter: '',
          selectboxDisable: false,
          updateData: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  buttonEdit = value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    this.setState({
      index,
      definition: getData.defenition,
      importance: getData.important,
      knowledgeArea: getData.critical_knowledge,
      performanceParameter: getData.critical_performance,
      selectboxDisable: true,
      updateData: true
    });
  };

  onUpdate = async () => {
    const { index, definition, importance, knowledgeArea, performanceParameter, adminId } = this.state;
    const formData = new FormData();
    formData.append('defenition', definition);
    formData.append('important', importance);
    formData.append('critical_knowledge', knowledgeArea);
    formData.append('critical_performance', performanceParameter);

    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;
    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param formData : json list value for update
       */
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Function has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.getTableMaster(adminId);
        this.setState({
          themeSelected: null,
          themeId: null,
          themeLabelName: null,
          errorthemeSelected: null,
          competencySelected: null,
          competencyId: null,
          competencyLabelName: null,
          errorcompetencySelected: null,
          definition: '',
          importance: '',
          knowledgeArea: '',
          performanceParameter: '',
          selectboxDisable: false,
          updateData: false
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  render() {
    const {
      themeOptions,
      themeSelected,
      errorthemeSelected,
      competencyOptions,
      competencySelected,
      errorcompetencySelected,
      definition,
      importance,
      knowledgeArea,
      performanceParameter,
      updateData,
      selectboxDisable,
      data
    } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Map Competency Params</h3>
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="theme">Theme</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect handleChange={this.themeSelect} options={themeOptions} selectedService={themeSelected} isDisabled={selectboxDisable} />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorthemeSelected} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">Competency</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect handleChange={this.competencySelect} options={competencyOptions} selectedService={competencySelected} isDisabled={selectboxDisable} />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorcompetencySelected} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="definition">Definition</label>
                </div>
                <div className="col-sm-5">
                  <textarea className="form-control" rows="3" id="definition" name="definition" placeholder="Please enter definition" onChange={this.handleUserInput} value={definition}></textarea>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="importance">Importance</label>
                </div>
                <div className="col-sm-5">
                  <textarea className="form-control" rows="3" id="importance" name="importance" placeholder="Please enter importance" onChange={this.handleUserInput} value={importance}></textarea>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="knowledgeArea">Knowledge area</label>
                </div>
                <div className="col-sm-5">
                  <textarea
                    className="form-control"
                    rows="3"
                    id="knowledgeArea"
                    name="knowledgeArea"
                    placeholder="Please enter knowledge area"
                    onChange={this.handleUserInput}
                    value={knowledgeArea}
                  ></textarea>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="performanceParameter">Performance Parameter</label>
                </div>
                <div className="col-sm-5">
                  <textarea
                    className="form-control"
                    rows="3"
                    id="performanceParameter"
                    name="performanceParameter"
                    placeholder="Please enter performance parameter"
                    onChange={this.handleUserInput}
                    value={performanceParameter}
                  ></textarea>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2"></div>
                <div className="col-sm-5">
                  {!updateData ? (
                    <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                      Update
                    </button>
                  )}
                </div>
                <div className="col-sm-3" />
              </div>
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MapCompetencyParams;
