import React from 'react';
import LoginModal from '../../components/Modal/Modal';
import HrMiddleWare from '../../middleWare/HrMiddleWare';
import { SingleSelect, FormMiddleWare } from '../../components/Form';
import ValidationView from '../../components/Form/ValidationView';
import './framwork.css';
var btoa = require('btoa');

class Newdictionary extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      show: false
      // show1: true
      // show2: false
    };
  }
  async componentWillMount() {
    // let adminId = await localStorage.getItem('adminId');
    // let userId = await localStorage.getItem('userId');
    let adminId = 167
    let userId =508
    try {
      let newthemedata = await HrMiddleWare.getRandomData('ClusterMaster,tbl_theme', 'ClusterMaster.themeId=tbl_theme.id group by themeId ', 'ClusterMaster.themeId');
      //  console.log(newthemedata.data,"newthemedata")
      var newthemeid = '';
      newthemedata.data.map(i => {
        //console.log(i)
        newthemeid += i.themeId + ',';
      });
      console.log(newthemeid);
      var newThemeid = newthemeid.replace(/,\s*$/, '');
      // console.log(newThemeid,"newThemeid");
      // console.log(adminId,"adminId");

      let themeList = await HrMiddleWare.getRandomData('tbl_theme', ` id  in (${newThemeid}) and customerid=${adminId} and textType='Behavioral' `, 'theme as label,id as value');
      // console.log(themeList,"themeList");
      let framWorkList = await HrMiddleWare.getRandomData(
        'ClusterMaster,tbl_competency,tbl_theme',
        `tbl_theme.id=ClusterMaster.themeId and tbl_competency.id=ClusterMaster.CmpId  Order by tbl_theme.id ASC`,
        'tbl_competency.competencyname,tbl_theme.theme,tbl_competency.id as cid,tbl_theme.id as tid'
      );

      let defaultList = await HrMiddleWare.getRandomData(
        'tbl_competencyframework,tbl_competency,tbl_theme,tbl_worklevel',
        `tbl_theme.id=tbl_competencyframework.theme and tbl_competency.id=tbl_competencyframework.competency  and tbl_competencyframework.status='default' and tbl_worklevel.id=tbl_competencyframework.worklevel Order by tbl_theme.id ASC`,
        'tbl_competency.competencyname,tbl_competency.Description,tbl_theme.theme,tbl_worklevel.worklevel,tbl_competency.id as cid,tbl_theme.id as tid,tbl_worklevel.id as wid,tbl_competencyframework.status'
      );

      let workLeveList = await HrMiddleWare.getRandomData('tbl_worklevel', `id =1 and  customerid=${adminId}  order by worklevel ASC`, 'worklevel as label,id as value');
      console.log(workLeveList.data);
      let subThemeList = themeList.data.filter((ival, i) => {
        if (i < 5) {
          return ival;
        }
      });
      this.setState({
        themeList: themeList.data,
        adminId,
        userId,
        framWorkList: framWorkList.data,
        defaultList: defaultList.data,
        workLeveList: workLeveList.data,
        subThemeList
      });
    } catch (error) {
      console.log(error);
    }
  }
  addComp = () => {
    console.log(0);
  };

  defaultList = () => {
    this.setState({ show: true });
  };
  bacfun = () => {
    this.setState({ show: false });
  };
  onSubmit = async () => {
    let { themeSelect, workSelect, competency, competencyDef, competencyImp, adminId } = this.state;

    if (this.validate(themeSelect, 'errorthemeSelect', 'Please select Theme')) return true;
    if (this.validate(workSelect, 'errorworkSelect', 'Please select Work-level')) return true;
    if (this.validateText(competency, 'errorcompetency', 'Please enter Competency')) return true;
    if (this.validateText(competencyDef, 'errorcompetencyDef', 'Please enter Definition')) return true;
    if (this.validateText(competencyImp, 'errorcompetencyImp', 'Please select Importance')) return true;
    let value1 = {};
    value1.themeId = themeSelect.value;
    value1.competencyname = competency;
    value1.Description = competencyDef;
    value1.CompetencyImportant = competencyImp;
    value1.textType = 'Behavioral';
    value1.status = 'active';
    value1.customerid = adminId;
    value1.adminId = adminId;

    let value2 = {};
    value2.theme = themeSelect.value;
    value2.worklevel = workSelect.value;
    value2.status = adminId;
    let body = {};
    body.tn1 = 'tbl_competency';
    body.tn2 = 'tbl_competencyframework';
    body.val1 = value1;
    body.val2 = value2;
    try {
      const result = await HrMiddleWare.multiInsert(body);
      if (result) {
        console.log(body);
      }
    } catch (error) {
      console.log(error);
    }
  };
  innerContent = (ia, ja) => {
    let res = this.state.framWorkList.filter((ival, i) => {
      if (ival.tid === ia) {
        return ival;
      }
    });
    let arr = [];
    if (res && res.length) {
      let result = res.map((ival, i) => {
        let val = btoa(`${ival.tid},${ival.cid},${ival.competencyname}`);
        arr.push(
          <tr>
            <td
              style={{
                padding: '5px 5px 5px 5px',
                borderBottom: '0.5px solid #1d95af'
              }}
            >
              <a href="#" onClick={() => window.open(`NewdictionaryView?${val}`, '_blank')} style={{ color: '#000' }}>
                {ival.competencyname}
              </a>
            </td>
          </tr>
        );
      });
      return <table style={{ width: '100%' }}>{arr}</table>;
    }
    return '--------';
  };

  render() {
    let {
      themeList,
      themeSelect,
      workLeveList,
      workSelect,
      competency,
      competencyDef,
      competencyImp,
      errorthemeSelect,
      errorworkSelect,
      errorcompetency,
      errorcompetencyDef,
      errorcompetencyImp,
      framWorkList,
      subThemeList,
      defaultList,
      show
    } = this.state;

    themeList = show ? subThemeList : themeList;
    framWorkList = show ? defaultList : framWorkList;
    return (
      <section id="main-content">
        <section className="wrapper">
          <div>
            <div className="box box-info">
              <div className="box-header with-border">
                <h3 className="box-title">Competency Dictionary</h3>
                <div className="box-tools pull-right">
                  <button className="btn btn-box-tool" data-widget="collapse" title="Collapse">
                    <i className="fa fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="box-body">
                <div className="row form-group">
                  <div className="col-sm-6" />
                  <div className="col-sm-2"></div>
                  {this.state.show ? (
                    <div className="row form-group">
                      <div className="col-sm-11"></div>
                      {this.state.show && (
                        <div className="col-sm-1">
                          <button type="button" className="btn btn-primary" onClick={this.bacfun}>
                            Back
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="col-sm-3">
                        {/* <button type="button" className="btn btn-primary" onClick={this.defaultList}>
                          Default Competency
                        </button> */}
                      </div>
                      <div className="col-sm-1">
                        <button type="button" className="btn btn-primary" onClick={this.addComp}>
                          Print
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '14%' }}>THEMES</th>
                      {workLeveList &&
                        workLeveList.map((ival, i) => {
                          return (
                            <th
                              style={{
                                borderLeft: '1px solid white',
                                width: '14%'
                              }}
                            >
                              {ival.label}
                            </th>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody>
                    {themeList &&
                      themeList.map((ival, i) => {
                        return (
                          <tr>
                            <td
                              style={{
                                padding: '5px 5px 5px 10px',
                                border: '0.5px solid #1d95af',
                                borderBottom: '2.5px solid #1d95af'
                              }}
                            >
                              {ival.label}
                            </td>
                            {workLeveList &&
                              workLeveList.map((jval, j) => {
                                return (
                                  <td
                                    valign="top"
                                    style={{
                                      border: '0.5px solid #1d95af',
                                      borderBottom: '2.5px solid #1d95af',
                                      padding: '0px'
                                    }}
                                  >
                                    {this.innerContent(ival.value, jval.value)}
                                  </td>
                                );
                              })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Newdictionary;
