import React, { Component } from 'react';
import SingleSelect from '../../../components/Form/SingleSelect';
import ValidationView from '../../../components/Form/ValidationView';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import Datatable from '../../../components/Datatable/Datatable';
import Swal from 'sweetalert2';
import MultiSelect from '../../../components/Form/MultiSelect';
import WizardPrograss from '../Master/wizardPrograssbar';
import WizardNextPreviousButton from '../Master/wizardNextPreviousButton';

class MapWorkleveToCompetency extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: '',
      workLevelOptionsBefore: [],
      behThemeOptions: [],
      behThemeSelected: null,
      behThemeId: null,
      behThemeLabelName: null,
      errorThemeSelected: null,
      workLevelOptions: [],
      workLevelSelected: null,
      workLevelId: null,
      workLevelLabelName: null,
      errorWorkLevelSelected: null,
      jobRoleOptions: [],
      jobRoleSelected: null,
      jobRoleId: null,
      jobRoleLabelName: null,
      errorJobRoleSelected: null,
      competencyOptionsBefore: [],
      competencyOptions: [],
      competencySelected: null,
      competencyId: null,
      competencyLabelName: null,
      errorCompetencySelected: null,
      updateData: false,
      groupCompanyOptions: [],
      groupCompanySelected: null,
      errorGroupCompanySelected: null,
      data: [],
      importantce: [
        { label: "Critical", value: "Critical" },
        { label: "Essential", value: "Essential" },
        { label: "Desirable", value: "Desirable" }
      ],
      tence: [
        { label: "Future", value: "Future" },
        { label: "Current", value: "Current" }
      ],
    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(
      adminId,
      'tbl_worklevel',
      'id',
      'worklevel',
      'workLevelOptionsBefore'
    );
    this.getSelectBoxMaster(
      adminId,
      'tbl_jobRole',
      'id',
      'jobRoleName',
      'jobRoleOptions'
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_worklevel",
      "id",
      "worklevel",
      "workLevelOptions"
    );

    try {
      const {
        data: behThemeOptions
      } = await MasterMiddleWare.getThemeForSelect(adminId, 'Behavioral');
      if (behThemeOptions) {
        this.setState({ behThemeOptions });
      }
      const {
        data: competencyOptionsBefore
      } = await MappingMiddleware.getCompetencyExtra(adminId, 'Behavioral');
      if (competencyOptionsBefore) {
        this.setState({ competencyOptionsBefore });
      }
      const { data } = await MappingMiddleware.getMapWorkLevelToComp(adminId);
      if (data) {
        this.setState({ data });
      }
      let { data: yearOptions } = await MappingMiddleware.getYear();
      if (yearOptions) {
        this.setState({
          yearOptions
        });
      }

      const {
        data: groupCompanyOptions
      } = await MappingMiddleware.getGroupCompanyForSelect(adminId);
      if (groupCompanyOptions) {
        this.setState({ groupCompanyOptions });
      }

    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: 'Theme',
      accessor: 'theme'
    },
    {
      Header: 'WorkLevel',
      accessor: 'worklevel'
    },
    {
      Header: 'JobRole',
      accessor: 'jobRoleName'
    },
    {
      Header: 'Competency',
      accessor: 'competencyname'
    }
    // {
    //   Header: 'Edit',
    //   accessor: 'edit',
    //   Cell: d => this.editMap(d)
    // },
    // {
    //   Header: 'Delete',
    //   accessor: 'delete',
    //   Cell: d => this.delete(d)
    // }
  ];

  editMap = value => {
    return (
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => this.buttonEdit(value)}
      >
        Edit
      </button>
    );
  };

  deleteMap = value => {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.buttonDelete(value)}
      >
        Delete
      </button>
    );
  };

  buttonDelete = async value => {
    let { data } = this.state;
    const index = value.index;
    const previousData = [...data];
    const getData = previousData[index];
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this file!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then(async result => {
      if (result.value) {
        try {
          data = previousData.filter(val => val.id !== getData.id);
          this.setState({
            data
          });
          const result = await MappingMiddleware.deleteMapWorkLevelToComp(
            getData.id
          );
          if (result) {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          }
        } catch (error) {
          Swal.fire('Cancelled', 'Something Went Wrong :)', 'error');
          this.setState({
            data: previousData
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your file is safe :)', 'error');
        this.setState({
          data: previousData
        });
      }
    });
  };

  buttonEdit = value => {
    const { data } = this.state;
    const index = value.index;
    const previousData = [...data];
    const getData = previousData[index];
    this.setState({
      groupCompanySelected: { label: getData.groupcompaniesname, value: getData.groupcompany },
      groupCompanyId: getData.groupcompany,
      groupCompanyLabelName: getData.groupcompaniesname,
      behThemeSelected: { label: getData.theme, value: getData.themeid },
      behThemeId: getData.themeid,
      behThemeLabelName: getData.theme,
      workLevelSelected: {
        label: getData.worklevel,
        value: getData.worklevelid
      },
      workLevelId: getData.worklevelid,
      workLevelLabelName: getData.worklevel,
      jobRoleSelected: { label: getData.jobRoleName, value: getData.jobRoleId },
      jobRoleId: getData.jobRoleId,
      jobRoleLabelName: getData.jobRoleName,
      competencySelected: {
        label: getData.competencyname,
        value: getData.competencyid
      },
      competencyId: getData.competencyid,
      competencyLabelName: getData.competencyname,
      updateData: true,
      index
    });
    this.behThemeSelect({ label: getData.theme, value: getData.themeid });
    this.getCompForJobRole({
      label: getData.jobRoleName,
      value: getData.jobRoleId
    });
  };

  groupCompanySelect = async selectedOption => {
    const { adminId, workLevelOptionsBefore, workLevelId } = this.state;
    this.setState({
      groupCompanySelected: selectedOption,
      groupCompanyId: selectedOption.value,
      groupCompanyLabelName: selectedOption.label,
    });
  }

  behThemeSelect = async selectedOption => {
    const { adminId, workLevelOptionsBefore, workLevelId } = this.state;
    this.setState({
      behThemeSelected: selectedOption,
      behThemeId: selectedOption.value,
      behThemeLabelName: selectedOption.label,
      workLevelSelected: null,
      workLevelId: null,
      workLevelLabelName: null,
      jobRoleSelected: null,
      jobRoleId: null,
      jobRoleLabelName: null
    });

    // try {
    //   const { data } = await MappingMiddleware.getWorkLevelForTheme(
    //     adminId,
    //     selectedOption.value
    //   );
    //   if (data) {
    //     let newWorkLevelOptionsBefore = [...workLevelOptionsBefore];
    //     let newWorkLevelArray = [];
    //     const result = data.map(val => {
    //       newWorkLevelOptionsBefore.filter(wrkLvl => {
    //         if (val.worklevelid === wrkLvl.value)
    //           newWorkLevelArray.push(wrkLvl);
    //       });
    //     });
    //     await Promise.all(result);
    //     this.setState({ workLevelOptions: newWorkLevelArray });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  workLevelSelect = selectedOption => {
    this.setState({
      workLevelSelected: selectedOption,
      workLevelId: selectedOption.value,
      workLevelLabelName: selectedOption.label,
      jobRoleSelected: null,
      jobRoleId: null,
      jobRoleLabelName: null
    });
  };

  getCompForJobRole = async selectedOption => {
    const {
      adminId,
      competencyOptionsBefore,
      behThemeId,
      workLevelId
    } = this.state;
    this.setState({
      jobRoleSelected: selectedOption,
      jobRoleId: selectedOption.value,
      jobRoleLabelName: selectedOption.label
    });

    try {
      const { data } = await MappingMiddleware.getCompForWorkLevelTheme(
        adminId,
        behThemeId,
        workLevelId,
        selectedOption.value
      );
      if (data) {
        let newCompetencyOptionsBefore = [...competencyOptionsBefore];
        const result = data.map(comp => {
          newCompetencyOptionsBefore = newCompetencyOptionsBefore.filter(
            val => comp.competencyid !== val.value
          );
        });
        await Promise.all(result);
        this.setState({ competencyOptions: newCompetencyOptionsBefore });
      }
    } catch (error) {
      console.log(error);
    }
  };

  competencySelect = selectedOption => {
    // console.log(selectedOption);
    this.setState({
      competencySelected: selectedOption,
      competencyId: selectedOption.value,
      competencyLabelName: selectedOption.label,
    });
    // if (selectedOption) {
    //   let selectedValue = '';
    //   selectedOption.map(values => (selectedValue += `${values.value},`));
    //   selectedValue = selectedValue.replace(/,\s*$/, '');
    //   this.setState({
    //     competencySelected: selectedOption,
    //     competencyId: selectedValue
    //   });
    // } else {
    //   this.setState({ competencySelected: '', competencyId: '' });
    // }
  };

  onSubmit = async () => {
    const {
      groupCompanyId,
      behThemeId,
      workLevelId,
      jobRoleId,
      competencyId,
      adminId,
      behThemeLabelName,
      workLevelLabelName,
      jobRoleLabelName,
      competencyLabelName,
      important,
      cF,
      yearSelected
    } = this.state;
    if (this.validate(groupCompanyId, 'errorGroupCompanySelected', 'Please Select GroupCompany'))
      return true;
    if (this.validate(yearSelected, 'erroryearSelected', 'Please Select FinancialYear')) return true;
    if (this.validate(behThemeId, 'errorThemeSelected', 'Please Select Theme'))
      return true;
    if (
      this.validate(
        workLevelId,
        'errorWorkLevelSelected',
        'Please Select WorkLevel'
      )
    )
      return true;
    if (
      this.validate(jobRoleId, 'errorJobRoleSelected', 'Please Select Job Role')
    )
      return true;
    if (
      this.validate(
        competencyId,
        'errorCompetencySelected',
        'Please Select Competency'
      )
    )
      return true;
    if (this.validate(important, "errorimportants", "Please select Importance"))
      return true;
    if (this.validate(cF, "errorcFs", "Please select current")) return true;

    // const competencyList = JSON.stringify(competencyId.split(','));
    const formData = new FormData();
    formData.set('cmid', adminId);
    formData.set('groupcompany', groupCompanyId);
    formData.set('themeid', behThemeId);
    formData.set('worklevelid', workLevelId);
    formData.set('jobRoleId', jobRoleId);
    // formData.set('competencyid', competencyList);
    formData.set('competencyid', competencyId);
    formData.set('financial_year', yearSelected.value);
    formData.set('type', 1);
    // formData.set('future', 'future');
    formData.append("imp_comp", important.value);
    formData.append("future", cF.value);
    console.log([...formData]);
    try {
      const { data } = await MappingMiddleware.createMapWorkLevelToComp(
        formData,
        adminId
      );
      if (data) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been completed',
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          data,
          behThemeId: '',
          behThemeSelected: '',
          workLevelId: '',
          workLevelSelected: '',
          workLevelOptions: [],
          jobRoleId: '',
          jobRoleSelected: '',
          competencyId: '',
          competencySelected: '',
          competencyOptions: [],
          yearSelected: "",
          groupCompanyId: "",
          groupCompanySelected: "",
          important: "",
          cF: ""
        });
        window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  };

  onUpdate = async () => {
    let {
      groupCompanyId,
      behThemeId,
      workLevelId,
      jobRoleId,
      competencyId,
      adminId,
      behThemeLabelName,
      workLevelLabelName,
      jobRoleLabelName,
      competencyLabelName,
      groupCompanyLabelName,
      index,
      data,
      yearSelected
    } = this.state;

    const previousData = [...data];
    const getData = { ...previousData[index] };
    const id = getData.id;
    // console.log(getData);
    getData.competencyid = competencyId;
    getData.competencyname = competencyLabelName;
    getData.id = id;
    getData.jobRoleId = jobRoleId;
    getData.jobRoleName = jobRoleLabelName;
    getData.groupcompaniesname = groupCompanyLabelName;
    getData.groupcompany = groupCompanyId;
    getData.theme = behThemeLabelName;
    getData.themeid = behThemeId;
    getData.worklevelid = workLevelId;
    getData.worklevel = workLevelLabelName;

    data = data.filter(val => val.id !== id);
    data.splice(index, 0, getData);
    this.setState({ data });

    const formData = new FormData();
    formData.set('cmid', adminId);
    formData.set('groupcompany', groupCompanyId);
    formData.set('themeid', behThemeId);
    formData.set('worklevelid', workLevelId);
    formData.set('jobRoleId', jobRoleId);
    formData.set('competencyid', competencyId);
    formData.set('financial_year', yearSelected.value);

    formData.set('type', 1);
    formData.set('future', 'future');
    // console.log([...formData]);
    try {
      const result = await MappingMiddleware.updateMapWorkLevelToComp(
        formData,
        id
      );
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Mapping has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          behThemeId: '',
          behThemeSelected: '',
          workLevelId: '',
          workLevelSelected: '',
          workLevelOptions: [],
          jobRoleId: '',
          jobRoleSelected: '',
          competencyId: '',
          competencySelected: '',
          competencyOptions: [],
          updateData: false,
          yearSelected: "",
          groupCompanyId: "",
          groupCompanySelected: ""
        });
        window.location.reload()
      }
    } catch (error) {
      this.setState({ data: previousData });
    }
  };
  yearSelectId = selectedOption => {
    this.setState({
      yearSelected: selectedOption,
    });
  };
  render() {
    const {
      behThemeOptions,
      behThemeSelected,
      errorThemeSelected,
      workLevelOptions,
      errorWorkLevelSelected,
      workLevelSelected,
      jobRoleOptions,
      jobRoleSelected,
      errorJobRoleSelected,
      competencyOptions,
      competencySelected,
      errorCompetencySelected,
      updateData,
      yearOptions,
      yearSelected,
      erroryearSelected,
      groupCompanyOptions,
      groupCompanySelected,
      errorGroupCompanySelected,
      data,
      importantce,
      important,
      errorimportants,
      tence,
      cF,
      errorcFs,
    } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Map Behavioral Competency</h3>
              <WizardPrograss step={14} />
              <div className="box-tools pull-right">
                <button
                  className="btn btn-box-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">

              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">GroupCompany</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.groupCompanySelect}
                    options={groupCompanyOptions}
                    selectedService={groupCompanySelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorGroupCompanySelected} />

              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="year">Financial Year</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.yearSelectId}
                    options={yearOptions}
                    selectedService={yearSelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={erroryearSelected} />

              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">Theme</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.behThemeSelect}
                    options={behThemeOptions}
                    selectedService={behThemeSelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorThemeSelected} />

              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">Work Level</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.workLevelSelect}
                    options={workLevelOptions}
                    selectedService={workLevelSelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorWorkLevelSelected} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">Job Role</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.getCompForJobRole}
                    options={jobRoleOptions}
                    selectedService={jobRoleSelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorJobRoleSelected} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">Competency</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.competencySelect}
                    options={competencyOptions}
                    selectedService={competencySelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorCompetencySelected} />
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="CompetencyImportance">Competency Importance</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={d =>
                      this.selectBoxStore("important", d)
                    }
                    options={importantce}
                    selectedService={important}
                  />
                </div>
              </div>
              <ValidationView errorname={errorimportants} />

              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor=" Current/Future"> Current / Future</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={d =>
                      this.selectBoxStore("cF", d)
                    }
                    options={tence}
                    selectedService={cF}
                  />
                </div>
              </div>
              <ValidationView errorname={errorcFs} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2"></div>
                <div className="col-sm-5">
                  {!updateData ? (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={this.onSubmit}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={this.onUpdate}
                    >
                      Update
                    </button>
                  )}
                </div>
                <div className="col-sm-3" />
                <WizardNextPreviousButton step={14} nextPage={"Mapping/MapCompetencyLevel"} previousPage={"Mapping/MapEmployee"} />

              </div>
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default MapWorkleveToCompetency;
