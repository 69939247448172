import React from 'react';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import { FormMiddleWare } from '../../../components/Form';

const tableName = `tbl_users`;

class Customer extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      name: null,
      errorname: null,
      user_name: null,
      erroruser_name: null,
      password: null,
      errorpassword: null,
      address: null,
      erroraddress: null,
      mobile_no: null,
      errormobile_no: null,
      telphone: null,
      errortelphone: null,
      email_id: null,
      erroremail_id: null,
      image: null,
      errorimage: null,
      is_customer: 1,
      userTypeId: 2,
      alertVisible: false,
      updateData: false,
      index: null
    };
  }

  async componentWillMount() {
    this.getMaster(tableName);
  }

  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'Customer Name',
      accessor: 'name'
    },
    {
      Header: 'User Name',
      accessor: 'user_name'
    },
    {
      Header: 'Password',
      accessor: 'password'
    },
    {
      Header: 'Address',
      accessor: 'address'
    },
    {
      Header: 'Mobile No',
      accessor: 'mobile_no'
    },
    {
      Header: 'Telephone',
      accessor: 'telphone'
    },
    {
      Header: 'Email Id',
      accessor: 'email_id'
    },
    {
      Header: 'Image',
      accessor: 'image'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }
  ];

  onSubmit = async () => {
    const { name, user_name, password, address, mobile_no, telphone, email_id, image, adminId, is_customer, userTypeId } = this.state;
    if (this.validate(name, 'errorname', 'Please Enter customer name')) return true;
    if (this.validate(user_name, 'erroruser_name', 'Please Enter user name')) return true;
    if (this.validate(password, 'errorpassword', 'Please Enter password')) return true;
    if (this.validate(address, 'erroraddress', 'Please Enter address')) return true;
    if (this.validate(mobile_no, 'errormobile_no', 'Please Enter mobile no')) return true;
    if (this.validate(telphone, 'errortelphone', 'Please Enter telphone')) return true;
    if (this.validate(email_id, 'erroremail_id', 'Please Enter email id')) return true;
    if (this.validate(image, 'errorimage', 'Please Enter image')) return true;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('user_name', user_name);
    formData.append('password', password);
    formData.append('address', address);
    formData.append('mobile_no', mobile_no);
    formData.append('telphone', telphone);
    formData.append('email_id', email_id);
    formData.append('image', image);
    formData.append('is_customer', is_customer);
    formData.append('userTypeId', userTypeId);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.createMaster(tableName, formData);
      if (result) {
        this.getMaster(tableName);
        this.setState({
          alertVisible: true,
          name: '',
          user_name: '',
          password: '',
          address: '',
          mobile_no: '',
          telphone: '',
          email_id: '',
          image: ''
        });
        window.location.reload()
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  buttonEdit = value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    this.setState({
      index,
      name: getData.name,
      errorname: '',
      user_name: getData.user_name,
      erroruser_name: '',
      password: getData.password,
      errorpassword: '',
      address: getData.address,
      erroraddress: '',
      mobile_no: getData.mobile_no,
      errormobile_no: '',
      telphone: getData.telphone,
      errortelphone: '',
      email_id: getData.email_id,
      erroremail_id: '',
      image: getData.image,
      errorimage: '',
      alertVisible: false,
      updateData: true
    });
  };

  onUpdate = async () => {
    let index = this.state.index;
    const { name, user_name,adminId, password, address, mobile_no, telphone, email_id, image ,is_customer,userTypeId} = this.state;
    if (this.validate(name, 'errorname', 'Please Enter customer name')) return true;
    if (this.validate(user_name, 'erroruser_name', 'Please Enter user name')) return true;
    if (this.validate(password, 'errorpassword', 'Please Enter password')) return true;
    if (this.validate(address, 'erroraddress', 'Please Enter address')) return true;
    if (this.validate(mobile_no, 'errormobile_no', 'Please Enter mobile no')) return true;
    if (this.validate(telphone, 'errortelphone', 'Please Enter telphone')) return true;
    if (this.validate(email_id, 'erroremail_id', 'Please Enter email id')) return true;
    if (this.validate(image, 'errorimage', 'Please Enter image')) return true;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('user_name', user_name);
    formData.append('password', password);
    formData.append('address', address);
    formData.append('mobile_no', mobile_no);
    formData.append('telphone', telphone);
    formData.append('email_id', email_id);
    formData.append('image', image);
    formData.append('is_customer', is_customer);
    formData.append('userTypeId', userTypeId);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    
    console.log(formData,"formData");

    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;
    getData.name = name;
    getData.user_name = user_name;
    getData.password = password;
    getData.address = address;
    getData.mobile_no = mobile_no;
    getData.telphone = telphone;
    getData.email_id = email_id;
    getData.image = image;

    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, getData); // update the user value in the list at particular index
    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param formData : json list value for update
       */
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        this.setState({
          // data,
          alertVisible: true,
          name: '',
          user_name: '',
          password: '',
          address: '',
          mobile_no: '',
          telphone: '',
          email_id: '',
          image: '',
          updateData: false
        });
        window.location.reload();
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  render() {
    const {
      data,
      name,
      errorname,
      user_name,
      erroruser_name,
      password,
      errorpassword,
      address,
      erroraddress,
      mobile_no,
      errormobile_no,
      telphone,
      errortelphone,
      email_id,
      erroremail_id,
      image,
      errorimage,
      updateData
    } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Customer</h3>
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="name">Customer Name</label>
                </div>
                <div className="col-sm-5">
                  <input type="text" className="form-control" name="name" id="name" placeholder="Please enter customer name" value={name} onChange={this.handleUserInput} />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{errorname}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="user_name">User Name</label>
                </div>
                <div className="col-sm-5">
                  <input type="text" className="form-control" name="user_name" id="user_name" placeholder="Please enter user name" value={user_name} onChange={this.handleUserInput} />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{erroruser_name}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="password">Password</label>
                </div>
                <div className="col-sm-5">
                  <input type="password" className="form-control" name="password" id="password" placeholder="Please enter password" value={password} onChange={this.handleUserInput} />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{errorpassword}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="address">Address</label>
                </div>
                <div className="col-sm-5">
                  <textarea className="form-control" rows="3" id="address" name="address" placeholder="Please enter address" onChange={this.handleUserInput} value={address}></textarea>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{erroraddress}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="mobile_no">Mobile No</label>
                </div>
                <div className="col-sm-5">
                  <input type="text" className="form-control" name="mobile_no" id="mobile_no" placeholder="Please enter mobile number" value={mobile_no} onChange={this.handleUserInput} />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{errormobile_no}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="telphone">Telephone</label>
                </div>
                <div className="col-sm-5">
                  <input type="text" className="form-control" name="telphone" id="telphone" placeholder="Please enter telephone" value={telphone} onChange={this.handleUserInput} />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{errortelphone}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="email_id">Email</label>
                </div>
                <div className="col-sm-5">
                  <input type="text" className="form-control" name="email_id" id="email_id" placeholder="Please enter email" value={email_id} onChange={this.handleUserInput} />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{erroremail_id}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  {/* <label htmlFor="email_id">Company Logo</label> */}
                  <label htmlFor="email_id">User Logo</label>
                </div>
                <div className="col-sm-5">
                  <input type="file" name="image" id="image" onChange={this.selectImage} />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2" />
                <div className="col-sm-5">
                  <span className="errorMsg">{errorimage}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group ">
                <div className="col-sm-4" />
                <div className="col-sm-4">
                  {!updateData ? (
                    <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                      Submit
                    </button>
                  ) : (
                    <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                      Update
                    </button>
                  )}
                </div>
                <div className="col-sm-4" />
              </div>
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Customer;
