import React, { Component } from 'react';
import { image } from '../Hr/graydot.png';
import HrMiddleWare from '../../middleWare/HrMiddleWare';
import Indicators from '../Admin/Master/Indicators';

class TechnicalCompetencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.id,
      adminId: this.props.adminId,
      data: this.props.data,
      userDetails: this.props.userDetails,
      head: []
    };
  }
  async componentWillMount() {
    const adminId = this.state.adminId;
    let data = this.state.data;
    let userDetails = this.state.userDetails;
    let level;
    // let Level = await HrMiddleWare.getRandomData(
    //   "tbl_competency,mapped_matrix_to_user,tbl_cmpLevel",
    //   `tbl_competency.id = mapped_matrix_to_user.competencyId and tbl_competency.customerid = ${adminId} and tbl_competency.adminId = ${adminId}  and mapped_matrix_to_user.levelid = tbl_cmpLevel.id and mapped_matrix_to_user.adminId = ${adminId} and tbl_cmpLevel.matrixType = 'Behavioral' and tbl_cmpLevel.CustomerID = ${adminId} and tbl_cmpLevel.adminId = ${adminId}`,
    //   "tbl_cmpLevel.Level as level , mapped_matrix_to_user.competencyId as compId"
    // );
    // console.log(Level.data[0]);
    console.log('test');
    let head = [];

    if (data.userTechIndicators) {
      console.log(data.userTechIndicators);
      data.userTechIndicators.map((item, index) => {
        let level = [];

        let cmplevalue = [];

        item.indicators.map((element, key) => {
          level.push(
            <li style={{ marginTop: '5px', color: 'black' }}>
              {/* <img style={{ width: '10px', marginRight: '5px' }} src={image} /> */}
              {element.indicators} &nbsp;
            </li>
          );

          cmplevalue.push(<p>{element.cmplevel}</p>);
          // Indicatorsvalue += element.indicators;
        });
        console.log(item.competencyname);
        head.push(
          <div>
            <dt class="text-primary">
              Competency {index + 1}&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
              {item.competencyname}
            </dt>
            <div className="row" style={{marginTop:10}}>
            <div className="col-sm-2"> </div>
            <div className="col-sm-1"> 
            <dt class="text-success" id="cardSupplies">
                {/*Level-1 */}
                {/* {'compete'} */}
                {cmplevalue}
              </dt>
            </div>
            <div className="col-sm-7"> 
            <dl>
              
              <ul>
                {level}
                {/* {'Level'} */}
                {/* Rarely shows enthusiasm and passion for achieving results
                            </li>	 */}
              </ul>
            </dl>
            
            </div>
            <div className="col-sm-2"> </div>
            </div>
           
          </div>
        );
      });
    }

    this.setState({ head });
  }

  render() {
    const { data, head } = this.state;
    return (
      <div>
        <section class="panel">
          <div class="panel-body bio-graph-info">
            <div class="row">
              <div class="col-lg-12 ">
                <div class="hpanel hgreen">
                  <div class="panel-heading">
                    <div class="panel-tools">{head}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default TechnicalCompetencies;
