import React, { Component } from "react";
import styled from "@emotion/styled";
import { Style } from "react-style-tag";
import { Tree, TreeNode } from "react-organizational-chart";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import PreLoader from './preloader'
import { ACCESS_POINT } from "../../config";
const StyledNode = styled.div`
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px #1c52fb;
  // background: #e4e4e4;
`;
class Organogram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem("userId"),
      yearId: localStorage.getItem("yearId"),
      groupcompId: localStorage.getItem("groupcompId"),
      userTypeId: localStorage.getItem("userTypeId"),
      jobRoleId: localStorage.getItem("jobRoleId"),

      content: [],
      supervisorData: '',
      peerData: '',
      customerData: '',
      reporteeData: '',
      loading:true
    };
  }
  async componentWillMount() {
    let { jobRoleId } = this.state
    try {
      let { data: Me } = await HrMiddleWare.getRandomData(
        "tbl_users",
        `tbl_users.id= ${this.state.userId}`,
        "*"
      );
      // console.log(Me,"Me");
      const mappers = await HrMiddleWare.getRandomData(
        "tbl_map_employee,tbl_users",
        `tbl_users.id=tbl_map_employee.empid and tbl_map_employee.mappedto = ${this.state.userId} and tbl_map_employee.finyear_id = ${this.state.yearId} and tbl_map_employee.jobRole=${this.state.jobRoleId} and (tbl_map_employee.is_supervisor = 1 || tbl_map_employee.is_peer = 1 || tbl_map_employee.is_significant = 1 || tbl_map_employee.is_fro = 1 ) `,
        "tbl_map_employee.*,tbl_users.user_name,tbl_users.image"
      );
      // console.log(mappers, "mappers");
      let AllContent = [];
      AllContent.peer = [];
      AllContent.supervisor = [];
      AllContent.customer = [];
      AllContent.reportee = [];
      if (mappers) {
        mappers.data.map((ival, i) => {
          if (
            ival.is_peer !== null &&
            (ival.is_peer === "1" || ival.is_peer === 1)
          ) {
            AllContent.peer.push({
              ival
            });
          }
          if (ival.is_supervisor === "1" || ival.is_supervisor === 1) {
            AllContent.supervisor.push({
              ival
            });
          }
          if (ival.is_significant === "1" || ival.is_significant === 1) {
            AllContent.customer.push({
              ival
            });
          }
          if (ival.is_fro === "1" || ival.is_fro === 1) {
            AllContent.reportee.push({
              ival
            });
          }
        });
      }
      //assessment for organogram
      const { userId, groupcompId, yearId, jobRoleId } = this.state;
      const { data: data1 } = await HrMiddleWare.getMappedUserHr(
        userId,
        yearId,
        groupcompId,
        jobRoleId
      );
      // console.log(data1,"data1");
      if (data1) {
        data1.map(value => {
          // console.log(value,"val");
          // console.log(value.supervisor,"value.supervisor");
          if (value.supervisor) {
            value.supervisor.map(async (ival, i) => {
              ival.alreadyAssessment = await this.alreadyAssessment(ival.empid, ival.userType, ival.finyear_id, ival.mappedto, ival.mappedJobRole)

            })
            // console.log(value.supervisor, " value.supervisor");
            this.setState({ supervisorData: value.supervisor });
          }
          if (value.peer) {
            value.peer.map(async (ival, i) => {
              ival.alreadyAssessment = await this.alreadyAssessment(ival.empid, ival.userType, ival.finyear_id, ival.mappedto, ival.mappedJobRole)

            })

            this.setState({ peerData: value.peer });
          }
          if (value.customer) {
            value.customer.map(async (ival, i) => {
              ival.alreadyAssessment = await this.alreadyAssessment(ival.empid, ival.userType, ival.finyear_id, ival.mappedto, ival.mappedJobRole)

            })
            this.setState({ customerData: value.customer });
          }
          if (value.reportee) {
            value.reportee.map(async (ival, i) => {
              ival.alreadyAssessment = await this.alreadyAssessment(ival.empid, ival.userType, ival.finyear_id, ival.mappedto, ival.mappedJobRole)

            })
            this.setState({ reporteeData: value.reportee });
          }
        });
      }


      //organogram structures show
      let data = [];
      data.push({ AllContent });
      this.content(Me[0], mappers.data, data);
    } catch (error) {
      console.log(error);
    }
  }
  alreadyAssessment = async (empid, rateType, yearid, adminid, jobroleid) => {
    try {
      const { data: alreadyAssessment } = await HrMiddleWare.alreadyAssessment(
        empid,
        rateType,
        yearid,
        adminid,
        jobroleid
      );
      if (alreadyAssessment.length > 0) {
        return true
        // this.setState({
        //   alreadyAssessment: true
        // });
      } else {
        return false
      }
    } catch (error) {
      console.error(error);
    }
  };
  content = (Me, mappers, data) => {
setTimeout(()=>{
  this.setState({loading:false})
},3000)
    let { userTypeId, jobRoleId } = this.state
    let userId = localStorage.getItem('userId')
    let yearId = localStorage.getItem('yearId')
    let content = [];
    if (Me) {
      content.push(
        // <Tree
        //   lineWidth={"2px"}
        //   lineColor={"green"}
        //   lineBorderRadius={"10px"}
        //   label={
        //     <StyledNode>
        //       <div>
        //         <img
        //           src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${Me.image}`}
        //           style={{ width: 100, heigth: 100 }}
        //           alt={`${Me.user_name} Image`}
        //         />
        //         <div>{Me.user_name}</div>
        //       </div>
        //     </StyledNode>
        //   }
        // >
        //   {this.innerContent(data)}
        // </Tree>
        <>
          <center>
            {/* <Tree>{this.state.supervisor}</Tree> */}
            <Tree
              label={
                // <StyledNode
                //   style={{
                //     border: "1px solid #1c52fb"
                //   }}
                // >
                <StyledNode
                  style={{
                    border: "none"
                  }}
                >
                  <div class="card_org">
                    <div class="align">
                      <span class="red"></span>
                      <span class="yellow"></span>
                      <span class="green"></span>
                    </div>
                    <img
                      src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${Me.image}`}
                      style={{ width: 60, heigth: 60, borderRadius: "inherit" }}
                      alt={`${Me.user_name} Image`}
                    />
                    <div style={{ color: "black" }} >{Me.user_name}</div>
                    <div style={{ color: "black" }} >(Login User)</div>
                    <div style={{ marginTop: "25px", marginLeft: "35px" }} class="col-md-6 custom-col-sm-10">
                      <a
                        class="light_blue"
                        href={userTypeId == 6 ? `/hr/wizardForm?empid=${userId}&adminid=${userId}&yearid=${yearId}&mappedjobid=${jobRoleId}&rateType=5&usermapType=self` : `/user/wizardForm?empid=${userId}&adminid=${userId}&yearid=${yearId}&rateType=5&usermapType=self`}
                      >
                        Self Assessment
                      </a>
                    </div>
                  </div>
                  {/* <div
                    style={{
                      width: "130px",
                      height: "140px",
                      marginTop: '13px'
                    }}
                  >
                    <img
                      src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${Me.image}`}
                      style={{ width: 60, heigth: 60 }}
                      alt={`${Me.user_name} Image`}
                    />
                    <div>{Me.user_name}</div>
                    <div>(Login User)</div>
                    <div style={{ marginTop: "25px", marginLeft: "8px" }} class="col-md-6 custom-col-sm-10">
                      <a
                        class="light_blue"
                        href={userTypeId == 6 ? `/hr/wizardForm?empid=${userId}&adminid=${userId}&yearid=${yearId}&mappedjobid=${jobRoleId}&rateType=5&usermapType=self` : `/user/wizardForm?empid=${userId}&adminid=${userId}&yearid=${yearId}&rateType=5&usermapType=self`}
                      >
                        Self Assessment
                      </a>
                    </div>
                  </div> */}
                </StyledNode>
              }
              className="Organotree"
            >
              {this.innerContent(data)}
            </Tree>

          </center>
        </>
      );
      this.setState({ content });
    }
    return null;
  };
  innerContent = data => {
    let { supervisorData, peerData, customerData, reporteeData, userTypeId } = this.state
    let position,
      innerContent = [];
    if (data) {
      data.map((ival, i) => {
        let value = ival.AllContent;
        // if (ival.is_supervisor == 1) {
        //   position = "Supervisor";
        // } else if (ival.is_peer == 1) {
        //   position = "Peer";
        // } else if (ival.is_significant == 1) {
        //   position = "Customer";
        // } else if (ival.is_fro == 1) {
        //   position = "Reportee";
        // }

        let peer = [];
        let customer = [];
        let reportee = [];
        let supervisor = [];

        if (value.peer.length > 1) {
          // value.peer.map(ival => {
          //   let val = ival.ival;
          peer.push(
            <TreeNode
              label={
                // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                <StyledNode style={{ border: "none" }}>
                  {
                    value.peer && value.peer.map(ival => {
                      let val = ival.ival;
                      return (
                        <div class="card_org">
                          <div class="align">
                            <span class="red"></span>
                            <span class="yellow"></span>
                            <span class="green"></span>
                          </div>
                          <img
                            src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                            style={{ width: 60, heigth: 60, borderRadius: "inherit"  }}
                          />
                          <div style={{ color: "black" }}>{val.user_name}</div>
                          <div style={{ color: "black" }}>(Peer)</div>
                          {peerData.length > 0 &&
                            peerData.map(value => {
                              if (value.id == val.id) {

                                return (
                                  <div class="row">
                                    {
                                      value.alreadyAssessment ? (
                                        <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                          <span class="badge badge-success"
                                          >Assmt. completed
                                            {
                                              value.alreadyAssessment
                                            }
                                          </span>
                                        </div>
                                      ) : (
                                        <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">
                                          <a
                                            class="light_blue"
                                            href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer`}
                                          >
                                            Assessment
                                          </a>
                                        </div>)}
                                  </div>
                                );
                              }
                            })}
                        </div>

                        // <div
                        //   style={{
                        //     width: "110px",
                        //     height: "140px", marginTop: '13px'
                        //   }}
                        // >
                        //   <img
                        //     src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        //     style={{ width: 60, heigth: 60 }}
                        //   />
                        //   <div>{val.user_name}</div>
                        //   <div>(Peer)</div>
                        //   {peerData.length > 0 &&
                        //     peerData.map(value => {
                        //       if (value.id == val.id) {

                        //         return (
                        //           <div class="row">
                        //             {
                        //               value.alreadyAssessment ? (
                        //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                        //                   <span class="badge badge-success"
                        //                   >Assmt. completed
                        //                     {
                        //                       value.alreadyAssessment
                        //                     }
                        //                   </span>
                        //                 </div>
                        //               ) : (
                        //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">
                        //                   <a
                        //                     class="light_blue"
                        //                     href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer`}
                        //                   >
                        //                     Assessment
                        //                   </a>
                        //                 </div>)}
                        //           </div>
                        //         );
                        //       }
                        //     })}
                        // </div>
                      )
                    })
                  }
                </StyledNode>
              }
            />
          );
          // });
        } else if (value.peer.length > 0) {
          value.peer.map(ival => {
            let val = ival.ival;
            peer.push(
              <TreeNode
                label={
                  // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <StyledNode style={{ border: "none" }}>
                    <div class="card_org">
                      <div class="align">
                        <span class="red"></span>
                        <span class="yellow"></span>
                        <span class="green"></span>
                      </div>

                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60, borderRadius: "inherit" }}
                      />
                      <div style={{ color: "black" }}>{val.user_name}</div>
                      <div style={{ color: "black" }}>(Peer)</div>
                      {peerData.length > 0 &&
                        peerData.map(value => {
                          return (
                            <div class="row">
                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">
                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer`}
                                    >
                                      Assessment
                                    </a>
                                  </div>)}
                            </div>
                          );
                        })}
                    </div>
                    {/* <div
                      style={{
                        width: "110px",
                        height: "140px", marginTop: '13px'
                      }}
                    >
                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60 }}
                      />
                      <div>{val.user_name}</div>
                      <div>(Peer)</div>
                      {peerData.length > 0 &&
                        peerData.map(value => {
                          return (
                            <div class="row">
                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">
                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Peer`}
                                    >
                                      Assessment
                                    </a>
                                  </div>)}
                            </div>
                          );
                        })}
                    </div> */}
                  </StyledNode>
                }
              />
            );
          });
        } else {
          peer.push(
            <TreeNode
              label={
                <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <div
                    style={{
                      width: "100px",
                      height: "140px"
                    }}
                  >
                    <div>No Peer Mapped</div>
                  </div>
                </StyledNode>
              }
            />
          );
        }

        if (value.customer.length > 1) {
          // value.customer.map(ival => {
          //   let val = ival.ival;
          customer.push(
            <TreeNode
              label={
                // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                <StyledNode style={{ border: "none" }}>

                  {

                    value.customer && value.customer.map(ival => {
                      let val = ival.ival;
                      return (
                        <div class="card_org">
                        <div class="align">
                          <span class="red"></span>
                          <span class="yellow"></span>
                          <span class="green"></span>
                        </div>
                        <img
                            src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                            style={{ width: 60, heigth: 60, borderRadius: "inherit"  }}
                          />
                          <div style={{ color: "black" }} >{val.user_name}</div>
                          <div style={{ color: "black" }} >(Customer)</div>
                          {customerData.length > 0 &&
                            customerData.map(value => {
                              if (value.id == val.id) {

                                return (
                                  <div class="row">

                                    {
                                      value.alreadyAssessment ? (
                                        <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                          <span class="badge badge-success"
                                          >Assmt. completed
                                            {
                                              value.alreadyAssessment
                                            }
                                          </span>
                                        </div>
                                      ) : (
                                        <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">

                                          <a
                                            class="light_blue"
                                            href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer`}
                                          >
                                            Assessment
                                          </a>
                                        </div>)}
                                  </div>
                                );
                              }
                            })}
                        </div>
                        // <div
                        //   style={{
                        //     width: "110px",
                        //     height: "140px", marginTop: '13px'
                        //   }}
                        // >
                        //   <img
                        //     src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        //     style={{ width: 60, heigth: 60 }}
                        //   />
                        //   <div>{val.user_name}</div>
                        //   <div>(Customer)</div>
                        //   {customerData.length > 0 &&
                        //     customerData.map(value => {
                        //       if (value.id == val.id) {

                        //         return (
                        //           <div class="row">

                        //             {
                        //               value.alreadyAssessment ? (
                        //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                        //                   <span class="badge badge-success"
                        //                   >Assmt. completed
                        //                     {
                        //                       value.alreadyAssessment
                        //                     }
                        //                   </span>
                        //                 </div>
                        //               ) : (
                        //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">

                        //                   <a
                        //                     class="light_blue"
                        //                     href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer`}
                        //                   >
                        //                     Assessment
                        //                   </a>
                        //                 </div>)}
                        //           </div>
                        //         );
                        //       }
                        //     })}
                        // </div>
                      )

                    })
                  }
                </StyledNode>
              }
            />
          );
          // });
        } else if (value.customer.length > 0) {
          value.customer.map(ival => {
            let val = ival.ival;
            customer.push(
              <TreeNode
                label={
                  // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <StyledNode style={{ border: "none" }}>

                    <div class="card_org">
                      <div class="align">
                        <span class="red"></span>
                        <span class="yellow"></span>
                        <span class="green"></span>
                      </div>
                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60, borderRadius: "inherit" }}
                      />
                      <div style={{ color: "black" }} >{val.user_name}</div>
                      <div style={{ color: "black" }} >(Customer)</div>
                      {customerData.length > 0 &&
                        customerData.map(value => {
                          return (
                            <div class="row">

                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">

                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer`}
                                    >
                                      Assessment
                                    </a>
                                  </div>)}
                            </div>
                          );
                        })}

                    </div>
                    {/* <div
                      style={{
                        width: "110px",
                        height: "140px", marginTop: '13px'
                      }}
                    >
                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60 }}
                      />
                      <div>{val.user_name}</div>
                      <div>(Customer)</div>
                      {customerData.length > 0 &&
                        customerData.map(value => {
                          return (
                            <div class="row">

                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">

                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Customer`}
                                    >
                                      Assessment
                                    </a>
                                  </div>)}
                            </div>
                          );
                        })}
                    </div> */}
                  </StyledNode>
                }
              />
            );
          });
        } else {
          customer.push(
            <TreeNode
              label={
                <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <div
                    style={{
                      width: "100px",
                      height: "140px"
                    }}
                  >
                    <div>No Customer Mapped</div>
                  </div>
                </StyledNode>
              }
            />
          );
        }

        if (value.reportee.length > 1) {
          // value.reportee.map(ival => {
          //   let val = ival.ival;
          reportee.push(
            <TreeNode
              label={
                // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                <StyledNode style={{ border: "none" }}>

                  {
                    value.reportee && value.reportee.map(ival => {
                      let val = ival.ival;
                      return (
                        <div class="card_org">
                        <div class="align">
                          <span class="red"></span>
                          <span class="yellow"></span>
                          <span class="green"></span>
                        </div>
                        <img
                            src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                            style={{ width: 60, heigth: 60 , borderRadius: "inherit" }}
                          />
                          <div style={{ color: "black" }} >{val.user_name}</div>
                          <div style={{ color: "black" }} >(Reportee)</div>
                          {reporteeData.length > 0 &&
                            reporteeData.map(value => {
                              if (value.id == val.id) {
                                return (
                                  <div class="row">

                                    {
                                      value.alreadyAssessment ? (
                                        <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                          <span class="badge badge-success"
                                          >Assmt. completed
                                            {
                                              value.alreadyAssessment
                                            }
                                          </span>
                                        </div>
                                      ) : (
                                        <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">

                                          <a
                                            class="light_blue"
                                            href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee`}
                                          >
                                            Assessment
                                          </a>
                                        </div>)}
                                  </div>
                                );
                              }
                            })}
                        </div>
                        // <div
                        //   style={{
                        //     width: "110px",
                        //     height: "140px", marginTop: '13px'
                        //   }}
                        // >
                        //   <img
                        //     src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        //     style={{ width: 60, heigth: 60 }}
                        //   />
                        //   <div>{val.user_name}</div>
                        //   <div>(Reportee)</div>
                        //   {reporteeData.length > 0 &&
                        //     reporteeData.map(value => {
                        //       if (value.id == val.id) {
                        //         return (
                        //           <div class="row">

                        //             {
                        //               value.alreadyAssessment ? (
                        //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                        //                   <span class="badge badge-success"
                        //                   >Assmt. completed
                        //                     {
                        //                       value.alreadyAssessment
                        //                     }
                        //                   </span>
                        //                 </div>
                        //               ) : (
                        //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">

                        //                   <a
                        //                     class="light_blue"
                        //                     href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee`}
                        //                   >
                        //                     Assessment
                        //                   </a>
                        //                 </div>)}
                        //           </div>
                        //         );
                        //       }
                        //     })}
                        // </div>
                      )
                    })
                  }
                </StyledNode>
              }
            />
          );
          // });
        } else if (value.reportee.length > 0) {
          value.reportee.map(ival => {
            let val = ival.ival;
            reportee.push(
              <TreeNode
                label={
                  // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <StyledNode style={{ border: "none" }}>

                    <div class="card_org">
                      <div class="align">
                        <span class="red"></span>
                        <span class="yellow"></span>
                        <span class="green"></span>
                      </div>
                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60, borderRadius: "inherit" }}
                      />
                      <div style={{ color: "black" }} >{val.user_name}</div>
                      <div style={{ color: "black" }} >(Reportee)</div>
                      {reporteeData.length > 0 &&
                        reporteeData.map(value => {
                          return (
                            <div class="row">

                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">

                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee`}
                                    >
                                      Assessment
                                    </a>
                                  </div>)}
                            </div>
                          );
                        })}
                    </div>
                    {/* <div
                      style={{
                        width: "110px",
                        height: "140px", marginTop: '13px'
                      }}
                    >
                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60 }}
                      />
                      <div>{val.user_name}</div>
                      <div>(Reportee)</div>
                      {reporteeData.length > 0 &&
                        reporteeData.map(value => {
                          return (
                            <div class="row">

                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">

                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Reportee`}
                                    >
                                      Assessment
                                    </a>
                                  </div>)}
                            </div>
                          );
                        })}
                    </div> */}
                  </StyledNode>
                }
              />
            );
          });
        } else {
          reportee.push(
            <TreeNode
              label={
                <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <div
                    style={{
                      width: "100px",
                      height: "140px"
                    }}
                  >
                    <div>No Reportee Mapped</div>
                  </div>
                </StyledNode>
              }
            />
          );
        }

        if (value.supervisor.length > 1) {
          // value.supervisor.map(ival => {
          //   // console.log(ival,"ival");
          //   let val = ival.ival;
          supervisor.push(
            <TreeNode
              label={
                // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                <StyledNode style={{ border: "none" }}>

                  {value.supervisor && value.supervisor.map(ival => {
                    // console.log(ival,"ival");
                    let val = ival.ival;
                    console.log(val, "val");
                    return (
                      <div class="card_org">
                      <div class="align">
                        <span class="red"></span>
                        <span class="yellow"></span>
                        <span class="green"></span>
                      </div>
                      <img
                          src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                          style={{ width: 60, heigth: 60, borderRadius: "inherit"  }}
                        />
                        <div style={{ color: "black" }} >{val.user_name}</div>
                        <div style={{ color: "black" }} >(Supervisor)</div>
                        {supervisorData.length > 0 &&
                          supervisorData.map(value => {
                            console.log(value, "value");
                            if (value.id == val.id) {

                              return (
                                <div class="row">
                                  {
                                    value.alreadyAssessment ? (
                                      <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                        <span class="badge badge-success"
                                        >Assmt. completed
                                          {
                                            value.alreadyAssessment
                                          }
                                        </span>
                                      </div>
                                    ) : (
                                      <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">
                                        <a
                                          class="light_blue"
                                          href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}rateType=${value.userType}&usermapType=Supervisor` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Supervisor`}
                                        >
                                          Assessment

                                        </a>
                                      </div>)
                                  }

                                </div>
                              );
                            }
                          })}
                      </div>
                      // <div
                      //   style={{
                      //     width: "110px",
                      //     height: "140px", marginTop: '13px'
                      //   }}
                      // >
                      //   <img
                      //     src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                      //     style={{ width: 60, heigth: 60 }}
                      //   />
                      //   <div>{val.user_name}</div>
                      //   <div>(Supervisor)</div>
                      //   {supervisorData.length > 0 &&
                      //     supervisorData.map(value => {
                      //       console.log(value, "value");
                      //       if (value.id == val.id) {

                      //         return (
                      //           <div class="row">
                      //             {
                      //               value.alreadyAssessment ? (
                      //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                      //                   <span class="badge badge-success"
                      //                   >Assmt. completed
                      //                     {
                      //                       value.alreadyAssessment
                      //                     }
                      //                   </span>
                      //                 </div>
                      //               ) : (
                      //                 <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">
                      //                   <a
                      //                     class="light_blue"
                      //                     href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}rateType=${value.userType}&usermapType=Supervisor` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Supervisor`}
                      //                   >
                      //                     Assessment

                      //                   </a>
                      //                 </div>)
                      //             }

                      //           </div>
                      //         );
                      //       }
                      //     })}
                      // </div>
                    )
                  })

                  }
                </StyledNode>
              }
            />
          );
          // });
        } else if (value.supervisor.length > 0) {
          value.supervisor.map(ival => {
            // console.log(ival, "ival");
            let val = ival.ival;
            supervisor.push(
              <TreeNode
                label={
                  // <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <StyledNode style={{ border: "none" }}>

                    <div class="card_org">
                      <div class="align">
                        <span class="red"></span>
                        <span class="yellow"></span>
                        <span class="green"></span>
                      </div>
                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60, borderRadius: "inherit" }}
                      />
                      <div style={{ color: "black" }} >{val.user_name}</div>
                      <div style={{ color: "black" }} >(Supervisor)</div>
                      {supervisorData.length > 0 &&
                        supervisorData.map(value => {
                          return (
                            <div class="row">
                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "50px" }} class="col-md-6 custom-col-sm-10">
                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}rateType=${value.userType}&usermapType=Supervisor` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Supervisor`}
                                    >
                                      Assessment

                                    </a>
                                  </div>)
                              }

                            </div>
                          );
                        })}
                    </div>
                    {/* <div
                      style={{
                        width: "110px",
                        height: "140px", marginTop: '13px'
                      }}
                    >
                      <img
                        src={`${ACCESS_POINT}/getpro/getprofile?fileurl=${val.image}`}
                        style={{ width: 60, heigth: 60 }}
                      />
                      <div>{val.user_name}</div>
                      <div>(Supervisor)</div>
                      {supervisorData.length > 0 &&
                        supervisorData.map(value => {
                          return (
                            <div class="row">
                              {
                                value.alreadyAssessment ? (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-left" >
                                    <span class="badge badge-success"
                                    >Assmt. completed
                                      {
                                        value.alreadyAssessment
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  <div style={{ marginTop: "25px", marginLeft: "15px" }} class="col-md-6 custom-col-sm-10">
                                    <a
                                      class="light_blue"
                                      href={userTypeId == 6 ? `/hr/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}rateType=${value.userType}&usermapType=Supervisor` : `/user/wizardForm?empid=${value.empid}&adminid=${value.mappedto}&yearid=${value.finyear_id}&mappedjobid=${value.mappedJobRole}&rateType=${value.userType}&usermapType=Supervisor`}
                                    >
                                      Assessment

                                    </a>
                                  </div>)
                              }

                            </div>
                          );
                        })}
                    </div> */}
                  </StyledNode>
                }
              />
            );
          });
        } else {
          supervisor.push(
            <TreeNode
              label={
                <StyledNode style={{ border: "1px solid #1c52fb" }}>
                  <div
                    style={{
                      width: "100px",
                      height: "140px"
                    }}
                  >
                    <div>No supervisor Mapped</div>
                  </div>
                </StyledNode>
              }
            />
          );
        }

        // console.log(supervisor, "supervisor");
        this.setState({ supervisor, peer, customer });

        innerContent.push(
          <>
            {/* <TreeNode>{peer}</TreeNode> */}
            {/* <TreeNode>{customer}</TreeNode> */}
            <TreeNode>{reportee}</TreeNode>
            {/* <TreeNode>{supervisor}</TreeNode> */}
          </>
        );
      });
      return innerContent;
    }
    return null;
  };
  render() {
    let { content } = this.state;
    console.log(content);
    if (!content) {
      return (<PreLoader />)
    }
    else {
      return (
        <React.Fragment>
          <section id="" style={{padding:"10px 30px"}}>
            <section className="wrapper" id="wrapper">
              <div className="container " style={{ maxWidth: "100%" }}>
                <div className="row form-group">
                  <div
                    className="col-sm-12 padding-10 bg-white height-500"
                    style={{ overflow: "auto", height: 1000 }}
                  >
                    <h3 style={{fontFamily:"cursive"}} class="btn-shine" >Organogram</h3>
                    <div className="col-sm-12">
                      {/* <div className="row form-group"> */}
                      {/* <div className="col-sm-4"></div> */}
                      {/* <Tree
                          lineWidth={"2px"}
                          lineColor={"none"}
                          lineBorderRadius={"7px"}
                        >
                          {this.state.supervisor}
                        </Tree>
                      </div>
                      <br /> */}
                      {/* <div className="row form-group">
                        <Tree
                          lineWidth={"2px"}
                          lineColor={"none"}
                          lineBorderRadius={"7px"}
                        >
                          {this.state.peer}
                        </Tree>
                      </div> */}
                      {/* <br /> */}
                      <div className="col-sm-12">
                        <div className="row form-group">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8">
                            {this.state.loading ?
                              <div class="spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              :
                              <div>
                                {/* <Tree
                                lineWidth={"2px"}
                                lineColor={"#1c52fb"}
                                lineBorderRadius={"7px"}
                                label={content}
                              ></Tree> */}
                                <Tree
                                  lineWidth={'2px'}
                                  lineColor={'green'}
                                  lineBorderRadius={'10px'}
                                  label={<StyledNode>{this.state.supervisor}</StyledNode>}
                                >
                                  <TreeNode label={<StyledNode>{this.state.customer}</StyledNode>}>
                                  </TreeNode>
                                  <TreeNode label={<StyledNode>{this.state.content}</StyledNode>}>
                                  </TreeNode>
                                  <TreeNode label={<StyledNode>{this.state.peer}</StyledNode>}>
                                  </TreeNode>

                                </Tree>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      {/* <div className="col-sm-4"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <Style>
            {`/*
Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
Template Version: 1.0
Author: Carlos Alvarez
Website: http://blacktie.co
Premium: http://www.gridgum.com
*/

/* Import fonts */
@import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);

/* BASIC THEME CONFIGURATION */
.css-1bgs0qn::after {
    left: 50%;
    border-left: var(--tree-line-width) solid #1c52fb;
}

.css-1bgs0qn::before, .css-1bgs0qn::after {
  padding-inline-start: 0;
  margin: 0;
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    width: 50%;
    border-top: var(--tree-line-width) solid #1c52fb;
    height: var(--tree-line-height);
}

.css-1gasay9 {
  padding-inline-start: 0;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  --line-height: 20px;
  --line-width: 1px;
  --line-color: black;
  --line-border-radius: 5px;
  --node-padding: 5px;
  --tree-line-height: var(--line-height,20px);
  --tree-line-width: var(--line-width,1px);
  --tree-line-color: #1c52fb;
  --tree-line-border-radius: var(--line-border-radius,5px);
  --tree-node-padding: var(--node-padding,5px);
}

.css-1bgs0qn::before, .css-1bgs0qn::after {
  padding-inline-start: 0;
  margin: 0;
  content: '';
  position: absolute;
  top: 0;
  right: 49%;
  width: 50%;
  border-top: var(--tree-line-width) solid #1c52fb;
  height: var(--tree-line-height);
}
.css-fzar6w {
  padding: 0px;
  border-radius: 8px;
  display: inline-block;
  border: 1px #1c52fb;
  margin-top: 0px;
}

`}

          </Style >
        </React.Fragment>
      );
    }

  }
}

export default Organogram;
