import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ startDate, changeDate, disabled = false }) => {
  return (
    <ReactDatePicker
      showYearDropdown
      scrollableYearDropdown
      showMonthDropdown
      useShortMonthInDropdown
      dropdownMode="select"
      selected={startDate}
      onSelect={changeDate}
      onChange={changeDate}
      disabled={disabled}
      timeIntervals={1}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/MM/yyyy" 
      className="date-picker-input"
    />
  );
};

export default DatePicker;
