

import React from 'react';
import HrMiddleWare from '../../middleWare/HrMiddleWare';
import MappingMiddleware from '../../middleWare/MappingMiddleware';
import { SingleSelect, FormMiddleWare } from '../../components/Form';
import { Bar } from 'react-chartjs-2';
import './style.css';

export default class ProfileChart extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      adminId: null,
      groupcompId: null,
      userDetails: [],
      dataChart: {
        labels: ['supervisor', 'self', 'reportee', 'customer', 'peer'],
        datasets: [
          {
            backgroundColor: '5cb85c',
            hoverBackgroundColor: '5cb85c',
            data: []
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        title: {
          display: true,
          fontColor: '#634aad'
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 5,
                stepSize: 1
              }
            }
          ]
        }
      },
      compChart: {
        labels: [],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: '5cb85c',
            hoverBackgroundColor: '5cb85c',
            data: []
          }
        ]
      },
      behTech: [{ label: 'Behavioural', value: 1 }, { label: 'Technical', value: 2 }],
      startDate: new Date(),
      endDate: new Date(),
      UpdateValue: {},
      scheduled_start_date: new Date(),
      scheduled_end_date: new Date(),
      start_date: null,
      end_date: null,
      BehData: [],
      TechData: [],
      CompHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "BEHAVIOURAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" }
      ],
      BehIndi: [],
      TechIndi: [],
      IndiHeaders: [
        { label: "SI.NO", key: "index" },
        { label: "THEME", key: "theme" },
        { label: "BEHAVIOURAL COMPETENCIES", key: "competencyname" },
        { label: "COMPETENCIES DESCRIPTION", key: "compDescription" },
        { label: "COMPETENCIES IMPORTANT", key: "compImportant" },
        { label: "INDICATORS", key: "indicators" }
      ]
    };
  }

  async componentWillMount() {
    this.getUserDetails();
    this.getChartData();

    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      this.setState({ yearList: yearList.data });
    }

  }

  getUserDetails = async () => {
    let adminId = await localStorage.getItem('adminId');
    let groupcompId = await localStorage.getItem('groupcompId');
    let userId = this.state.userId;
    // await localStorage.getItem('userId');
    let userData = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );
    let job = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `id = ${userData.data[0].jobRole}`,
      "jobRoleName as jobRoleName"
    );
    let designation = await HrMiddleWare.getRandomData(
      "tbl_designation",
      `id = ${userData.data[0].designation}`,
      "designation as designation"
    );
    let descrip = await HrMiddleWare.getRandomData(
      "tbl_jobdescription",
      `jobRole = ${userData.data[0].jobRole} and
       department = ${userData.data[0].department} and
       customerid = ${adminId} and status ='active'`,
      "*"
    );
    const { data: userDetails } = await HrMiddleWare.getUserDetails(userId);
    if (userDetails) {
      this.setState({ adminId, userId, groupcompId, userDetails, userData: userData.data, job: job.data, designation: designation.data, descrip: descrip.data });
    }
  };

  getChartData = async () => {
    const { dataChart, compChart } = this.state;
    let userId = this.state.userId;
    // await localStorage.getItem('userId');
    const { data } = await HrMiddleWare.getChartData(userId);
    if (data) {
      dataChart.datasets[0].data = data;
      this.setState({ dataChart });
    }
    const result = await HrMiddleWare.getCompetencyChartData(userId);
    if (result) {
      let labelsArray = [];
      let dataArray = [];
      result.data.map(list => {
        labelsArray.push(list.x);
        dataArray.push(list.y);
      });
      compChart.datasets[0].data = dataArray;
      compChart.labels = labelsArray;
      this.setState({ compChart });
    }
  };

  render() {
    const { dataChart, options, compChart } = this.state;
    return (
      <React.Fragment>
        <section className="wrapper" style={{marginTop:5}}>
          <div className="container">
            <div className="row form-group">
              <div className="col-sm-12 padding-10 bg-white height-300">
                <h2>Themes & Competencies Mapped</h2>
                <div>
                  <div className="box-tools pull-right">

                  </div>
                </div>




                {/* Indicators view */}
                <div className="row form-group">
                  <div className="col-sm-12 padding-10 bg-white height-600">
                    <h1>Employee Chart</h1>
                    {/* <BarChart dataValue={dataChart} /> */}
                    <Bar
                      data={dataChart}
                      width={100}
                      height={30}
                      options={options}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-12 padding-10 bg-white height-600">
                    <h1>Competency Chart</h1>
                    {/* <BarChart dataValue={dataChart} /> */}
                    <Bar
                      data={compChart}
                      width={100}
                      height={30}
                      options={options}
                    />
                  </div>
                </div>
              </div>
            </div>


          </div>
        </section>
      </React.Fragment>
    );
  }
}
