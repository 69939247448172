import React from "react";
import LoginModal from "../../components/Modal/Modal";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import ValidationView from "../../components/Form/ValidationView";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import Swal from "sweetalert2";
import Datatable from "../../components/Datatable/Datatable";
import ViewIndicatorList from "./ViewIndicatorList";
import "./style.css";
// import AddCompetency from './AddCompetency';
import { Style } from "react-style-tag";
import { set } from "lodash";
class CompetencyMatrix extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      techBehType: [
        { label: "Technical", value: 2 },
        { label: "Behavioral", value: 1 }
      ],
      behList: [],
      techList: [],
      behTechSelect: [],
      workLevelOptions: [],
      jobRoleOptions: [],
      importantce: [
        { label: "Critical", value: "Critical" },
        { label: "Essential", value: "Essential" },
        { label: "Desirable", value: "Desirable" }
      ],
      tence: [
        { label: "Future", value: "Future" },
        { label: "Current", value: "Current" }
      ],
      departmentOptions: [],
      filename: [],

      data: [],
      datas: [],
      techData: [],
      jobData: [],
      levelType: [
        { label: "Level-1", value: 1 },
        { label: "Level-2", value: 2 },
        { label: "Level-3", value: 3 },
        { label: "Level-4", value: 4 },
        { label: "Level-5", value: 5 }
      ],
      dictionarytype: [
        { label: "Dictionary1", value: 1 },
        { label: "Dictionary2", value: 2 }
      ],
      dictionarytypeselected: [],
      mappingDictionaryTypeSelect: [],
      NewThemeIdvalue: "",
      comptencySelected: {},
      disabled: false,
      disabledIndi: false,
      groupcompId: localStorage.getItem("groupcompId")
    };
  }

  async componentWillMount() {
    this.getUserDetails();
    // console.log("enter DID");
    let groupcompId = localStorage.getItem("groupcompId");
    let adminId = await localStorage.getItem("adminId");

    // console.log(groupcompId, "groupcompId");
    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      this.setState({ groupcompId, yearList: yearList.data });
    }

    let mapData = await HrMiddleWare.getRandomData(
      "tbl_theme,tbl_worklevel,tbl_jobRole,tbl_competency,tbl_cm_theme_worklevel_competency",
      `tbl_theme.id=tbl_cm_theme_worklevel_competency.themeid
      and tbl_worklevel.id=tbl_cm_theme_worklevel_competency.worklevelid and
      tbl_jobRole.id=tbl_cm_theme_worklevel_competency.jobRoleid and
      tbl_competency.id=tbl_cm_theme_worklevel_competency.competencyid and tbl_cm_theme_worklevel_competency.type='1' and  tbl_cm_theme_worklevel_competency.cmid=${adminId} and tbl_cm_theme_worklevel_competency.groupcompany = ${groupcompId} order by  tbl_cm_theme_worklevel_competency.id desc`,
      "tbl_theme.theme,tbl_worklevel.worklevel,tbl_jobRole.jobRoleName,tbl_competency.competencyname,tbl_cm_theme_worklevel_competency.financial_year",
      1
    );
    console.log(mapData, "mapData");
    await this.setState({ datas: mapData.data });

    let technicalData = await HrMiddleWare.getRandomData(
      "tbl_theme,tbl_worklevel,tbl_jobRole,tbl_competency,tbl_cm_theme_worklevel_competency,tbl_map_function_worklevel,tbl_department",
      `tbl_theme.id=tbl_cm_theme_worklevel_competency.themeid
      and tbl_worklevel.id=tbl_cm_theme_worklevel_competency.worklevelid and
      tbl_jobRole.id=tbl_cm_theme_worklevel_competency.jobRoleid and
      tbl_competency.id=tbl_cm_theme_worklevel_competency.competencyid and tbl_cm_theme_worklevel_competency.type='2' and  tbl_cm_theme_worklevel_competency.themeid=tbl_map_function_worklevel.function and tbl_map_function_worklevel.department=tbl_department.id	 and  tbl_cm_theme_worklevel_competency.cmid=${adminId} and tbl_cm_theme_worklevel_competency.groupcompany = ${groupcompId} and tbl_map_function_worklevel.groupcompany = ${groupcompId} order by  tbl_cm_theme_worklevel_competency.id desc`,
      "tbl_theme.theme,tbl_worklevel.worklevel,tbl_jobRole.jobRoleName,tbl_competency.competencyname,tbl_department.department,tbl_cm_theme_worklevel_competency.financial_year",
      "id"
    );
    await this.setState({ techData: technicalData.data });

    let jobdescription = await HrMiddleWare.getRandomData(
      "tbl_jobRole,tbl_jobdescription,tbl_department",
      ` tbl_jobRole.id=tbl_jobdescription.jobRole and
      tbl_department.id=tbl_jobdescription.	department and
      tbl_jobdescription.id and tbl_jobdescription.groupcompany = ${groupcompId}`,
      "tbl_jobRole.jobRoleName,tbl_department.department,tbl_jobdescription.describtion,tbl_jobdescription.fileupload"
    );
    await this.setState({ jobData: jobdescription.data });
  }

  column1 = [
    {
      Header: "Theme",
      accessor: "theme"
    },
    {
      Header: "Work Level",
      accessor: "worklevel"
    },
    {
      Header: "Year",
      accessor: "financial_year",
      className: "text-center"
    },
    {
      Header: "job Role",
      accessor: "jobRoleName"
    },
    {
      Header: "Competency",
      accessor: "competencyname"
    }
  ];
  column2 = [
    {
      Header: "Department",
      accessor: "department"
    },
    {
      Header: "Theme",
      accessor: "theme"
    },
    {
      Header: "Year",
      accessor: "financial_year",
      className: "text-center"

    },
    {
      Header: "Competency",
      accessor: "competencyname"
    },
    {
      Header: "Level",
      accessor: "worklevel"
    },
    {
      Header: "Job Role",
      accessor: "jobRoleName"
    }
  ];
  column3 = [
    {
      Header: "Department",
      accessor: "department"
    },
    {
      Header: "job Role",
      accessor: "jobRoleName"
    },
    {
      Header: "Job Description",
      accessor: "describtion"
    },
    {
      Header: "File Upload",
      accessor: "fileupload"
    }
  ];
  getUserDetails = async () => {
    let adminId = await localStorage.getItem("adminId");
    let groupcompId = await localStorage.getItem("groupcompId");
    let userId = await localStorage.getItem("userId");
    // console.log(adminId, adminId);
    try {
      const { data: userDetails } = await HrMiddleWare.getUserDetails(userId);
      let beh = await HrMiddleWare.getRandomData(
        "tbl_theme",
        `customerid=${adminId} and textType='Behavioral'`,
        "theme as label,id as value"
      );
      let tech = await HrMiddleWare.getRandomData(
        "tbl_theme",
        `customerid=${adminId} and textType='Technical'`,
        "theme as label,id as value"
      );
      this.getSelectBoxMaster(
        adminId,
        "tbl_worklevel",
        "id",
        "worklevel",
        "workLevelOptions"
      );
      this.getSelectBoxMaster(
        adminId,
        "tbl_jobRole",
        "id",
        "jobRoleName",
        "jobRoleOptions"
      );
      this.getSelectBoxMaster(
        adminId,
        "tbl_department",
        "id",
        "department",
        "departmentOptions"
      );
      let behComp = await HrMiddleWare.getRandomData(
        "tbl_competency",
        `customerid=${adminId} and textType='Behavioral' and status='active'`,
        "competencyname as label,id as value"
      );
      let techComp = await HrMiddleWare.getRandomData(
        "tbl_competency",
        `customerid=${adminId} and textType='Technical' and status='active'`,
        "competencyname as label,id as value"
      );
      const department = await HrMiddleWare.getRandomData(
        "tbl_department",
        `customerid = ${adminId} and status = "active"`,
        "department as label , id as value"
      );
      const jobRole = await HrMiddleWare.getRandomData(
        "tbl_jobRole",
        `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
        "jobRoleName as label , id as value"
      );

      let newthemedata = await HrMiddleWare.getRandomData(
        "ClusterMaster,tbl_theme",
        "ClusterMaster.themeId=tbl_theme.id group by themeId ",
        "ClusterMaster.themeId"
      );
      var newthemeid = "";
      newthemedata.data.map(i => {
        newthemeid += i.themeId + ",";
      });
      var newThemeid = newthemeid.replace(/,\s*$/, "");

      let groupUser = await HrMiddleWare.getRandomData(
        "tbl_users",
        `groupcomp = ${groupcompId}`,
        "id as user_ids"
      );

      this.setState({
        adminId,
        userId,
        groupcompId,
        userDetails,
        behList: beh.data,
        techList: tech.data,
        behCompList: behComp.data,
        techCompList: techComp.data,
        DeptList: department.data,
        jobRoleList: jobRole.data,
        NewThemeIdvalue: newThemeid,
        groupUser: groupUser.data
      });
    } catch (error) {
      console.log(error);
    }
  };
  deptSelect = e => {
    this.setState({ deptSelect: e });
  };
  jobRoleSelect = e => {
    this.setState({ jobRoleSelect: e });
  };
  text = e => {
    this.setState({ descriptionText: e.target.value });
  };
  // function = () => {
  //   // console.log(this.state.techBehTypeSelected);
  //   if (
  //     this.state.techBehTypeSelected == null ||
  //     this.state.techBehTypeSelected == undefined
  //   ) {
  //     this.setState({ errorTypeSelected: "Please select Competency Type" });
  //     return true;
  //   }
  //   if (this.state.CMyearList == undefined || this.state.CMyearList == null) {
  //     this.setState({ errorFinancial: "Please select Financial year" });
  //     return true;
  //   }

  //   if (
  //     this.state.CMworkSelect == undefined ||
  //     this.state.CMworkSelect == null
  //   ) {
  //     this.setState({ errorWorksel: "Please select work" });
  //     return true;
  //   }
  //   if (this.state.CMjobSelect == undefined || this.state.CMjobSelect == null) {
  //     this.setState({ errorjobSelect: "Please select job role" });
  //     return true;
  //   }

  //   if (
  //     this.state.CMthemeSelect == undefined ||
  //     this.state.CMthemeSelect == null
  //   ) {
  //     this.setState({ errorthemeSelect: "Please select theme" });
  //     return true;
  //   }
  // };

  onSubmit = async () => {
    const {
      groupcompId,
      filename,
      descriptionText,
      jobRoleSelect,
      deptSelect,
      adminId
    } = this.state;
    // if (!deptSelect) {
    //   this.setState({ errorDept: "Please select Department" });
    //   return false;
    // }
    // if (!jobRoleSelect) {
    //   this.setState({ errorDept: "", errorJob: "Please select Job Role" });
    //   return false;
    // }
    // if (!descriptionText) {
    //   this.setState({ errorJob: "", errorText: "Please fill description" });
    //   return false;
    // }
    // if (filename == [] || filename.name == null || filename.name == "") {
    //   this.setState({ errorText: "", errorFile: "Please choose a file" });
    //   return false;
    // }

    if (this.validate(deptSelect, "errorDept", "Please select Department"))
      return true;
    if (this.validate(jobRoleSelect, "errorjobRole", "Please select jobRole"))
      return true;
    if (
      this.validate(
        descriptionText,
        "errordescriptionText",
        "Please select description"
      )
    )
      return true;

    const formData = new FormData();
    formData.append("describtion", descriptionText);
    formData.append("jobRole", jobRoleSelect.value);
    formData.append("department", deptSelect.value);
    formData.append("fileupload", filename);
    formData.append("customerid", adminId);
    formData.append("status", "active");
    formData.append("groupcompany", groupcompId);

    try {
      const result = await MasterMiddleWare.createMaster(
        "tbl_jobdescription",
        formData
      );
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: "Job Description Added ",
          showConfirmButton: false,
          timer: 1500
        });
        // console.log(result.data.insertId);
        // const dataupdate= {
        //   id: parseInt(result.data.insertId),
        //   describtion:descriptionText,
        //   jobRole:jobRoleSelect,
        //   department:deptSelect,
        //  groupcompany:groupcompId
        // };
        let jobdescription = await HrMiddleWare.getRandomData(
          "tbl_jobRole,tbl_jobdescription,tbl_department",
          ` tbl_jobRole.id=tbl_jobdescription.jobRole and
          tbl_department.id=tbl_jobdescription.	department and
          tbl_jobdescription.id and tbl_jobdescription.groupcompany = ${groupcompId}`,
          "tbl_jobRole.jobRoleName,tbl_department.department,tbl_jobdescription.describtion,tbl_jobdescription.fileupload"
        );
        // await this.setState({ jobData: jobdescription.data });
        // const newData = [dataupdate, ...this.state.jobData];
        // console.log(newData);
        let d = jobdescription.data.sort((a, b) => b.id - a.id);
        this.setState({
          // jobData:newData, 
          jobData: d,
          descriptionText: "",
          deptSelect: "",
          jobRoleSelect: "",
          filename: []
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  onSubmitc = async () => {
    const {
      groupcompId,
      behSelect,
      workSelect,
      behCompSelect,
      adminId,
      jobSelect,
      important,
      cF,
      disabled,
      CMyearList
    } = this.state;

    if (this.validate(behSelect, "errorbehSelect", "Please select type"))
      return true;
    if (this.validate(workSelect, "errorworkSelect", "Please select worklevel"))
      return true;
    if (
      this.validate(
        behCompSelect,
        "errorbehCompSelect",
        "Please select competency"
      )
    )
      return true;
    if (this.validate(jobSelect, "errorjobSelect", "Please selct Jobrole"))
      return true;
    if (this.validate(important, "errorimportant", "Please select Importance"))
      return true;
    if (this.validate(cF, "errorcF", "Please select current")) return true;

    try {
      let formData = new FormData();
      formData.append("theme", behSelect.value);
      formData.append("work", workSelect.value);
      formData.append("comp", behCompSelect.value);
      formData.append("job", jobSelect.value);
      formData.append("imp", important.value);
      formData.append("customerid", adminId);
      formData.append("financial_year", CMyearList.value);
      formData.append("cF", cF.value);
      formData.append("from", 1);
      formData.append("groupcompany", groupcompId);
      console.log(...formData, "formData");
      // return false
      // console.log("enter1");
      // console.log(disabled,"disabled");
      // console.log("enter2");

      if (disabled === true) {
        this.popUp();
      } else {
        // console.log("enter3");
        let result = await HrMiddleWare.mapBechComp(formData);
        // console.log(result,"result");
        if (result) {
          Swal.fire({
            position: "center",
            type: "success",
            title: "Behavioural Competencies Mapped",
            showConfirmButton: false,
            timer: 1500
          });
          // const dataupdate={
          //   id: parseInt(result.data.insertId),
          //   job:jobSelect,
          //   theme:behSelect,
          //   comp:behCompSelect,
          //   // dept:depSelect,
          //   work:workSelect,
          //   imp:important,
          //   cF:cF,
          //   groupcompany:groupcompId
          // };
          // const newData = [dataupdate, ...this.state.datas];
          let mapData = await HrMiddleWare.getRandomData(
            "tbl_theme,tbl_worklevel,tbl_jobRole,tbl_competency,tbl_cm_theme_worklevel_competency",
            `tbl_theme.id=tbl_cm_theme_worklevel_competency.themeid
            and tbl_worklevel.id=tbl_cm_theme_worklevel_competency.worklevelid and
            tbl_jobRole.id=tbl_cm_theme_worklevel_competency.jobRoleid and
            tbl_competency.id=tbl_cm_theme_worklevel_competency.competencyid and tbl_cm_theme_worklevel_competency.type='1' and  tbl_cm_theme_worklevel_competency.cmid=${adminId} and tbl_cm_theme_worklevel_competency.groupcompany = ${groupcompId} order by  tbl_cm_theme_worklevel_competency.id desc`,
            "tbl_theme.theme,tbl_worklevel.worklevel,tbl_jobRole.jobRoleName,tbl_competency.competencyname",
            1
          );
          let d = mapData.data.sort((a, b) => b.id - a.id);
          this.setState({
            datas: d,
            disabled: false,
            behSelect: "",
            workSelect: "",
            behCompSelect: "",
            jobSelect: "",
            important: "",
            cF: "",
            CMyearList: ""
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  popUp = () => {
    Swal.fire({
      title: "Operation Not Permitted",
      type: "error",
      html:
        "<p>Assessment Rating already exists.<br>You cannot map new competencies or indicators.</p>"
    });
  };

  onSubmitt = async () => {
    const {
      groupcompId,
      depSelect,
      techSelect,
      workSelect,
      techCompSelect,
      adminId,
      jobSelect,
      important,
      cF,
      disabled,
      CMyearList
    } = this.state;

    if (this.validate(depSelect, "errortdepSelect", "Please Select Department"))
      return true;
    if (this.validate(techSelect, "errorbehSelects", "Please Select Function"))
      return true;
    if (
      this.validate(workSelect, "errorworkSelects", "Please select worklevel")
    )
      return true;
    if (
      this.validate(
        techCompSelect,
        "errortechCompSelect",
        "Please select competency"
      )
    )
      return true;
    if (this.validate(jobSelect, "errorjobSelects", "Please selct Jobrole"))
      return true;
    if (this.validate(important, "errorimportants", "Please select Importance"))
      return true;
    if (this.validate(cF, "errorcFs", "Please select current")) return true;

    try {
      let formData = new FormData();
      formData.append("dept", depSelect.value);
      formData.append("theme", techSelect.value);
      formData.append("work", workSelect.value);
      formData.append("comp", techCompSelect.value);
      formData.append("job", jobSelect.value);
      formData.append("imp", important.value);
      formData.append("customerid", adminId);
      formData.append("financial_year", CMyearList.value);
      formData.append("cF", cF.value);
      formData.append("from", 2);
      formData.append("groupcompany", groupcompId);

      if (disabled === true) {
        this.popUp();
      } else {
        let result = await HrMiddleWare.mapBechComp(formData);
        if (result) {
          Swal.fire({
            position: "center",
            type: "success",
            title: "Behavioural Competencies Mapped",
            showConfirmButton: false,
            timer: 1500
          });
          // const dataupdate={
          //   id: parseInt(result.data.insertId),
          //   job:jobSelect,
          //   theme:techSelect,
          //   comp:techCompSelect,
          //   dept:depSelect,
          //   work:workSelect,
          //   imp:important,
          //   cF:cF,
          //   groupcompany:groupcompId
          // };
          // const newData = [dataupdate, ...this.state.techData];
          let technicalData = await HrMiddleWare.getRandomData(
            "tbl_theme,tbl_worklevel,tbl_jobRole,tbl_competency,tbl_cm_theme_worklevel_competency,tbl_map_function_worklevel,tbl_department",
            `tbl_theme.id=tbl_cm_theme_worklevel_competency.themeid
            and tbl_worklevel.id=tbl_cm_theme_worklevel_competency.worklevelid and
            tbl_jobRole.id=tbl_cm_theme_worklevel_competency.jobRoleid and
            tbl_competency.id=tbl_cm_theme_worklevel_competency.competencyid and tbl_cm_theme_worklevel_competency.type='2' and  tbl_cm_theme_worklevel_competency.themeid=tbl_map_function_worklevel.function and tbl_map_function_worklevel.department=tbl_department.id	 and  tbl_cm_theme_worklevel_competency.cmid=${adminId} and tbl_cm_theme_worklevel_competency.groupcompany = ${groupcompId} and tbl_map_function_worklevel.groupcompany = ${groupcompId} order by  tbl_cm_theme_worklevel_competency.id desc`,
            "tbl_theme.theme,tbl_worklevel.worklevel,tbl_jobRole.jobRoleName,tbl_competency.competencyname,tbl_department.department",
            "id"
          );
          let d = technicalData.data.sort((a, b) => b.id - a.id)
          this.setState({
            disabled: false,
            techData: d,
            depSelect: "",
            techSelect: "",
            workSelect: "",
            techCompSelect: "",
            jobSelect: "",
            important: "",
            cF: "",
            CMyearList: ""
          });

        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  CMjobSelect = async d => {//CMyearList
    if (this.state.NewcompetencyData != true) {
      let tech = await HrMiddleWare.getRandomData(
        "tbl_theme,tbl_cm_theme_worklevel_competency,ClusterMaster",
        ` tbl_theme.id not in (${this.state.NewThemeIdvalue}) and tbl_theme.id=tbl_cm_theme_worklevel_competency.themeid and tbl_cm_theme_worklevel_competency.worklevelid=${this.state.CMworkSelect.value} and tbl_cm_theme_worklevel_competency.financial_year=${this.state.CMyearList.value} and tbl_cm_theme_worklevel_competency.jobRoleId=${d.value} and tbl_cm_theme_worklevel_competency.cmid=${this.state.adminId} and tbl_cm_theme_worklevel_competency.type=${this.state.techBehTypeSelected.value} and tbl_cm_theme_worklevel_competency.groupcompany = ${this.state.groupcompId} group by tbl_theme.id`,
        "tbl_theme.theme as label,tbl_theme.id as value"
      );
      this.setState({ CMjobSelect: d, CMtheme: tech.data });
    } else if (this.state.NewcompetencyData == true) {
      let tech = await HrMiddleWare.getRandomData(
        "tbl_theme,tbl_cm_theme_worklevel_competency,ClusterMaster",
        `tbl_theme.id  in (${this.state.NewThemeIdvalue}) and tbl_theme.id=tbl_cm_theme_worklevel_competency.themeid and tbl_cm_theme_worklevel_competency.worklevelid=${this.state.CMworkSelect.value} and tbl_cm_theme_worklevel_competency.financial_year=${this.state.CMyearList.value} and tbl_cm_theme_worklevel_competency.jobRoleId=${d.value} and tbl_cm_theme_worklevel_competency.cmid=${this.state.adminId} and tbl_cm_theme_worklevel_competency.type=${this.state.techBehTypeSelected.value} and tbl_cm_theme_worklevel_competency.groupcompany = ${this.state.groupcompId} group by tbl_theme.id`,
        "tbl_theme.theme as label,tbl_theme.id as value"
      );

      this.setState({ CMjobSelect: d, CMtheme: tech.data });
    }
  };
  CMthemeSelect = async d => {
    let tech = await HrMiddleWare.getRandomData(
      "tbl_competency,tbl_cm_theme_worklevel_competency",
      `tbl_competency.id=tbl_cm_theme_worklevel_competency.competencyid and tbl_cm_theme_worklevel_competency.worklevelid=${this.state.CMworkSelect.value} and tbl_cm_theme_worklevel_competency.jobRoleId=${this.state.CMjobSelect.value} and tbl_cm_theme_worklevel_competency.cmid=${this.state.adminId} and tbl_cm_theme_worklevel_competency.type=${this.state.techBehTypeSelected.value} and tbl_cm_theme_worklevel_competency.themeid=${d.value} and tbl_cm_theme_worklevel_competency.groupcompany = ${this.state.groupcompId}
      group by tbl_competency.id`,
      "tbl_competency.competencyname as label,tbl_competency.id as value"
    );

    this.setState({ CMthemeSelect: d, CMcomp: tech.data });
    // if (!this.state.NewcompetencyData==true)
    // {
    // } else if (this.state.NewcompetencyData == true)

    // {
    //   let newbehaviourData = await HrMiddleWare.getRandomData('ClusterMaster,tbl_competency', `ClusterMaster.themeId=${d.value} and tbl_competency.id= ClusterMaster.CmpId`,'ClusterMaster.CmpId as value,tbl_competency.competencyname as label');

    //   this.setState({ CMthemeSelect: d, CMcomp: newbehaviourData.data });
    // }
  };
  CMcompSelect = async d => {
    this.setState({ comptencySelected: d });
    let {
      CMjobSelect,
      CMworkSelect,
      adminId,
      techBehTypeSelected,
      CMthemeSelect
    } = this.state;

    let CMcompSelect = d.value;
    CMjobSelect = CMjobSelect.value;
    CMworkSelect = CMworkSelect.value;
    CMthemeSelect = CMthemeSelect.value;
    if (d && CMcompSelect) {
      let yearId = localStorage.getItem("yearId");
      var ids = "";
      let grup = this.state.groupUser;
      grup.map((item, key) => {
        ids += item.user_ids + ",";
      });
      ids = ids.replace(/,\s*$/, "");
      let rating = await HrMiddleWare.getRandomData(
        "tbl_assessment_rating",
        `themeid = ${CMthemeSelect} and competencyid = ${CMcompSelect} and financial_year_id = ${yearId} and admin_id	in (${ids})  `,
        "*"
      );
      // if (rating && rating.data.length > 0) {
      //   Swal.fire({
      //     title: "Operation Not Permitted",
      //     type: "error",
      //     html:
      //       "<p>Assessment Rating already exists.<br>You cannot map new competencies or indicators.</p>"
      //   });
      //   this.setState({ disabledIndi: true });
      // } else {
      // }
      try {
        if (techBehTypeSelected.value == 2) {
          let techParams = await HrMiddleWare.getRandomData(
            "tbl_tech_comp_criticals",
            `competency=${CMcompSelect} and theme=${CMthemeSelect} and cmpid=${adminId}`,
            "defenition,important,critical_knowledge,critical_performance"
          );
          this.setState({ techParams });
        }
        // console.log(CMcompSelect, "CMcompSelect");
        // console.log(adminId, "adminId");
        const {
          data: indicatorList
        } = await MappingMiddleware.getIndicatorsList(CMcompSelect, adminId);
        if (indicatorList) {
          const map = new Map();
          const levelList = [];
          for (const item of indicatorList) {
            if (!map.has(item.levelid)) {
              map.set(item.levelid, true); // set any value to Map
              levelList.push({ levelid: item.levelid, Level: item.Level });
            }
          }
          this.setState({ indicatorList, levelList });
        }
        const {
          data: alreadySelectedIndicator
        } = await MappingMiddleware.getAlreadySelectedIndicator(
          CMthemeSelect,
          CMjobSelect,
          CMworkSelect,
          CMcompSelect,
          adminId,
          this.state.CMyearList.value
        );
        if (alreadySelectedIndicator) {
          let indicatorIdArray = [];
          alreadySelectedIndicator.map(indic =>
            indicatorIdArray.push(indic.indicators)
          );
          this.setState({
            alreadySelectedIndicator: indicatorIdArray,
            originalMappedArray: indicatorIdArray
          });
        }
        this.state = { cmpdatalist: "", themeNamelabel: "" };
        let getcompetencydetails = await HrMiddleWare.getRandomData(
          "tbl_competency",
          `tbl_competency.id=${CMcompSelect}`,
          "tbl_competency.*"
        );

        let assessmentRating = await HrMiddleWare.getRandomData(
          "tbl_assessment_rating",
          `tbl_assessment_rating.id=${CMcompSelect}`,
          "tbl_assessment_rating.*"
        );

        await this.setState({ cmpdatalist: getcompetencydetails.data });
      } catch (error) {
        console.log(error);
      }
      this.setState({ showtable: true, CMcompSelect: d });
    } else {
      this.setState({ showtable: false, CMcompSelect: d });
    }
  };
  competencyText = e => {
    this.setState({ competencyText: e.target.value });
  };
  definitionText = e => {
    this.setState({ definitionText: e.target.value });
  };
  importanceText = e => {
    this.setState({ importanceText: e.target.value });
  };
  knowledgeText = e => {
    this.setState({ knowledgeText: e.target.value });
  };
  performanceText = e => {
    this.setState({ performanceText: e.target.value });
  };
  compSubmit = async () => {
    const {
      performanceText,
      knowledgeText,
      importanceText,
      definitionText,
      CMworkSelect,
      CMjobSelect,
      CMthemeSelect,
      competencyText,
      techBehTypeSelected,
      adminId
    } = this.state;

    if (
      this.validate(
        techBehTypeSelected,
        "errorTypeSelected",
        "Please select Competency Type"
      )
    )
      return true;

    if (
      this.validate(
        competencyText,
        "errorCompetency",
        "Please select Competency"
      )
    )
      return true;

    if (
      this.validate(
        importanceText,
        "errorimportanceText",
        "Please select importance"
      )
    )
      return true;
    if (
      this.validate(
        CMjobSelect,
        "errorCMjobSelect",
        "Please select Job Description"
      )
    )
      return true;

    if (this.validate(CMthemeSelect, "errorthemeSelect", "Please select Theme"))
      return true;
    if (
      this.validate(
        knowledgeText,
        "errorknowledgeText",
        "Please select knowledge Text"
      )
    )
      return true;
    if (
      this.validate(
        performanceText,
        "errorperformanceText",
        "Please select performance"
      )
    )
      return true;

    if (
      this.validate(
        CMworkSelect,
        "errorCMworkSelect",
        "Please select WorkSelect"
      )
    )
      return true;

    if (
      this.validate(
        definitionText,
        "errordefinitionText",
        "Please select definition Text"
      )
    )
      return true;

    let formData = new FormData();
    formData.append("themeId", CMthemeSelect.value);
    formData.append("competencyname", competencyText);
    formData.append("Description", definitionText);
    formData.append("CompetencyImportant", importanceText);
    formData.append("textType", techBehTypeSelected.label);
    formData.append("status", "active");
    formData.append("customerid", adminId);
    formData.append("adminId", adminId);
    formData.append("theme", CMthemeSelect.value);
    formData.append("competency", "Null");
    formData.append("defenition", definitionText);
    formData.append("important", importanceText);
    formData.append("critical_knowledge", knowledgeText);
    formData.append("cmpId", adminId);
    formData.append("critical_performance", performanceText);
    formData.append("worklevel", CMworkSelect.value);
    formData.append("jobRoleId", CMjobSelect.value);

    try {
      let result = await HrMiddleWare.CompetencyMaster(
        formData,
        "tbl_competency",
        "tbl_cm_theme_worklevel_competency",
        "tbl_competencyframework",
        "tbl_tech_comp_criticals"
      );
      if (result) {
        this.setState({
          performanceText: "",
          knowledgeText: "",
          importanceText: "",
          definitionText: "",
          competencyText: "",
          techBehTypeSelected: "",
          CMjobSelect: "",
          CMworkSelect: "",
          CMthemeSelect: ""
        });
      }
      Swal.fire({
        position: "center",
        type: "success",
        title: "New Competency Added ",
        showConfirmButton: false,
        timer: 1500
      });
      window.location.reload();

    } catch (error) {
      console.log(error);
    }
  };

  newcompetency = async () => {
    let newthemedata = await HrMiddleWare.getRandomData(
      "ClusterMaster,tbl_theme",
      "ClusterMaster.themeId=tbl_theme.id group by themeId ",
      "tbl_theme.id as value,tbl_theme.theme  as label"
    );

    this.state = { NewcompetencyData: "" };
    await this.setState({
      CMtheme: newthemedata.data,
      NewcompetencyData: true
    });
  };

  funselected = async d => {
    if (d != undefined) {
      if (d.label == "Behavioral") {
        return d.label;
      }
      return d;
    }
  };

  dictionarytypeselected = async (stVal, data) => {
    //dictionarytypeselected
    await this.setState({ [stVal]: data });
    let value = this.state.dictionarytypeselected.label;
    if (value === "Dictionary2") {
      this.newcompetency();
    } else if (value === "Dictionary1") {
      this.newcompetencystatechange();
    }
  };
  newcompetencystatechange = async () => {
    this.setState({ NewcompetencyData: false });
  };

  mappingDictionaryType = async (statevalue, data) => {
    await this.setState({ [statevalue]: data });
    let adminId = await localStorage.getItem("adminId");

    let valueselected = this.state.mappingDictionaryTypeSelect.label;
    // console.log(valueselected,"valueselected");
    if (valueselected === "Dictionary1") {
      let beh = await HrMiddleWare.getRandomData(
        "tbl_theme",
        `tbl_theme.id  not in (${this.state.NewThemeIdvalue}) and  tbl_theme.customerid=${adminId} and  tbl_theme.textType='Behavioral'`,
        "tbl_theme.theme as label,tbl_theme.id as value"
      );
      // console.log(beh,"beh");
      let behComp = await HrMiddleWare.getRandomData(
        "tbl_competency,ClusterMaster",
        `tbl_competency.customerid=${adminId} and tbl_competency.textType='Behavioral' and tbl_competency.status='active' and ClusterMaster.CmpId!=tbl_competency.id`,
        "tbl_competency.competencyname as label,tbl_competency.id as value"
      );
      // console.log(behComp,"behComp");

      const uniqueIds = new Set();

      // Filter the original array based on unique ids
      const uniqueArray = behComp.data.filter(obj => {
        if (!uniqueIds.has(obj.value)) {
          uniqueIds.add(obj.value);
          return true;
        }
        return false;
      });


      this.setState({ behList: beh.data, behCompList: uniqueArray });
    } else if (valueselected === "Dictionary2") {
      let beh = await HrMiddleWare.getRandomData(
        "tbl_theme",
        `tbl_theme.id  in (${this.state.NewThemeIdvalue}) and  tbl_theme.customerid=${adminId} and  tbl_theme.textType='Behavioral'`,
        "tbl_theme.theme as label,tbl_theme.id as value"
      );
      let behComp = await HrMiddleWare.getRandomData(
        "tbl_competency,ClusterMaster",
        `tbl_competency.customerid=${adminId} and tbl_competency.textType='Behavioral' and tbl_competency.status='active' and ClusterMaster.CmpId=tbl_competency.id`,
        "tbl_competency.competencyname as label,tbl_competency.id as value"
      );
      this.setState({ behList: beh.data, behCompList: behComp.data });
    }
  };
  techComp = async e => {
    let yearId = localStorage.getItem("yearId");
    var ids = "";
    let grup = this.state.groupUser;
    grup.map((item, key) => {
      ids += item.user_ids + ",";
    });
    ids = ids.replace(/,\s*$/, "");
    let rating = await HrMiddleWare.getRandomData(
      "tbl_assessment_rating",
      `themeid = ${this.state.techSelect.value} and competencyid = ${e.value} and financial_year_id = ${yearId} and admin_id	in (${ids})  `,
      "*"
    );
    // if (rating && rating.data.length > 0) {
    //   Swal.fire({
    //     title: "Operation Not Permitted",
    //     type: "error",
    //     html:
    //       "<p>Assessment Rating already exists.<br>You cannot map new competencies or indicators.</p>"
    //   });
    //   this.setState({ disabled: true });
    // } else {
    // }

    this.setState({ techCompSelect: e });
  };
  behComp = async e => {
    // this.state.workSelect
    // this.state.behSelect

    let yearId = localStorage.getItem("yearId");
    var ids = "";
    let grup = this.state.groupUser;
    grup.map((item, key) => {
      ids += item.user_ids + ",";
    });
    ids = ids.replace(/,\s*$/, "");
    let rating = await HrMiddleWare.getRandomData(
      "tbl_assessment_rating",
      `themeid = ${this.state.behSelect.value} and competencyid = ${e.value} and financial_year_id = ${yearId} and admin_id	in (${ids})  `,
      "*"
    );
    // if (rating && rating.data.length > 0) {
    //   Swal.fire({
    //     title: "Operation Not Permitted",
    //     type: "error",
    //     html:
    //       "<p>Assessment Rating already exists.<br>You cannot map new competencies or indicators.</p>"
    //   });
    //   this.setState({ disabled: true });
    // } else {
    // }

    this.setState({ behCompSelect: e });
  };

  REMomp = async e => { };
  render() {
    const {
      groupcompId,
      behList,
      techList,
      behSelect,
      workLevelOptions,
      workSelect,
      jobRoleOptions,
      jobSelect,
      importantce,
      important,
      tence,
      cF,
      errorbehSelect,
      errorworkSelect,
      errorjobSelect,
      errorimportant,
      errorcF,
      behCompList,
      techCompList,
      behCompSelect,
      errorbehCompSelect,
      techCompSelect,
      DeptList,
      jobRoleList,
      descriptionText,
      jobRoleSelect,
      deptSelect,
      errorDept,
      errorJob,
      errorText,
      errorFile,
      techSelect,
      adminId,
      departmentOptions,
      depSelect,
      errortdepSelect,
      errorbehSelects,
      errorworkSelects,
      alreadySelectedIndicator,
      errorjobSelects,
      errorimportants,
      errorcFs,
      errortechCompSelect,
      techBehType,
      techBehTypeSelected,
      CMyearList,
      yearList,
      CMworkSelect,
      CMjobSelect,
      CMtheme,
      CMthemeSelect,
      CMjobRole,
      CMcomp,
      CMcompSelect,
      indicatorList,
      levelList,
      showtable,
      originalMappedArray,
      performanceText,
      knowledgeText,
      importanceText,
      definitionText,
      competencyText,
      levelType,
      errorjobRole,
      errorFinancial,
      errorCompetency,
      errorthemeSelect,
      errorCMjobSelect,
      errorimportanceText,
      errorknowledgeText,
      errorperformanceText,
      errorCMworkSelect,
      errordefinitionText,
      errorTypeSelected,
      errordescriptionText,
      errorWorksel,
      comptencySelected,
      disabledIndi
    } = this.state;
    // console.log(CMcomp,"CMcomp");
    // console.log(this.state.disabled);
    // console.log(behCompList,"behCompList");
    return (
      <React.Fragment>
        <section id="" style={{ padding: "10px 30px" }}>
          <section className="wrapper" id="wrapper">
            <div className="container " style={{ maxWidth: "100%" }}>
              <div className="row form-group">
                <div className="col-sm-12 padding-10 bg-white height-500">
                  <h3>Competencies Matrix</h3>

                  <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">Competency Type</div>
                    <div className="col-sm-4">
                      <SingleSelect
                        handleChange={d =>
                          this.selectBoxStore("techBehTypeSelected", d)
                        }
                        options={techBehType}
                        selectedService={techBehTypeSelected}
                      />
                    </div>
                    <div className="col-sm-2">
                      <LoginModal
                        buttonTitle="Map Competency"
                        title="Map Competency"
                        id="Competency"
                        // modelClose="modal"
                        extraClass="newview-btn-new"
                        extraStyle={{ backgroundColor: "#56528F" }}
                        maxwidth="modal-lg"
                        bodyText={
                          <div>
                            <ul className="nav nav-tabs">
                              <li className="active">
                                <a
                                  className="header-color "
                                  data-toggle="tab"
                                  href="#behComp"
                                >
                                  Behavioural Competency
                                </a>
                              </li>
                              <li>
                                <a
                                  className="header-color"
                                  data-toggle="tab"
                                  href="#techComp"
                                >
                                  Technical Competency
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                id="behComp"
                                className="tab-pane fade in active"
                              >
                                <h3 className="PaddingTop">
                                  Behavioural Competency Mapping
                                </h3>

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">
                                    Dictionary Type
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.mappingDictionaryType(
                                          "mappingDictionaryTypeSelect",
                                          d
                                        )
                                      }
                                      options={this.state.dictionarytype}
                                      selectedService={
                                        this.state.mappingDictionaryTypeSelect
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Financial Year</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d => this.selectBoxStore("CMyearList", d)}
                                      options={yearList}
                                      selectedService={CMyearList}
                                    />
                                  </div>
                                </div>

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">
                                    Behavioural Theme
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("behSelect", d)
                                      }
                                      options={behList}
                                      selectedService={behSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorbehSelect} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Work Level</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("workSelect", d)
                                      }
                                      options={workLevelOptions}
                                      selectedService={workSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorworkSelect} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Competency</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      // handleChange={d =>
                                      //   this.selectBoxStore("behCompSelect", d)
                                      // }
                                      handleChange={e => this.behComp(e)}
                                      options={behCompList}
                                      selectedService={behCompSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView
                                  errorname={errorbehCompSelect}
                                />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Job Role</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("jobSelect", d)
                                      }
                                      options={jobRoleOptions}
                                      selectedService={jobSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorjobSelect} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">
                                    Competency Importance
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("important", d)
                                      }
                                      options={importantce}
                                      selectedService={important}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorimportant} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">
                                    Current / Future
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("cF", d)
                                      }
                                      options={tence}
                                      selectedService={cF}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorcF} />

                                <div className="row form-group">
                                  <div className="col-sm-3" />
                                  <div className="col-sm-5">
                                    <button
                                      type="button"
                                      className="btncolorsubmit"
                                      // disabled={this.state.disabled}
                                      onClick={this.onSubmitc}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                                {this.state.datas && (
                                  <Datatable
                                    data={this.state.datas}
                                    columnHeading={this.column1}
                                  />
                                )}
                              </div>
                              <div id="techComp" className="tab-pane fade in">
                                <h3 className="PaddingTop">
                                  Technical Competency Mapping
                                </h3>

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Department</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("depSelect", d)
                                      }
                                      options={departmentOptions}
                                      selectedService={depSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errortdepSelect} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Function</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("techSelect", d)
                                      }
                                      options={techList}
                                      selectedService={techSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorbehSelects} />
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Financial Year</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d => this.selectBoxStore("CMyearList", d)}
                                      options={yearList}
                                      selectedService={CMyearList}
                                    />
                                  </div>
                                </div>
                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Work Level</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("workSelect", d)
                                      }
                                      // handleChange={e => this.workLevel(e)}
                                      options={workLevelOptions}
                                      selectedService={workSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorworkSelects} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Competency</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      // handleChange={d =>
                                      //   this.selectBoxStore("techCompSelect", d)
                                      // }
                                      handleChange={e => this.techComp(e)}
                                      options={techCompList}
                                      selectedService={techCompSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView
                                  errorname={errortechCompSelect}
                                />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">Job Role</div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("jobSelect", d)
                                      }
                                      options={jobRoleOptions}
                                      selectedService={jobSelect}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorjobSelects} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">
                                    Competency Importance
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("important", d)
                                      }
                                      options={importantce}
                                      selectedService={important}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorimportants} />

                                <div className="row form-group">
                                  <div className="col-sm-1" />
                                  <div className="col-sm-2">
                                    Current / Future
                                  </div>
                                  <div className="col-sm-5">
                                    <SingleSelect
                                      handleChange={d =>
                                        this.selectBoxStore("cF", d)
                                      }
                                      options={tence}
                                      selectedService={cF}
                                    />
                                  </div>
                                </div>
                                <ValidationView errorname={errorcFs} />

                                <div className="row form-group">
                                  <div className="col-sm-3" />
                                  <div className="col-sm-5">
                                    <button
                                      type="button"
                                      className="btncolorsubmit"
                                      // disabled={this.state.disabled}
                                      onClick={this.onSubmitt}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                                {this.state.techData && (
                                  <Datatable
                                    data={this.state.techData}
                                    columnHeading={this.column2}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                    <div className="col-sm-3">
                      <LoginModal
                        buttonTitle="Manage Job Description"
                        title="Job Description"
                        id="Description"
                        extraClass="newview-btn-new"
                        extraStyle={{ backgroundColor: "#56528F" }}
                        bodyText={
                          <div>
                            <div className="row form-group">
                              <div className="col-sm-4">
                                <label htmlFor="subgroup">Department</label>
                              </div>
                              <div className="col-sm-1" />
                              <div className="col-sm-5">
                                <SingleSelect
                                  options={DeptList}
                                  handleChange={this.deptSelect}
                                  selectedService={deptSelect}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <ValidationView errorname={errorDept} />
                            <div className="row form-group">
                              <div className="col-sm-4">
                                <label htmlFor="subgroup">Job Role</label>
                              </div>
                              <div className="col-sm-1" />
                              <div className="col-sm-5">
                                <SingleSelect
                                  options={jobRoleList}
                                  handleChange={this.jobRoleSelect}
                                  selectedService={jobRoleSelect}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <ValidationView errorname={errorjobRole} />
                            <div className="row form-group">
                              <div className="col-sm-4">
                                <label htmlFor="exampleInputEmail1">
                                  Job Description
                                </label>
                              </div>
                              <div className="col-sm-1" />
                              <div className="col-sm-5">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Job Description Text "
                                  onChange={this.text}
                                  value={descriptionText}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            <ValidationView errorname={errordescriptionText} />
                            <div className="row form-group">
                              <div className="col-sm-4">
                                <label htmlFor="exampleInputEmail1">
                                  File Upload
                                </label>
                              </div>
                              <div className="col-sm-1" />
                              <div className="col-sm-5">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  name="filename"
                                  onChange={e => this.selectImage(e)}
                                />
                              </div>
                            </div>

                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-3" />
                              <div className="col-sm-5">
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  class="btncolorsubmit"
                                  onClick={this.onSubmit}
                                >
                                  Submit
                                </button>
                              </div>
                              <div className="col-sm-2" />
                            </div>
                            {this.state.jobData && (
                              <Datatable
                                data={this.state.jobData}
                                columnHeading={this.column3}
                              />
                            )}
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <ValidationView errorname={errorTypeSelected} />
                  {this.funselected(techBehTypeSelected) && (
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-2">Dictionary Choose</div>
                      <div className="col-sm-4">
                        <SingleSelect
                          handleChange={d =>
                            this.dictionarytypeselected(
                              "dictionarytypeselected",
                              d
                            )
                          }
                          options={this.state.dictionarytype}
                          selectedService={this.state.dictionarytypeselected}
                        />
                      </div>
                    </div>
                  )}

                  <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">Financial Year</div>
                    <div className="col-sm-4">
                      <SingleSelect
                        handleChange={d => this.selectBoxStore("CMyearList", d)}
                        options={yearList}
                        selectedService={CMyearList}
                      />
                    </div>
                  </div>

                  <ValidationView errorname={errorFinancial} />
                  <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">Work Level</div>
                    <div className="col-sm-4">
                      <SingleSelect
                        handleChange={d =>
                          this.selectBoxStore("CMworkSelect", d)
                        }
                        options={workLevelOptions}
                        selectedService={CMworkSelect}
                      />
                    </div>
                  </div>
                  <ValidationView errorname={errorWorksel} />
                  <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">Job Role</div>
                    <div className="col-sm-4">
                      <SingleSelect
                        handleChange={this.CMjobSelect}
                        options={jobRoleOptions}
                        selectedService={CMjobSelect}
                      />
                    </div>
                  </div>
                  <ValidationView errorname={errorjobSelect} />
                  <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">Theme/Function</div>
                    <div className="col-sm-4">
                      <SingleSelect
                        handleChange={this.CMthemeSelect}
                        options={CMtheme}
                        selectedService={CMthemeSelect}
                      />
                    </div>
                    <div className="col-sm-2"></div>
                  </div>
                  <ValidationView errorname={errorthemeSelect} />
                  <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-2">Competency</div>
                    <div className="col-sm-4">
                      <SingleSelect
                        handleChange={this.CMcompSelect}
                        options={CMcomp}
                        selectedService={CMcompSelect}
                      />
                    </div>
                    <div className="col-sm-2">
                      {CMthemeSelect && (
                        <LoginModal
                          buttonTitle="Add Competency"
                          title="Add New Competency"
                          id="NewCompetency"
                          extraClass="newview-btn-new"
                          extraStyle={{ backgroundColor: "#56528F " }}
                          // onClick={this.function}
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">Competency Type</div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    selectedService={techBehTypeSelected}
                                  />
                                </div>
                                <div className="col-sm-2" />
                              </div>
                              <ValidationView errorname={errorTypeSelected} />
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">Theme</div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    handleChange={this.CMthemeSelect}
                                    options={CMtheme}
                                    selectedService={CMthemeSelect}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView errorname={errorthemeSelect} />
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">Job Role</div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    handleChange={d =>
                                      this.selectBoxStore("CMjobSelect", d)
                                    }
                                    selectedService={CMjobSelect}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView errorname={errorCMjobSelect} />
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">Work Level</div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    handleChange={d =>
                                      this.selectBoxStore("CMworkSelect", d)
                                    }
                                    options={workLevelOptions}
                                    selectedService={CMworkSelect}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView errorname={errorCMworkSelect} />
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">Competency</div>
                                <div className="col-sm-5">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.competencyText}
                                    value={competencyText}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView errorname={errorCompetency} />
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  Competency Definition
                                </div>
                                <div className="col-sm-5">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Definition Text "
                                    onChange={this.definitionText}
                                    value={definitionText}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView errorname={errordefinitionText} />
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  Why it`s important?
                                </div>
                                <div className="col-sm-5">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Importance Text "
                                    onChange={this.importanceText}
                                    value={importanceText}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView errorname={errorimportanceText} />
                              <div className="row form-group">
                                {/* import Competency from '../Admin/Master/Competency';
import Dictionary from './Dictionary'; */}

                                <div className="col-sm-1" />
                                <div className="col-sm-3">Knowledge area</div>
                                <div className="col-sm-5">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Knowledge Text "
                                    onChange={this.knowledgeText}
                                    value={knowledgeText}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView errorname={errorknowledgeText} />
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-3">
                                  Performance Parameter
                                </div>
                                <div className="col-sm-5">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Job Performance Text "
                                    onChange={this.performanceText}
                                    value={performanceText}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <ValidationView
                                errorname={errorperformanceText}
                              />
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-3" />
                                <div className="col-sm-5">
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={this.compSubmit}
                                  >
                                    Submit
                                  </button>
                                </div>
                                <div className="col-sm-2" />
                              </div>
                            </div>
                          }
                        />
                      )}
                    </div>

                    <div className="col-sm-2">
                      <button
                        type="button"
                        className="newview-btn-new"
                        onClick={() => window.open("AddCriticals", "_blank")}
                      >
                        Add Parameters
                      </button>
                    </div>
                    {
                      this.props && this.props.wizardBtns &&
                      <div className='wizard-gropubtn'>
                        <button className="btn btn-primary" style={{ width: "100px", padding: "10px" }} onClick={() => this.setState({ Step: this.props.StepFun(2) })}>Previous</button>

                        <button className="btn btn-primary" style={{ width: "100px", padding: "10px" }} onClick={() => window.location.replace("dashboard")}>Finish</button>

                      </div>
                    }
                  </div>
                  {showtable && (
                    <ViewIndicatorList
                      groupcompany={groupcompId}
                      disabledIndi={disabledIndi}
                      work={CMworkSelect}
                      type={techBehTypeSelected}
                      comp={CMthemeSelect}
                      competency={CMcompSelect}
                      indicatorList={indicatorList}
                      levelList={levelList}
                      alreadySelectedIndicator={alreadySelectedIndicator}
                      originalMappedArray={originalMappedArray}
                      techBehTypeSelected={techBehTypeSelected.value}
                      CMcompSelect={CMcompSelect.value}
                      CMthemeSelect={CMthemeSelect.value}
                      CMjobSelect={CMjobSelect.value}
                      CMworkSelect={CMworkSelect.value}
                      companyid={adminId}
                      CMyearList={CMyearList.value}
                      levelType={levelType}
                      cmpdatalist={this.state.cmpdatalist}
                      themeNamelabel={this.state.CMthemeSelect.label}
                      fun_CMcompSelect={this.CMcompSelect}
                    />
                  )}
                  {/*this.state.NewTheme*/}
                </div>
              </div>
            </div>
          </section>
        </section>
        <Style>
          {`.newview-btn-new{
 background-color:
#56528F;
color:
white;
border-radius: 5%;
border-color:
#56528F;
font-size: 14px;
height: 32px;
          }

`}
        </Style>
      </React.Fragment>
    );
  }
}

export default CompetencyMatrix;
