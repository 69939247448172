import React, { Component } from "react";
import './style.css'


export default class preLoader extends Component {
  constructor(props) {
    super(props);
 
} 
 
  render() {
    return (
      <React.Fragment>
	<div id="loading_profile" style={{display:'block',left:'43%',position:'absolute',top: '43%'}} >
    <div class="loader"></div>
  </div>
		 
      </React.Fragment> 
    );
  }
}
