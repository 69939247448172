import React from "react";
import logo from '../../image/home.png'
import { ACCESS_POINT } from "../../config";
import moment from 'moment'
import customFont from "../ttf_files/Montserrat-SemiBold_72e0d276db1e5baa6f2b4caddfef5b93.ttf"
import ptsans from "../ttf_files/pt-sans.narrow.ttf"
import logo1 from "../Header/HrImg/Murugappa_Group_Logo.png";
import wexel from "../Header/HrImg/rsz_wexel.jpg";
import profile from "../Header/HrImg/ui-sherman.jpg"

import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";

const ConsolidatePDF = ({
  data,
  workLvl,
  designationName,
  jobRoleName,
  user,
  userTechIndicators,
  derailers,
  idp,
  show,
  jobdescription,
  technicalpdf,
  Behavioralpdf,
  image,
  content,
  logosrc
}) => {
  // console.log(data, "data", jobRoleName, "jobRoleName");
  // console.log(user,"user",derailers,"derailers");
  // console.log(idp,"idp",jobdescription,"jobdescription");
  // console.log(technicalpdf,"technicalpdf",Behavioralpdf,"Behavioralpdf");
  // console.log(content,"content",logosrc,"logosrc");
  let derailersbehaviour=[]
  let derailerstechnical=[]
  derailers.map((ival)=>{
    if (ival.textType == "Behavioral") {
      derailersbehaviour.push(ival)
    } else if(ival.textType == "Technical"){
      derailerstechnical.push(ival)
    }
  })

  var List = [];
  // console.log(content);

  //  console.log();
  const groupcompId = localStorage.getItem("groupcompId");

  console.log(logosrc);
  return (
    <Document>
      <Page size="A4" style={s.body} >
        {logosrc && logosrc[0] && logosrc[0].image && ACCESS_POINT +
          `/getpro/getprofile?fileurl_1=` +
          logosrc[0].image &&
          <Image
            style={{
              top: "-15px",
              left: "450px",
              width: "15%"
            }}
            src={groupcompId == "52" ? wexel : groupcompId == "24" ? logo1 :
              // `https://compexp.difuza.com/getpro/getprofile?fileurl=` +
              ACCESS_POINT +
              `/getpro/getprofile?fileurl_1=` +
              logosrc[0].image
            }
            fixed
          />
        }
        {/* <Image
            style={{top:"10px",
            left: "450px", 
          width: "15%"}} 
          src={
                
            `https://compexp.difuza.com/getpro/getprofile?fileurl=` +
          logosrc[0].image
          }  fixed
             
        />   */}
        <View style={s.border}>
          <View>
            {data && data.image && `https://compexp.difuza.com/getpro/getprofile?fileurl=` +
              data.image &&
              <Image
                style={s.image}
                src={

                  `https://compexp.difuza.com/getpro/getprofile?fileurl=` +
                  data.image
                }
              />
            }
          </View>
          <View>
            <Text style={s.topHead}>Consolidated Report of {data.name}</Text>
          </View>
          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Employee Name</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d1}>{data.name}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Age</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.age}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Email Address</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d1}>{data.email_id}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Telphone Number</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.telphone}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Mobile Number</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d1}>{data.mobile_no}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Qualification</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{data.qualification}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Location</Text>
            </View>
            <View style={[s.section, { width: 10 }]}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d1}>{data.address}</Text>
            </View>
            <View style={s.section}>
              <Text style={s.color}>Job Role</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot}>:</Text>
            </View>
            <View style={s.section}>
              <Text style={s.d}>{jobRoleName}</Text>
            </View>
            <View style={s.section} />
          </View>

          <View style={s.row}>
            <View style={s.section}>
              <Text style={s.color}>Job Description :</Text>
            </View>
            <View style={s.section}>
              <Text style={s.dot1}> : </Text>
            </View>
            <View style={s.section_3}>
              <Text style={s.fullrow}>{jobdescription}</Text>
            </View>
          </View>
        </View>
        {/* 
        <View style={{ marginTop: 700 }}>
          <Text></Text>
        </View> */}

        {/* <Page wrap={false}>
          <View>
            <Text style={s.head}>Job Description</Text>
          </View>
        </Page> */}



        {/* <Page wrap={false}> */}
        <View>
          <Text style={s.head}>TECHNICAL COMPETENCIES</Text>
        </View>
        {/*  <View>
            <Text style={s.section}>TECHNICAL derailers</Text>
          </View>
             {
              derailers &&
              derailers.map((ival,i)=>{
                return(
                  <Text style={s.width_70}>
               {  ival.derailers}
                </Text>                               
                  )
              })
              
            } */}
        {/* {user[0].userTechIndicators &&
            user[0].userTechIndicators.map((item, i) => {
              // if (item.textType === "Technical") {
              // console.log(item.indicators);
              if (item.compDescription == null || item.compDescription == "") {
                item.compDescription = "No Competency Description given";
              }
              if (item.compImportant == null || item.compImportant == "") {
                item.compImportant = "No Competency Important given";
              }
              return (
                <View>
                  <View>
                    c
                    <View style={s.row}>
                      <View style={s.section}>
                        <Text style={s.width_20}>Competency Name</Text>
                      </View>
                      <View style={s.section}>
                        <Text style={s.width_70}>{item.competencyname}</Text>
                      </View>
                      <View style={s.section}>
                        <Text style={s.width_10}></Text>
                      </View>
                    </View>
                    <View style={s.row}>
                      <View style={s.section}>
                        <Text style={s.width_20}>Competency Definition</Text>
                      </View>
                      <View style={s.section}>
                        <Text style={s.width_70}>{item.compDescription}</Text>
                      </View>
                      <View style={s.section}>
                        <Text style={s.width_10}></Text>
                      </View>
                    </View>

                    <View style={s.row}>
                      <View style={s.section}>
                        <Text style={s.width_20}>Why it s important</Text>
                      </View>
                      <View style={s.section}>
                        <Text style={s.width_70}>{item.compImportant}</Text>
                      </View>
                      <View style={s.section}>
                        <Text style={s.width_10}></Text>
                      </View>
                    </View>
                    <View style={s.row}>
                      <View style={s.section}>
                        <Text style={s.width_20}>Indicators</Text>
                      </View>
                      <View style={s.section}>
                        {item.indicators &&
                          item.indicators.map((element, j) => {
                              //   if(j==2||j==4||j==5){
                              //     console.log(element.indicators)
                              //   }
                               if(j==0||j==1||j==3){
                       // console.log(element)
                       //var desired = element.indicators.replace(/[^\w\s]/gi, '')
                      return (
                              <Text style={s.width_70}>
                            {element.indicators.toString}
                              </Text>

                                );
                                }
                              })}
                      </View>
                      <View style={s.section}>
                        <Text style={s.width_10}></Text>
                      </View>
                    </View>
                  </View>
                </View>
              );
              // }
            })}  */}
        {content}
        {/* </Page> */}
        {/* <Page wrap={false}> */}
        <View>
          <Text style={s.head}>BEHAVIOURAL COMPETENCIES</Text>
        </View>
        {
          user[0].userBehIndicators &&
          user[0].userBehIndicators.map((item, i) => {
            if (item.compDescription == null || item.compDescription == "") {
              item.compDescription = "No Competency Description given";
            }
            if (item.compImportant == null || item.compImportant == "") {
              item.compImportant = "No Competency Important given";
            }
            // let indicat = [];
            // item.indicators.map((element, j) => {
            //   indicat.push(
            //     <Text style={s.rowdata}>{element.indicators}</Text>
            //   );
            // });
            return (
              <View style={{
                marginLeft: 20,
                marginRight: 20
              }}>
                <View>
                  <View style={s.row}>
                    <View style={s.section}>
                      <Text style={s.width_20}>Theme</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_70}>{item.theme}</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_10}></Text>
                    </View>
                  </View>
                  <View style={s.row}>
                    <View style={s.section}>
                      <Text style={s.width_20}>Competency Name</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_70}>{item.competencyname}</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_10}></Text>
                    </View>
                  </View>
                  <View style={s.row}>
                    <View style={s.section}>
                      <Text style={s.width_20}>Competency Definition</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_70}>{item.compDescription}</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_10}></Text>
                    </View>
                  </View>

                  <View style={s.row}>
                    <View style={s.section}>
                      <Text style={s.width_20}>Why it s important</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_70}>{item.compImportant}</Text>
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_10}></Text>
                    </View>
                  </View>
                  <View style={s.row}>
                    <View style={s.section}>
                      <Text style={s.width_20}>Indicators</Text>
                    </View>
                    <View style={s.section}>
                      {item.indicators &&
                        item.indicators.map((element, j) => {
                          // console.log(element);
                          return (
                            <Text style={s.width_70}>
                              {j + 1}. {element.indicators}
                            </Text>
                          );
                        })}
                    </View>
                    <View style={s.section}>
                      <Text style={s.width_10}></Text>
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        {/* </Page> */}
        {/* <Page wrap={false}> */}
        <View>
          <Text style={s.head}>Technical Derailers</Text>
        </View>

        {derailerstechnical &&
          derailerstechnical.map(item => {
            return (
              <View style={s.row}>
                <View style={s.section}>
                  <Text style={s.derailers_under}> # {item.derailers}</Text>
                </View>
              </View>
            );
          })}
        {technicalpdf &&
          technicalpdf.map(item => {
            return (
              <View style={s.row}>
                <View style={s.section}>
                  <Text style={s.derailers_under}> # {item}</Text>
                </View>
              </View>
            );
          })}

        <View>
          <Text style={s.head}>Behavioural Derailers</Text>
        </View>
        {derailersbehaviour &&
          derailersbehaviour.map(item => {
            return (
              <View style={s.row}>
                <View style={s.section}>
                  <Text style={s.derailers_under}> # {item.derailers}</Text>
                </View>
              </View>
            );
          })}
        <View style={s.row}>
          <View style={s.section}>
            {Behavioralpdf &&
              Behavioralpdf.map(item => {
                return (
                  <View style={s.row}>
                    <View style={s.section}>
                      <Text style={s.derailers_under}>
                        <Text> # {item} </Text>
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        </View>
        {/* </Page> */}
        {/* <Page> */}
        <View>
          <Text style={s.head}>IDP</Text>
        </View>
        {/* <View style={s.row}>
          <View style={s.section}>
            <Text style={s.idpHead}>IDP Type</Text>
          </View>
          <View style={s.section}>
            <Text style={s.idpHead}>Goal</Text>
          </View>
          <View style={s.section}>
            <Text style={s.idpHead}>Criteria</Text>
          </View>
          <View style={s.section}>
            <Text style={s.idpHead}>Date</Text>
          </View>
          <View style={s.section}>
            <Text style={s.idpHead}>Progress</Text>
          </View>
          <View style={s.section}>
            <Text style={s.idpHead}>Reason</Text>
          </View>
          <View style={s.section}>
            <Text style={s.idpHead}>Comments</Text>
          </View>
          <View style={s.section}>
            <Text style={s.idpHead}>Status</Text>
          </View>
        </View> */}
        {/* {idp &&
          idp.length > 0 &&
          idp.map(item => {
            return (
              <View style={s.row}>
                <View style={s.section}>
                  <Text style={s.idpdata}>{item.tech_type}</Text>
                </View>
                <View style={s.section}>
                  <Text style={s.idpdata}>{item.goalname}</Text>
                </View>
                <View style={s.section}>
                  <Text style={s.idpdata}>{item.criteria}</Text>
                </View>
                <View style={s.section}>
                  <Text style={s.idpdata}>
                    <View style={s.row}>
                      <Text style={s.rowDate}>Start Date: </Text>
                      <Text style={s.rowDate}>
                        {moment(item.startdate).format("DD-MM-YYYY")}

                      </Text>
                    </View>

                    <View style={s.row}>
                      <Text style={s.rowDate}>{"  "}</Text>
                      <Text style={s.rowDate}>{"  "}</Text>
                    </View>
                    <View style={s.row}>
                      <Text style={s.rowDate}>End Date: </Text>
                      <Text style={s.rowDate}>{moment(item.enddate).format("DD-MM-YYYY")}</Text>
                    </View>
                    <View style={s.row}>
                      <Text style={s.rowDate}>{"  "}</Text>
                      <Text style={s.rowDate}>{"  "}</Text>
                    </View>
                    <View style={s.row}>
                      <Text style={s.rowDate}>Actual End Date: </Text>
                      <Text style={s.rowDate}>{moment(item.actual_end_date).format("DD-MM-YYYY")}</Text>
                    </View>
    
                  </Text>
                </View>
                <View style={s.section}>
                  <Text style={s.idpdata}>{item.percentage}%</Text>
                </View>
                <View style={s.section}>
                  <Text style={s.idpdata}>{item.reason_deviation}</Text>
                </View>
                <View style={s.section}>
                  <Text style={s.idpdata}>{item.supportrequired}</Text>
                </View>
                <View style={s.section}>
                  <Text style={s.idpdata}>{item.status}</Text>
                </View>
              </View>
            );
          })} */}
        <View style={styles.table}>
          <View style={styles.row}>
            <Text style={styles.idpHead}>IDP Type</Text>
            <Text style={styles.idpHead}>Goal</Text>
            <Text style={styles.idpHead}>Criteria</Text>
            <Text style={styles.idpHead}>Date</Text>
            <Text style={styles.idpHead}> Progress</Text>
            <Text style={styles.idpHead}>Reason</Text>
            <Text style={styles.idpHead}>Comments</Text>
            <Text style={styles.idpHead}>Status</Text>
          </View>
          {idp &&
            idp.length > 0 &&
            idp.map((item, i) => {
              return (
                <View style={styles.row}>
                  <Text style={styles.cell}>{item.tech_type}</Text>
                  <Text style={styles.cell}>{item.goalname}</Text>
                  <Text style={styles.cell}>{item.criteria}</Text>
                  <Text style={styles.cell}>
                    <Text style={s.rowDate}>Start Date: {moment(item.startdate).format("DD-MM-YYYY")}</Text>
                    <Text style={s.rowDate}>{"  "}</Text>
                    <Text style={s.rowDate}>End Date: {moment(item.enddate).format("DD-MM-YYYY")}</Text>
                    <Text style={s.rowDate}>{"  "}</Text>
                    <Text style={s.rowDate}>Actual End Date: {moment(item.actual_end_date).format("DD-MM-YYYY")}</Text>
                  </Text>
                  <Text style={styles.cell}>{item.percentage}%</Text>
                  <Text style={styles.cell}>{item.reason_deviation}</Text>
                  <Text style={styles.cell}>{item.supportrequired}</Text>
                  <Text style={styles.cell}>{item.status}</Text>
                </View>
              );
            })}
          {/* Add more rows as needed */}
        </View>
        {/* </Page> */}

        <Text
          style={s.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

Font.register({ family: 'CustomFont', src: customFont });
Font.register({ family: 'ptsans', src: ptsans });

const s = StyleSheet.create({
  row: {
    flexDirection: "row"
  },
  column: {
    flexDirection: "column"
  },
  section: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 1
  },
  color: {
    color: "#2D5986",
    fontSize: 10,
    textAlign: "justify",
    width: "25%",
    marginLeft: 85
  },
  d: {
    fontSize: 10,
    width: "25%",
    marginLeft: -25
  },
  d1: {
    fontSize: 10,
    width: "25%",
    marginLeft: -15
  },
  dot: {
    fontSize: 10,
    width: "1%",
    marginLeft: 35
  },
  dot1: {
    fontSize: 10,
    width: "1%",
    marginLeft: 20
  },
  border: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    paddingBottom: 123,
    // marginBottom: 123
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald"
  },
  head: {
    marginTop: "35px",
    textAlign: "center",
    fontWeight: 700,
    color: "white",
    fontSize: 12,
    lineHeight: 1.1,
    marginBottom: "10px",
    backgroundColor: "#666666",
    marginLeft: 10,
    marginRight: 10,
    fontFamily: 'CustomFont'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
    backgroundColor:"#EEEDE7"
  },
  breaking: {
    height: "100%",
    padding: "100%"
  },
  page: {
    width: "210mm",
    minHeight: "297mm"
  },
  rowdata: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 10,
    marginLeft: 150,
    top: 5
  },
  rowdata1: {
    position: "relative",
    margin: 8,
    padding: 0,
    top: 5,
    flexGrow: 1,
    fontSize: 15,
    marginLeft: 150
  },
  rowdata4: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 10,
    top: 7,
    marginLeft: 150
  },
  rowdata2: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 8,
    top: 5,
    marginLeft: 150
  },
  subdata: {
    position: "relative",
    margin: 7,
    padding: 0,
    flexGrow: 1,
    color: "#2D5986",
    fontSize: 10,
    width: "35%"
  },
  subdata1: {
    position: "relative",
    margin: 7,
    marginLeft: 10,
    // marginTop:'23px',
    padding: 0,
    flexGrow: 1,
    color: "red",
    fontSize: 10,
    width: "35%",
    top: 10
  },
  top: {
    marginTop: -130,
    paddingTop: 0
  },
  derailers: {
    marginTop: "15px",
    color: "red",
    // color: "#2D5986",
    fontSize: 10,
    width: "30%",
    marginLeft: "35px",
    textAlign: "center"
  },
  derailers1: {
    marginTop: "15px",
    color: "red",
    // color: "#2D5986",
    fontSize: 10,
    width: "70%",
    marginLeft: "140px"
    // textAlign: "center"
    // marginLeft: "35px",
    // marginTop: "15px",
    // color: "red",
    // fontSize: 10,
    // width: "70%",
    // textAlign: "center"
    // wordWrap: "break-word"
  },
  derailers_under: {
    marginTop: "15px",
    color: "black",
    // color: "#2D5986",
    fontSize: 10,
    width: "50%",
    marginLeft: "15px",
    fontFamily: 'ptsans'
  },
  derailers1_under: {
    // marginLeft: "px",
    marginTop: "15px",
    color: "black",
    fontSize: 10,
    width: "70%",
    textAlign: "justify"
    // wordWrap: "break-word"
  },
  image: {
    height: 50,
    width: 50,
    borderRadius: 100,
    top: 65,
    left: 18
  },
  partOne: {
    width: "25 %"
  },
  partTwo: {
    width: "75 %"
  },
  topHead: {
    textAlign: "center",
    fontWeight: 700,
    top: -30,
    color: "white",
    fontSize: 12,
    backgroundColor: "gray",
    marginLeft: 10,
    marginRight: 10,
    fontFamily: 'CustomFont'
  },
  idpHead: {
    width: "12.5%",
    fontSize: 10,
    color: "#2D5986"
  },
  idpdata: {
    width: "12.5%",
    fontSize: 10
  },
  rowDate: {
    position: "absolute",
    fontSize: 9,
    width: "12.5%",
    flexGrow: 3,
    marginLeft: 10
  },
  fullrow: {
    position: "absolute",
    fontSize: 9,
    width: "80%",
    flexGrow: 3
  },
  section_2: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 3
  },
  section_3: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 3,
    width: "80%",
    marginLeft: 30
  },
  rowdata5: {
    position: "relative",
    margin: 8,
    padding: 0,
    flexGrow: 1,
    fontSize: 15,
    marginLeft: 150
  },
  width_20: {
    width: "20%",
    fontSize: 10,
    color: "red",
    fontFamily: 'CustomFont'

  },
  width_70: {
    width: "70%",
    fontSize: 10,
    fontFamily: 'ptsans'
  },
  width_10: {
    width: "10%",
    fontSize: 10,
    marginLeft: 20,
    fontFamily: 'ptsans'
  }
});

const styles = StyleSheet.create({
  row: {
    flexDirection: "row"
  },
  column: {
    flexDirection: "column"
  },
  section: {
    position: "relative",
    margin: 10,
    padding: 0,
    flexGrow: 1
  },
  idpHead: {
    width: "12.5%",
    fontSize: 10,
    color: "#2D5986",
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px'

  },
  idpdata: {
    width: "12.5%",
    fontSize: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    // padding: '4px'
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    width: 'auto'
  },
  cell: {
    width: "12.5%",
    fontSize: 10,
    color: "#000",
    borderRightColor: '#000',
    borderRightWidth: 1,
    // width: 'auto',
    borderRightColor: '#000',
    borderRightWidth: 1,
    textAlign: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '5px',
    paddingRight: '3px'

    // height: 'auto'
  },

})

export default ConsolidatePDF;
