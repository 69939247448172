import React from 'react';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import { FormMiddleWare, SingleSelect } from '../../../components/Form';
import Swal from 'sweetalert2';
import { FilePond } from 'react-filepond';
import { Style } from "react-style-tag";
import { CSVLink } from "react-csv";
import WizardPrograss from './wizardPrograssbar';
import WizardNextPreviousButton from './wizardNextPreviousButton';


const tableName = `tbl_jobdescription`;

class JobDescription extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      departmentOptions: [],
      departmentSelected: null,
      departmentId: null,
      departmentName: null,
      errorDepartmentSelected: null,
      jobRoleOptions: [],
      jobRoleSelected: null,
      jobRoleId: null,
      jobRoleLabelName: null,
      errorJobRoleSelected: null,
      description: null,
      errorDescription: null,
      file: null,
      errorFile: null,
      alertVisible: false,
      updateData: false,
      index: null,
      fileUpdated: false,
      excelHead: [
        { label: "SI.NO", key: "id" },
        { label: "Job Description", key: "jobdescription" },
        { label: "Department", key: "department" },
        { label: "JobRole", key: "jobRoleName" },
        { label: "File", key: "fileupload" },

      ],
      excelHead2: [
        { label: "department", key: "id" },
        { label: "department name", key: "department" },
        { label: "status", key: "status" },
      ],
      excelHead3: [
        { label: "jobRole", key: "id" },
        { label: "jobRoleName", key: "jobRoleName" },
        { label: "status", key: "status" },
      ],
      excelHeadmultidata: [
        { label: "department", key: "Department" },
        { label: "jobRole", key: "Jobrole" },
        { label: "describtion", key: "Description" },

      ],
      addUserColumn: [
        { label: "Department", value: 2, type: "selectbox", selectOptions: [] },
        { label: "Jobrole", value: 3, type: "selectbox", selectOptions: [] },
        { label: "Description", value: 4, type: "text" },
      ],
      statusArray: [],
      multyIndicatorsAdd: true,
      userAddRow: "",
      RowCount: "",
      showDatatable: true,
      submitDataAlert: false,


    };
  }

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.getSelectBoxMaster(adminId, 'tbl_department', 'id', 'department', 'departmentOptions');
    this.getSelectBoxMaster(adminId, 'tbl_jobRole', 'id', 'jobRoleName', 'jobRoleOptions');
    try {
      const { data } = await MasterMiddleWare.getJobDescription(tableName, adminId);
      const { data: data1dept } = await MasterMiddleWare.getMaster('tbl_department', adminId);
      const { data: data1jobrole } = await MasterMiddleWare.getMaster('tbl_jobRole', adminId);
      if (data) {
        this.setState({ data, adminId, data1dept, data1jobrole });
      }
      const { data: departmentOptions } = await MasterMiddleWare.selectBoxDepartment("tbl_department", adminId);
      this.setState({ departmentOption: departmentOptions });
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'Job Description',
      accessor: 'jobdescription'
    },
    {
      Header: 'File',
      accessor: 'fileupload'
    },
    {
      Header: 'Department',
      accessor: 'department'
    },
    {
      Header: 'JobRole',
      accessor: 'jobRoleName'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }
  ];

  selectFile = e => {
    this.setState({ [e.target.name]: e.target.files[0], fileUpdated: true });
  };

  onSubmit = async () => {
    const { departmentId, jobRoleId, file, description, adminId, data, jobRoleLabelName, departmentName } = this.state;
    if (this.validate(departmentId, 'errorDepartmentSelected', 'Please select Department')) return true;
    if (this.validate(jobRoleId, 'errorJobRoleSelected', 'Please select Job-Role')) return true;
    if (this.validate(description, 'errorDescription', 'Please Enter description')) return true;
    // if (this.validate(file, 'errorFile', 'Please Upload Job-description File')) return true;
    const formData = new FormData();
    formData.append('department', departmentId);
    formData.append('jobRole', jobRoleId);
    formData.append('jobdescription', description);
    formData.append('describtion', description);
    // formData.append('fileupload', file);
    formData.append('customerid', adminId);
    formData.append('status', 'active');

    // const previousData = [...this.state.data];

    try {
      const result = await MasterMiddleWare.createMaster(tableName, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Job Description has been saved',
          showConfirmButton: false,
          timer: 1500
        });
        let dataJsonObj = {};
        dataJsonObj.department = departmentName;
        dataJsonObj.departmentId = departmentId;
        // dataJsonObj.fileupload = `competencyFramework/Images/${file.name}`;
        dataJsonObj.id = result.data.insertId;
        dataJsonObj.jobRoleId = jobRoleId;
        dataJsonObj.jobRoleName = jobRoleLabelName;
        dataJsonObj.jobdescription = description;
        let dataNew = [dataJsonObj, ...this.state.data];

        this.setState({
          alertVisible: true,
          data: dataNew,
          departmentId: '',
          jobRoleId: '',
          description: '',
          // file: '',
          departmentSelected: null,
          jobRoleSelected: null
        });
        window.location.reload()
      }
    } catch (error) {
      // this.setState({
      //   data: previousData
      // });
      console.log(error);
    }
  };

  buttonEdit = async value => {
    const { adminId, departmentOptions, jobRoleOptions } = this.state;
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    console.log(getData,"getData");

    try {
      if (departmentOptions) {
        const departmentSelected = departmentOptions.filter(value => value.value === getData.departmentId);
        this.setState({
          departmentOptions,
          departmentSelected,
          departmentId: getData.departmentId,
          departmentName: getData.department
        });
      }
      if (jobRoleOptions) {
        const jobRoleSelected = jobRoleOptions.filter(value => value.value === getData.jobRoleId);
        this.setState({
          jobRoleOptions,
          jobRoleSelected,
          jobRoleId: getData.jobRoleId,
          jobRoleLabelName: getData.jobRoleName
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({
      index,
      indicators: getData.indicators,
      errorindicators: '',
      errorcompetencySelected: '',
      errorlevelSelected: '',
      description: getData.jobdescription,
      errorDescription: '',
      // file: getData.fileupload,
      alertVisible: false,
      updateData: true,
      // fileUpdated: false
    });
  };

  onUpdate = async () => {
    let index = this.state.index;
    const { departmentId, jobRoleId, file, description, adminId, fileUpdated } = this.state;
    if (this.validate(departmentId, 'errorDepartmentSelected', 'Please select Department')) return true;
    if (this.validate(jobRoleId, 'errorJobRoleSelected', 'Please select Job-Role')) return true;
    if (this.validate(description, 'errorDescription', 'Please Enter description')) return true;
    // if (this.validate(file, 'errorFile', 'Please Upload Job-description File')) return true;

    const formData = new FormData();
    formData.append('department', departmentId);
    formData.append('jobRole', jobRoleId);
    formData.append('jobdescription', description);
    formData.append('describtion', description);
    // formData.append('fileupload', file);
    formData.append('customerid', adminId);
    formData.append('status', 'active');
    // formData.append('fileUpdated', fileUpdated);

    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;

    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param formData : json list value for update
       */
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Job Description has been updated',
          showConfirmButton: false,
          timer: 1500
        });

        const { data } = await MasterMiddleWare.getJobDescription(tableName, adminId);

        this.setState({
          data,
          alertVisible: true,
          departmentId: '',
          jobRoleId: '',
          description: '',
          // file: '',
          departmentSelected: null,
          jobRoleSelected: null,
          updateData: false
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  submitcsv = async (e) => {
    console.log(e, "e");
    await this.setState({ btnDisablecsv: true })
    const groupcompId = await localStorage.getItem('groupcompId');
    const { textType, competencyId, themeId, indicators, Description, adminId, files } = this.state;
    // console.log(files, "files");
    const formData = new FormData();
    formData.append("file", files[0]);
    // formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    formData.append('groupcompId', groupcompId)
    console.log(formData, "formData");
    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.csvUpload(tableName, formData);
      console.log(result, "result");
      // const result = await MasterMiddleWare.createMaster(tableName, formData);
      let Timing = 3000
      if (result.data.Message == "success") {
        if (result.data.csvHeaderErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvHeaderErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else if (result.data.csvDataErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvDataErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else {

          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Job Description has been saved',
            showConfirmButton: false,
            timer: 1500
          });
        }
        // let dataJsonObj = {};
        // dataJsonObj.department = departmentName;
        // dataJsonObj.departmentId = departmentId;
        // dataJsonObj.fileupload = `competencyFramework/Images/${file.name}`;
        // dataJsonObj.id = result.data.insertId;
        // dataJsonObj.jobRoleId = jobRoleId;
        // dataJsonObj.jobRoleName = jobRoleLabelName;
        // dataJsonObj.jobdescription = description;
        // let dataNew = [dataJsonObj, ...this.state.data];

        this.setState({
          alertVisible: true,
          // data: dataNew,
          departmentId: '',
          jobRoleId: '',
          description: '',
          file: '',
          departmentSelected: null,
          jobRoleSelected: null,
          btnDisablecsv: false,
          files: ''
        });
        setTimeout(() => {
          window.location.reload()
        }, Timing);
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  }

  handleFileUpload = (fileItems) => {
    this.setState({
      files: fileItems.map((fileItem) => fileItem.file),
    });
  };

  singlemultiselect = (e, val) => {
    this.setState({
      multi: val
    });
  };

  handleSelectChange = async (value, rowIndex, columnLabel) => {
    let { adminId } = this.state
    // console.log(value, "value");
    // console.log(rowIndex, "rowIndex");
    console.log(columnLabel, "columnLabel");

    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: columnLabel === "textType" ? value.label : value.value } : row
      )
    }));
    if (columnLabel === "Department") {

      const { data: jobRoleOptions } = await MasterMiddleWare.selectBoxDepartment("tbl_jobRole", adminId);

      this.setState(prevState => ({
        statusArray: prevState.statusArray.map((row, index) =>
          index === rowIndex ? { ...row, JobroleOption: jobRoleOptions } : row
        )
      }));
    }
  };
  addRow = (value) => {
    console.log(value, "values");
    let { userAddRow, statusArray } = this.state
    // this.setState({ statusArray: [] })
    let addIndex = value ? statusArray.length :0

    const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({
      Department: "",
      Jobrole: "",
      DepartmentSelect: "",
      JobroleSelect: "",
      Description: "",
      DepartmentOption: this.state.departmentOption,
      JobroleOption: [],
      index:value ? addIndex:index

    }));
    console.log(newItems, "newItems");
    this.setState(prevState => ({
      statusArray: [...prevState.statusArray, ...newItems]
    }));

    console.log(statusArray, "statusArray");
  }
  handleInputChange = (e, rowIndex, columnLabel) => {
    const { value } = e.target;
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel]: value } : row
      )
    }));
  };


  // addMultySubmit = async () => {
  //   let { statusArray, adminId } = this.state
  //   await this.setState({ btnDisablecsv: true })
  //   // console.log(statusArray, "statusArray");
  //   const formData = new FormData();
  //   formData.append("statusArray", JSON.stringify(statusArray));
  //   // formData.append('groupcompId', groupcompId)
  //   formData.append('adminId', adminId);
  //   formData.append('customerid', adminId);
  //   const previousData = [...this.state.data];
  //   try {
  //     const result = await MasterMiddleWare.insertMuldidata(tableName, formData);
  //     // console.log(result, "result");
  //     if (result.data.Message == "success") {
  //       if (result.data.fillDataErrorMsg) {
  //         Swal.fire({
  //           position: 'center',
  //           type: 'warning',
  //           title: `${result.data.fillDataErrorMsg}`,
  //           showConfirmButton: false,
  //           timer: 2000

  //         });
  //       } else {

  //         Swal.fire({
  //           position: 'center',
  //           type: 'success',
  //           title: 'Job Description has been saved',
  //           showConfirmButton: false,
  //           timer: 1500
  //         });
  //         this.getMaster(tableName);
  //         this.setState({
  //           alertVisible: true,
  //           btnDisablecsv: false,
  //           statusArray: "",
  //           userAddRow: ""
  //         });
  //         setTimeout(() => {
  //           window.location.reload()
  //         }, 3000);
  //       }

  //     }
  //   } catch (error) {
  //     this.setState({
  //       data: previousData
  //     });
  //     console.log(error);
  //   }
  // }
  addMultySubmit = () => {
    let { statusArray, adminId } = this.state;

    return new Promise((resolve, reject) => {
      this.setState({ submitDataAlert: true });
      setTimeout(() => {
        this.setState({ submitDataAlert: false });
        resolve();
      }, 3000);
    })
      .then(() => {
        const formData = new FormData();
        formData.append("statusArray", JSON.stringify(statusArray));
        formData.append("adminId", adminId);
        formData.append("customerid", adminId);

        const previousData = [...this.state.data];

        return MasterMiddleWare.insertMuldidata(tableName, formData);
      })
      .then(result => {
        if (result.data.Message === "success") {
          if (result.data.fillDataErrorMsg) {
            const maxLength = 29;
            const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
            console.log(wrappedErrorMsg, "wrappedErrorMsg");
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: wrappedErrorMsg,
              showConfirmButton: false,
              timer: 6000
            });
            // Swal.fire({
            //   position: "center",
            //   type: "warning",
            //   title: `${result.data.fillDataErrorMsg}`,
            //   showConfirmButton: false,
            //   timer: 2000
            // });
          } else {
            Swal.fire({
              position: "center",
              type: "success",
              title: "Job Description has been saved",
              showConfirmButton: false,
              timer: 1500
            });
            this.getMaster(tableName);
            this.setState({
              alertVisible: true,
              btnDisablecsv: false,
              statusArray: "",
              userAddRow: ""
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        }
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  };


  render() {
    const {
      data,
      departmentOptions,
      departmentSelected,
      errorDepartmentSelected,
      jobRoleOptions,
      jobRoleSelected,
      errorJobRoleSelected,
      description,
      errorDescription,
      errorFile,
      updateData,
      excelHead,
      excelHead2,
      excelHead3,
      data1dept,
      data1jobrole,
      addUserColumn,
      userAddRow,
      RowCount,
      statusArray,
      showDatatable
    } = this.state;

    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Job Description</h3>
              <WizardPrograss step={4} />
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>

            <div className="box-body">
              {this.state.multyIndicatorsAdd ?

                <div className="wizardCards">

                  <div className="row form-group ">
                    <div className="col-sm-4" />
                    <div className="col-sm-3" onClick={(e) => this.singlemultiselect(e, 1)} >
                      <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency">Add single job description</label>
                    </div>
                    {/* <div className="col-sm-1" /> */}
                    <div className="col-sm-3" onClick={(e) => this.singlemultiselect(e, 2)}>
                      <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency"> Add multiple job description</label>
                    </div>
                    <div className="col-sm-2" />
                  </div>
                  {
                    this.state.multi == 2 ?
                      <div className="card-body">
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Upload Files:
                            </label>
                          </div>
                          <div className="col-sm-5 filepond">
                            <FilePond
                              // type="file"
                              files={this.state.files}
                              // className="custom-file-input"
                              id="customFile"
                              // accept='.csv'
                              acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                              // onChange={this.fileupload}
                              // files={this.state.Testimonial_image}
                              onupdatefiles={this.handleFileUpload}
                              allowReorder={true}
                              allowMultiple={false}
                              width={10}
                              maxFileSize={'300KB'}
                              // imageValidateSizeMaxWidth={550}
                              // imageValidateSizeMaxHeight={355}
                              imagePreviewMinHeight="150"
                              imagePreviewMaxHeight="150"
                              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            />
                            {/* <label
                      className="custom-file-label"
                      htmlFor="customFile"
                    >
                      {this.state.csv ? this.state.csv.name : 'Choose File'}
                    </label> */}
                          </div>
                        </div>
                        <br />
                        <div className="row-form-group">
                          <div className="col-sm-4" />
                          {/* <div className="col-sm-2" /> */}
                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => this.submitcsv(e)}
                              disabled={this.state.btnDisablecsv ? true : false}
                            >
                              {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                              {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                            </button>
                          </div>
                        </div>
                        <br />
                        {/* <div className="row form-group">
                          <div className="col-md-2">
                            <a href="../../files/jobDescriptionSample.csv" download="jobDescriptionSample.csv">
                              download sample.csv
                            </a>
                          </div>
                          <div className="col-md-3" />
                        </div> */}
                        <div className="row form-group">
                          <div className="col-md-3">
                            <button className='btn btn-primary' style={{ marginTop: "-15px" }} onClick={() => this.setState({ multyIndicatorsAdd: false, showDatatable: false })}>Create job description CSV file</button>
                            {/* <button onClick={this.downloadSampleCsv}>Download CSV</button> */}
                          </div>
                          <div className="col-md-3" />
                        </div>
                      </div>
                      :
                      <div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="competency">Department</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect handleChange={this.departmentSelect} options={departmentOptions} selectedService={departmentSelected} />
                          </div>
                          {/* <div className="col-sm-1" >
                            {data1dept && data1dept.length ?
                              <div className="row form-group ">
                                <div className="col-sm-10" />
                                <div className="col-sm-2">
                                  <CSVLink
                                    headers={this.state.excelHead2}
                                    data={this.state.data1dept}
                                    filename={"department.xls"}
                                    className="excelexportlink"
                                    target="_blank"
                                  >
                                    View department
                                  </CSVLink>
                                </div>
                              </div>
                              :
                              <></>}
                          </div> */}
                          <div className="col-sm-2" />
                        </div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <span className="errorMsg">{errorDepartmentSelected}</span>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="Level">Job Role</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect handleChange={this.jobRoleSelect} options={jobRoleOptions} selectedService={jobRoleSelected} />
                          </div>
                          {/* <div className="col-sm-1" >
                            {data1jobrole && data1jobrole.length ?
                              <div className="row form-group ">
                                <div className="col-sm-10" />
                                <div className="col-sm-2">
                                  <CSVLink
                                    headers={this.state.excelHead3}
                                    data={this.state.data1jobrole}
                                    filename={"jobrole.xls"}
                                    className="excelexportlink"
                                    target="_blank"
                                  >
                                    View jobrole
                                  </CSVLink>
                                </div>
                              </div>
                              :
                              <></>}
                          </div> */}
                          <div className="col-sm-2" />
                        </div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <span className="errorMsg">{errorJobRoleSelected}</span>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="indicators">Description</label>
                          </div>
                          <div className="col-sm-5">
                            <textarea className="form-control" rows="3" id="description" name="description" placeholder="Please enter description" onChange={this.handleUserInput} value={description}></textarea>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <span className="errorMsg">{errorDescription}</span>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        {/* <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="Description">File Upload</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="file" name="file" id="file" onChange={this.selectFile} />
                          </div>
                          <div className="col-sm-3" />
                        </div> */}
                        {/* <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <span className="errorMsg">{errorFile}</span>
                          </div>
                          <div className="col-sm-3" />
                        </div> */}
                        <div className="row form-group ">
                          <div className="col-sm-4" />
                          <div className="col-sm-4">
                            {!updateData ? (
                              <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                                Submit
                              </button>
                            ) : (
                              <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                                Update
                              </button>
                            )}
                          </div>
                          <div className="col-sm-4" />
                        </div>

                        <WizardNextPreviousButton step={4} nextPage={"Master/WorkLevel"} previousPage={"Master/Department"} />
                      </div>
                  }
                </div>
                :


                <>
                  <button className='btn btn-primary' onClick={() => this.setState({ multyIndicatorsAdd: true, showDatatable: true })}>Back</button>
                  <br></br>

                  <>
                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="indicators">How many jobdescriptions add ?</label>
                      </div>
                      <div className="col-sm-5">
                        <input type="number" className="form-control" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                      </div>
                      <div className="col-sm-3" >
                        <button className='btn btn-primary' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                        {/* <button className='btn btn-info' onClick={()=>this.setState({statusArray:[]})}>Clear</button> */}
                      </div>
                      {/* {statusArray && statusArray.length ?
                        <div className="row form-group ">
                          <div className="col-sm-10" />
                          <div className="col-sm-2">
                            <CSVLink
                              headers={this.state.excelHeadmultidata}
                              data={statusArray}
                              filename={"JobDescription.xls"}
                              className="btn btncolor"
                              target="_blank"
                            >
                              Generate Excel
                            </CSVLink>
                          </div>
                        </div>
                        :
                        <></>} */}
                    </div>


                    <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No </th>

                            {addUserColumn.map((index) => (
                              <th key={index}> {index.label}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                            // console.log(ival,"type",i,"i")

                            <tr>
                              <td style={{ textAlign: "center" }} >{i + 1}</td>
                              {addUserColumn.map((jval, index) => (
                                <>
                                  <td style={{ textAlign: "center" }}  >{jval && jval.type === "text" ? <>
                                    <textarea
                                      style={{ height: "50px", width: "280px" }}
                                      // type="text"
                                      value={ival[jval.label]}
                                      onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                    /></> : jval.type === "selectbox" ?
                                    <div style={{ width: "280px" }}>
                                      <SingleSelect
                                        handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                        options={ival[jval.label + 'Option']}
                                        selectedService={ival[jval.label + "Select"]}
                                      // disabled={false}
                                      />

                                      {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                    </div>
                                    :
                                    <>
                                    </>}
                                  </td>
                                </>
                              ))}
                            </tr>
                          ))}

                        </tbody>
                      </table>
                      {this.state.submitDataAlert ?
                        <div className='loader1'></div>
                        :
                        <>
                          {statusArray && statusArray.length > 0 &&

                            <div className="row form-group ">
                              <div className="col-sm-1" >
                                <button className='btn btn-primary' onClick={() => this.addRow(1)}>Add one</button>
                              </div>
                              <div className="col-sm-1">
                                <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                                  Submit
                                </button>
                              </div>
                              <div className="col-sm-1">
                                <CSVLink
                                  headers={this.state.excelHeadmultidata}
                                  data={statusArray}
                                  filename={"JobDescription.xls"}
                                  className="btn btncolor"
                                  target="_blank"
                                >
                                  Generate Excel
                                </CSVLink>
                              </div>

                            </div>
                          }
                        </>
                      }
                    </div>
                    {/* {statusArray && statusArray.length > 0 &&
                      <div className="row form-group " style={{ marginTop: "20px" }}>
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                          <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                            Submit
                          </button>

                        </div>
                        <div className="col-sm-4" />
                      </div>
                    } */}

                  </>

                </>

              }

              {data && data.length && showDatatable ?
                <div className="row form-group ">
                  <div className="col-sm-10" />
                  <div className="col-sm-2">
                    <CSVLink
                      headers={excelHead}
                      data={data}
                      filename={"ProficiencyScale.xls"}
                      className="btn btncolor"
                      target="_blank"
                    >
                      Generate Excel
                    </CSVLink>
                  </div>
                </div>
                :
                <></>}
              {data && showDatatable && <Datatable data={data} columnHeading={this.column} />}


            </div>
          </div>
        </section>
        <Style>
          {`/*
Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
Template Version: 1.0
Author: Carlos Alvarez
Website: http://blacktie.co
Premium: http://www.gridgum.com
*/

/* Import fonts */
@import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);

/* BASIC THEME CONFIGURATION */
.filepond{
  height: 82px;
  background-color: #f1f1f1;
  border-radius: 20px;
  width: 300px;
}

.filepond--credits{
  color:#f7f7f7;
}

.filepond--root :not(text) {
  font-size: 13px;
}

.multi:hover{
  color: limegreen;
}

.excelexportlink{
  white-space: nowrap;
  text-decoration: underline;
}
.excelexportlink:hover{
  white-space: nowrap;
  text-decoration: underline;
  color:black
}


`}

        </Style >
      </React.Fragment>
    );
  }
}

export default JobDescription;
