import React from 'react';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import { FormMiddleWare } from '../../../components/Form';

import { ACCESS_POINT } from "../../../config/index";
import Swal from 'sweetalert2';
import { CSVLink } from "react-csv";
import WizardPrograss from './wizardPrograssbar';
import WizardNextPreviousButton from './wizardNextPreviousButton';

const tableName = `tbl_gcompanies`;

class GroupCompany extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      readershow: false,
      groupcompanies: null,
      errorgroupcompanies: null,
      alertVisible: false,
      updateData: false,
      index: null,
      excelHead: [
        { label: "SI.NO", key: "id" },
        { label: "Group Company", key: "groupcompanies" },

      ]
    };
  }

  async componentWillMount() {
    this.getMaster(tableName);
  }

  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'Group Company',
      accessor: 'groupcompanies'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }
  ];

  onSubmit = async () => {
    const { groupcompanies, adminId, file } = this.state;
    if (this.validate(groupcompanies, 'errorgroupcompanies', 'Please Enter group company')) return true;

    if (this.validate(file, 'errorlogo', 'Please Upload Logo')) return true;

    const formData = new FormData();
    formData.append('groupcompanies', groupcompanies);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    formData.append("image", file);

    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.createMaster(tableName, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Group Company has been saved',
          showConfirmButton: false,
          timer: 1500
        });
        this.getMaster(tableName);
        this.setState({
          alertVisible: true,
          groupcompanies: '',
          file: "",
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  buttonEdit = value => {
    this.setState({ readershow: false, file: "" })
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    var reader = new Image();
    if (getData.image) {
      reader.src = ACCESS_POINT + "/getpro/getprofile?fileurl_1=" + getData.image
      this.setState({ readershow: true, reader })
    }
    this.setState({
      index,
      groupcompanies: getData.groupcompanies,
      // file:getData.image,

      errorgroupcompanies: '',
      alertVisible: false,
      updateData: true
    });
  };

  onUpdate = async () => {
    let index = this.state.index;
    const { groupcompanies, file ,adminId} = this.state;
    if (this.validate(groupcompanies, 'errorgroupcompanies', 'Please Enter group company')) return true;

    const formData = new FormData();
    formData.append('groupcompanies', groupcompanies);
    formData.append("customerid", adminId);
    formData.append("adminId", adminId);
    if (typeof (file) != "string") {
      if (this.validate(file, 'errorlogo', 'Please Upload Logo')) return true;
      formData.append("image", file);
    }
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;
    getData.groupcompanies = groupcompanies;

    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, getData); // update the user value in the list at particular index
    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param formData : json list value for update
       */
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Group Company has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        console.log(formData.get("image"));

        this.setState({
          data,
          alertVisible: true,
          groupcompanies: '',
          updateData: false,
          readershow: false,
          file: "",
          groupcompanies:""
        });
        this.componentWillMount()
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };


  render() {
    const { data, groupcompanies, errorgroupcompanies, updateData, readershow, errorlogo, file, reader, excelHead } = this.state;
    console.log(typeof (file));
    if (file && typeof (file) == "object") {
      var reader1 = new FileReader();
      reader1.onload = function () {
        var output = document.getElementById('output');
        output.src = reader1.result;
      };
      reader1.readAsDataURL(file);

    }
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Group Company</h3>

              <br></br>
              <WizardPrograss step={1} />
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">

              <div className="wizardCards">

                {/* <div className={`step `}>3</div> */}

                {/* Add more steps similarly */}


                <div className="row form-group ">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="groupcompanies">Group Company</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="text"
                      className="form-control"
                      name="groupcompanies"
                      id="groupcompanies"
                      placeholder="Please enter group company"
                      value={groupcompanies}
                      onChange={this.handleUserInput}
                    />
                  </div>
                  <div className="col-sm-3" />
                </div>
                <div className="row form-group ">
                  <div className="col-sm-2" />
                  <div className="col-sm-2" />
                  <div className="col-sm-5">
                    <span className="errorMsg">{errorgroupcompanies}</span>
                  </div>
                  <div className="col-sm-3" />
                </div>
                {/* {!updateData && ( */}
                <div>
                  <div className="row form-group ">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="competency">Company Logo</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="file"
                        name="file"
                        id="file"
                        style={{ display: "none" }}
                        onChange={this.selectImage}
                        ref={imageinput => this.imageinput = imageinput}
                      // disabled
                      />
                      <button type="button" className="btn btn-warning" onClick={() => this.imageinput.click()}>
                        {
                          typeof (file) != "string" ? (
                            <div>
                              Click to add
                            </div>

                          ) : (
                            <div>
                              Update Logo here
                            </div>
                          )
                        }
                      </button>
                    </div>

                    <div className="col-sm-3" />
                  </div>
                  <div className="row form-group ">
                    <div className="col-sm-2" />
                    <div className="col-sm-2" />
                    <div className="col-sm-5">
                      {
                        typeof (file) != "string" && (
                          <img style={{ width: "30%" }} id="output" />
                        )
                      }

                      {(readershow && !file) &&
                        <img style={{ width: "30%" }} src={reader.src} />
                      }
                      <span className="errorMsg">{errorlogo}</span>
                    </div>
                    <div className="col-sm-3" />
                  </div>
                </div>
                {/* )} */}

                <div className="row form-group ">
                  <div className="col-sm-4" />
                  <div className="col-sm-4">
                    {!updateData ? (
                      <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                        Submit
                      </button>
                    ) : (
                      <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                        Update
                      </button>
                    )}
                  </div>
                  <div className="col-sm-4" />
                  <br></br>
                  <WizardNextPreviousButton step={1} nextPage={"Master/JobRole"} />
             

                </div>


              </div>
              {/* {data && data.length ?
                <div className="row form-group ">
                  <div className="col-sm-10" />
                  <div className="col-sm-2">
                    <CSVLink
                      headers={excelHead}
                      data={data}
                      filename={"WorkLevel.xls"}
                      className="btn btncolor"
                      target="_blank"
                    >
                      Generate Excel
                    </CSVLink>
                  </div>
                </div>
                :
                <></>} */}
              {data && <Datatable data={data} columnHeading={this.column} />}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default GroupCompany;
