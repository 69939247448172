import React from "react";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import ValidationView from "../../components/Form/ValidationView";
import SingleSelect from "../../components/Form/SingleSelect";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import Datatable from "../../components/Datatable/Datatable";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import Swal from "sweetalert2";
class Derailers extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      adminId: localStorage.getItem("adminId"),
      groupcompId: localStorage.getItem("groupcompId"),
      uderId:localStorage.getItem("userId"),
      addMap: "Submit",
      designationOptions: [],
      designationSelected: "",
      textType: null,
      themeOptions:[],
      themcol:[],
      designationId:""
    };
  }
  async componentDidMount() {
    const { adminId, groupcompId } = this.state;

    this.getSelectBoxMaster(
      adminId,
      "tbl_worklevel",
      "id",
      "worklevel",
      "workLevelOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_jobRole",
      "id",
      "jobRoleName",
      "jobRoleOptions"
    );
    this.getSelectBoxMaster(
      adminId,
      "tbl_derailers",
      "id",
      "derailers",
      "derailerOptions"
    );

    this.getSelectBoxMaster(
      adminId,
      "tbl_designation",
      "id",
      "designation",
      "designationOptions"
    );

    let designation_data = await HrMiddleWare.getRandomData(
      "tbl_designation",
      `customerid = ${groupcompId}`,
      "*"
    );
    
    // let themeOptions= await HrMiddleWare.getRandomData(
    //   "tbl_theme",
    //   `textType='Technical' and adminId = ${this.state.adminId}`,
    //   "tbl_theme.theme as label ,tbl_theme.id as value"
    // );

    // if (themeOptions) {
    //   this.setState({
    //     themeOptions:themeOptions.data
        
    //   });
    // }
    console.log(designation_data)
    console.log(groupcompId)
    // this.getMaster("tbl_derailers");
    // const adminId = await localStorage.getItem("userId");
    // const data1 = await HrMiddleWare.getRandomData(
    //   "",
    //   ` id  not in (${newThemeid}) and customerid=${adminId} and textType='Behavioral' `,
    //   "theme as label,id as value"
    // );
    // const { data } = await MasterMiddleWare.getMasterByGroup(
    //   "tbl_derailers",
    //   adminId,
    //   groupcompId
    // );

  let themeOptions= await HrMiddleWare.getRandomData(
    "tbl_theme",
    ` adminId = ${this.state.adminId}`,
    "tbl_theme.theme as label ,tbl_theme.id as value"
  );
  console.log(themeOptions.data);
  this.setState({themcol:themeOptions.data})
  let data1  = await HrMiddleWare.getRandomData(
      "Tblderailers_map",
      `groupcompany = ${groupcompId}`,
      "*"
    );
    this.setState({data1:data1.data})
    const { data } = await HrMiddleWare.getRandomData(
      "tbl_derailers",
      `adminId = ${adminId} and groupcompany = ${groupcompId}`,
      "*"
    );
    console.log(data1);
    if (data) {
      console.log(data);
      this.setState({
        data,
        adminId,
        groupcompId
      });
    }
    console.log(data);
  }
  column = [
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,

      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Derailers </span>,
      accessor: "derailers"
    },
    {
      Header: props => (
        <span style={{ fontWeight: "bold" }}> Description </span>
      ),
      accessor: "description"
      // Cell: d => this.NEW(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}>Edit</span>,
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Delete </span>,
      accessor: "delete",
      Cell: d => this.delete(d, "tbl_derailers")
    }
  ];
 
  column1 = [
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Si No </span>,

      accessor: "si no",
      Cell: d => this.siNo(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Theme Type </span>,
      accessor: "textType"
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Theme /Function </span>,
      accessor: "themeid",
      Cell : d=>this.getValueFromArray(d.original.themeid,this.state.themcol)
    },
    
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Designation </span>,
      accessor: "designationid",
      Cell : d=>this.getValueFromArray(d.original.designationid, this.state.designationOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> worklevel </span>,
      accessor: "worklevelid",
      Cell : d=>this.getValueFromArray(d.original.worklevelid, this.state.workLevelOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Derailers </span>,
      accessor: "derailersid",
      Cell : d=>this.getValueFromArray(d.original.derailersid, this.state.derailerOptions)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}>Edit</span>,
      accessor: "edit",
      Cell: (d) => this.edit1(d)
    },
    {
      Header: props => <span style={{ fontWeight: "bold" }}> Delete </span>,
      accessor: "delete",
      Cell: d => this.delete(d, "Tblderailers_map")
    }
  ];
  edit1 = value => {
    return (
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => this.buttonEdit1(value)}
      >
        Edit
      </button>
    );
  };
  buttonEdit1 = async value => {
    console.log("calling",value);
    let work={label:this.getValueFromArray(value.original.worklevelid, this.state.workLevelOptions),value:value.original.worklevelid}
    await this.setState({
      index: value.index,
      id: value.original.id,
      addMap: "Map Edit",
      derailerSelected:{label:this.getValueFromArray(value.original.derailersid, this.state.derailerOptions),value:value.original.derailersid},
      // description: value.original.description,
      workLevelSelected:work,
      themeOptionSelected:{label:this.getValueFromArray(value.original.themeid,this.state.themcol),value:value.original.themeid},
      designationSelected:{label:this.getValueFromArray(value.original.designationid, this.state.designationOptions),value:value.original.designationid},
      textType:value.original.textType,
      targetValue: value.original.textType,
      workLevelId: value.original.worklevelid
    });
    // console.log(textType);
    window.scrollTo(0, 0)
  };
  NEW = d => {
    console.log(d);
    return <div>{d.original.label}</div>;
  };

  buttonEdit = value => {
    this.setState({
      index: value.index,
      id: value.original.id,
      addMap: "Edit",
      derailer: value.original.derailers,
      description: value.original.description
    });
    window.scrollTo(0, 0)
  };
  changeState = addMap => {
    this.setState({ addMap });
  };
  resetState = () => {
    this.setState({ derailer: "", description: "" });
  };
  onSubmit = () => {
    const {
      groupcompId,
      workLevelId,
      // jobRoleId,
      addMap,
      adminId,
      uderId,
      derailer,
      description,
      derailerId,
      themeOptionSelected,
      designationSelected,
      designationId,
      workLevelSelected,
      derailerSelected
     
    } = this.state;

    if (addMap === "Submit") {
      if (this.validate(derailer, "errorDerailer", "Please enter derailer"))
        return true;
      if (
        this.validate(
          description,
          "errorDescription",
          "Please enter description"
        )
      )
        return true;

      var formData = new FormData();
      formData.append("derailers", derailer);
      formData.append("description", description);
      formData.append("customerid", localStorage.getItem("adminId"));
      formData.append("adminId", localStorage.getItem("adminId"));
      formData.append("status", "active");
      formData.append("groupcompany", groupcompId);

      this.addnew("tbl_derailers", formData, "Derailers Added");
    } else if (addMap === "Map") {
      if (
        this.validate(
          workLevelId,
          "errorWorkLevelSelected",
          "Please Select Work Level"
        )
      )
        return true;
        
      // if (
      //   this.validate(
      //     jobRoleId,
      //     "errorJobRoleSelected",
      //     "Please Select JobRole"
      //   )
      // )
      //   return true;
      if (
        this.validate(
          derailerId,
          "errorDerailerSelected",
          "Please Select Derailer"
        )
      )
        return true;

        if (
          this.validate(
            themeOptionSelected,
            "errorthemeOptionSelected",
            "Please Select Work Level"
          )
        )
          return true;

          if (
            this.validate(
              designationId,
              "errordesignationSelected",
              "Please Select Designation"
            )
          )
            return true;

          
      var formData = new FormData();
      formData.append("worklevelid", workLevelId);
      formData.append("jobroleid", localStorage.getItem("jobRoleId"));
      formData.append("customerid", localStorage.getItem("adminId"));
      formData.append("derailersid", derailerId);
      formData.append("groupcompany", groupcompId);
      formData.append("themeid", themeOptionSelected.value);
      formData.append("designationid", designationSelected.value);
      // formData.append("userId", "[]");
      formData.append("userId", uderId);

      formData.append("textType", this.state.targetValue);
      console.log([...formData])

       this.addnew("Tblderailers_map", formData, "Derailers Mapped");
    } else if (addMap === "Edit") {
      if (this.validate(derailer, "errorDerailer", "Please enter derailer"))
        return true;
      if (
        this.validate(
          description,
          "errorDescription",
          "Please enter description"
        )
      )
        return true;

      var formData = new FormData();
      formData.append("derailers", derailer);
      formData.append("description", description);
      formData.append("customerid", adminId);
      formData.append("adminId", adminId);
      formData.append("status", "active");
      formData.append("groupcompany", groupcompId);

      this.addnew("tbl_derailers", formData, "Derailers Updated");
    }else if (addMap === "Map Edit") {
               
          
      var formData = new FormData();
      formData.append("worklevelid", workLevelId);
      formData.append("jobroleid",  localStorage.getItem("jobRoleId"));
      formData.append("customerid", localStorage.getItem("adminId"));
      formData.append("derailersid", derailerId);
      formData.append("groupcompany", groupcompId);
      formData.append("themeid", themeOptionSelected.value);
      formData.append("designationid", designationSelected.value);
      // formData.append("userId", "[]");
      formData.append("userId", uderId);

      formData.append("textType", this.state.targetValue);
      console.log([...formData])

       this.addnew("Tblderailers_map", formData, "Derailers Mapped Updated");
    }
  };
  addnew = async (tableName, formData, msg) => {
    console.log([...formData]);
    // console.log(formData.get("derailers"));
    // let data = [...this.state.data];
    try {
      let addMap = this.state.addMap;
      if (addMap === "Submit" || addMap === "Map") {
        var result = await MasterMiddleWare.createMaster(tableName, formData);
      } else if (addMap === "Edit" || addMap === "Map Edit"  ) {
        var result = await MasterMiddleWare.updateMaster(
          tableName,
          this.state.id,
          formData
        );
      }
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: msg,
          showConfirmButton: false,
          timer: 1500
        });
        
        // let newArray = {};
        // newArray.derailers = formData.get("derailers");
        // newArray.description = formData.get("description");

        // let newData = [newArray, ...this.state.data];
        if (addMap === "Submit" || addMap === "Map") {
        let newData111  = await HrMiddleWare.getRandomData(
          "tbl_derailers",
          `groupcompany = ${this.state.groupcompId}`,
          "*"
        );
        let newData = newData111.data.sort((a,b)=>b.id-a.id);
        this.setState({ data: newData });
        
         let newData11  = await HrMiddleWare.getRandomData(
          "Tblderailers_map",
          `groupcompany = ${this.state.groupcompId} `,
          "*"
        );
        let newData1 = newData11.data.sort((a,b)=>b.id-a.id);
         this.setState({ data1: newData1 });
         }else{
           let newArray = {};
        newArray.derailers = formData.get("derailers");
        newArray.description = formData.get("description");

        let newData = [newArray, ...this.state.data];
        this.setState({ data: newData });

        
         }
        this.resetState();
        setTimeout(() => {
           this.componentDidMount()
        }, 4000);
       window.location.reload()
      }
    } catch (error) {
      console.log(error);
    }
  };
  themeOptionselect =(e) =>{
    console.log(e)
    this.setState({themeOptionSelected:e})
  }
  
 
  handleRadioInput = async e => {
    this.setState({ [e.target.name]: e.target.value });
    let targetValue = e.target.value;
    let adminId = this.state.adminId;
this.setState({targetValue:targetValue})

    try {


    let themeOptions= await HrMiddleWare.getRandomData(
      "tbl_theme",
      `textType='${targetValue}' and adminId = ${this.state.adminId}`,
      "tbl_theme.theme as label ,tbl_theme.id as value"
    );

    if (themeOptions) {
      this.setState({
        themeOptions:themeOptions.data
        
      });
    }
      
    } catch (error) {
      console.error(error);
    }

  }

  render() {
    const {
      workLevelOptions,
      workLevelSelected,
      errorWorkLevelSelected,
      jobRoleOptions,
      jobRoleSelected,
      errorJobRoleSelected,
      addMap,
      description,
      derailer,
      errorDerailer,
      errorDescription,
      derailerOptions,
      derailerSelected,
      errorDerailerSelected,
      data,
      data1,
      designationSelected,
      designationOptions,
      textType,
      themeOptions,
      themeOptionSelected,
      errorthemeOptionSelected,
      errordesignationSelected
    } = this.state;
    return (
      <React.Fragment>
        <section id="" style={{padding:"10px 30px"}}>
          <section className="wrapper" id="wrapper">
            <div className="container">
              <div className="form-group">
                <div className="col-sm-12 padding-10 bg-white">
                  <div className="box box-info">
                    <div className="box-header with-border">
                      <h3 className="box-title">{addMap} Derailer</h3>
                      <div className="box-tools pull-right">
                        <button
                          className="btn btn-box-tool"
                          data-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div className="box-body">
                      <div className="row form-group ">
                        <div className="col-sm-4" />
                        <div className="col-sm-3">
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => this.changeState("Submit")}
                          >
                            Add Derailers
                          </button>
                        </div>
                        <div className="col-sm-3">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => this.changeState("Map")}
                          >
                            Map Derailers
                          </button>
                        </div>
                        <div className="col-sm-2" />
                      </div>

                      {addMap === "Submit" || addMap === "Edit" ? (
                        <div>
                          <div className="row form-group ">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="indicators">Derailer</label>
                            </div>
                            <div className="col-sm-5">
                              <textarea
                                className="form-control"
                                rows="3"
                                id="description"
                                name="derailer"
                                placeholder="Enter derailer"
                                onChange={this.handleUserInput}
                                value={derailer}
                              ></textarea>
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <ValidationView errorname={errorDerailer} />
                          <div className="row form-group ">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="indicators">Description</label>
                            </div>
                            <div className="col-sm-5">
                              <textarea
                                className="form-control"
                                rows="3"
                                id="description"
                                name="description"
                                placeholder="Enter description"
                                onChange={this.handleUserInput}
                                value={description}
                              ></textarea>
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <ValidationView errorname={errorDescription} />
                        </div>
                      ) : (
                        <div>
                           

           <div className="row form-group ">
                <div class="col-sm-2"></div>
                <div class="col-sm-2">
                  <label>Theme Type</label>
                </div>
                <div class="col-sm-5">
                  <label>
                    <input type="radio" id="Behavioral" name="textType" value="Behavioral" checked={textType === 'Behavioral' && true} onChange={this.handleRadioInput} />
                    &nbsp;&nbsp;&nbsp;Behavioral
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label>
                    <input type="radio" id="Technical" name="textType" value="Technical" checked={textType === 'Technical' && true} onChange={this.handleRadioInput} /> &nbsp;&nbsp;&nbsp;Technical
                  </label>
                </div>
                <div class="col-sm-3" />
              </div>

                          <div className="row form-group ">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="competency"> Th/Function</label>
                            </div>
                            <div className="col-sm-5">
                            <SingleSelect
                            handleChange={this.themeOptionselect}
                            options={themeOptions}
                            selectedService={themeOptionSelected}
                          />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          
                          <ValidationView errorname={errorthemeOptionSelected} />
                          <div className="row form-group ">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="competency">Designation</label>
                            </div>
                            <div className="col-sm-5">
                            <SingleSelect
                            handleChange={this.designationSelect}
                            options={designationOptions}
                            selectedService={designationSelected}
                          />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <ValidationView errorname={errordesignationSelected} />

                          <div className="row form-group ">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="competency">Work Level</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                handleChange={this.workLevelSelect}
                                options={workLevelOptions}
                                selectedService={workLevelSelected}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <ValidationView errorname={errorWorkLevelSelected} />

                          {/* <div className="row form-group ">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="competency">Job Role</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                handleChange={this.jobRoleSelect}
                                options={jobRoleOptions}
                                selectedService={jobRoleSelected}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div> 
                           <ValidationView errorname={errorJobRoleSelected} />
                          */}
                         
                          <div className="row form-group ">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="competency">Derailers</label>
                            </div>
                            <div className="col-sm-5">
                              <SingleSelect
                                handleChange={this.derailerSelect}
                                options={derailerOptions}
                                selectedService={derailerSelected}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <ValidationView errorname={errorDerailerSelected} />
                        </div>
                      )}
                      <div className="row form-group ">
                        <div className="col-sm-4" />
                        <div className="col-sm-5">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={this.onSubmit}
                          >
                            {addMap}
                          </button>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                     
                      {addMap === "Submit" || addMap === "Edit" ? (<div>
                         {data && (
                        <Datatable data={data} columnHeading={this.column} />
                      )}
                      </div>
                      ):(
                        <div>
                         {data1 && (
                        <Datatable data={data1} columnHeading={this.column1} />
                      )}
                      </div>
                      )}
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </React.Fragment>
    );
  }
}
export default Derailers;
