import React from 'react';
import Select from 'react-select';
import './Style.css';

const MultiSelect = ({
  errorServices,
  handleChange,
  selectedService,
  options
}) => {
  return (
    <Select
      clearValue
      closeMenuOnSelect={false}
      isMulti
      value={selectedService}
      onChange={handleChange}
      options={options}
    />
  );
};

export default MultiSelect;
