import React from 'react';

const LoginModal = ({ atagLink = false, buttonTitle, title, id, bodyText, onClick, extraClass = '', extraStyle, maxwidth = null }) => {
  //console.log(id);
  return (
    <div>
      {atagLink ? (
        // <a >
        <i style={{ fontSize: '15px' }} className={extraClass} data-toggle="modal" data-target={`#${id}`} onClick={onClick}>
          {/* {buttonTitle} */}
        </i>
      ) : (
        <button
          //btn btn-primary
          type="button"
          className={`${extraClass}`}
          data-toggle="modal"
          data-target={`#${id}`}
          onClick={onClick}
        >
          {buttonTitle}
        </button>
      )}
      <div className="modal fade" id={id} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className={`modal-dialog ${maxwidth}`} role="document">
          <div className="modal-content">
            <div className="modal-header" style={extraStyle ? extraStyle : null}>
              <h3 className="modal-title" id="exampleModalLabel">
                {title}
              </h3>
              <button type="button" className="close "  style={{color:'#fff',margin:'-30px 12px'}} data-dismiss="modal" aria-label="Close"> &times;
                {/* <span   style={{color:'#fff',marginBottom:0,marginTop:0}} aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <div className="modal-body">{bodyText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
