import React from 'react';
import UserRoutes from './User.Routes';
import AdminRoutes from './Admin.Routes';
import HrRoutes from './Hr.Routes';
import LoginRoutes from './Login.Routes';

const IndexRoutes = () => {
  return (
    <React.Fragment>
      <LoginRoutes />
      <AdminRoutes />
      <HrRoutes />
      <UserRoutes />
    </React.Fragment>
  );
};

export default IndexRoutes;
