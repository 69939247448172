import React, { Component } from 'react';
import { FormMiddleWare, SingleSelect } from '../../../components/Form';
import MappingMiddleware from '../../../middleWare/MappingMiddleware';
import WizardPrograss from '../Master/wizardPrograssbar';
import WizardNextPreviousButton from '../Master/wizardNextPreviousButton';
import ValidationView from '../../../components/Form/ValidationView';


class CompFormOne extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      financialType: null,
      jobRoleOptions: [],
      jobRoleSelected: null,
      jobRoleId: null,
      jobRoleLabelName: null,
      financialYearOptions: [],
      financialYearSelected: null,
      financialYearId: null,
      financialYearName: null,
      workLevelOptionsBefore: [],
      workLevelOptions: [],
      workLevelSelected: null,
      workLevelId: null,
      workLevelLabelName: null,
      departmentOptions: [],
      departmentSelected: null,
      departmentId: null,
      departmentLabelName: null,
      functionOptions: [],
      functionSelected: null,
      functionId: null,
      functionLabelName: null,
      errorfinancialYearSelected:null,
      errorworkLevelSelected:null,
      errorjobRoleSelected:null,
      errorfinancialType:null,
      groupCompanyOptions: [],
      groupCompanySelected: null,
      errorGroupCompanySelected: null,

    };
  }

  onSubmit = () => {
    const {
      jobRoleId,
      jobRoleLabelName,
      workLevelId,
      workLevelLabelName,
      financialYearId,
      financialType,
      departmentLabelName,
      functionLabelName,
      groupCompanyId
    } = this.state;
    if (this.validate(financialType, 'errorfinancialType', 'Please Select FinancialType')) return true;
    if (this.validate(groupCompanyId, 'errorGroupCompanySelected', 'Please Select GroupCompany')) return true;
    if (this.validate(financialYearId, 'errorfinancialYearSelected', 'Please Select FinancialYear')) return true;
    if (this.validate(workLevelId, 'errorworkLevelSelected', 'Please Select Worklevel')) return true;
    if (this.validate(jobRoleId, 'errorjobRoleSelected', 'Please Select Jobrole')) return true;

    let type = financialType === 'Behavioral' ? 1 : 2;
    localStorage.setItem('jobRoleId', jobRoleId);
    localStorage.setItem('jobRoleName', jobRoleLabelName);
    localStorage.setItem('workLevelId', workLevelId);
    localStorage.setItem('workLevelName', workLevelLabelName);
    localStorage.setItem('financialYearId', financialYearId);
    localStorage.setItem('departmentName', departmentLabelName);
    localStorage.setItem('functionName', functionLabelName);
    localStorage.setItem('groupcompId', groupCompanyId);
    localStorage.setItem('type', type);
    window.location = '/admin/CompetencyMatrix/CompFormTwo';
  };

  async componentDidMount() {
    const adminId = await localStorage.getItem('userId');
    this.setState({ adminId });
    this.getSelectBoxMaster(
      adminId,
      'tbl_jobRole',
      'id',
      'jobRoleName',
      'jobRoleOptions'
    );
    // this.getSelectBoxMaster(
    //   adminId,
    //   'tbl_financial_years',
    //   'id',
    //   'to_year',
    //   'financialYearOptions'
    // );
    this.getSelectBoxMaster(
      adminId,
      'tbl_department',
      'id',
      'department',
      'departmentOptions'
    );
    this.getSelectBoxMaster(
      adminId,
      'tbl_worklevel',
      'id',
      'worklevel',
      'workLevelOptionsBefore'
    );
    // this.getSelectBoxMaster(
    //   adminId,
    //   "tbl_worklevel",
    //   "id",
    //   "worklevel",
    //   "workLevelOptions"
    // );
    let { data: financialYearOptions } = await MappingMiddleware.getYear();
    this.setState({financialYearOptions})
    const {
      data: groupCompanyOptions
    } = await MappingMiddleware.getGroupCompanyForSelect(adminId);
    if (groupCompanyOptions) {
      this.setState({ groupCompanyOptions });
    }
  }

  handleFinancialType = e => {
    const { adminId } = this.state;
    if (e.target.name === 'financialType') {
      // if (e.target.value === 'Behavioral') {
        this.getSelectBoxMaster(
          adminId,
          'tbl_worklevel',
          'id',
          'worklevel',
          'workLevelOptions'
        );
      // }
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  departmentSelect = async selectedOption => {
    const { adminId } = this.state;
    const departmentId = selectedOption.value;
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentLabelName: selectedOption.label
    });
    try {
      const {
        data: functionOptions
      } = await MappingMiddleware.getMapDepartmentToFunctionData(
        adminId,
        departmentId
      );
      if (functionOptions) {
        this.setState({
          functionOptions,
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  functionSelect = async selectedOption => {
    const { adminId, departmentId, workLevelOptionsBefore } = this.state;
    const functionId = selectedOption.value;
    this.setState({
      functionSelected: selectedOption,
      functionId: selectedOption.value,
      functionLabelName: selectedOption.label
    });
    // try {
    //   const {
    //     data: workLevelOptions
    //   } = await MappingMiddleware.getMapDepartmentToWorklevelData(
    //     adminId,
    //     departmentId,
    //     functionId
    //   );

    //   if (workLevelOptions) {
    //     this.setState({ workLevelOptions });
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  render() {
    const {
      financialType,
      jobRoleOptions,
      jobRoleSelected,
      financialYearOptions,
      financialYearSelected,
      workLevelOptions,
      workLevelSelected,
      departmentOptions,
      departmentSelected,
      functionOptions,
      functionSelected,
      errorfinancialYearSelected,
      errorworkLevelSelected,
      errorjobRoleSelected,
      errorfinancialType,
      groupCompanyOptions,
      groupCompanySelected,
      errorGroupCompanySelected
    } = this.state;
    return (
      <div>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Create Competency Matrix : Step 1</h3>
              <WizardPrograss step={16} />

              <div className="box-tools pull-right">
                <button
                  className="btn btn-box-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="box-body">
              <div class="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Financial Type</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="radio"
                    name="financialType"
                    onChange={this.handleFinancialType}
                    value="Behavioral"
                    checked={financialType === 'Behavioral' && true}
                  />
                  Behavioural&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    name="financialType"
                    onChange={this.handleFinancialType}
                    value="Technical"
                    checked={financialType === 'Technical' && true}
                  />
                  Technical
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorfinancialType} />
              <div className="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="competency">GroupCompany</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.groupCompanySelect}
                    options={groupCompanyOptions}
                    selectedService={groupCompanySelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorGroupCompanySelected} />
              <div class="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Financial Year</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.financialYearSelect}
                    options={financialYearOptions}
                    selectedService={financialYearSelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorfinancialYearSelected} />

              {/* {financialType === 'Technical' && (
                <div>
                  <div class="row form-group ">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Department</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect
                        handleChange={this.departmentSelect}
                        options={departmentOptions}
                        selectedService={departmentSelected}
                      />
                    </div>
                    <div className="col-sm-3" />
                  </div>
                  <div class="row form-group ">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Function</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect
                        handleChange={this.functionSelect}
                        options={functionOptions}
                        selectedService={functionSelected}
                      />
                    </div>
                    <div className="col-sm-3" />
                  </div>
                </div>
              )} */}
              {financialType && (
                <div>
                <div class="row form-group ">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">Work Level</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      handleChange={this.workLevelSelectsingal}
                      options={workLevelOptions}
                      selectedService={workLevelSelected}
                    />
                  </div>
                  <div className="col-sm-3" />
                </div>
                <ValidationView errorname={errorworkLevelSelected} />
              </div>

              )}
              <div class="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Job Role</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    handleChange={this.jobRoleSelect}
                    options={jobRoleOptions}
                    selectedService={jobRoleSelected}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <ValidationView errorname={errorjobRoleSelected} />

              <div class="row form-group ">
                <div className="col-sm-2" />
                <div className="col-sm-2"></div>
                <div className="col-sm-5">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.onSubmit}
                  >
                    Submit & Proceed To Step 2
                  </button>
                </div>
                <div className="col-sm-3" />
                <WizardNextPreviousButton step={16} nextPage={"Mapping/MapEmployee"} previousPage={"Mapping/MapCompetencyLevel"} />

              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CompFormOne;
