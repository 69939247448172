import React, { Component } from "react";
import $ from 'jquery';
import ScriptTag from "react-script-tag";
import overlayImg from "./N333333-1.png";
import CmsContent from "../../middleWare/HrMiddleWare";
import socket from "../../../src/middleWare/socketMiddleWare";
import SuperAdmin from "../../middleWare/HrMiddleWare";
// import "../../screens/Coromandel/style.css"
// import "./style.css";
import "./videoStyle.css";
import http from "../../../src/middleWare/httpMiddleWare";
import moment from "moment";
import renderHTML from "react-render-html";
import { FILEPATH } from "../../config";
const videoScript = "/assets/video-player.js";

var timeTracking = {
  watchedTime: 0,
  currentTime: 0
}
class NewVideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: "",
      openModal: false,
      videoPause: true,
      videoloader: true,
      videoError: false,
      disabled: false,
      video: null,
      QuizComplete1: false,
      videoUrl: null,
      Selectall: false,
      currentTimeArray: null,
      overlayListArray: [],
      jsonQuizListArray: [],
      overlayContinue: false,
      correctAnswer: [],
      userAnswer: [],
      multipleAnswer: [],
      quizId: null,
      quizAttended: false,
      quizComplete: false,
      startindex: 0,
      resumeVideo: false,
      currentQuizId: 0,
      newCurrentTimeArray: [],
      viewBanner: false,
      pageName: null,
      prepost: 0,
      isPrePost: 0,
      preDuration: 0,
      postDuration: 0,
      showAnswers: true,
      insertcheckvalue: 1,
      PageId: '',
      // retake: false,
      // vidControl: true,
      vidButton: false,
      Score: 0,
      completedOverlay: false,
      tempRetake: true
    };
  }

  async componentWillReceiveProps(nextProps) {
    //await this.getContent(nextProps);
    await this.getContent(nextProps.Video);
  }
  componentWillUnmount() {
    this.addVideoCounts()
    clearInterval(this.invertval)
  }
  async componentDidMount() {
    // this.player.seekTo(parseFloat(60.663194));
    let userDetails = await localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    localStorage.setItem("userId", userDetails.id);
    localStorage.setItem("userName", userDetails.user_name);
    // localStorage.removeItem("completed");
    this.setState({
      userId: userDetails.id,
      userName: userDetails.user_name
    });
    await this.getContent(this.props.Video);
    // await this.insertUserlog()
    this.invertval = setInterval(() => {
      const { currentTimeArray, pageList } = this.state;
      if (this.ref) {
        this.setState({ video: this.ref });
        // {
        //   this.props.resume &&
        //     this.props.resume(
        //       this.state.video.duration,
        //       this.state.video.currentTime
        //     );
        // }

        let currentVideoDuration = this.formatTime(this.state.video.duration);
        let currentVideoTime = this.formatTime(this.state.video.currentTime);
        this.setState({
          currentVideoTime,
          currentVideoDuration
        });
        let checkResume = localStorage.getItem(
          "videoId_" + this.state.pageList.id
        );
        if (
          !checkResume ||
          parseInt(checkResume) === 0 ||
          this.state.video.currentTime > 0
        ) {
          if (
            Math.floor(this.state.video.currentTime) ===
            Math.floor(this.state.video.duration)
          ) {
            // localStorage.setItem("videoId_" + pageList.id, 0); 
            localStorage.setItem("max", Math.floor(this.state.video.currentTime));
            this.completedOverlay()
          } else {
            // console.log(this.state.video.currentTime, "CURRENT", this.state.video.duration)
            localStorage.setItem("max", Math.ceil(this.state.video.currentTime));
            localStorage.setItem("total", Math.floor(this.state.video.duration));
            this.cnt = 0
            // localStorage.setItem(`videoId_${pageList.id}`,this.state.video.currentTime);
          }
        } else if (this.state.video.currentTime === 0) {
          // localStorage.setItem("videoId_" + pageList.id, 0);
        }
        if (currentTimeArray.includes(parseInt(this.state.video.currentTime))) {
          // console.log("enter pausePlay before");
          this.pausePlay();
        }

        const { id: videoId } = this.state.pageList;
        let userId = this.state.userId;
        let userName = this.state.userName;

        this.state.video.onseeking = async () => {
          const formData = {};
          formData.videoId = videoId;
          formData.seekSec = Math.floor(this.state.video.currentTime);
          formData.userId = userId;
        //   formData.customerId = userDetails.customerId
          // await SuperAdmin.createMasterValue(`tbl_seek_log`, formData);
        };

        // socket.emit("connection");
        // socket.emit("timestamp", {
        //   cId: JSON.parse(localStorage.getItem("userDetails")).customerId,
        //   timestamp: Math.floor(this.state.video.currentTime),
        //   userId,
        //   userName,
        //   videoId,
        //   pageName: this.state.pageName
        // });
        // socket.on("responseTimestamp", data => {});
      }
    }, 1000);
  }
  removeDuplicates = (a, key) => {
    let seen = new Set();
    return a.filter(item => {
      let k = key(item);
      return seen.has(k) ? false : seen.add(k);
    });

  };
  async getContent(videoid) {
    // console.log(videoid,"videoid--0");
    let { retake, vidControl } = this.state;
    //let themeid = this.props.Video;
    this.setState({ PageId: videoid, insertcheckvalue: 1 })
    let correctAnswer = []
    let themeid = videoid;

    let quizJson = [];
    let videoOverlay = null;
    let videoOverlayArray = [];
    let overlayTimeSetArray = null;
    const result = await CmsContent.getVideoContentById(themeid);
    // debugger;
    let pageName = result.data[0].name;
    if (result) {
      this.setState({ pageName });
      overlayTimeSetArray = [];
      if (result.data[0].videoOverlay) {
        videoOverlay = JSON.parse(result.data[0].videoOverlay);
        if (!Array.isArray(videoOverlay)) {
          videoOverlayArray.push(videoOverlay);
          videoOverlay = videoOverlayArray;
        }
        videoOverlay.map(async (jsonContent, index) => {
          if (jsonContent) {
            if (jsonContent.quizId) {
              let isPrePost = this.state.isPrePost + 1;
              let preDuration =
                isPrePost === 1 ? jsonContent.duration : this.state.preDuration;
              let postDuration =
                isPrePost === 2
                  ? jsonContent.duration
                  : this.state.postDuration;
              this.setState({
                quizId: JSON.parse(jsonContent.quizId),
                isPrePost,
                preDuration,
                postDuration
              });
              let userId = this.state.userId;
              let quizId = JSON.parse(jsonContent.quizId);
              try {
                let { data: quizAttended1 } = await CmsContent.CompQuizAttended(quizId, userId);
                jsonContent.quizAttended = quizAttended1.isAttended;


                quizJson.push(quizAttended1.quiz);
                correctAnswer.push(quizAttended1.quiz);

                if (quizAttended1.Userdata && (jsonContent.isCertified && jsonContent.isCertified == true || jsonContent.isCertified == 'true')) {
                  // this.setState({ Score: quizAttended1.Userdata.score, TotalQuestion: quizAttended1.Userdata.total })
                  jsonContent.Score = quizAttended1.Userdata.score;
                  jsonContent.TotalQuestion = quizAttended1.Userdata.total
                  if (quizAttended1.quizPercent >= 100) {
                    retake = false
                    vidControl = true
                  }
                  else if (quizAttended1.quizPercent >= 50 && quizAttended1.quizPercent < 100) {
                    retake = true
                    vidControl = true
                  } else if (quizAttended1.quizPercent < 50) {
                    retake = false
                    vidControl = false;
                  }
                } else if ((jsonContent.isCertified && jsonContent.isCertified == true || jsonContent.isCertified == 'true')) {
                  retake = false
                  vidControl = false //with quiz
                }
                this.setState({ retake, vidControl })
              } catch (error) {
                console.log(error);
              }
            } else {
              jsonContent.quizAttended = false
            }

            if (!jsonContent.quizAttended) {
              overlayTimeSetArray.push(parseInt(jsonContent.duration));
            }
          }
        });
        // if (result.data[0].quizList) {
        //   let quizList = result.data[0].quizList;
        //   quizList.map((list, index) => {
        //     // quizJson.push(JSON.parse(list));
        //     let correctJson = {};
        //     // correctJson.index = index;
        //     // correctJson.answer = list.answer;
        //     // correctJson.type = list.type;
        //     correctAnswer.push(JSON.parse(list));
        //   });
        // }
        // if(videoOverlay.length ==0){          
        //     console.log('qithout Quiz');
        //    retake = false
        //     vidControl = true //without quiz video seekbar unblock        
        // }

      } else {
        overlayTimeSetArray = [];
        videoOverlay = [];
        quizJson = [];
      }
      // console.log(this.props.playOn);
      // if (this.props.playOn) {
      //   if (Math.floor(this.props.playOn.totaltime) != this.props.playOn.maxtime) {
      //     if (this.props.playOn.maxtime > 0) {
      //       this.setState({ resumeVideo: true, resumeTime: this.props.playOn.maxtime })
      //     }
      //   }
      // }
      if (this.props.maxtime) {
        if (this.props.maxtime > 0) {
          this.setState({ resumeVideo: true, resumeTime: this.props.maxtime })
        }
      }
      // let checkResume = await localStorage.getItem(
      //   "videoId_" + result.data[0].id
      // );
      console.log({ overlayTimeSetArray, videoOverlay, quizJson, correctAnswer });
      this.setState({
        pageList: result.data[0],
        // videoUrl: `${result.data[0].file}#t=${checkResume}`,
        videoUrl: `${FILEPATH}` + result.data[0].filename,
        previousUrl: result.data[0].file,
        currentTimeArray: overlayTimeSetArray,
        overlayListArray: videoOverlay,
        jsonQuizListArray: quizJson,
        correctAnswer,
        retake, vidControl
      });
    }
    this.setState({ currentQuizId: 0 });
  }




  xButton = () => {
    const closeButton = document.querySelector("#closeButton");
    const banner = document.querySelector("#banner");
    closeButton.addEventListener("click", e => {
      banner.style = "display:none";
      const video = this.state.video;
      video.play();
      this.changeButtonState(video, "playpause");
    });
    this.setState({ vidButton: false })
  }
  formatTime = time => {
    if (!time) {
      return "00:00:00";
    }
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  pausePlay = async () => {
    // const video = this.state.video;
    // const banner = document.querySelector("#banner");
    // banner.style = "display:block";
    // banner.style = "width:60%";
    // banner.style = "height:80%";
   
    const video = this.state.video;
    const banner = document.querySelector("#banner");
    banner.style = "display:block";
    banner.style = "width:60%";
    banner.style = "height:80%";
    banner.style = "top:6%";
    video.pause();
    this.changeButtonState(video, "playpause");
    // }
  };
  pauseFor = async () => {
    const video = this.state.video;;
    video.pause();
    const playpause = document.querySelector("#playpause");
    playpause.setAttribute("data-state", "pause");
  };
  changeButtonState = (video, type) => {
    const playpause = document.querySelector("#playpause");
    if (type === "playpause") {
      if (video.paused || video.ended) {
        playpause.setAttribute("data-state", "play");
      } else {
        playpause.setAttribute("data-state", "pause");
      }
    }
  };

  toContinueOrNot = (value, quizId = 0) => {
    console.log(quizId);
    this.setState({ quizId });
    if (value === "yes") {
      this.setState({ overlayContinue: true });
    } else {
      const { video } = this.state;
      video.currentTime = Math.ceil(this.state.video.currentTime);
      const banner = document.querySelector("#banner");
      banner.style = "display:none";
      this.setState({ video });
      video.play();
      this.changeButtonState(video, "playpause");
      this.setState({ vidButton: false })
    }

  };

  quizAnswered = async (e, type, index, questionId = null) => {
    const { userAnswer, multipleAnswer } = this.state;
    let value = e.target.value;
    if (type === "single") {
      if (typeof userAnswer[index] === "undefined") {
        // console.log(value + '-->' + type);
        // console.log(userAnswer[index]);
        let answerJson = {};
        answerJson.index = index;
        answerJson.answer = value;
        answerJson.type = type;
        answerJson.questionId = questionId;
        userAnswer.push(answerJson);
        this.setState({ userAnswer });
      } else {
        let answerJson = userAnswer[index];
        answerJson.answer = value;
      }
    } else {
      let result = null;
      let answerJson = {};
      if (userAnswer[index]) {
        if (userAnswer[index]["answer"].includes(value)) {
          result = userAnswer[index]["answer"].filter(
            answer => answer !== value
          );
          answerJson.index = index;
          answerJson.answer = result;
          answerJson.type = type;
          answerJson.questionId = questionId;
          const previousData = [...this.state.userAnswer];
          const newAnswer = previousData.filter(value => value.index !== index);
          newAnswer.splice(index, 0, answerJson);
          this.setState({ userAnswer: newAnswer });
        } else {
          userAnswer[index]["answer"].push(value);
        }
      } else {
        let answerJsonArray = [];
        answerJsonArray.push(value);
        answerJson.index = index;
        answerJson.answer = answerJsonArray;
        answerJson.type = type;
        answerJson.questionId = questionId;
        userAnswer.push(answerJson);
        this.setState({ userAnswer });
      }
    }
  };
  changeQuizIndex = value => {
    // const { currentTimeArray, video } = this.state;
    // const newCurrentTimeArray = currentTimeArray.filter(videoIds => parseInt(videoIds) !== parseInt(Math.floor(video.currentTime)));

    // let newValue = this.state.currentQuizId + value;
    // this.setState({ currentQuizId: newValue, currentTimeArray: newCurrentTimeArray });
    this.toContinueOrNot("no");
  };

  submitQuizValue = async (value, duration, index2) => {
    this.setState({ vidButton: false })
    if (!(this.state.userAnswer[index2] && this.state.userAnswer[index2].answer && this.state.userAnswer[index2].answer.length)) {
      await this.setState({ Selectall: true });
      return true
    } else {
      this.setState({ Selectall: false })
    }
    const {
      userAnswer,
      correctAnswer,
      quizId,
      currentQuizId,
      currentTimeArray,
      video,
      jsonQuizListArray
    } = this.state;
    let currentNewQuizId = currentQuizId + duration;
    let question = 0;
    let count = 0;
    this.setState({ disabled: true })
    // let val = this.state.userAnswer[index] ? this.state.userAnswer[index].answer : this.state.userAnswer[index]

    let w = await correctAnswer.map((crctAnswerList, key) => {
      if (crctAnswerList.length > 0 && parseInt(crctAnswerList[0].quizId) == quizId) {
        crctAnswerList.map(async (crctAns, index) => {
          await userAnswer.map(async (userAns) => {
            let total = 0;
            if (
              userAns.type === "single" &&
              // parseInt(userAns.index) === index
              parseInt(userAns.questionId) === crctAns.questionId
            ) {
              if (
                // index === parseInt(userAns.index) &&
                parseInt(crctAns.questionId) === parseInt(userAns.questionId) &&
                crctAns.answer.toLowerCase() === userAns.answer.toLowerCase()
              ) {
                count += 1
                question += 1;
              }
            } else if (
              userAns.type === "multiple" &&
              // parseInt(userAns.index) === index
              parseInt(userAns.questionId) == parseInt(crctAns.questionId)
            ) {
              if (
                crctAns.answer &&
                crctAns.answer.split(",").length == userAns.answer.length
              ) {
                await crctAns.answer.split(",").map(async (orgAns) => {
                  await userAns.answer.map(ans => {
                    if (orgAns == ans) {
                      total += 1;
                    }
                  });
                });
                if (crctAns.answer.split(",").length == total) {
                  count += 1;
                }
              } else {
                // console.log("gone else");
                count += 0;
              }
            }
          });
        });
      }
    });
    await Promise.all(w)
    let prepost = this.state.prepost;
    try {
      let video = this.state.video;
      if (prepost) {
        let arr = {};
        arr.posttotal = count;
        arr.postanswer = JSON.stringify(userAnswer);
        arr.prepost = prepost;
        let postAnswerUpdate = await CmsContent.updateMaster(
          "comp_tbl_quizAnswer",
          this.state.rowId,
          arr
        );

        if (postAnswerUpdate) {
          let newCurrentTimeArray = currentTimeArray.filter(
            videoIds =>
              parseInt(videoIds) !== parseInt(Math.floor(video.currentTime))
          );
          this.setState({
            startindex: 0,
            quizComplete: true,
            overlayContinue: false,
            currentQuizId: currentNewQuizId,
            newCurrentTimeArray,
            userAnswer: [],
            prepost: prepost + 1
          });
        }
      } else {
        let quizAnswerJson = {};
        quizAnswerJson.userId = this.state.userId;
        quizAnswerJson.portid = this.state.PageId;
        quizAnswerJson.userName = this.state.userName;
        quizAnswerJson.quizId = quizId;
        quizAnswerJson.total = count;
        quizAnswerJson.totalQue = userAnswer.length;
        quizAnswerJson.quizanswer = JSON.stringify(userAnswer);
        quizAnswerJson.prepost = prepost;
        quizAnswerJson.serviceid = 8;

        // const answerUpdate = await CmsContent.updateQuizAnswer(
        //   quizAnswerJson,
        //   "comp_tbl_quizAnswer"
        // );
        const answerUpdate = await CmsContent.insertQuizAnswer(
          quizAnswerJson,
          "comp_tbl_quizAnswer"
        );
      
        if (answerUpdate) {
          // console.log(answerUpdate, 'answerUpdate');
          // let newCurrentTimeArray = currentTimeArray.filter(
          //   videoIds =>
          //     parseInt(videoIds) !== parseInt(Math.floor(video.currentTime))
          // );
          // let TotalQuestion = this.state.TotalQuestion ? this.state.TotalQuestion : userAnswer.length;
          // let Score = this.state.Score + count
          let { data: quizAttended1 } = await CmsContent.CompQuizAttended(quizId, this.state.userId, 'true', this.state.PageId);
          // if (quizAttended1.quiz.length > 0) {
          //   let ar = correctAnswer.map((ival) => {
          //     if (ival[0].quizId == 170) {
          //       ival = []
          //     }
          //     return ival
          //   })
          //   ar.push(quizAttended1.quiz);
          //   // correctAnswer.parseInt(crctAnswerList[0].quizId) == quizId
          //   // correctAnswer.push(quizAttended1.quiz);
          //   this.setState({ correctAnswer: ar })
          // }
          if (quizAttended1.isAttended) {
            let newCurrentTimeArray = currentTimeArray.filter(
              videoIds =>
                parseInt(videoIds) !== parseInt(Math.floor(video.currentTime))
            );
            this.setState({ QuizComplete1: true, newCurrentTimeArray })
          }

          let { retake, vidControl, overlayListArray } = this.state;
          if (quizAttended1.Userdata) {
            overlayListArray.map(async (jsonContent, index) => {
              if (jsonContent) {
                if (jsonContent.quizId == quizId) {
                  jsonContent.Score = quizAttended1.Userdata.score;
                  jsonContent.TotalQuestion = quizAttended1.Userdata.total
                }
                // console.log(jsonContent);

                if ((jsonContent.isCertified && jsonContent.isCertified == true || jsonContent.isCertified == 'true')) {
                  if (quizAttended1.quizPercent >= 50 && quizAttended1.quizPercent < 100) {
                    retake = true
                    // vidControl = true
                  } else if (quizAttended1.quizPercent < 50) {
                    retake = false
                    // vidControl = false
                  } else {
                    retake = false
                  }
                }
              }
            })

          } else {
            // retake = false
            // vidControl = false
          }

          this.setState({ retake, overlayListArray })

          this.setState({
            startindex: 0,
            QuizComplete1: true,
            // Score,
            // TotalQuestion,
            // overlayContinue: false,
            currentQuizId: currentNewQuizId,
            userAnswer: [],
            // newCurrentTimeArray,

            // prepost: 1,
            // rowId: answerUpdate.data.insertId
          });

          this.setState({ QuizComplete1: true, disabled: false })
          // if (TotalQuestion == Score) this.setState({ retake: false })
          // await this.getContent(this.state.PageId)
        }
        this.setState({ disabled: false })
      }
    } catch (error) {
      this.setState({ disabled: false })
      console.log(error);
    }
  };
  Quizretake = async (overlay) => {
    this.setState({ vidButton: false, prepost: 0, Score: 0, QuizComplete1: false })
    let { video } = this.state;
    await this.videoContinueNext()
    await this.getContent(this.state.PageId)
    // await this.retake()
    video.currentTime = video.currentTime - 1;
    this.setState({ video, overlayContinue: false, vidButton: true });
  }
  ViewCertificate = d => {
    // console.log(d, 'd');
    let attendedOn = new Date()
    window.open(`${window.location.origin}/vidya/Certificate/${btoa(d.overlayPreamble)}/${btoa(this.state.userName)}/${btoa(attendedOn)}/${btoa(d.Score * 100 / d.TotalQuestion)}/${btoa(d.Score * 100 / d.totalQue)}`);
  }

  videoContinueNext = () => {
    const { video, currentTimeArray } = this.state;
    let newCurrentTimeArray = currentTimeArray.filter(
      videoIds =>
        parseInt(videoIds) !== parseInt(Math.floor(video.currentTime))
    );

    this.setState({
      quizComplete: false,
      QuizComplete1: false,
      overlayContinue: false,
      currentTimeArray: newCurrentTimeArray
    });

    const banner = document.querySelector("#banner");
    banner.style = "display:none";
    video.currentTime = Math.ceil(this.state.video.currentTime);
    this.setState({ video, vidButton: false });

    video.play();
    this.changeButtonState(video, "playpause");
  };

  moveForwardOrBackward = value => {
    const { video } = this.state;
    const banner = document.querySelector("#banner");
    banner.style = "display:none";
    if (value === "forward") {
      video.currentTime = Math.ceil(this.state.video.currentTime + 10);
      if (video.currentTime >= video.duration) {
        video.currentTime = video.duration;
        this.setState({ video });
        video.pause();
      } else {
        this.setState({ video, startindex: 0, userAnswer: [], overlayContinue: false });
        video.play();
      }
    } else {
      if (video.currentTime > 10) {
        video.currentTime = Math.ceil(this.state.video.currentTime - 10);
      } else {
        video.currentTime = 0;
      }
      this.setState({ video, startindex: 0, userAnswer: [], overlayContinue: false });
      video.play();
    }
  };

  changeIndexValue = (index) => {
    // let val = this.state.userAnswer[index] ? this.state.userAnswer[index].answer : this.state.userAnswer[index]
    if (this.state.userAnswer[index] && this.state.userAnswer[index].answer && this.state.userAnswer[index].answer.length) {
      index += 1;
      this.setState({ startindex: index, Selectall: false });
    } else {
      this.setState({ Selectall: true });
    }

  };

  viewBanner = val => {
    if (val === "yes") {
      this.setState({ viewBanner: true });
    }
    this.setState({ vidButton: false });
  };
  onDuration = duration => {

    this.setState({ duration });
  };
  onProgress = async state => {
    // if (this.state.duration === state.playedSeconds) {
    //   localStorage.setItem('videoId_' + this.state.videoId + '_' + this.state.videoName, 0);
    // } else {
    //   localStorage.setItem('videoId_' + this.state.videoId + '_' + this.state.videoName, state.playedSeconds);
    // }
    // if (!this.state.seeking) {
    //   this.setState(state);
    // }
    // const { videoId } = this.state;
    // let userId = localStorage.getItem('userId');
    // let userName = localStorage.getItem('userName');
    // socket.emit('connection');
    // socket.emit('timestamp', { timestamp: state.playedSeconds, userId, userName, videoId });
  };

  videoinsert = async (insertcheckvalue) => {
    // if(this.state.insertcheckvalue==1)
    // {
    //   let ipaddressget= await http.get('https://ipapi.co/json/',function(data) {
    //   console.log(JSON.stringify(data, null, 2));
    // })
    //  console.log(ipaddressget.data.ip)

    //  const date = new Date();
    //  var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    //  var day = date.getUTCDate();
    //  var year = date.getUTCFullYear();
    //  var hour = date.getHours();
    //  var minutes = date.getMinutes();
    //  var seconds = date.getSeconds();

    //  var newdate = year + "-" + month + "-" + day;
    //  var timesDate =  year +"-" +month + "-" +day +" " + hour +":" + minutes +":" +seconds+'';
    //   const userlog=new FormData;
    //   userlog.append('userid',JSON.parse(localStorage.getItem("userlog")).id)
    //   userlog.append('ipaddress',ipaddressget.data.ip)
    //   userlog.append('datetime',timesDate)
    //   userlog.append('play',this.state.PageId)
    //   userlog.append('date',newdate)
    //   userlog.append('customerId',JSON.parse(localStorage.getItem("userlog")).customerId)
    //   let datauser=await CmsContent.addMaster('tbl_CMSuserAccessLog',userlog);
    //   this.setState({insertcheckvalue :2})
    //   console.log(this.state.insertcheckvalue)
    // }
  }
  Resume = () => {
    const {
      video,
      resumeTime
    } = this.state;
    // console.log(resumeTime, this.props.playOn, ' resumeTime');
    if (resumeTime) {
      timeTracking.watchedTime = resumeTime
      video.currentTime = resumeTime
      this.setState({ video });
      video.play()
    }
  }
  retake = async () => {
    const {
      prepost,
      videoUrl,
      overlayListArray,
      video,
      currentTimeArray,
      jsonQuizListArray,
      overlayContinue,
      quizComplete,
      currentVideoDuration,
      currentVideoTime,
      currentQuizId,
      quizAttended
    } = this.state;
    await this.getContent(this.state.PageId)
    overlayListArray.map((overlay, index) => {
      if (parseInt(overlay.duration)) {
        if (
          (!overlay.quizAttended) &&
          overlay.overlayType === "Quiz" && (overlay.isCertified && overlay.isCertified == true)
          // &&
          // !this.state.rowId &&
          // this.state.prepost === 0 &&
          // Math.floor(this.state.video.currentTime) ===
          // Number(this.state.postDuration)
        ) {
          // video.currentTime = this.state.preDuration;  
          let isAval = currentTimeArray.filter(a => a == overlay.duration)
          if (isAval.length == 0) { currentTimeArray.push(parseInt(overlay.duration)) }
          // console.log(currentTimeArray, 'currentarr');
          video.currentTime = overlay.duration;
          this.setState({ video, overlayContinue: false, vidButton: true, currentTimeArray, QuizComplete1: false });
          // video.play();
        }
      }
    })
  }

  volume = () => {
    document.getElementById("volumeslider").oninput = function () {
      var value = (this.value - this.min) / (this.max - this.min) * 100
      this.style.background = 'linear-gradient(to right, #fda83a 0%, #fda83a ' + value + '%, #fff ' + value + '%, white 100%)'
    };
  }
  cnt = 0
  completedOverlay = () => {
    let { completedOverlay } = this.state;
    this.cnt += 1
    if (this.cnt == 1) {
      this.pauseFor();
      completedOverlay = (
        // <div class="containers" style={{ top: '26%' }}>

        // <div className="centered">
        <div //className="suces-new-center"
        >
          <div className="row form-group">
            <div className="col-lg-12">
              <h1 className="banner-start" style={{ marginTop: 10 }}>
                Video Successfully Completed
              </h1>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-lg-12">

              <button
                style={{ width: '16%' }}
                type="button"
                className="btn btn-success btn-sm btn-radius btn-widthsuc"
                onClick={() => { this.addVideoCounts() }}
              >
                Ok
              </button>


            </div>
          </div>
          <div className="row form-group"><div className="col-lg-12">
            <button
              type="button"
              className="btn btn-success btn-lg btn-radius "
              onClick={() => {

                // this.addVideoCounts()
                // window.location.reload()

                window.location.replace(`${window.location.origin}/hr/sugestedTraingProgram`)
              }}
            >
              View Report
            </button></div>
          </div>
          <div className="row form-group"><div className="col-lg-12">
            <button
              type="button"
              className="btn btn-success btn-lg btn-radius "
              onClick={() => {

                // this.addVideoCounts()
                // window.location.reload()
                window.location.replace(`${window.location.origin}/hr/sugestedTraingProgram`)

              }}
            >
              Course List
            </button>
          </div></div>
          <div className="row form-group"><div className="col-lg-12">
            {this.state.overlayListArray && this.state.overlayListArray.filter(e => ((e.Score && e.TotalQuestion) ? e.Score != e.TotalQuestion : true) && (e.overlayType === "Quiz") && (e.isCertified && e.isCertified == true || e.isCertified == 'true')).length > 0 ?
              < button
                type="button"
                className="btn btn-success btn-lg btn-radius "
                onClick={() => { this.setState({ completedOverlay: false }); this.retake() }}
              >
                View Quiz
              </button> : null}
          </div></div>

        </div>
        // </div>
        //  </div >
      )
      this.setState({ completedOverlay })
    }
  }
  insertUserlog = async () => {
    try {
      const ipaddressget = await http.get('https://geolocation-db.com/json/')
      let newdate = moment().format('YYYY-MM-DD');
      let timesDate = moment().format('YYYY-MM-DD HH:MM:SS');
      var userId = JSON.parse(localStorage.getItem("userDetails")).id;
      var themeid = localStorage.getItem("themeId");
      var startTime = localStorage.getItem("startTime");
      const userlog = new FormData();
      userlog.append("userid", userId);
      if (ipaddressget) {
        userlog.append("ipaddress", ipaddressget.data.IPv4);
      }
      userlog.append("datetime", timesDate);
      userlog.append("date", newdate);
      userlog.append("startTime", startTime);
      userlog.append("max_timeline", 0);
      userlog.append("pageid", themeid);
      userlog.append("customerId", JSON.parse(localStorage.getItem("userDetails")).customerId
      );
      // let datauser = await CmsContent.addMaster("tbl_CMSuserAccessLog", userlog);
    } catch (error) {
      console.log(error);
    }

  }
  addVideoCounts = async () => {
    try {
      const ipaddressget = await http.get('https://geolocation-db.com/json/')
      const date = new Date();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
      // var day = date.getUTCDate();
      // var year = date.getUTCFullYear();
      var day = date.getDate();
      var year = date.getFullYear();
      var hour = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      var newdate = year + "-" + month + "-" + day;
      var timesDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minutes +
        ":" +
        seconds +
        "";
      var userId = JSON.parse(localStorage.getItem("userDetails")).id;
      var max = Math.floor(this.state.video.currentTime);
      var total = Math.floor(this.state.video.duration);
      var startTime = localStorage.getItem("startTime");
      max = max ? max : 0;
      total = total ? total : this.state.video.duration;
      // console.log(max, total, 'max total');
      var themeid = localStorage.getItem("themeId");
      // themeid = themeid ? themeid : this.props.match.params.id;  
      // console.log(JSON.parse(localStorage.getItem("userDetails")).customerId, "cusromer");
      const userlog = new FormData();
      userlog.append("userid", userId);
      if (ipaddressget) {
        userlog.append("ipaddress", ipaddressget.data.IPv4);
      }
      userlog.append("datetime", timesDate);
      userlog.append("date", newdate);
      userlog.append("startTime", startTime);
      userlog.append("max_timeline", max);
      userlog.append("pageid", themeid);
      userlog.append("customerId", JSON.parse(localStorage.getItem("userDetails")).customerId
      );
      userlog.append("userType", "EMPLOYEE"
      );
      // let datauser = await CmsContent.addMaster("tbl_CMSuserAccessLog", userlog);

      let data = [];
      let Innerdata = {};
      Innerdata.userid = userId;
      Innerdata.maxtime = max;
      Innerdata.totaltime = total;
      Innerdata.pageid = themeid;
      Innerdata.userType = "EMPLOYEE"
      data.push(JSON.stringify(Innerdata));
      console.log(Innerdata,"Innerdata");
      const log = new FormData();
      log.append("fullList", data);
      console.log("full", data);
      await CmsContent.insertMaster("comp_videos_view_count", data);

      localStorage.setItem("startTime", timesDate);
      localStorage.setItem("max", 0);
      localStorage.setItem("completed", 1);
      this.setState({ completedOverlay: false })
      window.removeEventListener("beforeunload", this.props.onUnload);
    } catch (err) {
      console.log(err);
    }
  }
  render() {

    if (this.state.retake == false && this.state.vidControl == false) { //forward control
      let video = document.getElementById('video');
      var lastUpdated = 'currentTime';
      video && video.addEventListener('timeupdate', function () {
        if (!video.seeking) {
          if (video.currentTime > timeTracking.watchedTime) {
            timeTracking.watchedTime = video.currentTime;
            lastUpdated = 'watchedTime';
          }
          else {
            timeTracking.currentTime = video.currentTime;
            lastUpdated = 'currentTime';
          }
        }
      });
      video && video.addEventListener('seeking', function () {
        var delta = video.currentTime - timeTracking.watchedTime;
        if (delta > 0) {
          video.pause();
          video.currentTime = timeTracking[lastUpdated];
          video.play();
        }
      });
    }



    if (this.state.vidControl == true) { //seekbar control
      let video = document.getElementById('video');
      $("#progress").on("click", function (e) {
        var offset = $(this).offset();
        var left = (e.pageX - offset.left);
        var totalWidth = $("#progress").width();
        var percentage = (left / totalWidth);
        var vidTime = video.duration * percentage;
        video.currentTime = vidTime;

      });
    }



    const {
      prepost,
      videoUrl,
      overlayListArray,
      video,
      currentTimeArray,
      jsonQuizListArray,
      overlayContinue,
      quizComplete,
      startindex,
      currentVideoDuration,
      currentVideoTime,
      currentQuizId,
      quizId,
      userAnswer,
      viewBanner,
      showAnswers,
      quizAttended,
      QuizComplete1
    } = this.state;

    let wid = false;
    if (window.screen.width <= 768) {
      wid = true
    } else {
      wid = false
    }
    let overlayAppend = null;
    let quizQuestionAppend = [];

    jsonQuizListArray.map((quizList, key) => {

      if (quizList.length > 0 && parseInt(quizList[0].quizId) == quizId) {
        quizList.map((quizListNew, index) => {
          if (startindex === index) {
            if (quizList[index].type === "single") {
              quizQuestionAppend.push(
                <div className="card card-shadow"
                  // body-overflow
                  style={{ maxHeight: 296}}
                >
                  <div className="card-header bg-set" style={{padding:"20px"}}>
                    <div className="row">
                      <div className="col-sm-12">
                        <h3 style={{fontSize:"20px"}}/*className="question-align"*/>{`${index + 1}) ${quizList[index].question
                          }`}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{position:"relative",top:"3px"}}>
                    <div className="row">
                      <div class="pretty p-icon p-round p-pulse">
                        <input
                          type="radio"
                          name="radio"
                          value="yes"
                          checked={(userAnswer[index] && userAnswer[index].answer === 'yes') ? true : false}
                          onChange={e => this.quizAnswered(e, "single", index, quizList[index].questionId)}
                        />
                        <div class="state p-info">
                          <i class="icon mdi mdi-check" />
                          <label style={{right:"20px"}}>Yes</label>
                        </div>
                      </div>
                      <div class="pretty p-icon p-round p-pulse">
                        <input
                          type="radio"
                          name="radio"
                          value="no"
                          checked={(userAnswer[index] && userAnswer[index].answer === 'no') ? true : false}
                          onChange={e => this.quizAnswered(e, "single", index, quizList[index].questionId)}
                        />
                        <div class="state p-info">
                          <i class="icon mdi mdi-check" />
                          <label style={{right:"20px"}}>No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.Selectall &&
                    <p style={{ color: "#F44336" }}>Selecet Any option</p>
                  }
                </div>
              );
            } else {
              var checkedBoxValue = false;
              quizQuestionAppend.push(
                <div className="card card-shadow"
                  style={{
                    // maxHeight: 296,
                    // overflowY: 'scroll',
                    height: 284,
                    margin: 8
                  }}
                >
                  <div className="card-header bg-set">
                    <div className="row">
                      <div className="col-sm-12">
                        <h3 style={{ margin: '-20px -20px 2px -20px', fontSize: 12 }}
                        // className="question-align"
                        >{`${index + 1}) ${quizList[index].question
                          }`}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-body" style={{marginTop:"40px"}}>
                    {quizList[index].options.map((values, key) => (
                      <div>
                        {userAnswer[index] &&
                          userAnswer[index].answer.map(optionList => {
                            // console.log(userAnswer[index].answer, 'userindex')
                            if (
                              userAnswer[index].answer.includes(
                                `option${key + 1}`
                              )
                            ) {
                              checkedBoxValue = true;
                            } else {
                              checkedBoxValue = false;
                            }
                          })}
                        {/* <div className="row my-2">
                          <div class="pretty p-default p-curve p-thick p-smooth">
                            <input
                              type="checkbox"
                              value={`option${key + 1}`}
                              onChange={e =>
                                this.quizAnswered(e, "multiple", index)
                              }
                              checked={checkedBoxValue}
                            />
                            <div class="state p-info-o">
                              <label>{values}</label>
                            </div>
                          </div>
                        </div> */}
                        <div className="row form-group">
                          <div className="col-sm-1" />
                          <div className="col-sm-1">
                            <input
                              type="checkbox"
                              value={`option${key + 1}`}
                              onChange={e =>
                                this.quizAnswered(e, "multiple", index, quizList[index].questionId)
                              }
                              checked={checkedBoxValue}
                            />
                          </div>
                          <div className="col-sm-8">
                            <label htmlFor="exampleInputEmail1" style={{ fontSize: 11 }}>
                              {values}
                            </label>
                          </div>
                          <div className="col-sm-2" />
                        </div>
                        {/* <br /> */}

                      </div>
                    ))}
                  </div>
                  <r />
                  {this.state.Selectall &&
                    <p style={{ color: "#F44336" }}>Select Any option</p>
                  }
                </div>
              );
              // quizList[index].options.map((values, key) => (               
              //     userAnswer[index] &&
              //       userAnswer[index].answer.map(optionList => {
              //         if (
              //           userAnswer[index].answer.includes(
              //             `option${key + 1}`
              //           )
              //         ) {
              //           if (quizList.length - 1 != index) {
              //           this.changeIndexValue(index)
              //           }
              //         }
              //       })
              // ))

            }
            if (quizList.length - 1 === index) {
              // let val = this.state.userAnswer[index] ? this.state.userAnswer[index].answer : this.state.userAnswer[index]
              quizQuestionAppend.push(
                <button
                  type="button"
                  className="btn btn-success btn-sm btn-radius"
                  onClick={() => this.submitQuizValue("yes", 1, index)}
                  disabled={this.state.disabled}
                >
                  Submit
                </button>
              );
            } else {
              quizQuestionAppend.push(
                <button
                  type="button"
                  className="btn btn-success btn-sm btn-radius btnwidth"
                  onClick={() => this.changeIndexValue(index)}
                >
                  Next
                </button>
              );
              // quizQuestionAppend.splice(0, 0, <a
              //   // type="button"
              //   // className="btn btn-success btn-sm btn-radius btnwidth"
              //   onClick={() => this.changeIndexValue(index)}
              // >
              //   <i style={{ fontSize: 28 }} class="fa fa-arrow-circle-right"></i>
              // </a>);
            }
          }
        });
      }
    });

    if (
      video &&
      currentTimeArray.includes(parseInt(this.state.video.currentTime))
    ) {
      overlayListArray.map((overlay, index) => {
        console.log( overlay,"overlay");
        if (
          (!overlay.quizAttended) &&
          parseInt(this.state.video.currentTime) === parseInt(overlay.duration)
        ) {
          // if (
          //   overlay.overlayType === "Quiz" &&
          //   !this.state.rowId &&
          //   this.state.prepost === 0 &&
          //   Math.floor(this.state.video.currentTime) ===
          //   Number(this.state.postDuration)
          // ) {
          //   video.currentTime = this.state.preDuration;
          //   // this.setState({ video, overlayContinue: false, });
          //   // video.play();
          // }
          overlayAppend = (
            <div class="containers">
              <img
                src={overlayImg}
                alt="Snow"
                style={{
                  opacity: "0.9",
                  height: "300px",
                  width: "100%",
                  borderRadius: "15px"
                }}
              />
              <div className="bottom-left" />
              <div className="top-left" />
              <div className="top-right" />
              <div className="bottom-right" />
              <div className="centered" style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)", height: 300, width: '100%' }}>
                <div className="row form-group">
                  <div className="col-sm-12 overlay-mt">
                    <p className="banner-start">{overlay.overlayPreamble}</p>
                  </div>
                </div>

                {overlay.Instruction ? renderHTML(overlay.Instruction) : null}
                <br />
                {/* <div className="row form-group button-width"> */}
                <div className="row form-group">
                  <div className="col-sm-4" />
                  <div className="col-sm-4">
                    {overlay.overlayType === "Survey" ||
                      overlay.overlayType === "Load Url" ? (
                      <div>
                        <a
                          href={`${overlay.loadUrl}`}
                          target="_blank"
                          class="btn btn-success btn-sm btn-radius btnwidthNew"
                        // onClick={() => {
                        //   this.setState({ vidButton: false })
                        //   this.toContinueOrNot("no")
                        // }
                        // }
                        >
                          Link
                        </a>
                        <br /> <br /> <br />
                        <a
                          // href={`${overlay.loadUrl}`}
                          target="_blank"
                          class="btn btn-success btn-sm btn-radius btnwidthNew"
                          onClick={() => {
                            this.setState({ vidButton: false })
                            this.toContinueOrNot("no")
                            this.videoContinueNext()
                          }
                          }
                        >
                          OK
                        </a>
                      </div>
                    ) : overlay.overlayType === "Ad Banner" ? (
                      <button
                        type="button"
                        className="btn btn-success btn-sm btn-radius btn-newWidth"
                        onClick={() => { this.viewBanner("yes"); }}
                      // onClick={() => { this.toContinueOrNot("no") }}
                      >
                        OK
                      </button>
                    ) :
                      overlay.overlayType === "Instruction" ? (
                        <div>
                          <button
                            type="button"
                            className="btn btn-success btn-sm btn-radius btn-newWidth"
                            onClick={() => { this.videoContinueNext() }}
                          // onClick={() => { this.toContinueOrNot("no") }}
                          >
                            OK
                          </button>



                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success btn-sm btn-radius btn-newWidth"
                          onClick={() => {
                            // console.log(overlay);
                            this.toContinueOrNot("yes", overlay.quizId)
                          }
                          }
                        >
                          Continue
                        </button>
                      )}
                  </div>
                  <div className="col-sm-4" />
                  {/* <div className="col-sm-6">
                    {overlay.overlayType === "Survey" ||
                    overlay.overlayType === "Load Url" ? (
                      <button
                        type="button"
                        className="btn btn-success btn-sm btn-radius btn-newWidth"
                        onClick={() => this.toContinueOrNot("no")}
                      >
                        CANCEL
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success btn-sm btn-radius btn-newWidth"
                        onClick={() => this.changeQuizIndex(1)}
                      >
                        CANCEL
                      </button>
                    )}
                  </div> */}
                </div>
              </div>
              {/* <div id="closeButton">
                <i
                  class="fa fa-times-circle-o fa-2x banner-close"
                  aria-hidden="true"
                  onClick={() => { this.xButton(); this.toContinueOrNot("no"); }}
               onClick={() => this.videoContinueNext()} /> 
                />
              </div> */}
            </div>

          );

          if (viewBanner && overlay.overlayType === "Ad Banner") {
            overlayAppend = (
              <div
                className="card-body body-overflow"
                style={{ background: `${overlay.overlayColor.hex}`, objectFit: 'contain' }}
              >
                <a href={`${overlay.bannerTargetUrl}`} target="_blank">
                  <img src={overlay.bannerImage} alt="Snow" />
                </a>
                <div id="closeButton">
                  <i
                    class="fa fa-times-circle-o fa-2x banner-close"
                    aria-hidden="true"
                    onClick={() => {
                      this.xButton(); this.toContinueOrNot("no");
                      this.videoContinueNext()
                    }}
                  />
                </div>
              </div>
            );
          }
          // console.log(overlay);
          if ((!overlay.quizAttended) && overlayContinue && overlay.overlayType === "Quiz") {
            overlayAppend = (
              <div className="card" style={{boxShadow:"none"}}>
                <div className="card-header card-color">
                  <h1 className="text-view" style={{padding:"15px",fontSize:"2rem"}}>{overlay.overlayName}</h1>
                </div>
                <div
                  // className="card-body body-overflow"
                  className="bg-white"
                  style={{
                    background: `${overlay.overlayColor.hex}`,
                    height: 360
                    // minHeight: 300,
                    // maxHeight: 360
                  }}
                >
                  {quizQuestionAppend}
                </div>
                {/* <div id="closeButton">
                  <i
                    class="fa fa-times-circle-o fa-2x banner-close"
                    aria-hidden="true"
                    onClick={() => { this.xButton(); this.toContinueOrNot("no"); }}
                  />
                </div> */}
              </div>
            );
          }
          if (QuizComplete1) {
            overlayAppend = (<div className="card" style={{boxShadow:"none"}}>
              <div className="card-header card-color">
                <h1 className="text-view" style={{padding:"15px",fontSize:"2rem"}}>Quiz Report</h1>
              </div>

              <div
                // className="card-body body-overflow"
                className="bg-white"

                style={{ background: `${overlay.overlayColor.hex}`, }}
              >

                <div className="card card-shadow"
                  style={{ maxHeight: 296 }}
                >

                  <div className="card-body" style={{padding:"20px",border:"1px solid gray",position:"relative",width:"80%",left:"10%",top:"30px"}}>
                    <div className="row my-1">
                      <h2>Your Score is :</h2><span style={{display:"flex",justifyContent:"center"}}>{overlay.Score} Out Of {overlay.TotalQuestion}</span>
                      <div className="col-sm-4" />
                      {overlay.Score == overlay.TotalQuestion ?
                        <button
                          type="button"
                          className="btn btn-success col-sm-4"
                          onClick={() => this.ViewCertificate(overlay)}
                        >
                          View Certificate
                        </button> : <button
                          type="button"
                          className="btn btn-danger col-sm-4"
                          onClick={() => this.Quizretake(overlay)}
                        >
                          Retake Quiz
                        </button>
                      }
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn btn-success btn-sm btn-radius btn-widthsuc"
                  onClick={() => { this.setState({ showAnswers: false, vidButton: false }); this.videoContinueNext() }}
                >
                  Ok
                </button>
              </div>
              {/* <div id="closeButton">
                  <i
                    class="fa fa-times-circle-o fa-2x banner-close"
                    aria-hidden="true"
                    onClick={() => { this.xButton(); this.toContinueOrNot("no"); }}
                  />
                </div> */}
            </div>)
          }
          if (quizComplete) {
            overlayAppend =
              showAnswers && prepost === 2 ? (
                <div className="card" style={{boxShadow:"none"}}>
                  <div className="card-header card-color">
                    <h1 className="text-view">Correct Quiz Answers</h1>
                  </div>

                  <div
                    className="card-body body-overflow"
                    style={{ background: `${overlay.overlayColor.hex}` }}
                  >
                    {jsonQuizListArray[0].map((values, key) => (
                      <div className="card card-shadow"
                        style={{ maxHeight: 296,
                          //  overflowY: 'scroll' 
                          }}
                      >
                        <div className="card-header bg-set">
                          <div className="row">
                            <div className="col-sm-12">
                              <h3 className="question-align">
                                {values.question}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="card-body" style={{marginTop:"40px"}}>
                          <div className="row my-1">
                            {values.type === "single" ? (
                              <div className="ml-2">{values.answer}</div>
                            ) : (
                              <div>
                                {values.answer.split(",").map((lval, l) => {
                                  return (
                                    <div>
                                      {
                                        values.options[
                                        Number(lval.slice(-1)) - 1
                                        ]
                                      }
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-radius btn-widthsuc"
                      onClick={() => this.setState({ showAnswers: false, vidButton: false })}
                    >
                      Ok
                    </button>
                  </div>
                  {/* <div id="closeButton">
                    <i
                      class="fa fa-times-circle-o fa-2x banner-close"
                      aria-hidden="true"
                      onClick={() => { this.xButton(); this.toContinueOrNot("no"); }}
                    />
                  </div> */}
                </div>
              ) : overlayAppend =
              showAnswers && prepost === 1 ? (
                <div className="card" style={{boxShadow:"none"}}>
                  <div className="card-header card-color">
                    <h1 className="text-view">Quiz Report</h1>
                  </div>

                  <div
                    className="card-body body-overflow"
                    style={{ background: `${overlay.overlayColor.hex}` }}
                  >

                    <div className="card card-shadow"
                      style={{ maxHeight: 296,
                        //  overflowY: 'scroll'
                         }}
                    >

                      <div className="card-body">
                        <div className="row my-1">
                          <h2>Your Score is :</h2><span>{this.state.Score} Out Of {this.state.TotalQuestion}</span>
                          <div className="col-sm-4" />
                          {this.state.Score == this.state.TotalQuestion ?
                            <button
                              type="button"
                              className="btn btn-success col-sm-4"
                              // onClick={() => this.ViewCertificate(overlay)}
                            >
                              Quiz Completed
                            </button> : <button
                              type="button"
                              className="btn btn-danger col-sm-4"
                              onClick={() => this.Quizretake(overlay)}
                            >
                              Retake Quiz
                            </button>
                          }
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-radius btn-widthsuc"
                      onClick={() => { this.setState({ showAnswers: false, vidButton: false }); this.videoContinueNext() }}
                    >
                      Ok
                    </button>
                  </div>
                  {/* <div id="closeButton">
                  <i
                    class="fa fa-times-circle-o fa-2x banner-close"
                    aria-hidden="true"
                    onClick={() => { this.xButton(); this.toContinueOrNot("no"); }}
                  />
                </div> */}
                </div>
              ) : (
                <div class="containers">
                  <img
                    src={overlayImg}
                    alt="Snow"
                    style={{
                      opacity: "0.9",
                      height: "300px",
                      width: "100%",
                      borderRadius: "15px"
                    }}
                  />
                  <div className="bottom-left" />
                  <div className="top-left" />
                  <div className="top-right" />
                  <div className="bottom-right" />
                  <div className="centered">
                    <div className="suces-new-center">
                      <div className="row form-group">
                        <div className="col-sm-12">
                          <h1 className="banner-start">
                            Quiz Successfully Completed
                          </h1>
                        </div>
                      </div>
                      <br />
                      <div className="row form-group">
                        <div className="col-sm-12">
                          <button
                            type="button"
                            className="btn btn-success btn-sm btn-radius btn-widthsuc"
                            onClick={() => this.videoContinueNext()}
                          >
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div id="closeButton">
                    <i
                      class="fa fa-times-circle-o fa-2x banner-close"
                      aria-hidden="true"
                      onClick={() => { this.xButton(); this.toContinueOrNot("no"); }}
                    />
                  </div> */}
                </div>
              );
          }
        }
      });
    }
    // console.log(this.state.videoError, 'videoError');
    return (
      <div>
        {videoUrl && (
          <figure id="videoContainer" data-fullscreen="false" style={{ maxHeight: '60rem' }}>
            {this.state.videoError == true ? <div id="videoloaaing_error" >Video Error</div> : (this.state.videoUrl == null || this.state.videoloader || currentVideoDuration == 'undefined' || currentVideoDuration == "undefined") ? < div id="videoloader" style={{ opacity: 1 }}></div> : ""}

            {window.screen.width <= 768
              ?
              <video
                // crossOrigin
                onContextMenu={(e) => e.preventDefault()}
                id="video"
                controls
                style={{
                  width: "330px",
                  marginLeft: "-25px",
                  // maxHeight: 426,
                  position: 'relative'
                }}
                // crossOrigin="anonymous"
                preload="metadata"
                className="player-center"
                ref={ref => {
                  this.ref = ref;
                }}
                // onDuration={this.onDuration}
                // onProgress={state => {
                // }}
                src={this.state.videoUrl}
                onLoadStart={() => this.setState({ videoloader: true })}
                onLoadedData={() => this.setState({ videoloader: false })}
                onError={() => this.setState({ videoError: true })}
              >
                {this.props.playfrom && (
                  <source src={this.props.playfrom} type="video/mp4" />
                )}
                {!this.props.playfrom && (
                  <source src={this.state.videoUrl} type="video/mp4" />
                )}

                {this.state.pageList.subTitleId.map(value => {
                  return (
                    <track
                      label={value.srcLang}
                      kind="subtitles"
                      srcLang={value.srcLang}
                      src={value.src}
                    />
                  );
                })}
              </video>
              :
              <video
                // crossOrigin
                onContextMenu={(e) => e.preventDefault()}
                id="video"
                controls
                style={{
                  width: "100%",
                  margin: "0px 0px",
                  // maxHeight: 426,
                  position: 'relative'
                }}
                // crossOrigin="anonymous"
                preload="metadata"
                className="player-center"
                ref={ref => {
                  this.ref = ref;
                }}
                // onDuration={this.onDuration}
                // onProgress={state => {
                // }}
                src={this.state.videoUrl}
                onLoadStart={() => this.setState({ videoloader: true })}
                onLoadedData={() => this.setState({ videoloader: false })}
                onError={() => this.setState({ videoError: true })}
              >
                {this.props.playfrom && (
                  <source src={this.props.playfrom} type="video/mp4" />
                )}
                {!this.props.playfrom && (
                  <source src={this.state.videoUrl} type="video/mp4" />
                )}

                {this.state.pageList.subTitleId.map(value => {
                  return (
                    <track
                      label={value.srcLang}
                      kind="subtitles"
                      srcLang={value.srcLang}
                      src={value.src}
                    />
                  );
                })}
              </video>
            }

            {window.screen.width <= 768
              ?
              <div
                id="video-controls"
                className="row controls full-vid-controls fullscreen-bg"
                data-state="hidden"
                style={{ width: "330px", marginLeft: "-25px" }}
              >

                <div
                  className="progress"
                  style={{ height: "10px" }}
                >

                  <progress id="progress" value="0" min="0">
                    <span id="progress-bar" />
                  </progress>

                </div>
                <br />

                <button
                  id="playpause"
                  className="vid-play-sub"
                  type="button"
                  disabled={this.state.vidButton}
                  data-state="play"
                  onClick={() => this.videoinsert(this.state.insertcheckvalue)}
                >
                  Play/Pause
                </button>


                {/* {(this.state.retake || this.state.vidControl) &&  */}
                <button
                  id="forward"
                  type="button"
                  className="vid-play-sub"
                  data-state="forward"
                  onClick={() => this.moveForwardOrBackward("forward")}
                  // set={(this.state.retake || this.state.vidControl) ? false : true}
                  disabled={this.state.vidButton}
                >
                  Forward
                </button>
                <button
                  id="stop"
                  type="button"
                  className="vid-play-sub"
                  data-state="stop"
                  disabled={this.state.vidButton}
                  onClick={() => this.setState({ startindex: 0, userAnswer: [], overlayContinue: false })}
                >
                  Stop
                </button>
                <button
                  id="backward"
                  type="button"
                  data-state="backward"
                  className="vid-play-sub"
                  onClick={() => this.moveForwardOrBackward("backward")}
                  disabled={this.state.vidButton}
                >
                  Backward
                </button>
                <button
                  id="mute"
                  className="vid-play-mute vid-play-common"
                  type="button"
                  data-state="mute"
                  disabled={this.state.vidButton}
                  style={{ marginLeft: "10px" }}
                >
                  Mute/Unmute
                </button>
                <input id="volumeslider" className='vid-play-common' style={{ width: wid ? 110 : 80, marginLeft: "5px" }} type="range" onChange={this.volume} min="0" max="100" step="1" />
                <button
                  id="fs"
                  type="button"
                  style={{ height: "18px", width: "18px", marginLeft: "15px", marginRight: "5px" }}
                  data-state="go-fullscreen"
                  className="vid-play-lastctrl vid-play-fullsc vid-play-full-fullsc"
                  disabled={this.state.vidButton}
                >
                  Fullscreen
                </button>
                <button
                  id="subtitles"
                  type="button"
                  className="vid-play-common vid-play-cc"
                  data-state="subtitles"
                  disabled={this.state.vidButton}
                >
                  CC
                </button>
                <br></br>
                <br></br>
                <div
                  className="vid-play-duration vid-play-align"
                  style={{ /*width: '120px',*/ width: wid ? 200 : "160px", height: "20px", marginLeft: "70px" }}
                >
                  <span id="currentVideoTime" className="vid-duration-left" style={{ marginLeft: 3 }}>
                    {currentVideoTime}
                  </span>
                  <span>/</span>
                  <span id="currentVideoDuration">{currentVideoDuration}</span>
                </div>
              </div>

              :

              <div
                id="video-controls"
                className="row controls full-vid-controls fullscreen-bg"
                data-state="hidden"
                style={{ width: "100%", margin: "0px", height: "80px" }}
              >

                <div
                  className="progress"
                  style={{ height: "10px" }}
                >

                  <progress id="progress" value="0" min="0">
                    <span id="progress-bar" />
                  </progress>

                </div>
                <br />
                <button
                  id="playpause"
                  className="vid-play-sub"
                  type="button"
                  disabled={this.state.vidButton}
                  data-state="play"
                  onClick={() => this.videoinsert(this.state.insertcheckvalue)}
                >
                  Play/Pause
                </button>
                <button
                  id="stop"
                  type="button"
                  className="vid-play-sub"
                  data-state="stop"
                  disabled={this.state.vidButton}
                  onClick={() => this.setState({ startindex: 0, userAnswer: [], overlayContinue: false })}
                >
                  Stop
                </button>
                <button
                  id="backward"
                  type="button"
                  data-state="backward"
                  className="vid-play-sub"
                  onClick={() => this.moveForwardOrBackward("backward")}
                  disabled={this.state.vidButton}
                >
                  Backward
                </button>
                {/* {(this.state.retake || this.state.vidControl) &&  */}
                <button
                  id="forward"
                  type="button"
                  className="vid-play-sub"
                  data-state="forward"
                  onClick={() => this.moveForwardOrBackward("forward")}
                  // set={(this.state.retake || this.state.vidControl) ? false : true}
                  disabled={this.state.vidButton}
                >
                  Forward
                </button>

                {/* } */}

                <button
                  id="mute"
                  className="vid-play-mute vid-play-common"
                  type="button"
                  data-state="mute"
                  disabled={this.state.vidButton}
                >
                  Mute/Unmute
                </button>
                <input id="volumeslider" className='vid-play-common' style={{ width: wid ? 42 : 80 }} type="range" onChange={this.volume} min="0" max="100" step="1" />

                <button
                  id="fs"
                  type="button"
                  style={{ height: "18px", width: "18px" }}
                  data-state="go-fullscreen"
                  className="vid-play-lastctrl vid-play-fullsc vid-play-full-fullsc"
                  disabled={this.state.vidButton}
                >
                  Fullscreen
                </button>
                <button
                  id="subtitles"
                  type="button"
                  className="vid-play-common vid-play-cc"
                  data-state="subtitles"
                  disabled={this.state.vidButton}
                >
                  CC
                </button>
                <div
                  className="vid-play-duration vid-play-align"
                  style={{ /*width: '120px',*/ width: wid ? 120 : "160px", height: "20px" }}
                >
                  <span id="currentVideoTime" className="vid-duration-left" style={{ marginLeft: 3 }}>
                    {currentVideoTime}
                  </span>
                  <span>/</span>
                  <span id="currentVideoDuration">{currentVideoDuration}</span>
                </div>
              </div>
            }


            <div class="container">
              <div class="row">
                <div class="col-lg-4">
                  {(!this.state.vidButton && this.state.retake) ?

                    <button class='btn btn-warning' style={{ marginTop: '10px', }}
                      onClick={() => this.retake()}
                    >
                      Retake Quiz
                    </button>
                    : null
                  }
                </div>
                <div class="col-lg-4">
                  {
                    (!this.state.vidButton && this.state.resumeVideo) ? <button class='btn btn-warning' style={{ marginTop: '10px', }}
                      onClick={() => this.Resume()}
                    >
                      Resume
                    </button> : null
                  }

                </div>
                <div class="col-lg-4">
                  <button class='btn btn-danger' style={{ marginTop: '10px', }}
                    onClick={async () => {
                      await this.addVideoCounts()
                      // window.location.reload()
                      window.location.replace(`${window.location.origin}/hr/sugestedTraingProgram`)

                    }}
                  >
                    Exit Activity
                  </button>
                </div>
              </div>

            </div>
            <div id="banner" style={{ background: "red" }}>
              {overlayAppend}
            </div>
            {
              this.state.completedOverlay && <div id="ban" className="container" style={{
                background: "#173438", height: '70%'
              }}>
                {this.state.completedOverlay}
              </div>}
            <ScriptTag type="text/javascript" src={videoScript} />
          </figure>
        )
        }

        <br /> <br />
        <br></br>
        Note: Please click exit activity inorder to update your learning progress


      </div >
    );
  }
}

export default NewVideoPlayer;
