import React, { Component } from "react";
import queryString from "query-string";
import LoginModal from "../../components/Modal/Modal";
import { SingleSelect, FormMiddleWare } from "../../components/Form";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
// import BehaviouralCompetencies from "./BehaviouralCompetencies";
import BehaviouralCompetencies from "./BehaviouralCompetencies";
import TechnicalCompetencies from "./TechnicalCompetencies";
import ProfileCompReport from "./ProfileCompReport";
import ProfileChart from "./ProfileChart";
import Datatable from "../../components/Datatable/Datatable";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import ProfileCompMap from "./ProfileCompMap";
import PreLoader from './preloader'
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import ConsolidatePDF from "../PDF/ConsolidatePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { ACCESS_POINT } from "../../config";


class ViewProfile extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: queryString.parse(this.props.location.search).id,
      techBehType: [
        { label: "Technical", value: 2 },
        { label: "Behavioral", value: 1 }
      ],
      des: [],
      file: [],
      yearSelect: [],
      typeSelect: [],
      showTable: false,
      // showTableBeh : false,
      // showTableTech : false,
      pdfyearId: localStorage.getItem("yearId"),
      btncolorchgs:'danger'
    };
  }

  async componentWillMount() {
    const userId = this.state.userId;
    const adminId = await localStorage.getItem("adminId");
    console.log(adminId);
    const userData = await HrMiddleWare.getUserDetails(userId);
    console.log(userData.data)
    this.setState({uservalue:userData.data})
    let userDetails = await HrMiddleWare.getRandomData(
      "tbl_users",
      `id = ${userId}`,
      "*"
    );
    console.log(userDetails.data[0]);
    let work = await HrMiddleWare.getRandomData(
      "tbl_worklevel",
      `id = ${userDetails.data[0].worklevel}`,
      "*"
    );
    let jobRole = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `id = ${userDetails.data[0].jobRole} `,
      "*"
    );
    let designation = await HrMiddleWare.getRandomData(
      "tbl_designation",
      `id = ${userDetails.data[0].designation} `,
      "*"
    );
    await this.setState({designationid:userDetails.data[0].designation})
    let department = await HrMiddleWare.getRandomData(
      "tbl_department",
      `id = ${userDetails.data[0].department}`,
      "*"
    );
    let Theme = await HrMiddleWare.getRandomData(
      "tbl_theme",
      `customerid = ${userDetails.data[0].adminId} and adminId = ${userDetails.data[0].adminId}`,
      // `id = ${userDetails.data[0].userTheme}`,
      "*"
    );
    let descrip = await HrMiddleWare.getRandomData(
      "tbl_jobdescription",
      `'function' = ${userId}`,
      "*"
    );
    console.log(descrip);
    let workLevel = await HrMiddleWare.getRandomData(
      "tbl_worklevel",
      `customerid = ${adminId} and adminId = ${adminId}`,
      "worklevel as label , id as value"
    );

    let comp = await HrMiddleWare.getRandomData(
      "tbl_competency",
      `customerid = ${adminId} and adminId = ${adminId}`,
      "*"
    );
    let deptList = await HrMiddleWare.getRandomData(
      "tbl_department",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "department as label , id as value"
    );
    let derailers = await HrMiddleWare.getRandomData(
      "tbl_derailers",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "derailers as label , id as value"
    );

    // let MappedDerail = await HrMiddleWare.getRandomData(
    //   "Tblderailers_map,tbl_derailers",
    //   `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and Tblderailers_map.jobroleid = ${userData.data[0].jobRole} and  Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
    //   "*"
    // );

    let MappedDerail = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and  designationid = ${userDetails.data[0].designation} and Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
      "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
    );

    console.log(MappedDerail)
    let mapDerail = "";
    if (MappedDerail.data) {
      MappedDerail.data.map((item, i) => {
        console.log(item);
        if(item.userId !=null)
        {
          console.log(item.userId.includes(this.state.userId))

          if(item.userId.includes(this.state.userId)==true)
          {
            mapDerail += item.derailers + ", ";
          }
        }
        

       
      });
      mapDerail = mapDerail.replace(/,\s*$/, "");
    }
    let yearList = await MappingMiddleware.getYear();
    if (yearList) {
      // this.setState({ yearList: yearList.data });
    }
    const jobList = await HrMiddleWare.getRandomData(
      "tbl_jobRole",
      `customerid = ${adminId} and adminId = ${adminId} and status = 'active'`,
      "jobRoleName as label , id as value"
    );

    let L1 = "";
    if (userData.data) {
      let themeID = userDetails.data[0].userTheme.toString();
      let split = themeID.split(",");
      let themeLabel = [];
      split.map((item, i) => {
        let json = Theme.data.filter((element, key) => {
          return element.id == item;
        });
        themeLabel.push({ label: json[0].theme });
        L1 += themeLabel[i].label + ", ";
      });
      L1 = L1.replace(/,\s*$/, "");
    }
    let L2 = "";
    if (userData.data[0].userFullIndicatorList) {
      userData.data[0].userFullIndicatorList.map((item, i) => {
        L2 += item.competencyname + ", ";
      });
      L2 = L2.replace(/,\s*$/, "");
    }

    let jobdescription = await HrMiddleWare.getRandomData(
      "tbl_jobdescription",
      `jobRole = ${userDetails.data[0].jobRole} and
       department = ${userDetails.data[0].department} and
       customerid = ${adminId} and status ='active'`,
      "*"
    );
// console.log(jobdescription);
    let pdfIdp = await HrMiddleWare.getRandomData(
      "idp_goals",
      `createdById=${userDetails.data[0].id} and yearId=${this.state.pdfyearId} and customerid=${adminId}`,
      "*"
    );

    if(jobdescription.data.length)
    {this.setState({jobdescription:jobdescription.data[0].jobdescription})
    }
    else
    {
      this.setState({jobdescription:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque vitae felis in malesuada. Nulla viverra odio vitae condimentum iaculis. Sed at egestas erat, eu maximus nibh. Pellentesque non lobortis augue, in tincidunt enim. Sed imperdiet, justo non maximus feugiat, arcu elit vestibulum purus, vel semper turpis sem a velit. Quisque vitae lobortis erat. Quisque in quam eu dolor sollicitudin viverra. Nulla sit amet felis vitae neque mollis tempus. Mauris quis velit nec diam finibus porttitor. Suspendisse luctus interdum maximus. Sed rutrum imperdiet feugiat. Pellentesque dictum elit sed quam posuere bibendum. Curabitur porta volutpat sapien, sit amet vulputate lacus gravida ac.'})
    }
   
    let mapped_derailers = await HrMiddleWare.getRandomData(
      "Tblderailers_map,tbl_derailers",
      `Tblderailers_map.worklevelid = ${userData.data[0].worklevel} and  designationid = ${userDetails.data[0].designation} and Tblderailers_map.customerId = ${adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
      "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
    );

    this.setState({mapped_derailers:mapped_derailers.data})
console.log(mapped_derailers)


    
    await this.setState({
      userDetails: userDetails.data[0],
      work: work.data[0].worklevel,
      jobRole: jobRole.data[0].jobRoleName,
      jobRoleid: jobRole.data[0].id,
      
      designation: designation.data[0].designation,
      department: department.data[0].department,
      L1,
      L2,
      userData: userData.data[0],
      userId,
      adminId,
      descrip: descrip.data,
      comp: comp.data,
      derailers: derailers.data,
      yearList: yearList.data,
      workLevel: workLevel.data,
      jobList: jobList.data,
      deptList: deptList.data,
      mapDerail,
      pdfIdp:pdfIdp.data
    });
  }

  column = [
    {
      id: "row",
      Header: "SI.No",
      accessor: "id",
      maxWidth: "100",
      Cell: row => {
        return <div>{row.index + 1}</div>;
      }
    },
    {
      Header: "Job Description",
      accessor: "jobdescription"
    },
    {
      Header: "Description",
      accessor: "describtion"
    },
    {
      Header: "File",
      accessor: "fileupload",
      Cell: d => this.fileView(d.original.fileupload)
    }
  ];


  de_mapped_column= [ 
    {
      Header: "derailers",
      accessor: "derailers"
    },
    {
      Header: "status",
      accessor: "description",
      Cell: d => this.returnvalue(d.original)
    }
     
  ];
  
  
  returnvalue =e =>{
    //console.log(e.userId.includes(this.state.userId)) 
    console.log(this.state.userId)
  
    let check='';
    if(e.userId)
    {
    
       check=e.userId.includes(this.state.userId)
    
     
    }
    
  
    return(<button className={check==true?'btn btn-danger':'btn btn-success'} onClick={()=>this.selectedfunction(e,check==true ? 'DeSelected': 'Selected')}> {check==true ? 'Selected': 'Select' }   </button>) 

  }

   
  selectedfunction =async (e,selected)=>{
let user= e.userId.includes(this.state.userId)

if(user ==false)
{
  var array=JSON.parse(e.userId);
  array.push(this.state.userId)
  e.userId=array;
}
else
{
  var array=JSON.parse(e.userId);   
 
  var filteredAry = array.filter(e => e !==this.state.userId)
  e.userId=filteredAry;

}


var formData = new FormData();
formData.append("userid", JSON.stringify(e.userId));

var updateMaster = await MasterMiddleWare.updateMastervalue(
"Tblderailers_map",
e.id,
formData
);

if(updateMaster)
{

  console.log( `Tblderailers_map.worklevelid = ${this.state.userData.worklevel} and  designationid = ${this.state.designationid} and Tblderailers_map.customerId = ${this.state.adminId} and tbl_derailers.id=Tblderailers_map.derailersid`)
  
  let mapped_derailers = await HrMiddleWare.getRandomData(
    "Tblderailers_map,tbl_derailers",
    `Tblderailers_map.worklevelid = ${this.state.userData.worklevel} and  designationid = ${this.state.designationid} and Tblderailers_map.customerId = ${this.state.adminId} and tbl_derailers.id=Tblderailers_map.derailersid`,
    "Tblderailers_map.*,tbl_derailers.derailers,tbl_derailers.description"
  );

  await this.setState({mapped_derailers:mapped_derailers.data})
  console.log(this.state.mapped_derailers)

 
}






  }

  fileView = d => {
    if (d) {
      return (
        <div>
         <center>
          <button class="btn logout-btn" onClick={()=>window.open( ACCESS_POINT +
                            "/getpro/getprofile?fileurl=" +`${d}`,'_blank')}> view </button>
          </center>
        </div>
      );
    }
  };

  jobText = e => {
    this.setState({ jobText: e.target.value });
  };
  descriptionText = e => {
    this.setState({ descriptionText: e.target.value });
  };
  onSubmit = async () => {
    const { filename, jobText, descriptionText, adminId, userId,jobRoleid } = this.state;
    const formData = new FormData();
    formData.append("describtion", descriptionText);
    formData.append("jobdescription", jobText);
    formData.append("function", userId);
    formData.append("jobRole", jobRoleid);
    formData.append("fileupload", filename);
    formData.append("customerid", adminId);
    formData.append("status", "active");
    try {
      const result = await MasterMiddleWare.createMaster(
        "tbl_jobdescription",
        formData
      );
      if (result) {
        Swal.fire({
          position: "center",
          type: "success",
          title: "Job Description Added ",
          showConfirmButton: false,
          timer: 1500
        });
        // let valueArray = {};
        // valueArray.id = result.data.insertId;
        // valueArray.jobdescription = jobText.trim();
        // valueArray.describtion = descriptionText.trim();
        // const newData = [valueArray, ...this.state.descrip];
        let descrip = await HrMiddleWare.getRandomData(
          "tbl_jobdescription",
          `function = ${userId}`,
          "*"
        );
        let newData = descrip.data.sort((a,b)=>b.id-a.id);
        await this.setState({
          descrip: newData,
          descriptionText: "",
          jobText: "",
          filename: []
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  typeSelect = e => {
    this.setState({ typeSelect: e });

    let comp = this.state.comp;

    let compOption = [];
    comp.map((item, i) => {
      if (e.label == item.textType) {
        compOption.push({ label: item.competencyname, value: item.id });
      }
    });
    this.setState({ compOption });
  };
  compSelect = e => {
    this.setState({ compSelect: e });
  };
  derailer = e => {
    this.setState({ derailerSelected: e });
  };
  derailersText = e => {
    this.setState({ derailersText: e.target.value });
  };
  desText = e => {
    this.setState({ desText: e.target.value });
  };

  mapDerailers = async () => {
    const { typeSelect, compSelect, derailerSelected, adminId } = this.state;
    const formData = new FormData();
    formData.append("themeid", compSelect.value);
    formData.append("customerId", adminId);
    formData.append("derailersid", derailerSelected.value);
    try {
      const result = await HrMiddleWare.addMaster("Tblderailers_map", formData);
      if (result) {
        this.setState({
          typeSelect: "",
          compSelect: "",
          derailerSelected: ""
        });
        Swal.fire({
          position: "center",
          type: "success",
          title: "Derailers Mapped",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  addDerailers = async () => {
    const {
      typeSelect,
      compSelect,
      derailerSelected,
      adminId,
      derailersText,
      desText
    } = this.state;
    const formData = new FormData();
    formData.append("derailers", derailersText);
    formData.append("description", desText);
    formData.append("status", "active");
    formData.append("customerId", adminId);
    formData.append("adminId", adminId);
    formData.append("createdAt", 1);
    try {
      const result = await HrMiddleWare.addMaster("tbl_derailers", formData);
      if (result) {
        this.setState({
          typeSelect: "",
          compSelect: "",
          derailerSelected: "",
          derailersText: "",
          desText: ""
        });
        Swal.fire({
          position: "center",
          type: "success",
          title: "Derailers Added ",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  wrklvl = async e => {
    let typeSelect = this.state.typeSelect;
    const {
      data: functionOptions
    } = await MappingMiddleware.getMapDepartmentToFunctionData(
      this.state.adminId,
      typeSelect.value
    );
    this.setState({ wrkSelected: e, functionOptions });
  };

  functionSelect = async selectedOption => {
    const { adminId, departmentId, workLevelOptionsBefore } = this.state;
    const functionId = selectedOption.value;
    this.setState({
      functionSelected: selectedOption,
      functionId: selectedOption.value,
      functionLabelName: selectedOption.label
    });
    try {
      const {
        data: workLevelOptions
      } = await MappingMiddleware.getMapDepartmentToWorklevelData(
        adminId,
        departmentId,
        functionId
      );

      if (workLevelOptions) {
        this.setState({ workLevelOptions });
      }
    } catch (error) {
      console.error(error);
    }
  };

  departmentSelect = async selectedOption => {
    const { adminId } = this.state;
    const departmentId = selectedOption.value;
    this.setState({
      departmentSelected: selectedOption,
      departmentId: selectedOption.value,
      departmentLabelName: selectedOption.label
    });
    try {
      const {
        data: functionOptions
      } = await MappingMiddleware.getMapDepartmentToFunctionData(
        adminId,
        departmentId
      );
      if (functionOptions) {
        this.setState({
          functionOptions,
          functionSelected: null,
          functionId: null,
          functionLabelName: null,
          errorfunctionSelected: null
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  jobSelect = e => {
    const {
      wrkSelected,
      yearSelect,
      typeSelect,
      financialType,
      departmentLabelName,
      functionLabelName
    } = this.state;
    if (e) {
      let type = typeSelect.label === "Behavioral" ? 1 : 2;
      localStorage.setItem("jobRoleId", e.value);
      localStorage.setItem("jobRoleName", e.label);
      localStorage.setItem("workLevelId", wrkSelected.value);
      localStorage.setItem("workLevelName", wrkSelected.label);
      localStorage.setItem("financialYearId", yearSelect.value);
      localStorage.setItem("departmentName", departmentLabelName);
      localStorage.setItem("functionName", functionLabelName);
      localStorage.setItem("type", type);

      this.setState({ showTable: true });
    }
  };

  

  TechWrkLvl = e => {
    this.setState({ wrkSelected: e });
    // const {
    //     wrkSelected,
    //     yearSelect,
    //     typeSelect,
    //       financialType,
    //       departmentLabelName,
    //       functionLabelName
    //   } = this.state;

    //   let type = typeSelect.label === 'Behavioral' ? 1 : 2;
    //       localStorage.setItem('jobRoleId', e.value);
    //       localStorage.setItem('jobRoleName', e.label);
    //       localStorage.setItem('workLevelId', wrkSelected.value);
    //       localStorage.setItem('workLevelName', wrkSelected.label);
    //       localStorage.setItem('financialYearId', yearSelect.value);
    //       localStorage.setItem('departmentName', departmentLabelName);
    //       localStorage.setItem('functionName', functionLabelName);
    //       localStorage.setItem('type', type);

    //       this.setState({showTableBeh : false , showTableTech : true});
  };

  //   Moveon = () =>{
  //       console.log(1212)
  //     return(
  //         <div>
  //             <Redirect to={{
  //                 pathname: '/hr/ConsolidateReport',
  //                 state: { userId: '167' }
  //             }}
  //             />
  //         </div>
  //     )
  //   } 

  ConsReport=async=>{
  
    window.open(`/hr/Hr_userConsreport/${this.state.userId}`, '_blank');
  }

  render() {
    let borderstyle = {
      border: "0px"
    };
    const {
      userDetails,
      userId,
      userData,
      typeSelect,
      compOption,
      work,
      jobRole,
      designation,
      department,
      workLevel,
      techBehType,
      L1,
      L2,
      descriptionText,
      jobText,
      derailers,
      derailersText,
      desText,
      yearList,
      yearSelect,
      jobList,
      deptList,
      pdfIdp
    } = this.state;

    if(!userDetails)
    {
return(<div><PreLoader/>  </div>)
    }
    else
    {

      let height=window.innerHeight-50;
      return (
        <section id="main-content">

          <section class="wrapper" style={{height:height}}>
            <div class="row">
              <div class="col-sm-12 main-chart custom-col-sm-12 ">
                <div class="row bg-white">
                  {/* <div class="col-sm-12 user-nav-tab "> */}
                 
                  <div class="centered header-color">
              <div className="row" style={{marginTop:20}}>    
                  <div className="col-sm-3" />
                  <div className="col-sm-4">
                    <h3>Profile of {userDetails && userDetails.name}</h3>
                    </div>
                    <div className="col-sm-2" />
                       <div className="col-sm-3">
                       {/* {userDetails && (
                  <PDFDownloadLink
                    style={{ color: "white" }}
                    document={
                      <ConsolidatePDF
                        data={userDetails}
                        workLvl={work}
                        designationName={designation}
                        jobRoleName={jobRole}
                       // user={this.state.uservalue}
                       // derailers={derailers}
                       // idp={pdfIdp}
                        jobdescription={this.state.jobdescription}
                      />
                    }
                    fileName="sample.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Download Pdf"
                    }
                    <button type="button" className="btn btn-success">
                      Download PDF
                    </button>
                  </PDFDownloadLink>
                )} */}

                       </div>
              </div>
                  </div>
                  {/* <div class="box-body">
                                  <div>{userDetails && userDetails.name}</div>
                                   <label htmlFor="employee">{userDetails && userDetails.name}</label>
                              </div> */}
                  <div class="panel-body">
                    {/*profile screen */}
                    <div class="col-lg-2 col-sm-2">
                      <h4 style={{textAlign:'center'}}>
                        <b>{userDetails && userDetails.name}</b>
                      </h4>
                      <div class="follow-ava">
                        <img
                          src={
                            ACCESS_POINT +
                            "/getpro/getprofile?fileurl=" +
                            `${userDetails && userDetails.image}`
                          }
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px"
                          }}
                          alt="user profile"
                        />
                      </div>
                    </div>

                        <div class="col-sm-4">
                          
                           {userDetails &&(
                               <table style={{ borderCollapse: "inherit" }}>
                               <tbody>
                                 <tr style={borderstyle}>
                                   <td>
                                     <p style={{ color: "#2d5986" }}>
                                       <b>Employee Name </b>&nbsp;
                                     </p>
                                   </td>
                                   <td>
                                     <span style={{ color: "#000000" }}>
                                       {" "}
                                       &nbsp;&nbsp;&nbsp; : &nbsp;{userDetails.name}
                                     </span>
                                     <p></p>
                                   </td>
                                 </tr>
                                 <tr style={borderstyle}>
                                   <td>
                                     <p style={{ color: "#2d5986" }}>
                                       <b>Email Address </b>
                                     </p>
                                   </td>
                                   <td>
                                     <span style={{ color: "#000000" }}>
                                       {" "}
                                       &nbsp;&nbsp;&nbsp; : &nbsp;{userDetails.email_id}
                                     </span>
                                     <p></p>
                                   </td>
                                 </tr>
                                 <tr style={borderstyle}>
                                   <td>
                                     <p style={{ color: "#2d5986" }}>
                                       <b>Mobile Number </b>
                                     </p>
                                   </td>
                                   <td>
                                     <span style={{ color: "#000000" }}>
                                       {" "}
                                       &nbsp;&nbsp;&nbsp; : &nbsp;{userDetails.mobile_no}
                                     </span>
                                     <p></p>
                                   </td>
                                 </tr>
                                 <tr style={{verticalAlign:'text-bottom', border: "0px"}}>
                                   <td>
                                     <p style={{ color: "#2d5986" }}>
                                       <b>Location </b>&nbsp;
                                     </p>
                                   </td>
                                   <td style={{ wordWrap: "break-word" }}>
                                     {" "}
                                     <span
                                       style={{ color: "#000000", whiteSpace: "nowrap" }}
                                     >
                                       {" "}
                                       &nbsp;&nbsp;&nbsp; : &nbsp;{userDetails.address}
                                     </span>
                                   </td>
                                 </tr>
         
                                 <tr style={borderstyle}>
                                   <td  >
                                     {" "}
                                     <p style={{ color: "#2d5986" }}>
                                       <b>Worklevel </b>&nbsp;
                                     </p>
                                   </td>
                                   <td>
                                     {" "}
                                     <span style={{ color: "#000000" }}>
                                       {" "}
                                       &nbsp;&nbsp;&nbsp; : &nbsp;{work}
                                     </span>
                                     <p></p>
                                   </td>
                                 </tr>
                               </tbody>
                             </table>         

                           )}
                        </div>
                       

                        <div class="col-sm-4">  
                        {userDetails && (
                    <table style={{ borderCollapse: "inherit" }}>
                      <tbody>
                        <tr style={borderstyle}>
                          <td>
                            <p style={{ color: "#2d5986" }}>
                              <b>Age </b>
                            </p>
                          </td>
                          <td>
                            <span style={{ color: "#000000" }}>
                              {" "}
                              : &nbsp;{userDetails.age}
                            </span>
                            <p></p>
                          </td>
                        </tr>
                        <tr style={borderstyle}>
                          <td>
                            <p style={{ color: "#2d5986" }}>
                              <b>Telphone Number</b>
                            </p>
                          </td>
                          <td>
                            <span style={{ color: "#000000" }}>
                              {" "}
                              : &nbsp;{userDetails.telphone}
                            </span>
                            <p></p>
                          </td>
                        </tr>
                        <tr style={borderstyle}>
                          <td>
                            <p style={{ color: "#2d5986" }}>
                              <b>Qualification </b>&nbsp;
                            </p>
                          </td>
                          <td>
                            <span style={{ color: "#000000" }}>
                              {" "}
                              :&nbsp;{userDetails.qualification}
                            </span>
                            <p></p>
                          </td>
                        </tr>
                        <tr style={borderstyle}>
                          <td>
                            <p style={{ color: "#2d5986" }}>
                              <b>Designation </b>&nbsp;
                            </p>
                          </td>
                          <td>
                            <span style={{ color: "#000000" }}>
                              {" "}
                              :&nbsp;{designation}
                            </span>
                            <p></p>
                          </td>
                        </tr>

                        <tr style={borderstyle}>
                          <td>
                            <p style={{ color: "#2d5986" }}>
                              <b>Job Role </b>&nbsp;
                            </p>
                          </td>
                          <td>
                            <span style={{ color: "#000000" }}>
                              {" "}
                              :&nbsp;{jobRole}
                            </span>
                            <p></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )} <div className="col-sm-2" />
                        
                        </div>
                    
 {/*profile screen */}

                  </div>


                  <div className="row">
               <div className="col-sm-2"></div>
               <div className="col-sm-8">


               <table style={{ borderCollapse: "inherit" }}>
                        <tbody>
                        <tr style={borderstyle}>
                          <td style={{ paddingLeft: 20,    verticalAlign:'top'}}>
                            {" "}
                            
                            <p style={{ color: "#2d5986",whiteSpace:'nowrap' }}>
                              <b>Job Description  </b>&nbsp;
                            </p>
                            
                          </td>
                          <td style={{ paddingLeft: 15}}>
                            {" "}
                            <span style={{ color: "#000000" }}>
                              {" "}
                             : {this.state.jobdescription} 
                            </span>
                            <p></p>
                          </td>
                        </tr>
                        </tbody>
                     </table>

               </div>
               <div className="col-sm-2"></div>
              </div>
              <div className="row form-group ">
            <div className="col-sm-8" />
            <div className="col-sm-4" >

              <button type="button" className="btn btn-success" onClick={this.ConsReport}>Consolidated Report</button>
            </div>
            </div>


                  <div class="row">
                    <div class="col-lg-12">
                      <section class="panel">
                        <header class="panel-heading tab-bg-info"></header>
                        <div>
                          <ul className="nav nav-tabs">
                            <li className="">
                              <a
                                data-toggle="tab"
                                href="#recent-activity"
                                style={{ color: "#5a508e" }}
                              >
                                Map Extra Competencies
                              </a>
                            </li>
                            <li className="">
                              <a
                                data-toggle="tab"
                                href="#profile"
                                style={{ color: "#5a508e" }}
                              >
                                {userDetails && userDetails.name}'s Profile
                              </a>
                            </li>
                            <li className="">
                              <a
                                data-toggle="tab"
                                href="#behComp"
                                style={{ color: "#5a508e" }}
                              >
                                Behavioural Competencies
                              </a>
                            </li>
                            <li className="">
                              <a
                                data-toggle="tab"
                                href="#techComp"
                                style={{ color: "#5a508e" }}
                              >
                                Technical Competencies
                              </a>
                            </li>
                            <li className="">
                              <a
                                data-toggle="tab"
                                href="#Competency-Report"
                                style={{ color: "#5a508e" }}
                              >
                                Competencies Report
                              </a>
                            </li>
                            <li className="">
                              <a
                                data-toggle="tab"
                                href="#Assesment-Graph"
                                style={{ color: "#5a508e" }}
                              >
                                Assesment Graph
                              </a>
                            </li>
                            <li className="">
                              <a
                                data-toggle="tab"
                                href="#Job-Description"
                                style={{ color: "#5a508e" }}
                              >
                                Job Description
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              id="recent-activity"
                              className="tab-pane fade in"
                            >
                              <br />
                              <div class="row">
                                <div class="col-lg-12">
                                  <section class="panel">
                                    <div class="table-responsive">
                                      <table class="table">
                                        <thead>
                                          <tr></tr>
                                          <tr>
                                            <th style={{ color: "black" }}>
                                              Themes
                                            </th>
                                            <th style={{ color: "black" }}>
                                              Competencies
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                color: "black",
                                                fontSize: "15px",
                                                valign: "top",
                                                width: "200px"
                                              }}
                                            >
                                              <br />
                                              <div>Year</div>
                                              <SingleSelect
                                                options={yearList}
                                                handleChange={d =>
                                                  this.selectBoxStore(
                                                    "yearSelect",
                                                    d
                                                  )
                                                }
                                                selectedService={yearSelect}
                                              />
                                              <br />
                                              <div>Type</div>
                                              <SingleSelect
                                                options={techBehType}
                                                handleChange={d =>
                                                  this.selectBoxStore(
                                                    "typeSelect",
                                                    d
                                                  )
                                                }
                                                selectedService={typeSelect}
                                              />
                                              <br />
                                              {typeSelect.label ===
                                                "Behavioral" && (
                                                <div>
                                                  <div>Work Level</div>
                                                  <SingleSelect
                                                    options={workLevel}
                                                    handleChange={this.wrklvl}
                                                    selectedService={
                                                      this.state.wrkSelected
                                                    }
                                                  />
                                                  <br />
                                                </div>
                                              )}
                                              {typeSelect.label ===
                                                "Technical" && (
                                                <div>
                                                  <div>Department</div>
                                                  <SingleSelect
                                                    options={deptList}
                                                    handleChange={
                                                      this.departmentSelect
                                                    }
                                                    selectedService={
                                                      this.state
                                                        .departmentSelected
                                                    }
                                                  />
                                                  <br />
                                                  <div>Function</div>
                                                  <SingleSelect
                                                    options={
                                                      this.state.functionOptions
                                                    }
                                                    handleChange={
                                                      this.functionSelect
                                                    }
                                                    selectedService={
                                                      this.state.functionSelected
                                                    }
                                                  />
                                                  <br />
                                                  <div>Work Level</div>
                                                  <SingleSelect
                                                    options={
                                                      this.state.workLevelOptions
                                                    }
                                                    handleChange={this.TechWrkLvl}
                                                    selectedService={
                                                      this.state.wrkSelected
                                                    }
                                                  />
                                                  <br />
                                                </div>
                                              )}
                                              <div>Job Role</div>
                                              <SingleSelect
                                                options={jobList}
                                                handleChange={this.jobSelect}
                                              />
                                            </td>
                                            <td style={{ color: "black" }}>
                                              {this.state.showTable == true && (
                                                <div>
                                                  <ProfileCompMap
                                                    userId={this.state.adminId}
                                                  />
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                            <div id="profile"  className="tab-pane fade in">
                              <div style={{marginTop:20,marginLeft:30}}>
                                <LoginModal
                                  buttonTitle="Add/Map Derailers"
                                  title="Add MapDerailers"
                                  id="behComp1"
                                  extraClass="btn btncolor"
                                  extraStyle={{
                                    backgroundColor: "#f44336",
                                    textAlign: "center"
                                  }}
                                  bodyText={
                                    <div>
                                      <ul className="nav nav-tabs">
                                        <li className="active">
                                          <a data-toggle="tab" href="#map">
                                            Map Derailers
                                          </a>
                                        </li>
                                        <li>
                                          <a data-toggle="tab" href="#add">
                                            Enter Derailers
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="tab-content">
                                        <div
                                          id="map"
                                          className="tab-pane fade in active"
                                        >


                                          {'derailer_map'}
                                          {this.state.mapped_derailers && (
                                  
                                  <Datatable 
                                    data={this.state.mapped_derailers}
                                    columnHeading={this.de_mapped_column}
                                  />
                                  
                                )}
                                          

                                          <div className="form-group" />
                                          {/* <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                              Matrix Type
                                            </div>
                                            <div className="col-sm-5">
                                              <SingleSelect
                                                options={techBehType}
                                                handleChange={this.typeSelect}
                                                selectedService={typeSelect}
                                              />
                                            </div>
                                            <div className="col-sm-2" />
                                          </div> */}
                                          {/* <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                              Competencies
                                            </div>
                                            <div className="col-sm-5">
                                              <SingleSelect
                                                options={compOption}
                                                handleChange={this.compSelect}
                                                selectedService={
                                                  this.state.compSelect
                                                }
                                              />
                                            </div>
                                            <div className="col-sm-2" />
                                          </div> */}
                                          {/* <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                              Derailers
                                            </div>
                                            <div className="col-sm-5">
                                              <SingleSelect
                                                options={derailers}
                                                handleChange={this.derailer}
                                                selectedService={
                                                  this.state.derailerSelected
                                                }
                                              />
                                            </div>
                                            <div className="col-sm-2" />
                                          </div> */}
                                          {/* <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-3" />
                                            <div className="col-sm-5">
                                              <button
                                                type="button"
                                                className="btn btn-success"
                                                class="btncolorsubmit"
                                                onClick={this.mapDerailers}
                                              >
                                                Submit
                                              </button>
                                            </div>
                                            <div className="col-sm-2" />
                                          </div> */}
                                        </div>
                                        <div
                                          id="add"
                                          className="tab-pane fade in"
                                        >
                                          <div className="form-group" />
                                          <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                              Matrix Type
                                            </div>
                                            <div className="col-sm-5">
                                              <SingleSelect
                                                options={techBehType}
                                                handleChange={this.typeSelect}
                                                selectedService={typeSelect}
                                              />
                                            </div>
                                            <div className="col-sm-2" />
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                              Competencies
                                            </div>
                                            <div className="col-sm-5">
                                              <SingleSelect
                                                options={compOption}
                                                handleChange={this.compSelect}
                                                selectedService={
                                                  this.state.compSelect
                                                }
                                              />
                                            </div>
                                            <div className="col-sm-2" />
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                              Derailer
                                            </div>
                                            <div className="col-sm-5">
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.derailersText}
                                                value={derailersText}
                                              />
                                            </div>
                                            <div className="col-sm-2" />
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                              Description
                                            </div>
                                            <div className="col-sm-5">
                                              <input
                                                type="text"
                                                className="form-control"
                                                onChange={this.desText}
                                                value={desText}
                                              />
                                            </div>
                                            <div className="col-sm-2" />
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-3" />
                                            <div className="col-sm-5">
                                              <button
                                                type="button"
                                                className="btn btn-success"
                                                class="btncolorsubmit"
                                                onClick={this.addDerailers}
                                              >
                                                Submit
                                              </button>
                                            </div>
                                            <div className="col-sm-2" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                />
                              </div>
                              <div class="form-group">
                                <div class="col-sm-6">
                                  {userDetails && (
                                    <div class="form-group">
                                      <div class="col-sm-10">
                                        <h1
                                          style={{
                                            color: "black",
                                            align: "center"
                                          }}
                                        >
                                          {" "}
                                          {userDetails && userDetails.name}'s
                                          Profile
                                        </h1>
                                        <div class="form-group">
                                          <div class="col-sm-4">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              {" "}
                                              User Name :
                                            </p>
                                          </div>
                                          <div class="col-sm-8">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {userDetails.name}
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div class="form-group">
                                                                      <div class="col-sm-4">
                                                                          <p style={{color:"green" , float:"right"}}> User Name  :</p>
                                                                      </div>
                                                                      <div class="col-sm-8">
                                                                          <p style={{color:"black" , float:"left"}}> kennedy</p>
                                                                      </div>
                                                                  </div> */}
                                        <div class="form-group">
                                          <div class="col-sm-4">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              {" "}
                                              Email Id :
                                            </p>
                                          </div>
                                          <div class="col-sm-8">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {userDetails.email_id}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="col-sm-4">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              {" "}
                                              Mobile No :
                                            </p>
                                          </div>
                                          <div class="col-sm-8">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {userDetails.mobile_no}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="col-sm-4">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              {" "}
                                              Address :
                                            </p>
                                          </div>
                                          <div class="col-sm-8">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {userDetails.address}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="col-sm-4">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              {" "}
                                              Age :
                                            </p>
                                          </div>
                                          <div class="col-sm-8">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {userDetails.age}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="col-sm-4">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              {" "}
                                              Telphone No :
                                            </p>
                                          </div>
                                          <div class="col-sm-8">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {userDetails.telphone}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="col-sm-4">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              {" "}
                                              Qualification :
                                            </p>
                                          </div>
                                          <div class="col-sm-8">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {userDetails.qualification}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <div class="col-sm-10">
                                      <h1
                                        style={{
                                          color: "black",
                                          align: "center"
                                        }}
                                      >
                                        {" "}
                                        Mapped Theme & Competency
                                      </h1>
                                      <div class="form-group">
                                        <div class="col-sm-4">
                                          <p
                                            style={{
                                              color: "green",
                                              float: "right"
                                            }}
                                          >
                                            {" "}
                                            Department :
                                          </p>
                                        </div>
                                        <div class="col-sm-8">
                                          <p
                                            style={{
                                              color: "black",
                                              float: "left"
                                            }}
                                          >
                                            {department}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="col-sm-4">
                                          <p
                                            style={{
                                              color: "green",
                                              float: "right"
                                            }}
                                          >
                                            {" "}
                                            JobRole :
                                          </p>
                                        </div>
                                        <div class="col-sm-8">
                                          <p
                                            style={{
                                              color: "black",
                                              float: "left"
                                            }}
                                          >
                                            {jobRole}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="col-sm-4">
                                          <p
                                            style={{
                                              color: "green",
                                              float: "right"
                                            }}
                                          >
                                            {" "}
                                            Worklevel :
                                          </p>
                                        </div>
                                        <div class="col-sm-8">
                                          <p
                                            style={{
                                              color: "black",
                                              float: "left"
                                            }}
                                          >
                                            {work}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="col-sm-4">
                                          <p
                                            style={{
                                              color: "green",
                                              float: "right"
                                            }}
                                          >
                                            {" "}
                                            Themes :
                                          </p>
                                        </div>
                                        <div class="col-sm-8">
                                          <p
                                            style={{
                                              color: "black",
                                              float: "left"
                                            }}
                                          >
                                            {L1}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div class="col-sm-4">
                                          <p
                                            style={{
                                              color: "green",
                                              float: "right"
                                            }}
                                          >
                                            {" "}
                                            Competencies :
                                          </p>
                                        </div>
                                        <div class="col-sm-8">
                                          <p
                                            style={{
                                              color: "black",
                                              float: "left"
                                            }}
                                          >
                                            {L2}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div id="">
                                    <div
                                      class="col-sm-12"
                                      style={{ color: "black" }}
                                    >
                                      <div class="form-group">
                                        <div
                                          class="col-sm-12"
                                          style={{ textAlign: "center" }}
                                        >
                                          <h2 style={{ color: "black" }}>
                                            Mapped Derailers{" "}
                                          </h2>
                                        </div>
                                        <div class="form-group">
                                          <div class="col-sm-6">
                                            <p
                                              style={{
                                                color: "green",
                                                float: "right"
                                              }}
                                            >
                                              Derailers:
                                            </p>
                                          </div>
                                          <div class="col-sm-6">
                                            <p
                                              style={{
                                                color: "black",
                                                float: "left"
                                              }}
                                            >
                                              {this.state.mapDerail}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div id="behComp" className="tab-pane fade in">
                              {userData && userDetails && (
                                <BehaviouralCompetencies
                                  id={this.state.userId}
                                  data={userData}
                                  userDetails={userDetails}
                                />
                              )}
                            </div>
                            <div id="techComp" className="tab-pane fade in">
                              <div>
                                {userData && userDetails && (
                                  <TechnicalCompetencies
                                    id={this.state.userId}
                                    data={userData}
                                    adminId={this.state.adminId}
                                    userDetails={userDetails}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              id="Competency-Report"
                              className="tab-pane fade in"
                            >
                              <div>
                                {userData && userDetails && (
                                  <ProfileCompReport
                                    // userId = {`/hr/ViewProfile?id=${this.state.userId}`}
                                    userid={this.state.userId}
                                  />
                                )}
                              </div>
                            </div>
                            <div
                              id="Assesment-Graph"
                              className="tab-pane fade in"
                            >
                              <div>
                                <button style={{marginLeft:30,marginTop:20}}
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    window.open(
                                      "/hr/ConsolidateReport" +
                                        "?" +
                                        "id=" +
                                        this.state.userId,
                                      "_blank"
                                    )
                                  }
                                >
                                 Consolidated Report
                                </button>
                                {userData && userDetails && (
                                  <ProfileChart userId={this.state.userId} />
                                )}
                              </div>
                            </div>
                            <div
                              id="Job-Description"
                              className="tab-pane fade in"
                            >
                              <div style={{margin:'15px 0 15px 30px'}} >
                                <LoginModal
                                  buttonTitle="Upload File"
                                  title="Add Extra jobDescription"
                                  id="fileextra"
                                  extraClass="btn btncolor"
                                  extraStyle={{
                                    backgroundColor: "#f44336",
                                    textAlign: "center"
                                  }}
                                  bodyText={
                                    <div  >
                                      <div className="row form-group">
                                        <div className="col-sm-4">
                                          <label htmlFor="exampleInputEmail1">
                                            Job Description
                                          </label>
                                        </div>
                                        <div className="col-sm-1" />
                                        <div className="col-sm-5">
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Job Description Text "
                                            onChange={this.jobText}
                                            value={jobText}
                                          />
                                        </div>
                                        <div className="col-sm-3" />
                                      </div>
                                      <div className="row form-group">
                                        <div className="col-sm-4">
                                          <label htmlFor="exampleInputEmail1">
                                            Description
                                          </label>
                                        </div>
                                        <div className="col-sm-1" />
                                        <div className="col-sm-5">
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Job Description Text "
                                            onChange={this.descriptionText}
                                            value={descriptionText}
                                          />
                                        </div>
                                        <div className="col-sm-3" />
                                      </div>
                                      <div className="row form-group">
                                        <div className="col-sm-4">
                                          <label htmlFor="exampleInputEmail1">
                                            File
                                          </label>
                                        </div>
                                        <div className="col-sm-1" />
                                        <div className="col-sm-5">
                                          <input
                                            type="file"
                                            className="custom-file-input"
                                            name="filename"
                                            onChange={e => this.selectImage(e)}
                                          />
                                        </div>
                                        <div className="col-sm-3" />
                                      </div>
                                      <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-3" />
                                        <div className="col-sm-5">
                                          <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={this.onSubmit}
                                          >
                                            Submit
                                          </button>
                                        </div>
                                        <div className="col-sm-2" />
                                      </div>
                                    </div>
                                  }
                                />
                                <div style={{marginBottom:20}}> </div> 
                                {this.state.descrip && (
                                  
                                  <Datatable 
                                    data={this.state.descrip}
                                    columnHeading={this.column}
                                  />
                                  
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      );
    }


  
  }
}

export default ViewProfile;
