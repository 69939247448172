import http from './httpMiddleWare';
import { ACCESS_POINT } from '../config';

const getYear = async () => {
  const result = await http.get(`${ACCESS_POINT}/mapping/getYear`);
  return result;
};

const mapData = async (id, columnName, yearId, jobId) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/mapData/${id}/${columnName}/${yearId}/${jobId}`
  );
  return result;
};

const mapEmployeeData = async data => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mapEmployeeData`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return result;
};

const getWorkLevelForTheme = async (adminId, themeId) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/workLevelForTheme/${adminId}/${themeId}`
  );
  return result;
};

const mapThemeWorkLevel = async (formData, adminId) => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevel/${adminId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return result;
};

const getMapThemeWorkLevel = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevel/${adminId}`
  );
  return result;
};

const updateMapThemeWorkLevel = async (fomrData, id) => {
  console.log(`${ACCESS_POINT}/mapping/mapThemeWorkLevel/${id}`);

  const result = await http.put(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevel/${id}`,
    fomrData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return result;
};

const deleteWorkLevelForTheme = async id => {
  const result = await http.delete(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevel/${id}`
  );
  return result;
};

const getDepartmentToFunctionData = async id => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getDepartmentToFunctionData/${id}`
  );
  return result;
};

const getCompetencyExtra = async (adminId, textType) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevelExtra/${adminId}/${textType}`
  );
  return result;
};

const getCompForWorkLevelTheme = async (
  adminId,
  behThemeId,
  workLevelId,
  jobRoleId
) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getCompForWorkLevelTheme/${adminId}/${behThemeId}/${workLevelId}/${jobRoleId}`
  );
  return result;
};

const deleteMapDepartmentToFunction = async id => {
  const result = await http.delete(
    `${ACCESS_POINT}/mapping/deleteMapDepartmentToFunction/${id}`
  );
  return result;
};

const createMapWorkLevelToComp = async (formData, adminId) => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevelToComp/${adminId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return result;
};

const getMapWorkLevelToComp = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevelToComp/${adminId}`
  );
  return result;
};

const getGroupCompanyForSelect = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getGroupCompanyForSelect/${adminId}`
  );
  return result;
};

const getDepartmentForSelect = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getDepartmentForSelect/${adminId}`
  );
  return result;
};

const updateMapWorkLevelToComp = async (formData, adminId) => {
  const result = await http.put(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevelToComp/${adminId}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return result;
};

const deleteMapWorkLevelToComp = async adminId => {
  const result = await http.delete(
    `${ACCESS_POINT}/mapping/mapThemeWorkLevelToComp/${adminId}`
  );
  return result;
};

const mapDepartmentToFunction = async data => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mapDepartmentToFunction`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
  return result;
};

const updateDepartmentToFunction = async (id, formData) => {
  const result = await http.put(
    `${ACCESS_POINT}/mapping/updateDepartmentToFunction/${id}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return result;
};

const getFunctiontoWorklevelData = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getFunctiontoWorklevelData/${adminId}`
  );
  return result;
};

const getMapDepartmentToFunctionData = async (adminId, departmentId) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getMapDepartmentToFunctionData/${adminId}/${departmentId}`
  );
  return result;
};

const getMapDepartmentToWorklevelData = async (
  adminId,
  departmentId,
  functionId
) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getMapDepartmentToWorklevelData/${adminId}/${departmentId}/${functionId}`
  );
  return result;
};

const mapFunctionToWorklevel = async formData => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mapFunctionToWorklevel`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return result;
};

const getThemeWorkLevelCompetencyData = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getThemeWorkLevelCompetencyData/${adminId}`
  );
  return result;
};

const getThemeMaster = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getThemeMaster/${adminId}`
  );
  return result;
};

const getMapthemeTocompetencyData = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getMapthemeTocompetencyData/${adminId}`
  );
  return result;
};

const getMapThemeCompetencyToWorklevelData = async (
  adminId,
  themeId,
  competencyId
) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getMapThemeCompetencyToWorklevelData/${adminId}/${themeId}/${competencyId}`
  );
  return result;
};

const mapCompetencyToWorklevel = async formData => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mapCompetencyToWorklevel`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return result;
};

const getCompetencyParamsData = async adminId => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getCompetencyParamsData/${adminId}`
  );
  return result;
};

const mapCompetencyParamsData = async formData => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mapCompetencyParamsData`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return result;
};

const getThemeListMM = async (
  jobRoleId,
  workLevelId,
  companyId,
  matrixType
) => {
  // console.log(`${jobRoleId} - ${workLevelId} - ${companyId}`);
  const result = await http.get(
    `${ACCESS_POINT}/mapping/mappedMatrix/getThemeList/${jobRoleId}/${workLevelId}/${companyId}/${matrixType}`
  );
  return result;
};

const getIndicatorsList = async (competencyId, companyId) => {
  const result = await http.get(
    `${ACCESS_POINT}/mapping/mappedMatrixIndic/getIndicators/${competencyId}/${companyId}`
  );
  return result;
};

const mappedMatrixAdmin = async formData => {
  const result = await http.post(
    `${ACCESS_POINT}/mapping/mappedMatrixAdmin`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  );
  return result;
};

const getAlreadySelectedIndicator = async (
  themeId,
  jobRoleId,
  workLevelId,
  competencyId,
  companyId,
  yearId
) => {
  yearId = yearId ? yearId : localStorage.getItem("yearId");
  const result = await http.get(
    `${ACCESS_POINT}/mapping/getAlreadySelectedIndicator/${themeId}/${jobRoleId}/${workLevelId}/${competencyId}/${companyId}/${yearId}`
  );
  return result;
};

export default {
  getYear,
  mapData,
  mapEmployeeData,
  getWorkLevelForTheme,
  mapThemeWorkLevel,
  getMapThemeWorkLevel,
  updateMapThemeWorkLevel,
  deleteWorkLevelForTheme,
  getDepartmentToFunctionData,
  getCompetencyExtra,
  getCompForWorkLevelTheme,
  deleteMapDepartmentToFunction,
  createMapWorkLevelToComp,
  mapDepartmentToFunction,
  getMapWorkLevelToComp,
  updateDepartmentToFunction,
  updateMapWorkLevelToComp,
  deleteMapWorkLevelToComp,
  getFunctiontoWorklevelData,
  getMapDepartmentToFunctionData,
  getMapDepartmentToWorklevelData,
  mapFunctionToWorklevel,
  getThemeWorkLevelCompetencyData,
  getThemeMaster,
  getMapthemeTocompetencyData,
  getMapThemeCompetencyToWorklevelData,
  mapCompetencyToWorklevel,
  getCompetencyParamsData,
  mapCompetencyParamsData,
  getThemeListMM,
  getIndicatorsList,
  mappedMatrixAdmin,
  getAlreadySelectedIndicator,
  getGroupCompanyForSelect,
  getDepartmentForSelect
};
