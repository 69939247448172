import React, { Component } from "react";
import { FormMiddleWare, SingleSelect,MultiSelect } from "../../components/Form";
import { Style } from "react-style-tag";
import Datatable from "../../components/Datatable/Datatable";
import HrMiddleWare from "../../middleWare/HrMiddleWare";
import MasterMiddleWare from "../../middleWare/MasterMiddleWare";
import MappingMiddleware from "../../middleWare/MappingMiddleware";
import Swal from 'sweetalert2';
import LoginModal from "../../components/Modal/Modal";
import AddTrainer from "../Admin/Master/addtrainer";
// import AddTrainerLocation from "./addTrainerLocation"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';




// import HrMiddleWare from "../../middleWare/HrMiddleWare";
// import Datatable from "../../components/Datatable/Datatable";
const tableName = `tbl_coursemapresource`;



class HrCoursemapwithTrainer extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            matrixType: null,
            alertVisible: false,
            updateData: false,
            showDatatable: true,
            Duration: [{ label: "1-Hour", value: 1 },
            { label: "2-Hour", value: 2 },
            { label: "3-Hour", value: 3 },
            { label: "4-Hour", value: 4 },
            ],
            resources: [{ label: "PDF", value: 1 },
            { label: "PPT", value: 2 },
            { label: "Videos", value: 3 },
            { label: "Webinars", value: 4 },
            ],
            selectedDate: null,
            mapOpen: false,
            latitude: null,
            longitude: null,
            address: null,
        }
        this.mapRef = React.createRef();
        this.map = null;
        this.marker = null;
    }

    async componentWillMount() {
        try {
            const adminId = await localStorage.getItem('adminId');
            const { data: classroomprgmOptions } = await MasterMiddleWare.selectBoxTrainingProgram(adminId);
            const { data: trainerNames } = await MasterMiddleWare.trainerNames(adminId);
            const { data: trainerLocationOpt } = await MasterMiddleWare.trainerLocation(adminId);
            const { data } = await MasterMiddleWare.Coursemapwithresources(adminId);
            console.log(data, "data");
            const uniqueIds = new Set();
            const uniqueArray = data.filter(obj => {
                if (!uniqueIds.has(obj.id)) {
                    uniqueIds.add(obj.id);
                    return true;
                }
                return false;
            });
            console.log(uniqueArray,"uniqueArray");

            await Promise.all(data.map((ival) => {
                this.state.Duration.map((jval) => {
                    if (ival.duration == jval.value) {
                        ival.duration = jval.label
                    }
                })
                this.state.resources.map((jval) => {
                    if (ival.resource == jval.value) {
                        ival.resourcelabel = jval.label
                    }
                })

            }))

            this.setState({
                classroomprgmOptions,
                trainerOptions: trainerNames,
                trainerLocationOpt,
                data: uniqueArray
            });

            this.setState({
                adminId,
            });

            // const script = document.createElement('script');
            // script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBb_slSJpAEKOZASQ9g_VpTBaq1v596f8A&libraries=places`;
            // script.async = true;
            // script.onload = this.initMap;
            // document.head.appendChild(script);

        } catch (error) {
            console.log(error);
        }

    }

    column = [
        {
            Header: 'Si No',
            accessor: 'si no',
            Cell: d => this.siNo(d)
        },
        {
            Header: 'Training Program',
            accessor: 'trainingProgram'
        },
        {
            Header: 'Trainer',
            accessor: 'trainerName'
        },
        // {
        //     Header: 'Location',
        //     accessor: 'trainerLocation'
        // },
        {
            Header: 'Location venue',
            accessor: 'trainerVenue'
        },
        // {
        //     Header: 'Strat date',
        //     accessor: 'startDate'
        // },
        // {
        //     Header: 'End date',
        //     accessor: 'endDate'
        // },
        {
            Header: 'QR Code',
            accessor: 'view',
            Cell: d => this.Qrcode(d)
        },
        {
            Header: 'Edit',
            accessor: 'edit',
            Cell: d => this.edit(d)
        },
        {
            Header: 'Delete',
            accessor: 'delete',
            Cell: d => this.delete(d, tableName)
        }
    ];

    Qrcode = value => {
        // console.log(value.original.trainingProgram, "value");
        return (
            <LoginModal
                buttonTitle="View"
                title="Training program QR code"
                id="Comments"
                extraClass="btn btncolor"
                onClick={() => this.viewQRCode(value)}
                extraStyle={{ backgroundColor: "#56528F" }}
                maxwidth="modal-lg"
                bodyText={
                    <>
                        <div className="row form-group " >
                            {/* <div className="col-sm-2" /> */}
                            {/* <div className="col-sm-8" style={{ textAlign: "center" }}> */}
                            <div className="row form-group " id="printableArea">
                                <div className="col-sm-4" />
                                <div className="col-sm-4" style={{ textAlign: "center" }}>
                                    <div style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}>Training program : { this.state.QRCodePrgmName}</div>
                                    <div className="qr-code-viewer" dangerouslySetInnerHTML={{ __html: this.state.QRdata }}>
                                    </div>

                                    {/* {this.state.qrcode && this.state.QRdata && <QRCode className="qrcode" value={this.state.QRdata} />} */}
                                </div>
                                <div className="col-sm-4" />
                            </div>
                            <br /> <br /> <br />
                            <div class="centered header-color">
                                <div className="row">
                                    <div className="col-sm-10 " />
                                    <div className="col-sm-2" >
                                        <button className="btn btncolor" onClick={this.printDiv}>Print PDF</button>
                                    </div>
                                </div>
                            </div>

                            {/* </div> */}
                            {/* <div className="col-sm-2" /> */}
                        </div>

                    </>
                }
            />
        );

    };

    viewQRCode = async (value) => {
        // console.log(value,"value");
        const result = await HrMiddleWare.createMasterforQR('tbl_nominateform', value.original);
        // console.log(result,"result");
        this.setState({
            qrcode: true,
            QRdata: result.data,
            QRCodePrgmName:value.original.trainingProgram

        });
        // console.log(result,"result");
    };


    printDiv = () => {
        const input = document.getElementById('printableArea');

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imageWidth = 400; // Set the image width to 400px
            const imageHeight = (imageWidth * canvas.height) / canvas.width;
            const x = (pdfWidth - imageWidth) / 2;
            const y = (pdfHeight - imageHeight) / 2;

            pdf.addImage(imgData, 'PNG', x, y, imageWidth, imageHeight);
            pdf.save('download.pdf');
        });

    };


    siNo = d => {
        return <label>{d.index + 1}</label>;
    };

    classroomProgramsSelect = selectedOption => {
        this.setState({
            classroomProgramsSelected: selectedOption,
            classroomProgramsId: selectedOption.value
        });
    };

    DurationSelect = selectedOption => {
        this.setState({
            DurationSelected: selectedOption,
            Duration: selectedOption.value
        });
    };

    resourcesSelect = selectedOption => {
        this.setState({
            resourcesSelected: selectedOption,
            resources: selectedOption.value
        });
    };

    onSubmit = async () => {
        const {data, adminId, Programcost, classroomProgramsId,classroomProgramsSelected, Duration, resources, TrainingVenue, trainerLocationId, trainerId, selectedDate, selectedstartdate, selectedenddate,address, latitude, longitude } = this.state;
       console.log(data,"data");
       
      
       
        if (this.validate(classroomProgramsId, 'errorclassroomPrograms', 'Please select classroom training program')) return true;
        // if (this.validate(Duration, 'errorDuration', 'Please select duration')) return true;
        // if (this.validate(resources, 'errorResources', 'Please select resourcess')) return true;
        if (this.validate(Programcost, 'errorProgramcost', 'Please enter program cost')) return true;
        if (this.validate(trainerId, 'errortrainer', 'Please select trainer')) return true;
        // if (this.validate(trainerLocationId, 'errortrainerlocation', 'Please select trainer location')) return true;
        if (this.validate(address, 'errorTrainingVenue', 'Please select trainer venue in map')) return true;
        // if (this.validate(Latitude, 'errorLatitude', 'Please enter latitude')) return true;
        // if (this.validate(longitude, 'errorLongitude', 'Please enter longitude')) return true;
        // if (this.validate(selectedDate, 'errordateandtime', 'Please select date and time')) return true;

        data.map((ival)=>{
            if (ival.startDate != selectedstartdate && ival.endDate != selectedenddate &&ival.trainingProgram !=classroomProgramsSelected.label ) {
                if (this.validate(address, 'errordate', 'The selected fromdate and endate is already taken this program')) ;
           setTimeout(() => {
            if (this.validate(address, 'errordate', '')) return true;
    
           }, 3000);
            }
           })
           
        // return false
        const formData = new FormData();
        formData.append('program', classroomProgramsId);
        formData.append('startDate', selectedstartdate);
        formData.append('endDate', selectedenddate);
        // formData.append('duration', Duration);
        // formData.append('resource', resources);
        formData.append('programcost', Programcost);
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);
        formData.append('trainer', trainerId);
        // formData.append('trainerLoc', trainerLocationId);
        formData.append('trainerVenue', address);
        // formData.append('DateTime', selectedDate);
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        formData.append('status', 'active');
        console.log([...formData], "formdata");
        // const previousData = [...this.state.data];
        // return false
        try {
            // console.log(tableName, "tableName");
            // return false
            const result = await MasterMiddleWare.createMaster(tableName, formData);
            console.log(result, "result");
            if (result) {
                Swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'TrainingProgram mapping has been saved',
                    showConfirmButton: false,
                    timer: 1500
                });
                this.getMaster(tableName);
                this.setState({
                    alertVisible: true,
                    matrixType: '',
                    competencySelected: '',
                    programsSelected: '',
                    yearSelected: ''
                });
                setTimeout(
                    window.location.reload(),
                    4000
                );
            }
        } catch (error) {
            // this.setState({
            //     data: previousData
            // });
            console.log(error);
        }
    };

    buttonEdit = async (value) => {
        // console.log(value, "value");
        // return false
        let { classroomprgmOptions, resources, trainerLocationOpt, trainerOptions} = this.state

        const index = value.index;
        const previousData = [...this.state.data];
        const getData = { ...previousData[index] };
        console.log(getData, "getData");

        let classroomProgramsSelected = classroomprgmOptions.find(x => x.value == getData.program)
        // let resourcesSelected = resources.find(x => x.value == getData.resource)
        // let trainerLocationSelected = trainerLocationOpt.find(x => x.value == getData.trainerLoc)
        let trainerSelected = trainerOptions.find(x => x.value == getData.trainer)
        // console.log(resourcesSelected, "resourcesSelected");


        this.setState({
            index,
            updateId: getData.id,
            classroomProgramsId: classroomProgramsSelected.value,
            // resources: resourcesSelected.value,
            trainerId: trainerSelected.value,
            // trainerLocationId: trainerLocationSelected.value,
            classroomProgramsSelected: classroomProgramsSelected,
            // resourcesSelected: resourcesSelected,
            trainerSelected: trainerSelected,
            // trainerLocationSelected: trainerLocationSelected,
            selectedstartdate: getData.startDate,
            selectedenddate: getData.endDate,
            Programcost: getData.programcost,
            latitude: getData.latitude,
            longitude: getData.longitude,
            address: getData.trainerVenue,
            alertVisible: false,
            updateData: true,
        });
    //     let latitude= parseFloat(getData.latitude)
    //     let longitude= parseFloat(getData.longitude)
    // if (latitude !== null && longitude !== null) {
    //   this.addMarker({ lat: latitude, lng: longitude });
    // }
        
        // this.getAddressFromCoordinates(latitude, longitude);
        
        // this.addMarker({ lat: latitude, lng: longitude });


        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    };

    onUpdate = async () => {
        // const { adminId, yearId, matrixType, compvalue, updateId, minScore, classroomProgramsId, elearnProgramsId } = this.state;
        const { adminId, Programcost, classroomProgramsId, resources, TrainingVenue, trainerLocationId, trainerId, selectedDate, selectedstartdate, selectedenddate,updateId, address, latitude, longitude } = this.state;

        if (this.validate(classroomProgramsId, 'errorclassroomPrograms', 'Please select classroom training program')) return true;
        // if (this.validate(resources, 'errorResources', 'Please select resourcess')) return true;
        if (this.validate(Programcost, 'errorProgramcost', 'Please select program cost')) return true;
        if (this.validate(trainerId, 'errortrainer', 'Please select trainer')) return true;
        // if (this.validate(trainerLocationId, 'errortrainerlocation', 'Please select trainer location')) return true;
        if (this.validate(address, 'errorTrainingVenue', 'Please select trainer venue')) return true;
        // if (this.validate(Latitude, 'errorLatitude', 'Please enter latitude')) return true;
        // if (this.validate(Longitude, 'errorLongitude', 'Please enter longitude')) return true;
        // if (this.validate(selectedDate, 'errordateandtime', 'Please select date and time')) return true;

        const formData = new FormData();
        formData.append('program', classroomProgramsId);
        formData.append('startDate', selectedstartdate);
        formData.append('endDate', selectedenddate);
        // formData.append('duration', Duration);
        // formData.append('resource', resources);
        formData.append('programcost', Programcost);
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);
        formData.append('trainer', trainerId);
        // formData.append('trainerLoc', trainerLocationId);
        formData.append('trainerVenue', address);
        // formData.append('DateTime', selectedDate);
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        formData.append('status', 'active');
        console.log([...formData], "formdata");

        // const previousData = [...this.state.data];
        // return false
        try {
            // console.log(tableName, "tableName");
            // return false
            const result = await MasterMiddleWare.updateMaster(tableName, updateId, formData);
            console.log(result, "result");
            if (result) {
                Swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'TrainingProgram mapping has been Updated',
                    showConfirmButton: false,
                    timer: 1500
                });
                this.getMaster(tableName);
                this.setState({
                    alertVisible: true,
                    matrixType: '',
                    competencySelected: '',
                    programsSelected: '',
                    yearSelected: ''
                });
                setTimeout(
                    window.location.reload(),
                    4000
                );
            }
        } catch (error) {
            // this.setState({
            //     data: previousData
            // });
            console.log(error);
        }
    };

    addNewTrainer = async () => {
        const formData = new FormData();
        // let TrainerArray = {};
        // let TrainerAddExtra = {};
        let TrainerName = this.state.trainer;
        // let trainerOptions = this.state.trainerOptions;
        // console.log(TrainerName,"TrainerName");

        if (!TrainerName) {
            this.setState({ errortrainer: "Enter Trainer Name" });
            return false;
        } else {
            this.setState({ errortrainer: "", btnDisable: true });
            formData.append('trainerName', TrainerName);
            formData.append('status', 'active');
            formData.append('customerId', this.state.adminId);
            // TrainerArray.trainerName = TrainerName.trim();
            // TrainerArray.status = "active";
            // TrainerArray.customerId = this.state.adminId;
            try {
                const result = await MasterMiddleWare.createMaster(
                    "tbl_traineradd",
                    formData
                );
                // console.log(result, "result");
                // return false
                if (result) {
                    // TrainerAddExtra.value = result.data.insertId;
                    // TrainerAddExtra.label = TrainerName;
                    // trainerOptions.splice(0, 0, TrainerAddExtra);
                    this.setState({
                        trainer: "",
                        btnDisable: false,
                        alertVisible: true,
                        // trainerOptions,
                    });
                    setTimeout(() => this.setState({ alertVisible: false }), 3000);
                    // this.componentDidMount()
                    this.componentWillMount();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    addNewTrainerLocation = async () => {
        const formData = new FormData();
        // let TrainerArray = {};
        // let TrainerLocationAddExtra = {};
        let TrainerLocation = this.state.trainerLocation;
        // let trainerLocationOptions = this.state.trainerLocationOptions;
        // console.log(TrainerName,"TrainerName");

        if (!TrainerLocation) {
            this.setState({ errortrainer: "Enter Trainer Location" });
            return false;
        } else {
            this.setState({ errortrainer: "", btnDisable: true });
            formData.append('trainerLocation', TrainerLocation);
            formData.append('status', 'active');
            formData.append('customerId', this.state.adminId);

            // TrainerArray.trainerName = TrainerLocation.trim();
            // TrainerArray.status = "active";
            // TrainerArray.customerId = this.state.adminId;
            try {
                const result = await MasterMiddleWare.createMaster(
                    "tbl_trainerLocation",
                    formData
                );
                // console.log(result, "result");
                // return false
                if (result) {
                    // TrainerLocationAddExtra.value = result.data.insertId;
                    // TrainerLocationAddExtra.label = TrainerLocation;
                    // trainerLocationOptions.splice(0, 0, TrainerLocationAddExtra);
                    this.setState({
                        trainerLocation: "",
                        btnDisable: false,
                        alertVisible: true,
                        // trainerLocationOptions,
                    });
                    setTimeout(() => this.setState({ alertVisible: false }), 3000);
                    // this.componentDidMount()
                    this.componentWillMount();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    trainerSelect = selectedOption => {

        this.setState({
            trainerSelected: selectedOption,
            trainerId: selectedOption.value
        });

    };

    // trainerLocationSelect = selectedOption => {

    //     this.setState({
    //         trainerLocationSelected: selectedOption,
    //         trainerLocationId: selectedOption.value
    //     });

    // };

    handleDateChange = date => {
        // let formattedDate = moment(date).format("MM-D-YYYY h:mm A");
        this.setState({
            selectedDate: date
        });
    };

    fromDateSelect = (e) => {
        this.setState({ selectedstartdate: e.target.value });
        // console.log(this.state.selectedstartdate);
    };

    endDateSelect = async (e) => {
        this.setState({ selectedenddate: e.target.value });
        // console.log(e.target.value);
    };

    handleOpenMap = () => {
        this.setState({ mapOpen: true });
        this.initMap();
    };

    handleCloseMap = () => {
        this.setState({ mapOpen: false });
        this.clearMap();
    };

    handleMapClick = (event) => {
        // console.log(event.latLng,"event latlan");
        const latitude = event.latLng.lat();
        const longitude = event.latLng.lng();

        this.setState({ latitude, longitude });

        this.getAddressFromCoordinates(latitude, longitude);
        this.addMarker(event.latLng);
    };

    getAddressFromCoordinates = async (latitude, longitude) => {
        console.log(latitude,"latitude",longitude,"longitude");
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );
            const data = await response.json();
            if (data.display_name) {
                this.setState({ address: data.display_name });
            } else {
                this.setState({ address: 'Address not found' });
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    initMap = () => {
        const { latitude, longitude } = this.state;
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBsbgdB0252ncJ0j50rCSFEZcB0EI9qZ4c&libraries=places`;
        window.document.body.appendChild(googleMapScript);

        googleMapScript.addEventListener('load', () => {
            this.map = new window.google.maps.Map(this.mapRef.current, {
                center: { lat: 11.127123, lng:78.656891},
                zoom: 6,
            });

            this.map.addListener('click', this.handleMapClick);
        });
    };

    addMarker = (position) => {
        // console.log(position,"position");
        if (this.marker) {
          this.marker.setMap(null);
        }
    
        this.marker = new window.google.maps.Marker({
          position: position,
          map: this.map,
        });
      };


    clearMap = () => {
        if (this.marker) {
            this.marker.setMap(null);
        }
        this.setState({ latitude: null, longitude: null, address: null });
    };



    render() {
        // let { userData, section1Data } = this.state
        const { updateData, showDatatable, data, DurationSelected, Duration, errorDuration, errorclassroomPrograms,
            classroomprgmOptions, classroomProgramsSelected,errordate, errorResources, resources, resourcesSelected, errorProgramcost, Programcost, errorTrainingVenue, TrainingVenue, trainerLocationOpt, trainerLocationSelected, errortrainerlocation, errortrainer, trainerOptions, trainerSelected, trainer, errorTrainer, btnDisable, alertVisible, errorTrainerLocation, errordateandtime, errorLatitude, errorLongitude, Latitude, Longitude, mapOpen, latitude, longitude, address } = this.state;
        // console.log(this.state.selectedDate,"selectedDate");
        return (
            <React.Fragment>
                <section id="" style={{padding:"10px 30px"}}>
                <section className="wrapper" id="wrapper" >
                    <div className="box box-info">
                        <div className="box-header with-border">
                            <h3 className="box-title">Course map with resources</h3>
                            <div className="box-tools pull-right">
                                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                                    <i className="fa fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="wizardCards">
                                <div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="trainingprogramvalue">Classroom training programs</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <SingleSelect handleChange={this.classroomProgramsSelect} options={classroomprgmOptions} selectedService={classroomProgramsSelected} />

                                        </div>
                                        <div className="col-sm-4" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errorclassroomPrograms}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">Date</div>
                                        <div className="col-sm-2">
                                            From Date
                                            <input
                                                type="date"
                                                id="myDate"
                                                className="form-control"
                                                onChange={this.fromDateSelect}
                                                value={this.state.selectedstartdate}
                                            />
                                            <span className="error-shows">
                                                {this.state.startdateerror}
                                            </span>
                                        </div>
                                        {/* <div className="col-sm-1"/> */}
                                        <div className="col-sm-2">
                                            To Date
                                            <input
                                                type="date"
                                                className="form-control"
                                                onChange={this.endDateSelect}
                                                value={this.state.selectedenddate}
                                            />
                                            <span className="error-shows">
                                                {this.state.endateerror}
                                            </span>
                                        </div>
                                        <div className="col-sm-2 error-shows"> </div>
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-4">
                                            <span className="errorMsg">{errordate}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group " />
                                    {/* <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="trainingprogramvalue">Duration</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <SingleSelect handleChange={this.DurationSelect} options={Duration} selectedService={DurationSelected} />

                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errorDuration}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div> */}
                                    {/* <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="trainingprogramvalue">Tr.resources</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <SingleSelect handleChange={this.resourcesSelect} options={resources} selectedService={resourcesSelected} />

                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errorResources}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div> */}
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="Programcost">Program cost</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <input type="number" className="form-control" name="Programcost" id="Programcost" placeholder="Please enter program cost" value={Programcost} onChange={this.handleUserInput} />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errorProgramcost}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="trainingprogramvalue">Trainer name</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <SingleSelect handleChange={this.trainerSelect} options={trainerOptions} selectedService={trainerSelected} />

                                        </div>
                                        {/* <div className="col-sm-3" /> */}
                                        <div className="col-sm-3">
                                            <LoginModal
                                                buttonTitle="Add Trainer"
                                                title="Add Trainer"
                                                id="Trainer"
                                                extraClass="btn-width"
                                                onClick={this.reset}
                                                bodyText={
                                                    <AddTrainer
                                                        trainer={trainer}
                                                        handleChange={this.handleChange}
                                                        addNewTrainer={this.addNewTrainer}
                                                        error={errorTrainer}
                                                        alert={alertVisible}
                                                        dismiss={this.onDismiss}
                                                        disableValue={btnDisable}
                                                        alertMsg="New Trainer Added"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-4">
                                            <span className="errorMsg">{errortrainer}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                   {/*  <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="trainingprogramvalue">Trainer location</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <SingleSelect handleChange={this.trainerLocationSelect} options={trainerLocationOpt} selectedService={trainerLocationSelected} />

                                        </div>
                                       
                                        <div className="col-sm-3">
                                            <LoginModal
                                                buttonTitle="Add Trainer Location"
                                                title="Add Trainer Location"
                                                id="TrainerLocation"
                                                extraClass="btn-width"
                                                onClick={this.reset}
                                                bodyText={
                                                    <AddTrainerLocation
                                                        trainerLocation={this.state.trainerLocation}
                                                        handleChange={this.handleChange}
                                                        addNewTrainerLocation={this.addNewTrainerLocation}
                                                        error={errorTrainerLocation}
                                                        alert={alertVisible}
                                                        dismiss={this.onDismiss}
                                                        disableValue={btnDisable}
                                                        alertMsg="New Trainer Location Added"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-4">
                                            <span className="errorMsg">{errortrainerlocation}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>*/}
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="TrainingVenue">Training Venue</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <input type="textarea" className="form-control" name="TrainingVenue" id="TrainingVenue" placeholder="Please select Training Venue in map" value={address}  disabled/>
                                        </div>
                                        <div className="col-sm-3" >
                                            <button onClick={this.handleOpenMap}>Open Map</button>
                                        </div>
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errorTrainingVenue}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    {/* <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="Latitude">Latitude</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" name="Latitude" id="Latitude" placeholder="Please enter Latitude" value={Latitude} onChange={this.handleUserInput} />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errorLatitude}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="Longitude">Longitude</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <input type="text" className="form-control" name="Longitude" id="Longitude" placeholder="Please enter Longitude" value={Longitude} onChange={this.handleUserInput} />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errorLongitude}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div> */}
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <div>
                                                {/* <button onClick={this.handleOpenMap}>Open Map</button> */}
                                                {mapOpen && (
                                                    <div>
                                                        <button onClick={this.handleCloseMap}>Close Map</button>
                                                        <div ref={this.mapRef} style={{ width: '100%', height: '300px' }}></div>
                                                        <div>
                                                            Latitude: {latitude}, Longitude: {longitude}
                                                        </div>
                                                        <div>Address: {address}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>

                                    {/* <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label htmlFor="TrainingVenue">Date and Time </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <DatePicker
                                                selected={this.state.selectedDate}
                                                onChange={this.handleDateChange}
                                                showTimeSelect
                                                dateFormat="MM-d-yyyy / h:mm aa"
                                                placeholderText=" MM - dd - yyyy              🗓️"
                                            />
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="row form-group ">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-5">
                                            <span className="errorMsg">{errordateandtime}</span>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div> */}

                                    <div className="row form-group ">
                                        <div className="col-sm-4" />
                                        <div className="col-sm-4">
                                            {!updateData ? (
                                                <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                                                    Submit
                                                </button>
                                            ) : (
                                                <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                                                    Update
                                                </button>
                                            )}
                                        </div>
                                        <div className="col-sm-4" />
                                    </div>
                                </div>

                            </div>

                            {/* {data && data.length && showDatatable ?
                                <div className="row form-group ">
                                    <div className="col-sm-10" />
                                    <div className="col-sm-2">
                                        <CSVLink
                                            headers={excelHead}
                                            data={data}
                                            filename={"WorkLevel.xls"}
                                            className="btn btncolor"
                                            target="_blank"
                                        >
                                            Generate Excel
                                        </CSVLink>
                                    </div>
                                </div>
                                :
                                <></>} */}
                            {data && showDatatable && <Datatable data={data} columnHeading={this.column} />}
                        </div>
                    </div>
                    </section>
                </section>
                <Style>
                    {`/*
      Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
      Template Version: 1.0
      Author: Carlos Alvarez
      Website: http://blacktie.co
      Premium: http://www.gridgum.com
      */
      
      /* Import fonts */
      @import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);
      
      /* BASIC THEME CONFIGURATION */
      .filepond{
        height: 82px;
        background-color: #f1f1f1;
        border-radius: 20px;
        width: 300px;
      }
      
      .filepond--credits{
        color:#f7f7f7;
      }
      
      .filepond--root :not(text) {
        font-size: 13px;
      }
      
      .multi:hover{
        color: limegreen;
      }
      
      .close {
        float: right;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000 !important;
        text-shadow: 0 1px 0 #1b1b1b;
        filter: alpha(opacity = 20);
        opacity: .2;
    }

    .modal-title {
        margin: 0;
        line-height: 1.42857143;
        color: white;
    }
    #main-content {
        margin-left: 50px !important;
        margin-top: 50px
    }
    .wrapper{
        margin-top:0px!important;
      }
     
    @media only screen and (max-width: 600px) {
        .wrapper {
          margin-top: 60px !important; 
        }
      }
      
      `}

                </Style >
            </React.Fragment>
        );

    }
}

export default HrCoursemapwithTrainer;