import React from "react";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import "./login.css";
// import logo from "../../image/Murugappa_Group_Logo.svg.png";
import logo from "../../image/difuzaWebLogo.png";
import competency from "../../image/competencyy.jpg"
import LoginMiddleWare from "../../middleWare/LoginMiddleWare";
import { SingleSelect } from "../../components/Form";
import { ACCESS_POINT } from "../../config";
import { FaUserAlt } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
class Login extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      password: null,
      errorUserName: null,
      errorPassword: null,
      passwordView: 1,
      userType: [
        { label: "SuperAdmin", value: 1 },
        { label: "Admin", value: 2 },
        { label: "Employee", value: 5 },
        { label: "HR", value: 6 }
      ],
      userTypemobile: [
        { label: "Employee", value: 5 },
        { label: "HR", value: 6 }
      ],
      visibility: false,
      isMobile: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.handleWindowSizeChange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    const isMobile = window.innerWidth <= 768;
    this.setState({ isMobile });
  };

  checkUser = async () => {
    const { selectedUserType, userName, password } = this.state;
    if (this.validate(selectedUserType, "errorUserType", "Select User Type"))
      return false;
    if (this.validate(userName, "errorUserName", "Please Enter UserName"))
      return true;
    if (this.validate(password, "errorPassword", "Please Enter Password"))
      return true;
    const formData = new FormData();
    formData.append(
      "userType1",
      selectedUserType.value === 2 ? 1 : selectedUserType.value
    );
    formData.append("userType", selectedUserType.value);
    formData.append("userName", userName);
    formData.append("password", password);

    console.log([...formData]);
    try {
      const { data } = await LoginMiddleWare.checkUser("tbl_user", formData);
      console.log(data.length);
      if (data.length > 0) {
        localStorage.setItem("userId", data[0].id);
        localStorage.setItem("name", data[0].name);
        localStorage.setItem("userName", data[0].user_name);
        localStorage.setItem("userTypeId", data[0].userTypeId);
        localStorage.setItem("groupcompId", data[0].groupcomp);
        if (selectedUserType.value === 1) {
          localStorage.setItem("adminId", data[0].adminId);
          window.location.href = "/admin/Master/Customer";
          // this.props.history.push('/admin/Master/WorkLevel');
        } else if (selectedUserType.value === 2) {
          localStorage.setItem("adminId", data[0].adminId);
          window.location.href = "/admin/Master/GroupCompany";
          // this.props.history.push('/admin/Master/WorkLevel');
        } else if (selectedUserType.value === 6) {
          localStorage.setItem("adminId", data[0].adminId);
          window.location.href = "hr/EmployeeList";
          // this.props.history.push('/hr');
        } else if (selectedUserType.value === 5) {
          localStorage.setItem("adminId", data[0].adminId);
          window.location.href = "/user/dashboard";
          // this.props.history.push('/user');
        } else {
          window.location.href = "/admin/Master/WorkLevel";
          // this.props.history.push('/admin/Master/WorkLevel');
        }
      } else {
        this.setState({
          errorPassword: "UserName or Password Is Incorrect"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  viewPassword = () => {
    const { passwordView } = this.state;
    const passcode = document.querySelector("input[name='password']");
    if (passwordView) {
      passcode.setAttribute("type", "text");
      this.setState({ passwordView: 0 });
    } else {
      passcode.setAttribute("type", "password");
      this.setState({ passwordView: 1 });
    }
  };

  handleKeyDown = e => {
    if (e.key === "Enter") {
      this.checkUser();
    }
  };

  selectUserType = e => {
    this.setState({ selectedUserType: e });
    // this.checkUser();
  };

  showpassword = e => {
    console.log('et')
  }

  render() {
    const {
      userName,
      password,
      errorUserType,
      errorUserName,
      errorPassword,
      userType,
      visibility,
      isMobile,
      userTypemobile,
      passwordView
    } = this.state;
    // competencyFramework/Images/difuzaWebLogo_1592744230854.png
    return (
      // <div className="card-view">
      //   <div class="card card-fix">
      //     <div class="card-body">
      //       <div className="row">
      //         <br />
      //         <center>
      //           {/* <img
      //             src={logo}
      //             className="logo-fix mt-center-fix"
      //             alt="novalid"
      //           /> */}
      //           <img
      //             src={logo}
      //             // src={
      //             //   ACCESS_POINT +
      //             //   "/getpro/getprofile?fileurl=" +
      //             //   "competencyFramework/Images/difuzaWebLogo_1592744230854.png"
      //             // }
      //             className="mt-center-fix"
      //             style={{
      //               width: "200px",
      //               height: "71px",
      //               borderRadius: "6px"
      //             }}
      //             alt="novalid"
      //           />
      //           {/* <h1
      //             style={{
      //               color: "black",
      //               fontFamily: "prototyperegular",
      //               fontSize: 60
      //             }}
      //           >
      //             difuza
      //           </h1> */}
      //         </center>
      //         {/* <center className="mt-center-fix2">
      //           <div style={{ fontWeight: 500 }}>Sign in to Murugappa</div>
      //         </center> */}
      //       </div>
      //       <br />
      //       <div className="row form-group">
      //         <div className="col-sm-2" />
      //         <div className="col-sm-8">
      //           <SingleSelect
      //             options={isMobile?userTypemobile:userType}
      //             handleChange={this.selectUserType}
      //             selectedService={this.state.selectedUserType}
      //           />
      //           <span className="error-show">{errorUserType}</span>
      //         </div>
      //         <div className="col-sm-2" />
      //       </div>

      //       <div className="row form-group">
      //         <div class="col-sm-2" />
      //         <div class="col-sm-8">
      //           <div class="input-group" style={{ zIndex: 0 }}>
      //             <span
      //               class="input-group-addon"
      //               style={{ width: "auto", padding: "4px 12px" }}
      //             >
      //               <a style={{ color: "#555555" }}>
      //                 <i class="fa fa-user" />
      //               </a>
      //             </span>
      //             <input
      //               className="form-control"
      //               type="text"
      //               name="userName"
      //               placeholder="username"
      //               value={userName}
      //               onChange={this.handleUserInput}
      //               onKeyDown={this.handleKeyDown}
      //             />
      //           </div>
      //           <div class="col-sm-2" />
      //           <span className="error-view">{errorUserName}</span>
      //         </div>
      //       </div>
      //       <div className="row form-group">
      //         <div class="col-sm-2" />
      //         <div class="col-sm-8">
      //           <div class="input-group" style={{ zIndex: 0 }}>
      //             <span
      //               class="input-group-addon"
      //               style={{ width: "auto", padding: "4px 12px", cursor: 'pointer' }}
      //               onClick={() => this.setState({ visibility: !visibility })}
      //             >

      //               <i class={visibility ? "fa fa-eye" : "fa fa-eye-slash"} />

      //             </span>
      //             <input
      //               className="form-control"
      //               type={visibility ? "text" : "password"}
      //               id="password"
      //               name="password"
      //               placeholder="password"
      //               value={password}
      //               onChange={this.handleUserInput}
      //               onKeyDown={this.handleKeyDown}
      //             />
      //           </div>
      //           <div class="col-sm-2" />
      //           <span className="error-view">{errorPassword}</span>
      //         </div>
      //       </div>
      //       <div className="form-group">
      //         <div className="col-sm-4" />
      //         <div className="col-sm-4">
      //           <button
      //             type="button"
      //             className="btn btn-center btn-info"
      //             onClick={this.checkUser}
      //           >
      //             Login
      //           </button>
      //         </div>
      //         <div className="col-sm-4" />
      //       </div>
      //     </div>
      //   </div>
      // </div>
      // <video autoPlay loop muted src="https://videos.pexels.com/video-files/9737955/9737955-uhd_2560_1440_24fps.mp4" className="background-video">
      //     </video>
      <section id="sec-1">
        <div className="video-background">
          <video autoPlay loop muted src="https://videos.pexels.com/video-files/9737955/9737955-uhd_2560_1440_24fps.mp4" className="background-video">
          </video>
          <div className="login-content">
            <div className="login-main-content">
              <div className="login-head text-center">
                {/* <h2>difuza</h2> */}
                <img src={logo}/>
              </div>
              <div className="">
                <div className="px-4 selectBox">
                  <SingleSelect
                    options={isMobile ? userTypemobile : userType}
                    handleChange={this.selectUserType}
                    selectedService={this.state.selectedUserType}
                  />
                  <span className="selectbox-error-show">{errorUserType}</span>
                </div>
                <div className="login-inputs">
                  <input type="text" className="py-1" placeholder="Enter your username" name="userName" onChange={this.handleUserInput} value={userName} />
                  <span className="input-error">{errorUserName}</span>
                </div>
                <div className="login-inputs">
                  <input type="password" className="py-1" placeholder="Enter your password" name="password" onChange={this.handleUserInput} value={password} />
                  <span className="input-error">{errorPassword}</span>
                </div>
                <div className="login-btn text-center">
                  <button onClick={this.checkUser}>Login</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section >

    );
  }
}

export default Login;
