import React from 'react';
import MasterMiddleWare from '../../../middleWare/MasterMiddleWare';
import { Datatable } from '../../../components/Datatable';
import { FormMiddleWare } from '../../../components/Form';
import Swal from 'sweetalert2';
import { FilePond } from 'react-filepond';
import { Style } from "react-style-tag";
import { CSVLink } from "react-csv";
import WizardPrograss from './wizardPrograssbar';
import WizardNextPreviousButton from './wizardNextPreviousButton';


const tableName = `tbl_function`;
class Function extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      functionname: null,
      errorfunctionname: null,
      alertVisible: false,
      updateData: false,
      index: null,
      excelHead: [
        { label: "SI.NO", key: "id" },
        { label: "Function", key: "functionname" }
      ],
      excelHeadmultidata: [
        // { label: "SI.NO", key: "id" },
        { label: "functionname", key: "Function" },
      ],
      addUserColumn: [
        { label: "Function", value: 1, type: "text" },
      ],
      statusArray: [],
      multyIndicatorsAdd: true,
      userAddRow: "",
      RowCount: "",
      showDatatable: true,
      submitDataAlert: false,

    };
  }

  async componentWillMount() {
    this.getMaster(tableName);
  }
  column = [
    {
      Header: 'Si No',
      accessor: 'si no',
      Cell: d => this.siNo(d)
    },
    {
      Header: 'Function',
      accessor: 'functionname'
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d, tableName)
    }
  ];

  onSubmit = async () => {
    const { functionname, adminId } = this.state;
    if (this.validate(functionname, 'errorfunctionname', 'Please Enter function')) return true;
    const formData = new FormData();
    formData.append('functionname', functionname);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.createMaster(tableName, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Function has been saved',
          showConfirmButton: false,
          timer: 1500
        });
        this.getMaster(tableName);
        this.setState({
          alertVisible: true,
          functionname: ''
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  buttonEdit = value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    this.setState({
      index,
      functionname: getData.functionname,
      errorfunctionname: '',
      alertVisible: false,
      updateData: true
    });
  };

  onUpdate = async () => {
    let index = this.state.index;
    const { functionname } = this.state;
    if (this.validate(functionname, 'errorfunctionname', 'Please Enter function')) return true;
    const formData = new FormData();
    formData.append('functionname', functionname);

    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const id = getData.id;
    getData.functionname = functionname;

    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, getData); // update the user value in the list at particular index
    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param formData : json list value for update
       */
      const result = await MasterMiddleWare.updateMaster(tableName, id, formData);
      if (result) {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Function has been updated',
          showConfirmButton: false,
          timer: 1500
        });
        this.setState({
          data,
          alertVisible: true,
          functionname: '',
          updateData: false
        });
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  submitcsv = async (e) => {
    console.log(e, "e");
    await this.setState({ btnDisablecsv: true })
    // const groupcompId = await localStorage.getItem('groupcompId');
    const { textType, competencyId, themeId, indicators, Description, adminId, files } = this.state;
    // console.log(files,"files");
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append('adminId', adminId);
    formData.append('customerid', adminId);
    // formData.append('groupcompId', groupcompId)
    console.log(formData, "formData");
    const previousData = [...this.state.data];
    try {
      const result = await MasterMiddleWare.csvUpload(tableName, formData);
      console.log(result, "result");
      // const result = await MasterMiddleWare.createMaster(tableName, formData);
      let Timing = 3000
      if (result.data.Message == "success") {
        if (result.data.csvHeaderErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvHeaderErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else if (result.data.csvDataErrorMessage) {
          Swal.fire({
            position: 'center',
            type: 'warning',
            title: `${result.data.csvDataErrorMessage}`,
            showConfirmButton: false,
            timer: 4000
          });
          Timing = 5000
        } else {

          Swal.fire({
            position: 'center',
            type: 'success',
            title: 'Function has been saved',
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.getMaster(tableName);
        this.setState({
          alertVisible: true,
          functionname: '',
          btnDisablecsv: false,
          files: ''
        });
        setTimeout(() => {
          window.location.reload()
        }, Timing);
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  }

  handleFileUpload = (fileItems) => {
    this.setState({
      files: fileItems.map((fileItem) => fileItem.file),
    });
  };

  singlemultiselect = (e, val) => {
    this.setState({
      multi: val
    });
  };

  handleSelectChange = async (value, rowIndex, columnLabel) => {
    let { adminId } = this.state
    // console.log(value, "value");
    // console.log(rowIndex, "rowIndex");
    // console.log(columnLabel, "columnLabel");
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: columnLabel === "Function" ? value.label : value.value } : row
      )
    }));
  };

  addRow = (value) => {
    console.log(value, "values");
    let { userAddRow, statusArray } = this.state
    // this.setState({ statusArray: [] })
    let addIndex = value ? statusArray.length :0

    const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({
      Function: "",
      index:value ? addIndex:index

    }));
    this.setState(prevState => ({
      statusArray: [...prevState.statusArray, ...newItems]
    }));

    console.log(statusArray, "statusArray");
  }

  handleInputChange = (e, rowIndex, columnLabel) => {
    const { value } = e.target;
    this.setState(prevState => ({
      statusArray: prevState.statusArray.map((row, index) =>
        index === rowIndex ? { ...row, [columnLabel]: value } : row
      )
    }));
  };


  addMultySubmit = async () => {
    let { statusArray, adminId } = this.state
    return new Promise((resolve, reject) => {
      this.setState({  submitDataAlert: true });
      setTimeout(() => {
        this.setState({ submitDataAlert: false });
        resolve();
      }, 3000);
    })
      .then(() => {
        const formData = new FormData();
        formData.append("statusArray", JSON.stringify(statusArray));
        // formData.append('groupcompId', groupcompId)
        formData.append('adminId', adminId);
        formData.append('customerid', adminId);
        const previousData = [...this.state.data];
        return MasterMiddleWare.insertMuldidata(tableName, formData);
      })
      .then(result => {
        if (result.data.Message == "success") {
          if (result.data.fillDataErrorMsg) {
            const maxLength = 29;
            const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
            console.log(wrappedErrorMsg, "wrappedErrorMsg");
            Swal.fire({
              position: 'center',
              type: 'warning',
              title: wrappedErrorMsg,
              showConfirmButton: false,
              timer: 6000
            });
            // Swal.fire({
            //   position: 'center',
            //   type: 'warning',
            //   title: `${result.data.fillDataErrorMsg}`,
            //   showConfirmButton: false,
            //   timer: 2000

            // });
          } else {
            Swal.fire({
              position: 'center',
              type: 'success',
              title: 'Function has been saved',
              showConfirmButton: false,
              timer: 1500
            });
            this.getMaster(tableName);
            this.setState({
              alertVisible: true,
              functionname: '',
              btnDisablecsv: false,
              statusArray: "",
              userAddRow: ""
            });
            setTimeout(() => {
              window.location.reload()
            }, 3000);
          }

        }
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  render() {
    const { data, functionname, errorfunctionname, updateData, excelHead,
      addUserColumn,
      userAddRow,
      RowCount,
      statusArray,
      showDatatable } = this.state;
    return (
      <React.Fragment>
        <section className="content">
          <div className="box box-info">
            <div className="box-header with-border">
              <h3 className="box-title">Function</h3>
              <WizardPrograss step={7} />
              <div className="box-tools pull-right">
                <button className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="box-body">
              {this.state.multyIndicatorsAdd ?
                <div className="wizardCards">

                  <div className="row form-group ">
                    <div className="col-sm-4" />
                    <div className="col-sm-3" onClick={(e) => this.singlemultiselect(e, 1)} >
                      <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency">Add single function </label>
                    </div>
                    {/* <div className="col-sm-1" /> */}
                    <div className="col-sm-3" onClick={(e) => this.singlemultiselect(e, 2)}>
                      <label className='multi' style={{ fontSize: '16px' }} htmlFor="competency"> Add multiple function </label>
                    </div>
                    <div className="col-sm-2" />
                  </div>
                  {
                    this.state.multi == 2 ?
                      <div className="card-body">
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Upload Files:
                            </label>
                          </div>
                          <div className="col-sm-5 filepond">
                            <FilePond
                              // type="file"
                              files={this.state.files}
                              // className="custom-file-input"
                              id="customFile"
                              // accept='.csv'
                              acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                              // onChange={this.fileupload}
                              // files={this.state.Testimonial_image}
                              onupdatefiles={this.handleFileUpload}
                              allowReorder={true}
                              allowMultiple={false}
                              width={10}
                              maxFileSize={'300KB'}
                              // imageValidateSizeMaxWidth={550}
                              // imageValidateSizeMaxHeight={355}
                              imagePreviewMinHeight="150"
                              imagePreviewMaxHeight="150"
                              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                            />
                            {/* <label
                      className="custom-file-label"
                      htmlFor="customFile"
                    >
                      {this.state.csv ? this.state.csv.name : 'Choose File'}
                    </label> */}
                          </div>
                        </div>
                        <br />
                        <div className="row-form-group">
                          <div className="col-sm-4" />
                          {/* <div className="col-sm-2" /> */}
                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => this.submitcsv(e)}
                              disabled={this.state.btnDisablecsv ? true : false}
                            >
                              {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                              {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                            </button>
                          </div>
                        </div>
                        <br />
                        {/* <div className="row form-group">
                          <div className="col-md-2">
                            <a href="../../files/functionSample.csv" download="functionSample.csv">
                              download sample.csv
                            </a>
                          </div>
                          <div className="col-md-3" />
                        </div> */}
                        <div className="row form-group">
                          <div className="col-md-2">
                            <button className='btn btn-primary' style={{ marginTop: "-15px" }} onClick={() => this.setState({ multyIndicatorsAdd: false, showDatatable: false })}>Create function CSV file</button>
                            {/* <button onClick={this.downloadSampleCsv}>Download CSV</button> */}
                          </div>
                          <div className="col-md-3" />
                        </div>
                      </div>
                      :
                      <div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="functionname">Function</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="text" className="form-control" name="functionname" id="functionname" placeholder="Please enter Function" value={functionname} onChange={this.handleUserInput} />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group ">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <span className="errorMsg">{errorfunctionname}</span>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group ">
                          <div className="col-sm-4" />
                          <div className="col-sm-4">
                            {!updateData ? (
                              <button type="button" className="btn btn-success" onClick={this.onSubmit}>
                                Submit
                              </button>
                            ) : (
                              <button type="button" className="btn btn-success" onClick={this.onUpdate}>
                                Update
                              </button>
                            )}
                          </div>
                          <div className="col-sm-4" />
                        </div>
                        <WizardNextPreviousButton step={7} nextPage={"Master/Competency"} previousPage={"Master/Themes"} />
                      </div>
                  }
                </div>
                :

                <>
                  <button className='btn btn-primary' onClick={() => this.setState({ multyIndicatorsAdd: true, showDatatable: true })}>Back</button>
                  <br></br>

                  <>
                    <div className="row form-group ">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="indicators">How many functions add ?</label>
                      </div>
                      <div className="col-sm-5">
                        <input type="number" className="form-control" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                      </div>
                      <div className="col-sm-3" >
                        <button className='btn btn-primary' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                        {/* <button className='btn btn-info' onClick={()=>this.setState({statusArray:[]})}>Clear</button> */}
                      </div>
                      {/* {statusArray && statusArray.length ?
                        <div className="row form-group ">
                          <div className="col-sm-10" />
                          <div className="col-sm-2">
                            <CSVLink
                              headers={this.state.excelHeadmultidata}
                              data={statusArray}
                              filename={"function.xls"}
                              className="btn btncolor"
                              target="_blank"
                            >
                              Generate Excel
                            </CSVLink>
                          </div>
                        </div>
                        :
                        <></>} */}
                    </div>


                    <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No </th>

                            {addUserColumn.map((index) => (
                              <th key={index}> {index.label}</th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                            // console.log(ival,"type",i,"i")

                            <tr>
                              <td style={{ textAlign: "center" }} >{i + 1}</td>
                              {addUserColumn.map((jval, index) => (
                                <>
                                  <td style={{ textAlign: "center" }}  >{jval && jval.type === "text" ? <>
                                    <textarea
                                      style={{ height: "50px", width: "280px" }}
                                      // type="text"
                                      value={ival[jval.label]}
                                      onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                    /></> :
                                    <>
                                    </>}
                                  </td>
                                </>
                              ))}
                            </tr>
                          ))}

                        </tbody>
                      </table>
                      {this.state.submitDataAlert ?
                        <div className='loader1'></div>
                        :
                        <>
                          {statusArray && statusArray.length > 0 &&

                            <div className="row form-group ">
                              <div className="col-sm-1" >
                                <button className='btn btn-primary' onClick={() => this.addRow(1)}>Add one</button>
                              </div>
                              <div className="col-sm-1">
                                <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                                  Submit
                                </button>
                              </div>
                              <div className="col-sm-1">
                                <CSVLink
                                  headers={this.state.excelHeadmultidata}
                                  data={statusArray}
                                  filename={"function.xls"}
                                  className="btn btncolor"
                                  target="_blank"
                                >
                                  Generate Excel
                                </CSVLink>
                              </div>

                            </div>
                          }
                        </>
                      }
                    </div>
                    {/* {statusArray && statusArray.length > 0 &&
                      <div className="row form-group " style={{ marginTop: "20px" }}>
                        <div className="col-sm-4" />
                        <div className="col-sm-4">
                          <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                            Submit
                          </button>

                        </div>
                        <div className="col-sm-4" />
                      </div>
                    } */}

                  </>

                </>

              }

              {data && data.length && showDatatable ?
                <div className="row form-group ">
                  <div className="col-sm-10" />
                  <div className="col-sm-2">
                    <CSVLink
                      headers={excelHead}
                      data={data}
                      filename={"Function.xls"}
                      className="btn btncolor"
                      target="_blank"
                    >
                      Generate Excel
                    </CSVLink>
                  </div>
                </div>
                :
                <></>}
              {data && showDatatable && <Datatable data={data} columnHeading={this.column} />}

            </div>
          </div>
        </section>
        <Style>
          {`/*
Template Name: DASHGUM FREE - Bootstrap 3.2 Admin Theme
Template Version: 1.0
Author: Carlos Alvarez
Website: http://blacktie.co
Premium: http://www.gridgum.com
*/

/* Import fonts */
@import url(http://fonts.googleapis.com/css?family=Ruda:400, 700, 900);

/* BASIC THEME CONFIGURATION */
.filepond{
  height: 82px;
  background-color: #f1f1f1;
  border-radius: 20px;
  width: 300px;
}

.filepond--credits{
  color:#f7f7f7;
}

.filepond--root :not(text) {
  font-size: 13px;
}

.multi:hover{
  color: limegreen;
}


`}

        </Style >
      </React.Fragment>
    );
  }
}

export default Function;
