import React, { Component } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ExamplePDF from '../PDF/ExamplePDF';
import TableExample from '../PDF/TableExample';
import HrMiddleWare from '../../middleWare/HrMiddleWare';

class Hr extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.getMaster();
  }

  getMaster = async () => {
    try {
      const adminId = await localStorage.getItem('adminId');
      const { data } = await HrMiddleWare.employeeList(adminId);
      if (data.length > 0) {
        const header = [
          'Name',
          'Mobile',
          'Email Id',
          'Worklevel',
          'Jobrole',
          'Department'
        ];

        this.setState({
          header,
          data,
          adminId
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { header, data } = this.state;
    return (
      <React.Fragment>
        <section id="main-content">
          <section className="wrapper">
            <div className="container">
              <div className="form-group">
                <div className="col-sm-12 padding-10 bg-white">
                  <h2>Hr</h2>
                  <h3>Sample Pdf download</h3>
                  <PDFDownloadLink
                    style={{ color: 'white' }}
                    document={<ExamplePDF />}
                    fileName="sample.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Download Pdf'
                    }
                    <button className="btn btn-success">Download Pdf</button>
                  </PDFDownloadLink>

                  <h3>Sample Table Pdf download</h3>
                  <PDFDownloadLink
                    style={{ color: 'white' }}
                    document={<TableExample header={header} data={data} />}
                    fileName="sample.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Download Pdf'
                    }
                    <button className="btn btn-success">
                      Download Table Pdf
                    </button>
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </section>
        </section>
      </React.Fragment>
    );
  }
}

export default Hr;
